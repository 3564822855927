<div
    #asd
    fxLayout="row"
    style="background-size: 160vh; background-position:center;  width: 100vw; height: 100vh; overflow-y: auto; overflow-x: hidden"
    [style.background-image]="'url(' + chosenBg+ ')'"
>
    <div
        fxFlexFill
        class="p-2"
    >
        <div>
            <mat-expansion-panel
                style="background-color:rgba(255, 0, 0, 0.0);"
                (afterExpand)="scrollToBottom()"
                [expanded]="i==conversationNumber && !reading "
                *ngFor="let conversationItem of conversation let i = index"
                hideToggle
                class="mat-elevation-z0 m-0"
            >
                <mat-expansion-panel-header
                    style=" width:100%; padding-left: 5px;"
                    [style.height]="true? chip.scrollHeight + 30 + 'px' : null"
                >
                    <div
                        fxLayout="column"
                        style="width:100%"
                    >
                        <div
                            style="width: 100%;"
                            [fxLayoutAlign]="conversationItem?.item ? 'start center':'end center'"
                            fxLayout="row"
                            class=" mt-2"
                        >
                            <span
                                *ngIf="conversationItem?.item"
                                style="font-weight: bold; width:100%"
                            >{{  botName   }}
                            </span>
                            <span
                                class="mr-2"
                                *ngIf="!conversationItem?.item"
                                style="font-weight: bold;"
                            >{{  yourName   }}
                            </span>
                        </div>
                        <div
                            fxLayout="row"
                            style="width: 100%;"
                        >
                            <div
                                fxLayout="row"
                                style="width: 100%;"
                                [fxLayoutAlign]="conversationItem?.item ? 'start center':'end center'"
                            >
                                <app-user-avatar-small
                                    *ngIf="conversationItem.item"
                                    [avatar]="conversationItem?.item? botAvatar : yourAvatar "
                                ></app-user-avatar-small>
                                <mat-chip-list>
                                    <mat-chip
                                        #chip
                                        style="transform: scale(0.95)"
                                        selected
                                        [color]="conversationItem.item? 'primary' : 'accent' "
                                    >
                                        <span> {{ conversationItem.item? conversationItem?.item?.qa?.question1?.question : conversationItem.selectedAnswer }}
                                        </span>
                                        <span
                                            (click)="readText(conversationItem.item? conversationItem?.item?.qa?.question1.question : conversationItem.selectedAnswer)"
                                            [style.opacity]="reading? 0.1:1 "
                                            class="material-symbols-outlined ml-2"
                                        >volume_up
                                        </span>
                                    </mat-chip>

                                </mat-chip-list>
                                <app-user-avatar-small
                                    *ngIf="!conversationItem.item"
                                    [avatar]="conversationItem?.item? botAvatar : yourAvatar "
                                ></app-user-avatar-small>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div
                    *ngIf="conversationItem.item"
                    class="mt-2 mb-2"
                >
                    <mat-chip-list>
                        <mat-chip
                            style="transform: scale(0.95)"
                            (click)="answerChosen(answer)"
                            *ngFor="let answer of conversationItem.item.qa.answers"
                        >
                            <span>{{ answer.text }}</span>
                            <span
                                (click)="$event.stopPropagation();readText(answer.text)"
                                [style.opacity]="reading? 0.1:1 "
                                class="material-symbols-outlined ml-2"
                            >volume_up
                            </span>
                        </mat-chip>
                    </mat-chip-list>
                </div>
                <div
                    *ngIf="!conversationItem.item"
                    style="width:100%; "
                    fxLayout="row"
                    class="mt-5 mb-2"
                    fxFlex
                    fxLayoutAlign="space-around center"
                >
                    <div
                        fxLayoutAlign="center center"
                        fxLayout="row"
                    >
                        <div
                            (click)="openChatbotSpeakPanel(conversationItem.selectedAnswer)"
                            style="width:100px; height:100px;border-radius: 50%; background-color:#ec407a; "
                            fxLayoutAlign="center center"
                            class="mat-elevation-z3"
                        >
                            <mat-icon style=" color:white; transform:scale(2)">keyboard_voice</mat-icon>
                        </div>
                    </div>
                    <div
                        fxLayoutAlign="center center"
                        fxLayout="row"
                    >
                        <div
                            style="width:100px; height:100px;border-radius: 50%; background-color:#ec407a; "
                            fxLayoutAlign="center center"
                            class="mat-elevation-z3"
                        >
                            <mat-icon style=" color:white; transform:scale(2)">sync_alt</mat-icon>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <div></div>
        </div>
    </div>
</div>
