<div
    fxLayout="row wrap"
    style="   justify-content: center;
                align-items: center; width: 100%; "
>
    <span *ngFor="let word of disassembledSentence">
        <span
            [class]="word.hasTranslation && !word.blank ? 'translationExists ' : ''"
            [innerHTML]="word.word  "
            [style.color]="word.connectedWords ? word.connectionColor  : 'none'"
        >
        </span>
        <span
            [innerHTML]="'&nbsp' "
            style="border: none;"
        >
        </span>

    </span>
</div>
