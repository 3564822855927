import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { HelpersService } from './helpers.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  url;
  uuid;
  readonly VAPID_PUBLIC_KEY =
    'BHDeBFqXAwqW5sQN99upwKh6iMm1myh0AFALEsqXiEkweBbyYnKciMEScs3vBXisKx2Bv2wVMw56Zk6VZ0DIp2w';

  constructor(
    private http: HttpClient,
    private swPush: SwPush,
    private helpers: HelpersService,
    private userDetailsService: UserDetailsService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;

    console.log(
      '🚀 ~ file: push.service.ts ~ line 42 ~ PushService ~ this.uuid',
      this.uuid,
    );
    this.swPush.notificationClicks.subscribe((arg) => {
      console.log(
        '🚀 ~ file: app.component.ts ~ line 30 ~ AppComponent ~ this.swPush.notificationClicks.subscribe ~ arg',
        arg,
      );
    });

    setTimeout(() => {
      if (
        this.userDetailsService.getSimpleUserDetails()?.role === 'owner' ||
        this.userDetailsService.getSimpleUserDetails()?.role === 'teacher' ||
        this.userDetailsService.getSimpleUserDetails()?.role === 'office'
      ) {
        // this.subscribeToNotifications();
      }
      if ('permissions' in navigator) {
        navigator.permissions
          .query({ name: 'notifications' })
          .then((permission) => {
            console.log(
              '🚀 ~ file: push.service.ts ~ line 52 ~ PushService ~ .then ~ permission',
              permission,
            );
            if (permission.state === 'granted') {
            }
            if (permission.state == 'denied') {
              this.deleteSubscription();
            }
            permission.onchange = (state) => {
              console.log(
                '🚀 ~ file: push.service.ts ~ line 76 ~ PushService ~ .then ~ state',
                state,
              );
              if (permission.state == 'denied') {
                this.deleteSubscription();
              }
              if (permission.state === 'granted') {
                this.subscribeToNotifications();
              }
            };
          });
      }
    }, 1000);
  }

  subscribeToNotifications() {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY,
      })
      .then((sub: any) => {
        let additionalData = { userId: '', role: '', uuid: this.uuid };

        additionalData.userId =
          this.userDetailsService.getSimpleUserDetails().id;
        additionalData.role =
          this.userDetailsService.getSimpleUserDetails().role;
        additionalData.uuid = this.uuid;
        this.sendSubscriptionToTheServer(sub, additionalData);
      })
      .catch((err) =>
        console.error('Could not subscribe to notifications', err),
      );
  }

  public sendSubscriptionToTheServer(
    subscription: PushSubscription,
    additionalData: any,
  ) {
    this.http
      .post(this.url + '/api/notifications/subscription', {
        subscription: subscription,
        additionalData: additionalData,
      })
      .subscribe((res) => {
        console.log(
          '🚀 ~ file: push.service.ts ~ line 67 ~ PushService ~ sendSubscriptionToTheServer ~ res',
          res,
        );
      });
  }
  public sendNotification(userId, role?) {
    return this.http
      .post(this.url + '/api/notifications/send-notification', {
        userId: userId,
        role: role,
      })
      .subscribe((res) => {
        console.log(
          '🚀 ~ file: push.service.ts ~ line 76 ~ PushService ~ sendNotification ~ res',
          res,
        );
      });
  }
  public deleteSubscription() {
    return this.http
      .post(this.url + '/api/notifications/delete-subscription', {
        uuid: this.uuid,
      })
      .subscribe((res) => {
        console.log(
          '🚀 ~ file: push.service.ts ~ line 76 ~ PushService ~ sendNotification ~ res',
          res,
        );
      });
  }
}
