import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { VersionService } from './version.service';
@Injectable({
  providedIn: 'root',
})
export class PictureService {
  private picturesUpdated = new Subject<any[]>();
  private foundPicturesUpdated = new Subject<any[]>();
  private numberOfPicturesUpdated = new Subject<any>();
  userAuthenticated = false;
  // private url = 'https://backend-int.linget.it'
  // private url = 'https://backend-int.linget.it'
  url;
  fileUrl;
  Pictures;
  foundPictures;
  randomPicturesRequested = false;

  randomPictures = [];
  picturesToSend: Array<File> = [];
  constructor(
    private http: HttpClient,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }

  getLatestPictures() {
    this.http
      .get<{ message: string; pictures: any; number: number }>(
        this.url + '/api/pictures/latest',
      )
      .pipe(
        map((pictures) => {
          return pictures.pictures.map((picture) => {
            return {
              id: picture._id,
              tags: picture.tags,
              bigUrl: picture.bigUrl,
              smallUrl: picture.smallUrl,
              iconUrl: picture.iconUrl,
              level: picture.level,
              added: picture.added,
              bigSize: picture.bigPictureSize,
              smallSize: picture.smallPictureSize,
              iconSize: picture.iconPictureSize,
            };
          });
        }),
      )
      .subscribe((transformedPictures) => {
        this.Pictures = transformedPictures;
        this.picturesUpdated.next(transformedPictures);
      });

    // this.getNumberOfSentences()
  }
  getRandomPictures() {
    if (this.randomPictures.length > 0) {
      this.picturesUpdated.next(this.randomPictures);
    } else {
      this.randomPicturesRequested = true;
      this.http
        .get<{ message: string; pictures: any; number: number }>(
          this.url + '/api/pictures/random/',
        )
        .pipe(
          map((pictures) => {
            return pictures.pictures.map((picture) => {
              return {
                id: picture._id,
                tags: picture.tags,
                bigUrl: picture.bigUrl,
                smallUrl: picture.smallUrl,
                iconUrl: picture.iconUrl,
                level: picture.level,
                added: picture.added,
                bigSize: picture.bigPictureSize,
                smallSize: picture.smallPictureSize,
                iconSize: picture.iconPictureSize,
              };
            });
          }),
        )
        .subscribe((transformedPictures) => {
          this.randomPictures = transformedPictures;
          this.picturesUpdated.next(transformedPictures);
        });
    }
    // this.getNumberOfSentences()
  }

  findPictures(tag, level) {
    const routeToAllLevels = '/api/pictures/findtagAllLevels?tag=';
    const routeToLevel = '/api/pictures/findtag?tag=';
    let route = routeToAllLevels;
    if (level != undefined) {
      route = routeToLevel;
    }
    this.http
      .get<{ message: string; pictures: any }>(
        this.url + route + tag.replace('#', '%hash%') + '&level=' + level,
      )
      .pipe(
        map((pictures) => {
          return pictures.pictures.map((picture) => {
            return {
              id: picture._id,
              tags: picture.tags,
              level: picture.level,
              bigUrl: picture.bigUrl,
              smallUrl: picture.smallUrl,
              iconUrl: picture.iconUrl,
              added: picture.added,
              bigSize: picture.bigPictureSize,
              smallSize: picture.smallPictureSize,
              iconSize: picture.iconPictureSize,
            };
          });
        }),
      )
      .subscribe((transformedPictures) => {
        this.foundPictures = transformedPictures;
        this.picturesUpdated.next(transformedPictures);
      });

    // this.getNumberOfSentences()
  }
  findPicturesOnlyLevel(level) {
    this.http
      .get<{ message: string; pictures: any }>(
        this.url + '/api/pictures/findlevel?level=meme',
      )
      .pipe(
        map((pictures) => {
          return pictures.pictures.map((picture) => {
            return {
              id: picture._id,
              tags: picture.tags,
              level: picture.level,
              bigUrl: picture.bigUrl,
              smallUrl: picture.smallUrl,
              iconUrl: picture.iconUrl,
              added: picture.added,
              bigSize: picture.bigPictureSize,
              smallSize: picture.smallPictureSize,
              iconSize: picture.iconPictureSize,
            };
          });
        }),
      )
      .subscribe((transformedPictures) => {
        this.foundPictures = transformedPictures;
        this.picturesUpdated.next(transformedPictures);
      });

    // this.getNumberOfSentences()
  }

  addPicture(
    pictureData,
    bigPicture: File,
    smallPicture: File,
    iconPicture: File,
    imageChanged,
  ) {
    // const dataToSend = sentence

    let dataToSend = pictureData;

    // if (imageChanged === true){
    // this.fileUrl = this.url + '/api/pictures/'

    dataToSend = new FormData();

    dataToSend.append('tags', pictureData.tags);
    dataToSend.append('picturesToSend', smallPicture);
    dataToSend.append('picturesToSend', bigPicture);
    dataToSend.append('picturesToSend', iconPicture);
    dataToSend.append('added', pictureData.added);
    dataToSend.append('level', pictureData.level);

    // }
    // else{

    //   dataToSend = pictureData
    //   //
    // }
    new Response(dataToSend).text().then(console.log);

    this.http
      .post<{ message: string; createdPicture }>(
        this.url + '/api/pictures/add/',
        dataToSend,
      )
      .subscribe((response) => {
        this.getLatestPictures();
      });
  }
  updatePictureWithImage(
    pictureData,
    bigPicture: File,
    smallPicture: File,
    iconPicture: File,
    imageChanged,
  ) {
    // const dataToSend = sentence

    let dataToSend = pictureData;

    // if (imageChanged === true){
    // this.fileUrl = this.url + '/api/pictures/'

    dataToSend = new FormData();

    dataToSend.append('tags', pictureData.tags);
    dataToSend.append('picturesToSend', smallPicture);
    dataToSend.append('picturesToSend', bigPicture);
    dataToSend.append('picturesToSend', iconPicture);
    dataToSend.append('added', pictureData.added);
    dataToSend.append('level', pictureData.level);

    // }
    // else{

    //   dataToSend = pictureData
    //   //
    // }
    new Response(dataToSend).text().then(console.log);

    this.http
      .put<{ message: string; createdPicture }>(
        this.url + '/api/pictures/updateImage/',
        dataToSend,
      )
      .subscribe((response) => {
        //
        // pictureData.id = response.picture.createdPictureId
        // pictureData.pictureUrl = response.picture.createdPictureUrl;
        //
        // const addedPicture ={
        //   id: response.picture.createdPictureId,
        //   level: response.picture.createdPictureLevel,
        //   tags: response.picture.createdPictureTags,
        //   url: response.picture.createdPictureUrl,
        //   added: response.picture.createdPictureAdded,
        // }
        pictureData.id = response.createdPicture.createdPictureId;
        pictureData.bigUrl = response.createdPicture.createdPictureBigUrl;
        pictureData.smallUrl = response.createdPicture.createdPictureSmallUrl;
        pictureData.iconUrl = response.createdPicture.createdPictureIconUrl;

        this.Pictures.unshift(pictureData);
        // this.Pictures.splice(5,1)
        this.picturesUpdated.next(this.Pictures);

        //update sentences
      });
  }

  updatePicture(id: string, picture) {
    let dataToSend;

    dataToSend = picture;

    this.http
      .put(this.url + '/api/pictures/edit/' + id, dataToSend, {
        reportProgress: true,
        observe: 'response',
      })
      .subscribe((response) => {
        if (response.type === HttpEventType.Response) {
          // const updatedPicture = picture
          // const oldPictureIndex = updatedPicture.findIndex(s=>s.id === id)
          // //
          // updatedPicture[oldPictureIndex] = picture
          // // this.Sentences = updatedSentences2
          //   this.picturesUpdated.next(updatedPicture)
          //   //
        }
      });
  }
  deletePicture(pictureId) {
    this.http
      .delete(this.url + '/api/pictures/delete/' + pictureId)
      .subscribe(() => {
        //   const updatedPictures = this.Pictures.filter(picture =>picture.id !== pictureId);
        //   // this.Pictures = updatedPictures;
        //   this.getLatestPictures()
        //   this.picturesUpdated.next([...this.Pictures])
        //  this.foundPictures.filter(picture=>picture.id===pictureId)
        //  this.foundPicturesUpdated.next(this.foundPictures)
      });
  }

  pictureUpdateListener() {
    return this.picturesUpdated.asObservable();
  }
  foundPicturesUpdateListener() {
    return this.foundPicturesUpdated.asObservable();
  }
}
