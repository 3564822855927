import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-found-meanings-dialog',
  templateUrl: './found-meanings-dialog.component.html',
  styleUrls: ['./found-meanings-dialog.component.css'],
})
export class FoundMeaningsDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    console.log(
      '🚀 ~ file: found-meanings-dialog.component.ts ~ FoundMeaningsDialogComponent ~ ngOnInit ~ this.data',
      this.data,
    );
  }
}
