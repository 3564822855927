<div
    style="overflow: show;"
    fxLayout="column"
>
    <div
        #frame
        [style.bottom]="keyboardHeight  + 'px'"
        [style.position]="(isHandset$ | async) ? 'absolute' : ''"
        style="left: 0px; width: 100%;"
        [class]="tablet && !teacherMode? 'ml-3' : null"
        fxFlex
        fxLayout="row"
    >
        <mat-form-field
            appearance="none"
            [style.width]="puzzle? '30vw' : '100%'"
            [style.background-color]="userDetailsService.checkAgeStatus() ==='smallChild' ? 'white' : '' "
            #list
            style="border: 3px solid #512da8; border-radius: 15px;"
            fxFlex
        >
            <div
                class="mt-2 ml-2 mr-2"
                [matBadge]="wrongAnswerTries !== -1 ? wrongAnswerTries :0"
            >
                <img
                    matPrefix
                    src="/assets/emojis/1f525.svg"
                    style="width: 36px;"
                    alt
                 />
            </div>
            <textarea
                autofocus
                (touchstart)="$event.preventDefault(); $event.stopPropagation();"
                (touchend)="$event.preventDefault(); $event.stopPropagation();"
                spellcheck="false"
                [placeholder]="hiddenAnswer? '???' :underscores"
                class="ml-1 mr-1"
                fxFlex
                [cdkTextareaAutosize]="true"
                [readonly]="this.handset2"
                [ngStyle]="{'color': textColor}"
                (keydown.enter)="checkAnswer()"
                (keydown.alt)="help()"
                (keyup.alt)="cancelHelp()"
                [maxLength]="correctAnswer.length"
                (keyup)="keyUp()"
                #input
                matInput
                cdkTextareaAutosize
                style="font-size: 28px; font-weight: bold; text-align: center; "
            >
            </textarea>
            <div fxLayoutAlign="center center">
                <button
                    class="mr-3"
                    mat-icon-button
                    (touchstart)="help()"
                    (touchend)="cancelHelp()"
                    (mousedown)="help()"
                    (mouseup)="cancelHelp()"
                    (mouseout)="cancelHelp()"
                    style="transform: scale(1.5);"
                >
                    <mat-icon
                        style="color: #ffab00;"
                        *ngIf="this.clue"
                        class="pointer"
                    >help
                    </mat-icon>
                </button>
            </div>
        </mat-form-field>

    </div>
    <div
        class="mt-2"
        fxFlex
        fxLayout="row"
        fxLayoutGap="10px"
        *ngIf="teacherMode && !this.handset2 "
    >
        <button
            fxFlex
            mat-flat-button
            color="accent"
            (click)=" showAnswer()"
        >Odpowiedź
        </button>
        <button
            fxFlex
            mat-flat-button
            color="primary"
            (click)="nextQuestion2()"
        >Dalej
        </button>
    </div>
    <div
        #keyboard
        [style.display]="(this.handset2 || this.tablet || this.showKeyboard) && !this.teacherMode ? 'block': 'none'"
        [style.width]="this.handset2? '100%': this.tablet? '44%': '44%'"
        [style.margin-left]="this.handset2? '0%': this.tablet? '54%': '54%'"
        [style.margin-right]="this.handset2? '0%': this.tablet? '2%': null"
        style="
      z-index: 5;
      position: absolute;
      bottom: 0;
      left: 0;
      overflow-x: hidden;
    "
        class="simple-keyboard"
    ></div>
    <!-- </div> -->
</div>
