import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { SetsService } from 'src/app/shared/services/sets.service';

@Component({
  selector: 'app-big-picture',
  templateUrl: './big-picture.component.html',
  styleUrls: ['./big-picture.component.css'],
})
export class BigPictureComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BigPictureComponent>,
    public userDetailsService: UserDetailsService,
    private setsService: SetsService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  ngOnInit(): void {}
  setPictureAsMain() {
    this.setsService.selectTitleImage(this.data);
    this.dialogRef.close();
  }
}
