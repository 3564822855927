<div class="work-hours">
    <div class="print-only name-header mb-5">{{titleText}} {{customName?.length>0? customName: selectedWorker?.name}}
    </div>
    <div
        style="width: 90% ;"
        class="controls-container no-print m-2"
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
    >
        <mat-form-field
            fxFlex
            *ngIf="userDetailsService.owner || userDetailsService.office"
            class="mr-2"
            style="margin-bottom: -1.25em;"
            appearance="outline"
        >
            <mat-label>{{!selectedWorker? 'Pracownik' : selectedWorker.name}}
            </mat-label>
            <mat-select
                (selectionChange)="onWorkerChange()"
                [(value)]="selectedWorker"
            >
                <mat-option
                    [value]="worker"
                    *ngFor="let worker of workers"
                >
                    <div
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="10px"
                    >
                        <img
                            height="30px"
                            [src]="worker.avatar"
                            alt
                         />
                        <span>{{worker.name}}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field
            appearance="outline"
            fxFlex
        >
            <mat-label>Miesiąc</mat-label>
            <mat-select
                [(ngModel)]="selectedMonth"
                (selectionChange)="onYearMonthChange()"
            >
                <mat-option
                    *ngFor="let month of monthNames; let i = index"
                    [value]="i"
                >{{ month }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field
            appearance="outline"
            fxFlex
        >
            <mat-label>Rok</mat-label>
            <input
                matInput
                type="number"
                [(ngModel)]="selectedYear"
                (change)="onYearMonthChange()"
             />
        </mat-form-field>
        <div
            style="width: 100% ;"
            fxLayout="row"
            fxLayoutAlign="start center"
        >
            <span
                cladd="mt-2 ml-2"
                *ngIf="!editAvalibility"
                class="mat-h3"
            >{{avalibility?.length > 0 ? avalibility : 'Opisz tutaj swoją dostępność, i czy chcesz mieć więcej pracy'}}
            </span>
            <mat-form-field
                *ngIf="editAvalibility"
                appearance="outline"
                style="width:100%"
            >
                <mat-label>Dostępność</mat-label>
                <textarea
                    #input
                    matInput
                    type="number"
                    [(ngModel)]="avalibility"
                >
                </textarea>
            </mat-form-field>
            <div fxFlex></div>
            <button
                mat-icon-button
                (click)="editAvalibility = !editAvalibility; saveAvalibility() "
            >
                <mat-icon>{{editAvalibility? 'done' : 'edit'}}</mat-icon>
            </button>
        </div>
        <div
            fxLayout="row"
            style="width:100%"
            class="mt-2"
            fxLayoutGap="10px"
        >
            <button
                fxFlex
                color="primary"
                mat-raised-button
                (click)="print()"
            >Drukuj
            </button>
<button
                fxFlex
                *ngIf="userDetailsService.owner || userDetailsService.office"
                color="accent"
                mat-raised-button
                (click)="changeMode()"
            >Zmień rodzaj
            </button>
            <button
                fxFlex
                color="primary"
                mat-stroked-button
                [routerLink]="['/']"
            >Zamknij
            </button>
            <button
                fxFlex
                *ngIf="userDetailsService.owner"
                color="warn"
                mat-raised-button
                (click)="removeHours()"
            >Usuń godziny
            </button>
        </div>
    </div>
    <div class="table-container"></div>
    <table>
        <thead>
            <tr>
                <th rowspan="2">{{monthNames[selectedMonth]}} {{selectedYear}}
                </th>
                <th
                    colspan="2"
                    *ngIf="!cleaningMode"
                >Godziny
                </th>
                <th
                    class="print-only"
                    *ngIf="!cleaningMode"
                    rowspan="2"
                >Podpis
                </th>

                <!-- Add this line -->
                <th
                    *ngIf="cleaningMode"
                    rowspan="2"
                >Ilość
                </th>
            </tr>
            <tr>
                <th [class]="cleaningMode? 'print-only' : 'null'">{{cleaningMode? 'Podpis' : 'Godziny zegarowe (60 min)'}}
                </th>
                <th *ngIf="!cleaningMode">Godziny lekcyjne (45 min)</th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let day of daysArray"
                [ngClass]="{'weekend': isWeekend(day), 'weekday': !isWeekend(day), 'monday': getDayOfWeek(day) === 'Pon', 'tuesday': getDayOfWeek(day) === 'Wt', 'wednesday': getDayOfWeek(day) === 'Śr', 'thursday': getDayOfWeek(day) === 'Czw', 'friday': getDayOfWeek(day) === 'Pt'}"
            >
                <td
                    style="text-align: left; "
                    class="ml-5"
                >{{ padWithZero(day) }}.{{ padWithZero(selectedMonth ) }} {{ getDayOfWeek2(day) }}
                </td>
                <td>
                    <span
                        class="value"
                        *ngIf="!dayInputEnabled[day]"
                    >{{ workerHours[day]?.fullHours || '' }}
                    </span>
                    <div
                        class="input-container"
                        *ngIf="dayInputEnabled[day]"
                    >
                        <input
                            type="number"
                            min="0"
                            step="0.5"
                            [(ngModel)]="workerHours[day].fullHours"
                         />
                    </div>
                </td>
                <td *ngIf="!cleaningMode">
                    <span
                        class="value"
                        *ngIf="!dayInputEnabled[day]"
                    >{{ workerHours[day]?.lessonHours || '' }}
                    </span>
                    <div
                        class="input-container"
                        *ngIf="dayInputEnabled[day]"
                    >
                        <input
                            type="number"
                            min="0"
                            step="0.5"
                            [(ngModel)]="workerHours[day].lessonHours"
                         />
                    </div>
                </td>
                <td>
                    <div
                        class="no-print"
                        style="display: flex; justify-content: center;"
                    >
                        <button
                            mat-icon-button
                            (click)="toggleInput(day)"
                        >
                            <mat-icon>{{ dayInputEnabled[day] ? 'save' : 'edit' }}
                            </mat-icon>
                        </button>
                    </div>
                    <div
                        style="min-width: 150px;"
                        class="print-only"
                    ></div>
                    <!-- Add an empty cell for the signature -->
                </td>
            </tr>
        </tbody>
    </table>
</div>
