import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { StarSetterService} from '../star-setter.service'
@Component({
  selector: 'app-star-setter',
  templateUrl: './star-setter.component.html',
  styleUrls: ['./star-setter.component.css']
})
export class StarSetterComponent implements OnInit {
@Input() elementStar
@Input() elementId
displayStar
  constructor(
    private starSetterService: StarSetterService
  ) { }
ngOnChanges(changes: SimpleChanges): void {
if(changes.elementStar){
  this.displayStar = this.elementStar
}

}
  ngOnInit(): void {
  }
setStar(){
this.displayStar = true
this.starSetterService.setStar(this.elementId)
}
resetStar(){
this.displayStar = false
this.starSetterService.resetStar(this.elementId)

}
}
