import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';
import { MatDialogRef } from '@angular/material/dialog';
import { SendSmsComponent } from '../components/schedule/send-sms/send-sms.component';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  url;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private versionService: VersionService,
    private dialogRef: MatDialogRef<SendSmsComponent>
  ) {
    this.url = this.versionService.url;
  }
  sendEmail(customerData) {
    this.http
      .post(this.url + '/api/email/sendemail', customerData)
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: email.service.ts ~ line 32 ~ EmailService ~ sendEmail ~ response',
          response,
        );
      });
  }
  sendEmailWithHtml(data, forReal) {
    if (forReal) {
      data.forReal = forReal;
    }

    this.http
      .post(this.url + '/api/email/sendemailwithhtml', data)
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: email.service.ts ~ line 32 ~ EmailService ~ sendEmail ~ response',
          response,
        );
      });
  }
  sendSMSToCustomers(numbers, message) {
    this.http
      .post(this.url + '/api/notifications/send-sms-to-customers', {

        customersPhones: numbers,
        message: message,
      })
      .subscribe((response: any) => {
        console.log(
          '🚀 ~ file: email.service.ts ~ line 32 ~ EmailService ~ sendEmail ~ response',
          response,
        );
        if (response.message == 'SMS sent') {
          alert('Wiadomości zostały wysłane')
          this.dialogRef.close()
        }
      });
  }
}
