import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { DictionaryService } from '../../services/dictionary.service'
import { HelpersService } from '../../services/helpers.service'
import { ReadTextService } from '../../services/read-text.service'

@Component({
  selector: 'app-student-dictionary-dialog',
  templateUrl: './student-dictionary-dialog.component.html',
  styleUrls: ['./student-dictionary-dialog.component.css']
})
export class StudentDictionaryDialogComponent implements OnInit {
  sub2: any;
  foundWords: any;
  index: any;
  sub: any;
  sortedWords: any = []
  isHandset: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dictionaryService: DictionaryService,
    private helper: HelpersService,
    private readTextService: ReadTextService,
  ) { }

  ngOnInit(): void {
    this.sub2 = this.dictionaryService.translationsFoundListener().subscribe((translations: any) => {
      console.log('🚀 ~ file: dictionary.component.ts ~ line 45 ~ DictionaryComponent ~ this.sub2=this.dictionaryService.translationsFoundListener ~ translations', translations)
    })
    // this.sub = this.dictionaryService.foundWordsForTranslationsListener().subscribe((words: any) => {
    this.foundWords = this.data.meanings
    console.log('🚀 ~ file: dictionary.component.ts ~ line 47 ~ DictionaryComponent ~ this.sub=this.dictionaryService.foundWordsForTranslationsListener ~ foundWords', this.foundWords)

    this.foundWords = this.helper.sortArrayByProperty(this.foundWords, 'cefr', 'asc')
    this.foundWords.forEach((word: any) => {
      switch (word.cefr) {
        case 'a1':
          word.color = 'orange'
          break
        case 'a2':
          word.color = 'red'
          break
        case 'b1':
          word.color = 'green'
          break
        case 'b2':
          word.color = 'blue'
          break
        case 'c1':
          word.color = 'purple'
          break
        case 'c2':
          word.color = 'black'
          break

        default:
          break
      }
    })
    this.index = 0
    if (this.foundWords?.length > 0) {
      this.sortedWords = [
        this.foundWords.filter((word: any) => word.cefr === 'a1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'a2').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'b1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'b2').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'c1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'c2').reverse()
      ]
    }

    // })
    // if(!this.data.otherMeanings){
    //   this.dictionaryService.findWords(this.data.word, false, 'exact')
    // }
    // else{
    //   this.dictionaryService.findWords(this.data.word.trim(), true, 'exact')

    // }
  }
  readWord(word) {
    this.readTextService.readAudio(
      word.wordAudioES ? word.wordAudioES : word.wordAudio,
      1,
      null,
      true,
      false,
      false,
      true
    );

  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.data = null
  }
}
