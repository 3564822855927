import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerParamsService {
text = 'Trwa Ładowanie...'
  constructor() { }
  changeParams(text){
    this.text = text
  }
}
