import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { CustomersService } from "src/app/shared/services/customers.service";
import { HelpersService } from "../../services/helpers.service";

@Component({
  selector: "app-create-report",
  templateUrl: "./create-report.component.html",
  styleUrls: ["./create-report.component.css"],
})
export class CreateReportComponent implements OnInit {
  selectedStudent: Object;
  showEvaluateStudentPanel = true;
  groupsStudents: Array<[]>;
  sub: Subscription;
  foundStudents: any[];
  students: {
    name: string;
    customerId: any;
    studentAppId: any;
  }[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private customersService: CustomersService,
    private helpers: HelpersService
  ) {}

  ngOnInit(): void {
    this.sub = this.customersService
      .studentsForGroupUpdatedListener()
      .subscribe((data) => {
        this.foundStudents = data;
        console.log(
          "🚀 ~ file: create-report.component.ts ~ line 33 ~ CreateReportComponent ~ .subscribe ~  this.foundStudents",
          this.foundStudents
        );
        this.refreshStudentData();
        console.log(
          "🚀 ~ file: create-report.component.ts ~ line 50 ~ CreateReportComponent ~ .subscribe ~ this.students",
          this.students
        );
        this.showEvaluateStudentPanel = true;
      });
    this.data;
    console.log(
      "🚀 ~ file: create-report.component.ts ~ line 60 ~ CreateReportComponent ~ ngOnInit ~ this.data;",
      this.data
    );
    this.customersService.getStudentsForGroup(this.data.group.students);
  }
  studentsGenderChanged(event, student) {
    this.setStudentsGender(student.studentId, student.customerId, event.value);
  }
  setStudentsGender(studentId, studentCustomerId, gender) {
    this.customersService.setStudentsGender(
      gender,
      studentCustomerId,
      studentId
    );
  }
  refreshStudentData() {
    let newStudents = this.helpers.clone(this.foundStudents);
    this.students = newStudents.map((student) => {
      const newStudent = {
        name: !student.adult
          ? student.childFirstName + " " + student.childLastName
          : student.adultFirstName + " " + student.adultLastName,
        customerId: student.studentCustomerId,
        studentAppId: student.studentAppId,
        gender: student.gender,
        reports: student.reports,
        studentId: student.id,
        selected: true,
        smallChild:
          student.childGrade == "przedszkole" ||
          student.childGrade == "1 klasa" ||
          student.childGrade == "2 klasa" ||
          student.childGrade == "3 klasa",
        addStudentLoginData: false,
        addCustomerLoginData: false,
      };
      return newStudent;
    });
    this.students = this.helpers.sortArrayByProperty(
      this.students,
      "name",
      "asc"
    );
  }
  resetReport(event) {
    this.showEvaluateStudentPanel = false;
    this.customersService.getStudentsForGroup(this.data.group.students);
  }
}
