<div class="files-container m-3" style="overflow-y: auto">
  <div class="file-upload d-flex flex-row" style="gap: 10px">
    <input multiple type="file" (change)="uploadFiles($event)" matButton />
    <button mat-raised-button color="accent" (click)="listFilesAndFolders()">
      Refresh
    </button>

    <button mat-raised-button color="primary" (click)="createFolder()">
      Create Folder
    </button>

    <a [href]="downloadLink" target="_blank" mat-raised-button color="primary"
      >Download File</a
    >
  </div>

  <div style="width: 80vw" *ngIf="currentPath" class="path-info">
    <button mat-icon-button (click)="navigateBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="p-2">{{ currentPath }}</span>
  </div>

  <div *ngIf="folders.length > 0" class="folder-list">
    <mat-list>
      <mat-list-item
        class="pointer"
        matTooltip="{{ getFolderName(folder.Prefix) }}"
        (click)="navigateToFolder(folder.Prefix)"
        *ngFor="let folder of folders"
      >
        <span class="d-flex flex-row align-items-center">
          <mat-icon>folder</mat-icon>
          <span class="truncate">{{ getFolderName(folder.Prefix) }}</span>
        </span>
      </mat-list-item>
    </mat-list>
    <hr />
  </div>

  <div *ngIf="files.length > 0" class="file-list">
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <div class="file-item pointer">
          <mat-icon>description</mat-icon>
          <span class="truncate" matTooltip="{{ getFileName(file.Key) }}">{{
            getFileName(file.Key)
          }}</span>
          <div style="margin-left: auto">
            <!-- <button mat-icon-button (click)="downloadFile(file.Key)">
              <mat-icon>download</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteFile(file.Key)">
              <mat-icon>delete</mat-icon>
            </button> -->
            <button mat-icon-button (click)="getDownloadLink(file.Key, file)">
              <mat-icon>link</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
