<div
  class="d-flex flex-column justify-content-center align-items-center"
  style="width: 100%; height: 100%; position: relative"
  [style.background-color]="backgroundColor"
>
  <video
    class="fade-in"
    #videoDivider
    *ngIf="showVideoDivider"
    autoplay
    style="
      background-size: cover;
      object-fit: cover;
      height: 100%;
      z-index: 7;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    "
    [style.filter]="currentHueFilter"
    loop
    src="/assets/bg/divider-blue.mp4"
  ></video>
  <div
    [style.background-color]="backgroundColor"
    style="
      z-index: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    "
  ></div>
  <div
    *ngIf="!showIntro"
    style="
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      overflow: hidden;
    "
  >
    <img
      class="blur-in"
      #image
      style="
        z-index: 1;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: calc(100%);
        height: calc(100%);
        object-fit: cover;
        object-fit: cover;
        opacity: 0.9;
        box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.8); /* Increased shadow */
      "
      *ngIf="showImage"
      [src]="getDownloadedPicture()"
      alt=""
    />
  </div>

  <div
    #textBox
    *ngIf="showImage"
    class="scale-in-ver-bottom"
    style="
      position: absolute;
      z-index: 2;
      top: 30px;
      left: 30px;
      width: 40%;
      height: 42%;

      background-size: cover;
      border-radius: 10px;
    "
  >
    <!-- <div style="width: 100%; height: 1px; background-color: gray"></div> -->

    <div
      class="mb-3"
      style="
        width: 100%;
        display: flex;
        height: 70%;
        justify-content: center;
        align-items: center;
      "
    ></div>
  </div>

  <app-video-intro-pictures-background
    class="fade-in"
    style="
      background-size: cover;
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    "
    [allImages]="allImages"
    *ngIf="showIntro"
  ></app-video-intro-pictures-background>

  <span
    #intro
    class="fade-in p-5 comfortaa-bold typing-effect"
    *ngIf="showIntro"
    style="
      line-height: 1.5em;
      text-align: center;
      font-size: 4.5em;
      z-index: 15;
    "
    [style.color]="'white'"
    [style.text-shadow]="helpers.getTextShadow('#e91e63')"
    [innerHTML]="introTextToDisplay"
  >
  </span>
  <img
    #introAvatar
    style="
      width: 250px;
      height: 250px;
      position: absolute;
      z-index: 5;
      top: 4%;
      right: 2%;
    "
    class="scale-in-tr"
    *ngIf="showIntro"
    [src]="introData?.introAvatar"
    alt=""
  />
</div>
<span
  style="
    width: 60%;
    height: 40%;
    background-color: transparent;
    position: absolute;
    bottom: 10%;
    left: 20%;
  "
  class="d-flex justify-content-center align-items-center flex-column"
>
  <img
    *ngIf="showImage"
    style="
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: 0.9;
    "
    src="/assets/bg/brush-stroke-big.png"
    alt=""
  />
  <div
    style="
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
      width: 80%;
      margin-left: 10%;
      height: 100%;
      font-size: 8em;
    "
    [style.color]="textColor"
    class="d-flex flex-column justify-content-center align-items-center bebas-neue-regular"
  >
    <span class="animate__animated animate__pulse" *ngIf="showYourTurn"
      >Your Turn!</span
    >
    <span
      style="font-family: anton; font-size: 0.6em"
      class="animate__animated animate__pulse"
      *ngIf="showPolishText"
      >{{ currentItem?.wordPolish }}</span
    >
    <div
      *ngIf="showGetReady"
      class="d-flex flex-row animate__animated animate__flash animate__infinite"
    >
      <span>Get Ready... </span>
      <span>{{ countDown }}</span>
    </div>
    <span *ngIf="showSpeak" class="animate__animated animate__tada"
      >Speak now</span
    >
  </div>
</span>

<div
  *ngIf="showImage"
  style="position: absolute; width: 100%; top: 11%; left: 0%; z-index: 6"
  class="d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
>
  <span
    #text
    style="
      z-index: 6;
      font-size: 4em;
      color: #0d47a1;
      width: 60%;
      text-align: center;
    "
    class="mat-form-field-appearance-outline comfortaa-bold p-2"
    *ngIf="showWord"
    >{{ (currentItem?.word1 + " " + currentItem?.word2).trim() }}</span
  >
</div>
<img
  *ngIf="showImage"
  style="
    transform: scale(0.7);
    z-index: 3;
    width: 60%;
    position: absolute;
    top: 0px;
    left: 20%;

    opacity: 0.9;
  "
  src="/assets/bg/brush_stroke.png"
  alt=""
/>
<video
  style="
    width: 0px;
    height: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
  "
  autoplay
  *ngIf="beep"
  src="/assets/bg/beep.mp4"
></video>
