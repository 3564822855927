<div  style=" width:100%; height:100%; overflow-y:auto; background-attachment:fixed; background-size:cover; background-image: url('assets/bg/4128141.jpg');" >
<mat-card class="p-1" fxLayoutAlign="center start" style=" opacity:0.9; width:100%; height:100%; overflow-y:auto; ">



<div fxFlex.lt-md="100" fxFlex="50"  fxLayout="column">
    <div fxLayoutAlign="center start" fxLayout="row">
        <h1 style="font-weight: bold;" class="primary">Ling King pomoc</h1>
    </div>
    <div fxLayoutAlign="center center" class="mt-2">  
        <mat-accordion multi fxFlex="90">
          <h2 style="font-weight: bold;">Instalacja i logowanie</h2>
          <mat-expansion-panel >
            <mat-expansion-panel-header>
              <mat-panel-title  style="font-weight: bolder;" fxFlex>
                Wymagania systemowe
              </mat-panel-title >
            
            </mat-expansion-panel-header>
    
              
              <span class="mat-h4">
              Aplikacja działa na komputerach, tabletach oraz telefonach z systememi Windows, Android oraz iOS.  
              Do poprawnego działania wymagana jest przeglądarka internetowa Google Chrome dla systemów operacyjnych Windows oraz Android, 
              Safari dla systemu iOS oraz w przypadku urządzenia mobilnego ekran o przekątnej co najmniej 5 cali.
              </span>
          </mat-expansion-panel>
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;"   fxFlex>
            Instalacja Windows/Android
          </mat-panel-title>
         
        </mat-expansion-panel-header>
        <img class="mb-2" width="100%" src="../../assets/help-gifs/1a.jpg">
        <span class="mat-h4">
            Po przejściu na stronę <a href="https://lingking.com.pl">lingking.com.pl</a>  w przeglądarce Chrome powinna pojawić się informacja o
            instalacji (na dole na urządzeniu mobilnym lub u góry w wersji komputerowej). Należy w niego kliknąć i
            zezwolić na instalację aplikacji. </span>
            <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/2a.jpg">
            <span class="mat-h4">
            Jeśli informacja o instalacji nie pojawi się sama, należy kliknąć w menu
            (trzy kropki) i wybrać opcję
            zainstaluj aplikację. 
            </span>
            <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/3.gif">

            <span class="mat-h4">
            Po chwili ikona aplikacji pojawi się na pulpicie.
            nstalowanie aplikacji dostępne jest w normalnym trybie przeglądarki. 
            Jeśli ktoś korzysta z trybu incognito, aby zainstalować aplikację należy otworzyć stronę lingking.com.pl w zwykłej karcie.
            nstalowanie aplikacji dostępne jest w normalnym trybie przeglądarki. Jeśli ktoś korzysta z trybu incognito, 
            aby zainstalować aplikację należy otworzyć stronę lingking.com.pl w zwykłej karcie.
        </span>
      </mat-expansion-panel>
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Instalacja iOS
          </mat-panel-title >
        
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex>

          <img class="mb-2 mt-2" height="100%" src="../../assets/help-gifs/4.gif">
          
          <span class="mat-h4">
            Na systemie iOS należy otworzyć stronę internetową <a href="https://lingking.com.pl">lingking.com.pl</a> w przeglądarce Safari.    
            Następnie należy wybrać ikonę udostępniania i wybrać opcję dodaj do ekranu startowego (na niektórych modelach aby zobaczyć tę opcję trzeba przejechać palcem od dołu ekranu w górę) .
          </span>
        </div>
      </mat-expansion-panel>
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Logowanie, login i hasło
          </mat-panel-title >
     
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutAlign="center center">

          <span class="mat-h4">
            Po zainstalowaniu aplikacji i jej uruchomieniu można się 
            zalogować przy użyciu loginu i hasła tymczasowego, które kursant otrzymał wcześniej.
           </span>
           <div fxFlex>
             <img class="mb-2 mt-2" height="100%" src="../../assets/help-gifs/5.gif">
            </div>
            
            <span class="mat-h4">
              Następnie należy ustawić swoje własne hasło, które będzie używane do logowania. Hasło należy wpisać dwa razy.
              W przypadku zagubienia hasła należy skontaktować się z sekretariatem lub lektorem.
            </span>
          </div>
          </mat-expansion-panel>
          <h2 class="mt-3" style="font-weight: bold;">Nauka i śledzenie postępów</h2>

    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Postępy
          </mat-panel-title>
        
        </mat-expansion-panel-header>
        <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/6.jpg">

        <span class="mat-h4">
            Po zalogowaniu pojawi się ekran przywitalny, na którym należy nacisnąć przycisk rozpocznij naukę. Nastepnie wyświetli się panel tygodniowych postępów. Minimalnym celem tygodniowym jest uzbieranie pełnego koła, czyli 45 minut nauki. Aby uzyskać optymalne efekty, najlepiej jest przeznaczyć chociaż 15 min dziennie na regularną naukę. Postępy tygodniowe resetują się w nocy z niedzieli na poniedziałek.
        </span>
      </mat-expansion-panel>
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Zestawy słówek
          </mat-panel-title>
          
        </mat-expansion-panel-header>
        <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/7.jpg">

        <span class="mat-h4">
            Po wybraniu przycisku dalej wyświetli się lista zestawów do nauki. Nowe zestawy oznaczone są pomarańczową kreską. Zestawy rozpoczęte wyświetlają się na fioletowo, a ukończone na żółto. Kolorowe ikony zdobytych trofeów pokazują aktuakny postęp nauki.     
        </span>
      </mat-expansion-panel>
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Lista słówek w zestawie
          </mat-panel-title>
        
        </mat-expansion-panel-header>
        <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/8.gif">

        <span class="mat-h4">
            Po wybraniu przycisku dalej wyświetli się lista zestawów do nauki. Nowe zestawy oznaczone są pomarańczową kreską. Zestawy rozpoczęte wyświetlają się na fioletowo, a ukończone na żółto. Kolorowe ikony zdobytych trofeów pokazują aktuakny postęp nauki.  
        </span>
        </mat-expansion-panel>
  
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Opis sposobu uczenia
          </mat-panel-title>
        
        </mat-expansion-panel-header>
        <span class="mat-h4">
            Aplikacja została zaprojektowana tak aby imitować naturalną naukę języka tzw. immersję językową, czyli zanurzenie w języku, kiedy to jesteśmy otoczeni komunikatami w języku obcym i zaczynamy w nim myśleć, minimalizując tłumaczenie na język ojczysty. Dlatego w pierwszej kolejności warto zwrócić uwagę na obrazek i zdanie przykładowe, a dopiero na końcu na tłumaczenie.
        </span>
        </mat-expansion-panel>
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Tryby nauki
          </mat-panel-title>
        
        </mat-expansion-panel-header>
        <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/11.jpg">

        <span class="mat-h4">
            Na początku dostępne są dwa tryby: Ucz się oraz Fiszki. Zalecamy rozpoczęcie nauki od trybu Ucz się. Fiszki przeznaczone są głównie do nauki przez słuchanie, np. podczas jazdy samochodem i lepiej z niego korzystać jako utrwalenie słów już wcześniej opanowanych.    
        </span>
        </mat-expansion-panel>
    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Quiz
          </mat-panel-title>
         
        </mat-expansion-panel-header>
        <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/15.gif">

        <span class="mat-h4">
            Na górze okna quizu widoczny jest pasek quizu, na którym wyświetlana jest: ilość słówek pozostałych do nauki, tych już opanowanych, pasek postępu rundy, numer pytania, oraz ustawienia pozwalające dostosować prędkość czytania.        
        </span>
        
          
        </mat-expansion-panel>
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title  style="font-weight: bolder;" fxFlex>
              Tłumaczenie słówek w zdaniu
            </mat-panel-title>
           
          </mat-expansion-panel-header>
          <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/9.gif">

          <span class="mat-h4">
              Można również klikać we wszystkie słowa w zdaniu (zarówno w widoku zestawu jak i podczas nauki), aby sprawdzić wymowę oraz tłumaczenie. Jeśli dane słowo znajduje się w naszym słowniku, wyskoczy okienko z tłumaczeniami, wymową i przykładami. 
              Może się zdarzyć, że któreś słowo nie będzie dostępne, ponieważ nie ma go jeszcze w słowniku, jednak jest on na bieżąco uzupełniany o nowe pozycje. Warto też spróbować kliknąć podwójnie, bo czasami połączenie internetowe może spowodować opóźnienie. Całe wyrażenia lub odmienione formy zaznaczone są na kolorowo.
          </span>
          </mat-expansion-panel>
        <h2 style="font-weight: bold;" class="mt-3">Rodzaje ćwiczeń</h2>

    <mat-expansion-panel >
        <mat-expansion-panel-header>
          <mat-panel-title  style="font-weight: bolder;" fxFlex>
            Ucz się
          </mat-panel-title>
         
        </mat-expansion-panel-header>
        <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/11.jpg">

        <span class="mat-h4">
          Tryb Ucz się został przygotowany, żeby wspierać optymalną i trwałą naukę.  Podczas nauki słowa z zestawu podzielone są na mniejsze porcje. Każda z tych porcji utrwalana jest w zróżnicowanych ćwiczniach (słuchanie, czytanie, pisanie, układanie zdań) na trzech poziomach trudności. Po ukończeniu poziomu wyświetli się taki ekran, a przejściu trzech poziomów dla danej porcji materiału pojawią się opanowane słówka i rozpocznie się nauka kolejnej porcji. 
        </span>
        <span class="mat-h4">
          Po przerobieniu całego quizu w trybie Ucz się zostaną odblokowane inne tryby nauki. Są to wszystkie poszczególne rodzaje ćwiczeń, które w opcji Ucz się są wymieszane. Ponadto jest też tryb Fiszki, który jest dostępny wraz z Ucz się od samego początku nauki danego zestawu.
        </span>
        <img class="mb-2 mt-2" height="100%" src="../../assets/help-gifs/12.gif">

            </mat-expansion-panel>
            <mat-expansion-panel >
              <mat-expansion-panel-header>
                <mat-panel-title  style="font-weight: bolder;" fxFlex>
                  Ucz się - poziom 1
                </mat-panel-title>
              
              </mat-expansion-panel-header>
              <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/13.gif">

              <span class="mat-h4">
                  W pierwszym poziomie trudności słychać całe zdanie i wystarczy wybrać odpowiedź, którą słychać, lub w innej wersji ćwiczenia wpisać ją w odpowiednio pole i nacisnąć odpowiedź. W przypadku poprawnej odpowiedzi wyświetli się zielony ekran z poprawnie uzupełnionym zdaniem (w nim również można sprawdzać znaczenie słów w zdaniu i usłyszeć poprawną wymowę). W przypadku odpowiedzi błędnej pytanie powtórzy się na końcu rundy.        
              </span>
              </mat-expansion-panel>
           <mat-expansion-panel >
              <mat-expansion-panel-header>
                <mat-panel-title  style="font-weight: bolder;" fxFlex>
                 Ucz się - poziom 2
                </mat-panel-title>
               
              </mat-expansion-panel-header>
              <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/17.gif">

              <span class="mat-h4">
                  W drugim poziomie trudności dalej słychać całe zdanie, jednak odpowiedź trzeba już samodzielnie zapisać. Pojawią się też zadania, w których trzeba słowo przetłumaczyć. W razie trudności można skorzystać ze znaku zapytania, który na chwilę wyświetli podpowiedź. Użycie podpowiedzi również spowoduje, że pytanie powtórzy się na końcu rundy.             
              </span>
              </mat-expansion-panel>
           <mat-expansion-panel >
              <mat-expansion-panel-header>
                <mat-panel-title  style="font-weight: bolder;" fxFlex>
                  Ucz się - poziom 3
                </mat-panel-title>
              
              </mat-expansion-panel-header>
              <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/18.gif">

              <span class="mat-h4">
            
                  W trzecim poziomie trudności nie słychać już słowa z luki (zamiast niego czytane jest “blank”) i trzeba przypomnieć sobie odpowiedź na podstawie kontekstu zdania i tego co zostało zapamiętane w poprzednich rundach.   
              </span>
              </mat-expansion-panel>
            <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title  style="font-weight: bolder;" fxFlex>
                    Mówienie
                  </mat-panel-title>
                
                </mat-expansion-panel-header>
                <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/16.gif">

                <span class="mat-h4">
                    W niektórych pytaniach dostępny jest tryb mówienia. Na obrazku wyświetla się wtedy ikona mikrofonu i zamiast wybrania lub wpisania odpowiedzi można ją powiedzieć. Aby skorzystać z opcji mówienia należy zezwolić na użycie mikrofonu. Wtedy mikrofon zaczyna migać na żółto i można udzielić odpowiedzi.   
                </span>
                </mat-expansion-panel>

            <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title  style="font-weight: bolder;" fxFlex>
                    Rozsypanka
                  </mat-panel-title>
                 
                </mat-expansion-panel-header>
                <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/19.gif">

                <span class="mat-h4">
                W innym rodzaju ćwiczenia należy ułożyć zdanie z rozsypanki po wysłuchaniu całego zdania. Jeśli popełnimy błąd można usunąć pojedyncze słowa lub zacząć od nowa po naciśnięciu okrągłej strzałki. Czasami zamiast wcześniej wyświetlanych obrazków pojawią się animacje, aby uniknąć zapamiętywania wyłącznie “po obrazku” zamiast na podstawie słowa i zdania.
                </span>  
              </mat-expansion-panel>

            <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title  style="font-weight: bolder;" fxFlex>
                    Wybierz
                  </mat-panel-title>
                
                </mat-expansion-panel-header>
                <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/13.gif">

                <span class="mat-h4">
                    W trybie Wybierz mamy do wyboru 4 podpowiedzi, ale w zależności od wybranej opcji lektor przeczyta całe zdanie wraz z luką (Powiedz, Powiedz blank, Posłuchaj, Przeczytaj), poprawną odpowiedź trzeba będzie wpisać z podanych podpowiedzi (Posłuchaj+, Przeczytaj +), zamiast luki usłyszymy słowo “blank” (Powiedz blank, Przeczytaj, Przeczytaj+), lub trzeba będzie wybrać poprawne tłumaczenie na język angielski (Przetłumacz).          
                </span>    
            </mat-expansion-panel>
            <mat-expansion-panel >
                <mat-expansion-panel-header>
                  
                  <mat-panel-title  style="font-weight: bolder;" fxFlex>
                    Napisz
                  </mat-panel-title>
             
                </mat-expansion-panel-header>
                <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/17.gif">

                <span class="mat-h4">
                    W trybie Napisz nie ma już 4 podpowiedzi i odpowiedź należy wpisać samodzielnie (można skorzystać z podpowiedzi przez znak zapytania). W zależności od wybranej opcji lektor przeczyta całe zdanie wraz z luką (Posłuchaj), zamiast luki usłyszymy słowo “blank” (Przeczytaj), lub trzeba będzie wybrać poprawne tłumaczenie na język angielski (Przetłumacz). 
                </span>    
            </mat-expansion-panel>
            <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title  style="font-weight: bolder;" fxFlex>
                    Utwórz
                  </mat-panel-title>
                
                </mat-expansion-panel-header>
                <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/19.gif">

                <span class="mat-h4">
                    Z kolei w trybie Utwórz usłyszymy całe zdanie, które trzeba będzie ułożyć z rozsypanki słów.        
                </span>    
            </mat-expansion-panel>
            <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title  style="font-weight: bolder;" fxFlex>
                    Fiszki
                  </mat-panel-title>
                  
                </mat-expansion-panel-header>
                <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/21.gif">

                <span class="mat-h4">
                    Tryb Fiszki służy głównie do nauki przez słuchanie, np. podczas jazdy samochodem. Po uruchomieniu lektor automatycznie zacznie czytać słowo, tłumaczenie oraz zdanie przykładowe. Przełącznikiem można włączyć/wyłączyć czytanie poszczególnych elementów (np. jeśli na początku nauki słówka nie są jeszcze wystarczająco opanowane, żeby zrozumieć zdania to można je wyłączyć). Zalecamy jednak, aby jak najczęściej słuchać całych zdań, bo właśnie to pozwala odtworzyć naturalne warunki przyswajania języka.                         
                  </span>
                  <img class="mb-2 mt-2" width="100%" src="../../assets/help-gifs/22.gif">

                <span class="mat-h4">
                  Po naciśnięciu przycisku odtwórz kolejne karty będą odtwarzane automatycznie. Należy nacisnąć go zanim lektor skończyć czytać. Jeśli nie zdążymy uruchomić tej opcji w trakcie czytania, należy przesunąć palcem w prawo na kolejną kartę i dalej będzie przechodziło już automatycznie (w wersji komputerowej nacisnąć przycisk umiem/nie umiem).
                  </span>
                  </mat-expansion-panel>
                <h2 style="font-weight: bold;" class="mt-3">Inne</h2>
            <mat-expansion-panel >
                <mat-expansion-panel-header>
                  <mat-panel-title  style="font-weight: bolder;" fxFlex="50">
                    Menu
                  </mat-panel-title>
                
                </mat-expansion-panel-header>
            

                <span class="mat-h4">
                    W widoku ekranu głównego, w prawym górnym rogu znajduje się ikona opcji, którą można się wylogować z aplikacji. Ikona znajdująca się obok (podobna do plusa) służy do podłączenia się telefonem do interaktywnej gry wyświetlanej na tablicy przez nauczyciela, w której uczestnicy rywalizują kto jako pierwszy ukończy rundę. Aby wrócić do nauki indywidualnej należy nacisnąć ikonę domu.
                </span>
                </mat-expansion-panel>

           
                </mat-accordion>
    </div>
</div>
</mat-card>
</div>