import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class CheckUsersTasksService {
  url: string;
  increaseNumber = 0;
  audio = new Audio();
  tasksUpdated = new Subject();
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url + '/api/users/';

    this.audio.src = '../../../assets/sounds/ding.wav';
  }
  checkUsersTasks(userId, tasksNumber, email?) {
    tasksNumber = tasksNumber + this.increaseNumber;
    this.http
      .put(
        this.url + 'checkuserstasks/' + userId,
        { tasksNumber: tasksNumber, email: email },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response: any) => {
        if (response.message == 'tasksIncreased') {
          this.increaseNumber = this.increaseNumber + response.number;
          this.tasksUpdated.next(response);
          this.audio.play();
          this.openSnackBar('Nowe zadanie!');
        }
        if (response.message == 'tasksDecreased') {
          this.increaseNumber = -1;
        }
      });
  }
  openSnackBar(message: string) {
    const snackRef = this._snackBar.open(message, null, {
      duration: 5000,
    });
  }
  tasksUpdatedListener() {
    return this.tasksUpdated.asObservable();
  }
}
