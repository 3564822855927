<!-- <app-canvas-controls (lineChanged)="lineChanged($event)" (undoClicked)="undoClicked($event)" (colorChanged)="colorChanged($event)"> </app-canvas-controls> -->

<canvas
  [style.border]="imageSrc? null : '5px solid #673ab7'"
  [style.transform]="displayMode == 'double' ? 'scale(1.5)' : 'null'"
  style="position: absolute; z-index: 1; border-radius: 10px;"
  #canvas
>
</canvas>
<canvas style="position: absolute; z-index: 0;" #imgCanvas> </canvas>
<!-- <img [src]="canvasImg" [width]="width" [height]="height" style="border: 1px solid blue"> -->
