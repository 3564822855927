import { Injectable } from "@angular/core";
// import { Socket } from 'ngx-socket-io';
import { Observable, Subject } from "rxjs";
// import { Message } from '../models/message.model';
import { map } from "rxjs/operators";
import * as io from "socket.io-client";
import { disconnect } from "cluster";
import { EventEmitter } from "events";
import { MatDialog } from "@angular/material/dialog";
import { SimpleDialogComponent } from "../components/simple-dialog/simple-dialog.component";
import { HelpersService } from "./helpers.service";

@Injectable({
  providedIn: "root",
})
export class LiveserviceService {
  socket;
  id;
  showDisconnected = true;
  changeMode = new Subject();
  private url = "dev.lingking.com.pl:3002";
  reconnectionDialog: any;
  internalLiveMessages = new Subject();
  reconnectionInterval: NodeJS.Timeout;
  constructor(public dialog: MatDialog, private helpers: HelpersService) {
    const loc = window.location.hostname;
    // ssl uncomment key files in server for production
    if (loc !== "test.lingking.com.pl" && loc !== "lingking.com.pl") {
      // this.url = "https://test.lingking.com.pl:3002";
      this.url = "http://" + window.location.hostname + ":3003";
      // this.fileUrl = this.url +'/api/sentences/'
      // this.url = "https://lingking.com.pl:3002";

    }

    if (loc == "test.lingking.com.pl") {
      this.url = "https://test.lingking.com.pl:3002";
    }
    if (loc === "lingking.com.pl") {
      this.url = "https://lingking.com.pl:3002";
    }
  }

  public sendMessage(message, userName, room, complete, senderId?, points?) {
    if (!this.socket) {
      this.socket = io(this.url);
    }

    this.socket.emit(
      "addmessage",
      message,
      userName,
      room,
      complete,
      senderId,
      points
    );
  }
  increaseGhostPoints(gameCode) {
    this.socket.emit(
      "addmessage",
      { drawer: false },
      'increase-ghost-points',
      gameCode,

    );

  }
  dropGhost(gameCode) {
    this.socket.emit(
      "addmessage",
      { drawer: false },
      'drop-ghost',
      gameCode,

    );

  }
  updatePlayers(players, gameCode) {
    this.sendMessage(
      { data: players },
      "update-players-on-server",
      gameCode,
      false
    );
  }
  createTeams(players, gameCode) {
    let result = { teams: [] };
    let teams = [];
    let numberOfTeams = Math.floor((players.length + 0.8) / 2);

    for (let index = 0; index < numberOfTeams; index++) {
      teams.push({
        id: this.helpers.makeid(),
        name: "Team " + (index + 1),
        avatar: "",
        players: [],
        points: 0,
        penalty: "loseAllPoints",
        currentQuestionNumber: 0,
      });
    }

    //choose random player?
    players.forEach((player) => {
      let selectedTeam = this.helpers.getRandomItemFromArray(teams);
      if (
        !teams.some(
          (team) => selectedTeam.players.length - team.players.length == 1
        )
      ) {
        selectedTeam.players.push(this.helpers.clone(player));
      } else {
        let teamWithMissingPlayer = teams.find(
          (team) => selectedTeam.players.length - team.players.length == 1
        );

        teamWithMissingPlayer.players.push(this.helpers.clone(player));
      }
    });

    // update players on server
    result = { teams: teams };

    this.updatePlayers(players, gameCode);

    return result;
  }
  stopRejoining() {
    if (this.reconnectionDialog) {
      this.reconnectionDialog.close();
    }
    if (this.reconnectionInterval) {
      clearInterval(this.reconnectionInterval);
    }
  }
  public sendName(name, gameCode, avatar) {
    this.socket = io(this.url, {
      reconnectionDelayMax: 10000,
    });

    this.socket.on("connect", () => {
      this.stopRejoining();
    });
    this.socket.on("disconnect", () => {
      // if(this.showDisconnected){

      this.reconnectionDialog = this.dialog.open(SimpleDialogComponent, {
        data: {
          text: "Próba ponownego połączenia...",
        },
        disableClose: true,
      });
      this.reconnectionInterval = setInterval(() => {
        this.rejoinGame(
          sessionStorage.getItem("gameCode"),
          sessionStorage.getItem("gameId")
        );
      }, 500);
      // this.checkGame(sessionStorage.getItem('gameCode'), sessionStorage.getItem('gameId'))
      // window.location.reload()
      // this.changeMode.next('join')
      // }
    });
    this.socket.emit("sendName", name, gameCode, avatar);
  }

  changeModeListener(): Observable<any> {
    return this.changeMode.asObservable();
  }
  checkGame(code, id) {
    if (!sessionStorage.getItem("gameCode")) {
      this.showDisconnected = false;
      this.stopRejoining();
    }
    if (!this.socket) {
      this.socket = io(this.url);
    }
    this.socket.emit("checkGame", code, id);
    if (this.showDisconnected) {
      this.socket.on("connect", () => {
        this.stopRejoining();
      });
      this.socket.on("disconnect", () => {
        this.reconnectionDialog = this.dialog.open(SimpleDialogComponent, {
          data: {
            text: "Próba ponownego połączenia...",
          },
          disableClose: true,
        });
        this.reconnectionInterval = setInterval(() => {
          this.rejoinGame(
            sessionStorage.getItem("gameCode"),
            sessionStorage.getItem("gameId")
          );
        }, 500);
        // this.checkGame(sessionStorage.getItem('gameCode'), sessionStorage.getItem('gameId'))
        // window.location.reload()
      });
    }
  }
  rejoinGame(code, id) {
    // this.disconnect()
    if (!this.socket) {
      this.socket = io(this.url);
    }
    this.socket.on("connect", () => {
      this.stopRejoining();
    });
    this.socket.on("disconnect", () => {
      if (this.showDisconnected) {
        this.reconnectionDialog = this.dialog.open(SimpleDialogComponent, {
          data: {
            text: "Próba ponownego połączenia...",
          },
          disableClose: true,
        });
        this.reconnectionInterval = setInterval(() => {
          this.rejoinGame(
            sessionStorage.getItem("gameCode"),
            sessionStorage.getItem("gameId")
          );
        }, 500);
        // this.checkGame(sessionStorage.getItem('gameCode'), sessionStorage.getItem('gameId'))
        // window.location.reload()
        // this.changeMode.next('join')
      }
    });
  }
  createGame(numberOfItems) {
    this.socket = io(this.url);
    this.socket.emit("createGame", numberOfItems);
    const observable = new Observable((observer) => {
      this.socket.on("game created", (data) => {
        observer.next(data);
      });
      return () => {
        this.socket.disconnect();
      };
    });
    return observable;
  }
  disconnect() {
    this.socket = undefined;
  }
  startGame(room, items, questionNumber?, quizMode?) {
    this.socket.emit("startGame", room, items, questionNumber, quizMode);
  }
  mainBoardMessages() {
    const observable = new Observable((observer) => {
      this.socket.on("mainBoardMessage", (data) => {
        observer.next(data);
        if (data.changed) {
          if (this.reconnectionDialog) {
            this.reconnectionDialog.close();
          }
          if (this.reconnectionInterval) {
            clearInterval(this.reconnectionInterval);
          }
        }
        if (data.type === "gameExists" && data.text === true) {
          this.showDisconnected = true;
        }
      });
      return () => {
        // this.socket.disconnect();
        // this.checkGame(sessionStorage.getItem('gameCode'), sessionStorage.getItem('gameId'))
      };
    });
    return observable;
  }
  setQuizAnswer(answer, senderId) {
    this.internalLiveMessages.next({ answer: answer, senderId: senderId });
  }
  getMessages() {
    const observable = new Observable((observer) => {
      // this.socket = io(this.url);
      if (!this.socket) {
        this.socket = io(this.url);
      }

      this.socket.on("message", (data) => {
        if (data.type === "team-answer") {
          this.setQuizAnswer(data.text, data.sender);
        } else {
          observer.next(data);
        }
      });
      return () => {
        // this.socket.disconnect();
      };
    });
    return observable;
  }
  roomEntered() {
    const observable = new Observable((observer) => {
      if (!this.socket) {
        this.socket = io(this.url);
      }
      this.socket.on("yourRoom", (data) => {
        observer.next(data);
      });
      return () => {
        // this.socket.disconnect();
        // this.checkGame(sessionStorage.getItem('gameCode'), sessionStorage.getItem('gameId'))
      };
    });
    return observable;
  }
  internalLiveMessagesListener() {
    return this.internalLiveMessages.asObservable();
  }
}
