<div fxLayout="column" style="width: 100%">
  <div fxLayout="row" style="width: 100%" *ngIf="exportMode">
    {{ (meaning.word1 + " " + meaning.word2).trim() }}---{{
      meaning.translation
    }}---{{ meaning.id }}
  </div>
  <div
    *ngIf="!exportMode"
    [formGroup]="form"
    class="pt-2 pb-2"
    fxFlex
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <div fxFlex fxLayout="row" fxLayoutAlign="start center">
      <span *ngIf="number" class="mr-1">{{ number + ". " }} </span>
      <mat-checkbox
        (change)="onCheckboxChanged($event)"
        *ngIf="checkBox"
        class="mt-2 mr-2"
        color="warn"
        formControlName="cb"
        mat-list-icon
      ></mat-checkbox>
      <div class="mr-2" *ngIf="!showDescriptions">
        <img
          *ngIf="this.meaning?.cefr == 'a1'"
          alt
          class="pointer md-24"
          src="/assets/cefr_icons/a1.png"
        />
        <img
          *ngIf="this.meaning?.cefr == 'a2'"
          alt
          class="pointer md-24"
          src="/assets/cefr_icons/a2.png"
        />
        <img
          *ngIf="this.meaning?.cefr == 'b1'"
          alt
          class="pointer md-24"
          src="/assets/cefr_icons/b1.png"
        />
        <img
          *ngIf="this.meaning?.cefr == 'b2'"
          alt
          class="pointer md-24"
          src="/assets/cefr_icons/b2.png"
        />
        <img
          *ngIf="this.meaning?.cefr == 'c1'"
          alt
          class="pointer md-24"
          src="/assets/cefr_icons/c1.png"
        />
        <img
          *ngIf="this.meaning?.cefr == 'c2'"
          alt
          class="pointer md-24"
          src="/assets/cefr_icons/c2.png"
        />
      </div>
      <span *ngIf="!showDescriptions" class="mr-2"
        >{{ meaning.langMode }}
      </span>
      <span *ngIf="!showDescriptions" class="mr-2">{{ meaning.part }} </span>
      <div
        [style.font-size]="showDescriptions ? '0.8em' : '1em'"
        fxFlex="40"
        fxFlex.lt-md="100"
      >
        <span *ngIf="this.meaning?.before">(</span>
        <span [innerHTML]="this.meaning?.before"></span>
        <span *ngIf="this.meaning?.before">)&nbsp;</span>
        <span
          [innerHTML]="this.meaning?.word1 | filterPipe: textToMark"
          style="font-weight: bold"
        ></span>
        <span *ngIf="this.meaning?.between">&nbsp;(</span>
        <span
          *ngIf="this.meaning?.between !== ''"
          [innerHTML]="this.meaning?.between | filterPipe: textToMark"
        ></span>
        <span *ngIf="this.meaning?.between">)</span>
        <span
          [innerHTML]="' ' + this.meaning?.word2 | filterPipe: textToMark"
          style="font-weight: bold"
        ></span>
        <span *ngIf="this.meaning?.after">&nbsp;(</span>
        <span [innerHTML]="this.meaning?.after"></span>
        <span *ngIf="this.meaning?.after">)</span>
      </div>
    </div>
    <div
      fxLayoutAlign="center center "
      *ngIf="showDescriptions"
      class="m-0"
      fxFlex="2"
    >
      <img
        class="pointer"
        (click)="meaning.langMode = 'uk'"
        *ngIf="meaning.langMode == 'ukus'"
        class="md-20 img-disabled"
        src="/assets/langmode/worlds.png"
        alt
      />
      <img
        class="pointer"
        (click)="meaning.langMode = 'us'"
        *ngIf="meaning.langMode == 'uk'"
        class="md-20"
        src="/assets/langmode/uks.png"
        alt
      />
      <img
        class="pointer"
        (click)="meaning.langMode = 'ukus'"
        *ngIf="meaning.langMode == 'us'"
        class="md-20"
        src="/assets/langmode/uss.png"
        alt
      />
    </div>
    <span
      *ngIf="meaning?.plainAlsoes?.length > 0 && showDescriptions"
      style="color: #673ab7"
      class="material-symbols-outlined"
      >dns
    </span>
    <div
      *ngIf="meaning?.plainAlsoes?.length > 0 && showDescriptions"
      fxLayout="column"
      fxFlex="15"
      matTooltip="Search for this meaning"
      style="font-size: 0.8em; line-height: 90%; cursor: pointer"
    >
      <span
        (click)="searchForMeaning(item)"
        *ngFor="let item of meaning.plainAlsoes"
        >{{ item }}
      </span>
    </div>
    <span
      [style.font-size]="showDescriptions ? '0.8em' : '1.3em'"
      fxFlex="15"
      style="color: gray"
      >{{ this.meaning?.translation }}
    </span>
    <!-- <span style="font-size: 1.3em;" class="disabled" *ngIf="this.meaning?.description" fxFlex="25">{{this.meaning?.description}}</span>
    <span style="font-size: 1.3em;" class="disabled" *ngIf="!this.meaning?.description" fxFlex="25">No description!</span> -->
    <mat-chip-list *ngIf="childrenMode" fxFlex="50">
      <span
        *ngFor="let word of this.meaning?.disassembledChildrenSentence"
        [ngClass]="{ highlighted: word.blank === true }"
      >
        <span
          [innerHTML]="word.word + '&nbsp' | filterPipe: textToMark"
          style="font-size: 1.3em"
        ></span>
      </span>
    </mat-chip-list>
    <div
      [style.font-size]="showDescriptions ? '0.8em' : '1.3em'"
      *ngIf="!childrenMode"
      fxFlex="40"
      fxLayout="row wrap"
    >
      <span
        *ngFor="let word of this.meaning?.disassembledSentence"
        [ngClass]="{ highlighted: word.blank === true }"
      >
        <span [innerHTML]="word.word + '&nbsp' | filterPipe: textToMark"></span>
      </span>
    </div>
    <div fxFlex></div>
    <div *ngIf="showDescriptionsFlags" fxLayout="row">
      <button
        *ngIf="meaning.descriptionChecked"
        color="primary"
        mat-flat-button
        class="mr-2"
        (click)="setMeaningsDescriptionChecked()"
      >
        <span class="material-symbols-outlined"> source_notes </span>
        <div style="width: 100%">Description checked</div>
      </button>
      <button
        *ngIf="!meaning.descriptionChecked"
        color="warn"
        mat-stroked-button
        class="mr-2"
        (click)="setMeaningsDescriptionChecked()"
      >
        <span class="material-symbols-outlined"> source_notes </span>
        <div style="width: 100%">Description to check</div>
      </button>
      <button
        *ngIf="
          meaning.part == 'verb' ||
          meaning.part == 'phrasal verb' ||
          meaning.part == 'expression' ||
          meaning.part == 'idiom'
        "
        mat-stroked-button
        color="primary"
        class="mr-2"
        (click)="setMeaningsAddTo(meaning.id)"
      >
        <span class="material-symbols-outlined"> add_circle_outline </span>Add
        to
      </button>
      <button
        *ngIf="meaning.part == 'noun'"
        mat-stroked-button
        color="primary"
        class="mr-2"
        (click)="addA(meaning.id)"
      >
        <span class="material-symbols-outlined"> add_circle_outline </span>Add a
      </button>
      <button
        *ngIf="
          meaning?.description?.includes('Like,') ||
          meaning?.description?.includes('Like')
        "
        mat-stroked-button
        color="accent"
        class="mr-2"
        (click)="changeLike()"
      >
        <span class="material-symbols-outlined"> refresh </span>Like
      </button>
    </div>
    <img
      (click)="openBigPicture(this.meaning?.childrenSmallPicture)"
      *ngIf="childrenMode"
      [src]="this.meaning?.childrenIconPicture"
      height="40px"
      style="border-radius: 5px"
    />
    <img
      (click)="openBigPicture(this.meaning?.smallPicture)"
      *ngIf="!childrenMode"
      [src]="this.meaning?.iconPicture"
      height="40px"
      style="border-radius: 5px"
    />
  </div>
  <div fxLayout="row" *ngIf="showDescriptions">
    <span
      [style.border]="
        meaning?.description?.length == 0 || !meaning?.description
          ? '3px solid red'
          : ''
      "
      *ngIf="!editDescription || !editDescriptionAllowed"
      style="font-size: 1em; width: 90%; display: flex; align-items: center"
      >{{ meaning?.description }}
    </span>
    <textarea
      *ngIf="editDescription && editDescriptionAllowed"
      [style.color]="
        meaning.descriptionToCheckWiths
          ? 'purple'
          : meaning.descriptionToCheck
          ? 'red'
          : 'black'
      "
      style="
        font-size: 1em;
        width: 90%;
        height: auto;
        border: 2px solid #673ab7;
        border-radius: 5px;
        resize: both; /* This allows both vertical and horizontal resize */
        overflow: auto;
      "
      [(ngModel)]="meaning.description"
    ></textarea>
    <button
      color="warn"
      *ngIf="editDescriptionAllowed"
      matTooltip="Redo description with AI"
      (click)="redoDescription()"
      mat-icon-button
    >
      <mat-icon>refresh</mat-icon>
    </button>
    <button
      color="primary"
      *ngIf="!editDescription && editDescriptionAllowed"
      matTooltip="Edit description"
      (click)="editDescription = true"
      mat-icon-button
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      color="primary"
      *ngIf="editDescriptionAllowed"
      matTooltip="Copy prompt"
      (click)="copyPrompt()"
      mat-icon-button
    >
      <mat-icon>content_copy</mat-icon>
    </button>
    <button
      color="primary"
      *ngIf="editDescriptionAllowed"
      matTooltip="Wrap selected text in quotation marks"
      (click)="wrapInQuotationMarks()"
      mat-icon-button
    >
      <mat-icon>format_quote</mat-icon>
    </button>
    <button
      color="accent"
      *ngIf="editDescriptionAllowed"
      matTooltip="Change all single quotation marks to double quotation marks"
      (click)="changeQuotationMarks()"
      mat-icon-button
    >
      <mat-icon>format_quote</mat-icon>
    </button>
    <button
      color="warn"
      *ngIf="editDescriptionAllowed"
      matTooltip="Change all single quotation marks to double quotation marks in all occurences of this word"
      (click)="changeQuotationMarks()"
      mat-icon-button
    >
      <mat-icon>format_quote</mat-icon>
    </button>
    <button
      color="primary"
      *ngIf="editDescription && editDescriptionAllowed"
      matTooltip="Save description"
      (click)="editDescriptionFinished()"
      mat-icon-button
    >
      <mat-icon>done</mat-icon>
    </button>
    <button
      color="warn"
      *ngIf="editDescription && editDescriptionAllowed"
      matTooltip="Cancel"
      (click)="editDescription = false"
      mat-icon-button
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
  <div fxLayout="row" *ngIf="showSentencesTranslations">
    <span
      [style.color]="meaning.sentenceTranslationChecked ? 'black' : 'red'"
      *ngIf="
        (!editDescription && !editSentenceTranslation) ||
        !editDescriptionAllowed
      "
      style="font-size: 1em; width: 90%; display: flex; align-items: center"
      >{{ meaning?.sentenceTranslation }}
    </span>
    <textarea
      *ngIf="editSentenceTranslation"
      [style.color]="meaning.sentenceTranslationChecked ? 'black' : 'red'"
      style="
        font-size: 1em;
        width: 90%;
        height: auto;
        border: 2px solid #673ab7;
        border-radius: 5px;
        resize: both; /* This allows both vertical and horizontal resize */
        overflow: auto;
      "
      [(ngModel)]="meaning.sentenceTranslation"
    ></textarea>
    <!-- <button
      color="warn"
      *ngIf="editDescriptionAllowed"
      matTooltip="Redo description with AI"
      (click)="redoDescription()"
      mat-icon-button
    >
      <mat-icon>refresh</mat-icon>
    </button> -->
    <button
      color="primary"
      *ngIf="!editSentenceTranslation"
      matTooltip="Edit Sentence translation"
      (click)="editSentenceTranslation = true"
      mat-icon-button
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      color="primary"
      *ngIf="!editSentenceTranslation"
      matTooltip="Mark as checked"
      (click)="setMeaningsSentenceTranslationChecked(true)"
      mat-icon-button
    >
      <mat-icon>check_circle</mat-icon>
    </button>
    <button
      color="primary"
      *ngIf="editSentenceTranslation"
      matTooltip="Save sentence translation"
      (click)="editsentenceTranslationFinished()"
      mat-icon-button
    >
      <mat-icon>done</mat-icon>
    </button>
    <button
      color="warn"
      *ngIf="editSentenceTranslation"
      matTooltip="Cancel"
      (click)="editDescription = false"
      mat-icon-button
    >
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
<hr />
<div *ngIf="showAudioProperties" class="d-flex flex-row flex-wrap">
  <span
    *ngFor="let audioProp of audioProperties; let i = index"
    class="d-flex flex-column"
  >
    <span>{{ audioProp.name }}</span>
    <audio
      #audioElement
      controls
      [src]="audioProp.link"
      (canplaythrough)="audioProp.canPlay = true"
      (error)="audioProp.canPlay = false"
      [ngClass]="{
        'can-play': audioProp.canPlay,
        'cannot-play': !audioProp.canPlay
      }"
    ></audio>
  </span>
  <button (click)="recordAudio()">record</button>
</div>
