<div
    fxLayout="column"
    style="height: 100%;"
>
    <div
        class="mb-2"
        fxFlex
        style="border: 2px solid #673ab7; overflow-y: scroll;"
    >
        <pdf-viewer
            [src]="{
                        url: data.file,
                        withCredentials: false
                       }"
            [render-text]="true"
            [fit-to-page]="true"
            [original-size]="false"
            style=" width: 90vw; height: 70vh;"
            [show-all]="true"
        ></pdf-viewer>
    </div>
    <div
        style="width: 100%;"
        fxLayout="row wrap"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        class="mb-2"
    >
        <button
            fxFlex
            (click)="printPdf()"
            mat-flat-button
            color="primary"
        >Drukuj
        </button>
        <button
            fxFlex
            (click)="openPdfInNewWindow()"
            mat-flat-button
            color="primary"
        >Otwórz
        </button>
        <button
            fxFlex
            (click)="openSendEmailDialog()"
            mat-flat-button
            color="accent"
        >Wyślij
        </button>
        <button
            fxFlex
            mat-dialog-close
            mat-stroked-button
            color="primary"
        >Zamknij
        </button>
    </div>
</div>
