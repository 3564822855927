<div class="w-100 h-100 d-flex flex-row" style="position: relative">
  <div
    class="d-flex flex-row align-items-center justify-content-center"
    style="overflow: hidden"
    *ngIf="showIntro"
  >
    <img style="width: 1610px; height: 920px" [src]="introImgPath" alt="" />
    <img
      class="roll-in-blurred-left"
      style="position: absolute; top: 8%; height: 100%"
      [src]="introImagePath"
      alt=""
    />
  </div>

  <div *ngIf="!showIntro">
    <video
      class="animate__animated animate__fadeIn"
      style="
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      "
      [src]="fact.videoDownloaded"
      loop
      autoplay
    ></video>
    <div
      class="h-100 d-flex flex-column"
      style="
        width: 50%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      "
    >
      <div
        class="w-100 d-flex flex-row align-items-center"
        [style.margin-top]="showRevealText ? '25%' : '35%'"
      >
        <div class="d-flex flex-column" style="margin-left: 15%">
          <span
            class="comfortaa-bold"
            style="
              line-height: 1.5em;
              text-align: center;
              font-size: 3em;
              z-index: 5;
            "
            [style.color]="'white'"
            [style.text-shadow]="helpers.getTextShadow('#000000')"
            [innerHTML]="getQuestionOnly(fact.ABCQuestionWithAnswers.English)"
          >
          </span>
          <div
            class="w-100 d-flex flex-row align-items-center justify-content-center"
          >
            <span
              class="comfortaa-bold mt-3"
              style="font-size: 1.5em; text-align: center; line-height: 1.3em"
              [style.text-shadow]="helpers.getTextShadow('#ffffff')"
              [style.color]="'black'"
              *ngIf="polishMode"
              [innerHTML]="fact.ABCQuestionWithAnswers.Polish"
            ></span>
          </div>

          <span
            *ngIf="showRevealText"
            class="comfortaa-bold"
            style="
              margin-top: 7%;
              line-height: 1.5em;
              text-align: center;
              font-size: 3em;
              z-index: 5;
            "
            [style.color]="'white'"
            [style.text-shadow]="helpers.getTextShadow('#4caf50')"
            [innerHTML]="fact.ABCExplanation.English"
          >
          </span>

          <span
            *ngIf="showContinueWatching"
            class="comfortaa-bold heartbeat"
            style="
              margin-top: 7%;
              line-height: 1.5em;
              text-align: center;
              font-size: 3em;
              z-index: 5;
            "
            [style.color]="'white'"
            [style.text-shadow]="helpers.getTextShadow('#2196f3')"
            [innerHTML]="'Continue watching to <br> find out!'"
          >
          </span>
        </div>
      </div>
    </div>
    <div
      class="h-100"
      style="
        width: 50%;
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      "
    >
      <img
        [src]="fact.image"
        style="
          opacity: 0.9;
          width: 55%;
          height: 49%;
          position: absolute;
          top: 15%;
          left: 23.2%;
          z-index: -1;
        "
        alt=""
      />
      <div
        style="
          position: relative;
          width: 60%;
          margin-left: 20%;
          margin-top: 7.8%;
        "
      >
        <img
          style="width: 100%; opacity: 0.9"
          src="/assets/polaroid.png"
          alt=""
        />
        <div
          style="
            position: absolute;
            width: 100%;
            height: 24%;
            bottom: 0px;
            left: 0;
          "
          class="d-flex flex-column"
        >
          <div
            style="flex: 1"
            class="w-100 d-flex flex-row align-items-end justify-content-center"
          >
            <span
              *ngIf="
                showRevealText
                  ? correctAnswer ==
                    fact.ABCAnswers.English[0]
                      .replace('A) ', '')
                      .replace('B) ', '')
                      .replace('C) ', '')
                  : true
              "
              style="font-family: anton; color: #c2185b; font-size: 32px"
              [class]="
                !showRevealText
                  ? 'animate__animated animate__pulse animate__infinite'
                  : 'animate__animated animate__flash animate__infinite'
              "
            >
              {{ fact.ABCAnswers.English[0] }}
            </span>
          </div>
          <div
            style="flex: 1"
            class="mt-1 mb-1 w-100 d-flex flex-row align-items-center justify-content-center"
          >
            <span
              *ngIf="
                showRevealText
                  ? correctAnswer ==
                    fact.ABCAnswers.English[1]
                      .replace('A) ', '')
                      .replace('B) ', '')
                      .replace('C) ', '')
                  : true
              "
              style="font-family: anton; color: #c2185b; font-size: 32px"
              [class]="
                !showRevealText
                  ? 'animate__animated animate__pulse animate__infinite'
                  : 'animate__animated animate__flash animate__infinite'
              "
            >
              {{ fact.ABCAnswers.English[1] }}
            </span>
          </div>
          <div
            style="flex: 1"
            class="w-100 d-flex flex-row align-items-start justify-content-center"
          >
            <span
              *ngIf="
                showRevealText
                  ? correctAnswer ==
                    fact.ABCAnswers.English[2]
                      .replace('A) ', '')
                      .replace('B) ', '')
                      .replace('C) ', '')
                  : true
              "
              [class]="
                !showRevealText
                  ? 'animate__animated animate__pulse animate__infinite'
                  : 'animate__animated animate__flash animate__infinite'
              "
              style="font-family: anton; color: #c2185b; font-size: 32px"
            >
              {{ fact.ABCAnswers.English[2] }}
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column w-100 pl-5 pr-5 pb-5">
        <span
          class="comfortaa-bold mt-3"
          style="font-size: 1.5em; text-align: center; line-height: 1.3em"
          [style.text-shadow]="helpers.getTextShadow('#ffffff')"
          [style.color]="'black'"
          *ngIf="polishMode && showRevealText"
          [innerHTML]="fact.ABCExplanation.Polish"
        ></span>
      </div>
    </div>
  </div>
</div>
