import { Injectable } from '@angular/core';
import { VersionService } from '../version.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  url = ''
  constructor(
    private http: HttpClient,
    private versionService: VersionService
  ) {
    this.url = this.versionService.url
  }
  getFileById(fileId: string): Observable<HttpResponse<Blob>> {
    if (!fileId) return
    return this.http.get(`https://backend-dev.linget.it/api/google/get-file-by-id/${fileId}`, {
      responseType: 'blob',
      observe: 'response' // Observe the full response
    });
  }

}
