

import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { HelpersService } from "src/app/shared/services/helpers.service";
import { ReadTextService } from "src/app/shared/services/read-text.service";
import Keyboard from "simple-keyboard";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";
import { UserDetailsService } from "src/app/profile/user-details.service";

@Component({
  selector: 'app-test-bed',
  templateUrl: './test-bed.component.html',
  styleUrls: ['./test-bed.component.css']
})
export class TestBedComponent implements OnInit {
  @ViewChild("input", { static: false }) _input: ElementRef;

  // @Input() correctAnswer
  @Input() big = false
  @Input() puzzle = null;
  @Input() lang = "spanish";
  @Input() teacherMode = false
  @Input() hiddenAnswer = false
  @Input() clue = true

  @Input() handset = true;
  @Input() nextQuestion = false;
  audio = new Audio();
  @Output() answer = new EventEmitter();
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  inputAnswer = "";
  showAnswerArray = false;
  answerArray = [];
  sub;
  textColor = "black";
  insertMargin = false;
  underscoreWidth = 0;
  frameHeight = 0;
  wrongAnswerTries = 5;
  spacesIndexes = [];
  firstSpaceIndex = 0;
  secondSpaceIndex = 0;
  underscores = [];
  helpCount = 0;
  keyboardHeight = 0;
  margin = 50;
  handset2 = false;
  readOnly = false;
  cursorPosition = 0;
  attr = "ythfhyh";
  answerClicked;
  value;
  showKeyboard = false;
  keyboard;
  answerIsCorrect = false;
  helpClicked = false;
  underscoresArray = [];
  interval: NodeJS.Timeout;
  underscoresLighted = false;
  @ViewChild("keyboard") _keyboard;
  @ViewChild("formField") formField;
  @ViewChild("frame") frame;
  enteredValue: any;
  oldColor: any;
  tablet: boolean;

  constructor(
    private readTextService: ReadTextService,
    private ref: ChangeDetectorRef,
    private helper: HelpersService,
    private breakpointObserver: BreakpointObserver,
    public userDetailsService: UserDetailsService
  ) { }

  private _correctAnswer;

  get correctAnswer(): any {
    return this._correctAnswer
      .replace(/\(.*?\)/g, "")
      .trim()
      .replaceAll("  ", " ");
  }

  @Input()
  set correctAnswer(val: any) {
    this._correctAnswer = val;
    // this._correctAnswer.status = 'In Process';
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.correctAnswer && this._input?.nativeElement) {
      this._input.nativeElement.value = "";
      this.createUnderscores();
    }
  }
  ngAfterViewInit(): void {
    this.audio.src = "/assets/sounds/wrong.mp3";
    this.audio.volume = 0.5;
    this.audio.load();
    this.handleFocus2();
    if (this.userDetailsService.teacher) {
      this._input.nativeElement.focus();
    }
    this.keyboardHeight = this._keyboard?.nativeElement?.offsetHeight;
    this.frameHeight = this.frame?.nativeElement?.offsetHeight;
    if (this.userDetailsService.teacher) {
      this._input.nativeElement.focus();
    }
    this.createUnderscores();
    // console.log('🚀 ~ file: quiz-write-check.component.ts ~ line 108 ~ QuizWriteCheckComponent ~ ngAfterViewInit ~ this.list', this.list);
    this._input.nativeElement.value = "";

  }

  handleFocus(event) {
    console.log(
      "🚀 ~ file: quiz-write-check.component.ts ~ line 74 ~ QuizWriteCheckComponent ~ handleFocus ~ event",
      event
    );
    switch (event.type) {
      case "focus":
        this.setBlinkingUnderscores(true);
        break;
      case "blur":
        if (window.innerWidth > 800) {
          this.setBlinkingUnderscores(false);
        }
        break;

      default:
        break;
    }
  }

  setBlinkingUnderscores(mode) {
    // switch (mode) {
    //   case true:
    //     clearInterval(this.interval);
    //     this.interval = setInterval(() => {
    //       this.underscoresLighted = !this.underscoresLighted;
    //     }, 1000);
    //     break;
    //   case false:
    //     clearInterval(this.interval);
    //     this.underscoresLighted = false;
    //   default:
    //     break;
    // }
  }
  handleFocus2() {
    if (!this.tablet) {
      this.readOnly = true;
      this._input.nativeElement.focus();
      setTimeout(() => {
        this.readOnly = false;
      }, 50);
    }
  }
  onKeyReleased(key) {
    if (key == "{shift}") {
      this.handleShift();
    }
    if (key == "{backspace}") {
      this._input.nativeElement.value = this._input.nativeElement.value.slice(0, -1);
      this.keyboard.setInput(this._input.nativeElement.value)
      this.keyUp(true);
    }

  }

  // onChange = (value) => {
  //   this.inputAnswer = value;

  //   this.keyUp();

  //   // const event = {
  //   //   currentTarget: { value: this.inputAnswer, fromHelp: false },
  //   // };

  //   // this.inputValueChanged(event);
  //   // // this.checkAnswer(true);
  //   if (this.userDetailsService.teacher) {
  //     this._input.nativeElement.focus();
  //   }
  //   // this.handleFocus({ type: true });

  //   // this.setBlinkingUnderscores(false);
  //   // this.setBlinkingUnderscores(true);
  // };
  keyUp(fromVirtualKeyboard?) {

    const previousColor = this.helper.clone(this.textColor);
    let enteredValue = this._input.nativeElement.value


    if (!fromVirtualKeyboard) {
      this.addNextSpecialChar(enteredValue, this.correctAnswer)
    }
    const correctChunk = this.correctAnswer
      .slice(0, this._input.nativeElement.value.length)
      .replaceAll("’", "'")
    if (this._input.nativeElement.value.toUpperCase() === correctChunk.toUpperCase()) {
      this.textColor = "#4caf50";

      if (!this.helpClicked) {
        this.checkAnswer();
      }
    } else {
      this.textColor = "#f44336";
      if (previousColor === "#4caf50") {
        const forbiddenCharacters = [
          "'",
          ",",
          ".",
          "+",
          "-",
          '"',
          "?",
          ";",
          " ",
        ];
        this.audio.play();
        if (
          !forbiddenCharacters.includes(enteredValue[enteredValue.length - 1])
        ) {
          this.wrongAnswerTries--;
        }
        if (this.wrongAnswerTries == -1) {
          this.answer.emit({
            answer: "wrong",
            text: this.inputAnswer,
            correctAnswer: this.correctAnswer,
          });
        }
      }
    }
    // this._input.nativeElement.focus();
  }

  createUnderscores() {
    this.underscores = this.correctAnswer.replace(/([a-z])/gi, "_");
  }
  isAccentMatch(char1, char2) {
    const accentMap = {
      'à': 'a',
      'á': 'a',
      'â': 'a',
      'ã': 'a',
      'ä': 'a',
      'ç': 'c',
      'è': 'e',
      'é': 'e',
      'ê': 'e',
      'ë': 'e',
      'ì': 'i',
      'í': 'i',
      'î': 'i',
      'ï': 'i',
      'ñ': 'n',
      'ò': 'o',
      'ó': 'o',
      'ô': 'o',
      'õ': 'o',
      'ö': 'o',
      'ù': 'u',
      'ú': 'u',
      'û': 'u',
      'ü': 'u',
      'ý': 'y',
      'ÿ': 'y',
    };

    char1 = char1.toLowerCase();
    char2 = char2.toLowerCase();

    if (char1 === char2) {
      return true;
    } else if (accentMap[char2] === char1) {
      return true;
    } else {
      return false;
    }
  }
  addNextSpecialChar(str, correctAnswer) {
    let result = str
    const enteredValueLength = str.length - 1;
    if (enteredValueLength === 0 && !(/[a-zA-Z]/).test(correctAnswer[0])) {
      result = correctAnswer[0] + correctAnswer[1];
    }
    else {


      const correctAnswerLength = correctAnswer.length;
      const nextCharIsSpecial = enteredValueLength < correctAnswerLength && !(/[a-zA-Z]/).test(correctAnswer[enteredValueLength]);

      if (nextCharIsSpecial) {

        let nextCharIndex = enteredValueLength;
        let nextChar = correctAnswer[nextCharIndex];

        if (this._input.nativeElement.value.length > nextCharIndex) {
          this._input.nativeElement.value = this._input.nativeElement.value.slice(0, -1);
        }
        result = this._input.nativeElement.value + nextChar;

      }
    }
    this._input.nativeElement.value = result
    this.inputAnswer = result
    this.keyboard.setInput(result)
  }



  ngOnInit(): void {
    if (window.innerWidth < 600) {
      this.handset2 = true;
    }
    if (window.innerWidth > 600 && window.innerWidth < 1100) {
      this.tablet = true;
    }

    this.attr = null;
    this.ref.detectChanges();

    this.sub = this.readTextService
      .readingFinishedIndicator()
      .subscribe((finished: any) => {
        if (!this.tablet) {
          this._input.nativeElement.focus();
        }
      });

    // this.handleFocus({ type: true });
    this.keyboard = new Keyboard({
      onKeyReleased: (button) => this.onKeyReleased(button),
      onChange: (value) => {
        if (value.length > this._input.nativeElement.value.length) {
          this.addNextSpecialChar(value, this.correctAnswer);
        }

        this.keyUp(true);


      },
      mergeDisplay: true,
      layoutName: "default",
      layout: {
        default: [
          "q w e r t y u i o p",
          "a s d f g h j k l",
          "{shift} z x c v b n m {backspace}",
          "; ' ? {space} , . -",
        ],
        shift: [
          "1 2 3 4 5 6 7 8 9 0",
          "ä ö ü ß á é í ñ ó ú ü ¿ «",
          "{shift} è à ù î ô û œ ë ï é ç â ê {backspace}",
          "/ ! ’ {space} , . -",
        ],
      },
      buttonTheme: [
        {
          class: "hg-space",
          buttons: "{space}",
        },
        {
          class: "hg-around-space",
          buttons: ",",
        },
      ],
      display: {
        "{numbers}": "123",
        "{ent}": "return",
        "{escape}": "esc ⎋",
        "{tab}": "tab ⇥",
        "{backspace}": "⌫",
        "{capslock}": "caps lock ⇪",
        "{shift}": "1ùß",
        "{controlleft}": "ctrl ⌃",
        "{controlright}": "ctrl ⌃",
        "{altleft}": "alt ⌥",
        "{altright}": "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC",
      },
    });
    // setTimeout(() => {
    //   this.keyboard.addButtonTheme('a b c {enter}', 'space');
    // }, 1000);

    console.log(
      "🚀 ~ file: quiz-write-check.component.ts ~ line 94 ~ QuizWriteCheckComponent ~ ngAfterViewInit ~ this._input",
      this._input
    );
    // const event = { currentTarget: { value: "", fromHelp: false } };
    // this.inputValueChanged(event);
    this.keyboard.setOptions({
      layoutName: "default",
      disableButtonHold: false,
    });
  }
  handleShift() {
    let currentLayout = this.keyboard.options.layoutName;
    let shiftToggle = currentLayout === "default" ? "shift" : "default";

    this.keyboard.setOptions({
      layoutName: shiftToggle,
    });
  }

  cancelHelp() {
    if (this.helpClicked) {
      if (this._input.nativeElement.value) {
        this.helpClicked = false;
        this._input.nativeElement.value = this.enteredValue;
        this.textColor = this.oldColor;
      }
      this.keyUp();
      setTimeout(() => {
        if (!this.tablet) {
          this._input.nativeElement.focus();
        }
      }, 500);
    }
  }
  help() {
    if (!this.helpClicked) {
      if (this.helpCount > 0) {
        this.helpClicked = true;
        if (this.helpClicked) {
          this.helpCount++;
          this.enteredValue = this.helper.clone(
            this._input.nativeElement.value
          );
          this.oldColor = this.helper.clone(this.textColor);
          this.textColor = "#9e9e9e";
          this._input.nativeElement.value = this.correctAnswer;
        }
      }
      if (this.helpCount == 0) {
        this.helpClicked = true;
        if (this.helpClicked) {
          this.helpCount++;
          this.enteredValue = this.helper.clone(
            this._input.nativeElement.value
          );
          this.oldColor = this.helper.clone(this.textColor);
          this.textColor = "#9e9e9e";
          const correctAnswer = this.helper.clone(this.correctAnswer);
          let spaces = correctAnswer.replace(/([a-z])/gi, "_");
          const correctAnswerToDisplay = spaces.replace(
            spaces[0],
            this.correctAnswer[0]
          );
          this._input.nativeElement.value = correctAnswerToDisplay;
        }
      }
    }
  }
  showAnswer() {
    this.help();
    this.cancelHelp();
    this.help();
    setTimeout(() => {
      this.cancelHelp();
      if (!this.tablet) {
        this._input.nativeElement.focus();
      }
    }, 1000);
  }
  nextQuestion2() {
    this._input.nativeElement.value = this.correctAnswer;
    this.keyUp();
    this.checkAnswer();
  }
  inputValueChanged(event) { }

  checkAnswer(auto?) {
    this.generateAnswerArray(this.correctAnswer, null, auto);
  }

  generateAnswerArray(correctAnswer, help?, auto?) {
    if (
      this.correctAnswer.toUpperCase().replaceAll("’", "'") ==
      this._input.nativeElement.value.toUpperCase()
    ) {
      if (!help) {
        if (this.helpCount > 1) {
          this.answer.emit({
            answer: "correct",
            text: this.inputAnswer,
            correctAnswer: this.correctAnswer,
            removeFromWordsToLearn: false,
          });
          this.correctAnswer = "";
          return;
          // setTimeout(() => {
          //   this._input.nativeElement.value = "";
          // }, 3000);
        }
        if (this.helpCount <= 1) {
          this.answer.emit({
            answer: "correct",
            text: this.inputAnswer,
            correctAnswer: this.correctAnswer,
          });
          // setTimeout(() => {
          //   this._input.nativeElement.value = "";
          // }, 3000);
        }
      }
    } else {
      //   if(!auto){
      //   if (!help){
      //     this.answer.emit({
      //       answer: 'wrong',
      //       text: this.inputAnswer,
      //   correctAnswer: this.correctAnswer,
      //             this.inputAnswer = ''
      //     });
      //     this.helpClicked = false;
      //     this.cursorPosition = 0;
      //   }
      // }
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.answerClicked?.unsubscribe();
  }
}
