import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { UserDetailsService } from "../../../profile/user-details.service";
import { HelpersService } from "../../services/helpers.service";

@Component({
  selector: "app-workers-tasks",
  templateUrl: "./workers-tasks.component.html",
  styleUrls: ["./workers-tasks.component.css"],
})
export class WorkersTasksComponent implements OnInit {
  @Input() workers;
  @Input() selectedWorker;
  @Input() overdueTasks;
  @Input() platformTasks;
  @Input() forTodayTasks;
  @Input() forFutureTasks;
  @Input() finishedTasks;
  @Input() limit = 50000;
  savedTasks;
  finishedTasksOpened = false;
  platformTasksOpened = false;

  constructor(
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService
  ) {}

  ngOnInit(): void {}
}
