import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopBarVisibilityService {
_toolbarVisible = new Subject()
  constructor() { }

toggle(mode : true | false){
  this._toolbarVisible.next(mode)
}
toolbarVisible(){
  return this._toolbarVisible.asObservable()

}
}
