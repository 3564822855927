<form [formGroup]="answers"  #form="ngForm">
  <hr>
  <div class="body-1">1. Uzupełnij zdania czasownikiem w odpowiedniej formie.</div>
  <hr>
<div  fxLayout = "row wrap" fxLayout.xs="column" fxLayoutGap="1%" >

  <div formArrayName="firstTask" fxLayoutAlign="center center"  fxFlex = "49" *ngFor="let question of firstTask let i = index">
  <mat-form-field fxFlex = "80" >
    <input matInput (blur)='updateAnswers()' [formControlName]="i" [placeholder]="question">
  </mat-form-field>
</div>
</div>
<hr>
<div class="body-1">2. Uzupełnij zdania jednym słowem.</div>
<hr>
<div  fxLayout = "row wrap" fxLayout.xs="column" fxLayoutGap="1%" >

  <div formArrayName="secondTask" fxLayoutAlign="center center"  fxFlex = "49" *ngFor="let question of secondTask let i = index">
  <mat-form-field fxFlex = "80" >
    <input (blur)='updateAnswers()' matInput [formControlName]="i" [placeholder]="question">
  </mat-form-field>
</div>
</div>

</form>
