import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  token: number;
  newPassword : string
  private sub: any;
  backendMessagesSub
  message
  constructor(private authService: AuthenticationService, 
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.token = params['token']
      console.log('this.token: ', this.token);
      this.spinner.hide()
   });
this.backendMessagesSub = this.authService.userBackendMessagesListener().subscribe(message=>{
  this.message = message
  console.log("🚀 ~ file: reset-password.component.ts ~ line 29 ~ ResetPasswordComponent ~ this.backendMessagesSub=this.authService.userBackendMessagesListener ~ message", message)
  console.log('message: ', this.message);
  if(this.message === 'passwordChanged'){
    setTimeout(() => {
      this.router.navigateByUrl('/login')
    },2000)
  }
})
  }
resetPassword(){
  this.authService.resetPassword(this.token, this.newPassword)
}
ngOnDestroy(): void {
  this.sub.unsubscribe()
  this.backendMessagesSub.unsubscribe()

}
}
