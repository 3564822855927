import { Directive, Input } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Directive({
  selector: '[showIfLoggedIn]'
})
export class CheckRoleDirective {
isLoggedIn
  subscription;
  @Input('showIfLoggedIn') renderTemplate;
  // @select(["session", "isLoggedIn"]) isLoggedIn$ : Observable<boolean>

  constructor( private templateRef : TemplateRef<any>,
               private viewContainer : ViewContainerRef, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn()
     if( this.isLoggedIn ) {
        if( this.renderTemplate ) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      } else {
        if( this.renderTemplate ) {
          this.viewContainer.clear();
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }

  }


 ngOnDestory() {
    this.subscription.unsubscribe();
 }

}
