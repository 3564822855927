import { Component, Input, OnInit } from "@angular/core";
import {
  setHours,
  setMinutes,
  differenceInMinutes,
  getHours,
  getMinutes,
  parseISO,
  isDate,
} from "date-fns";
import { HelpersService } from "src/app/shared/services/helpers.service";

@Component({
  selector: "app-schedule-add-group-bar",
  templateUrl: "./schedule-add-group-bar.component.html",
  styleUrls: ["./schedule-add-group-bar.component.css"],
})
export class ScheduleAddGroupBarComponent implements OnInit {
  @Input() avalibility;
  segments = [];
  constructor(private helpers: HelpersService) {}

  ngOnInit(): void {
    let startDaysDate = this.avalibility[0].start;
    console.log(
      "🚀 ~ file: schedule-add-group-bar.component.ts ~ line 25 ~ ScheduleAddGroupBarComponent ~ ngOnInit ~  this.avalibility",
      this.avalibility
    );
    if (!isDate(startDaysDate) && startDaysDate) {
      startDaysDate = this.helpers.convertFromStringToDate(startDaysDate);
    }
    startDaysDate = setHours(startDaysDate, 8);
    startDaysDate = setMinutes(startDaysDate, 0);

    let endDaysDate = setHours(startDaysDate, 21);

    this.avalibility.forEach((singleAvalibility, index) => {
      if (!singleAvalibility.value) {
        singleAvalibility.start = this.helpers.convertFromStringToDate(
          singleAvalibility.start
        );
        singleAvalibility.end = this.helpers.convertFromStringToDate(
          singleAvalibility.end
        );
        if (index == 0 && getHours(singleAvalibility.start) !== 8) {
          this.createSegment(startDaysDate, singleAvalibility.start, false); // first empty segment
        }
        if (index == 0) {
          this.createSegment(
            singleAvalibility.start,
            singleAvalibility.end,
            true
          );
        }
        const nextAvail = this.avalibility[index + 1];

        if (
          (nextAvail && index !== 0) ||
          index == this.avalibility.length - 1
        ) {
          const previousAvailEnd = this.avalibility[index - 1]?.end;
          if (previousAvailEnd) {
            this.createSegment(
              previousAvailEnd,
              singleAvalibility.start,
              false
            );
            //empty segment

            this.createSegment(
              singleAvalibility.start,
              singleAvalibility.end,
              true
            );
            //full segment
          }
        }
      }
    });
  }
  createSegment(start: Date, end: Date, available: boolean) {
    const differenceInMins = differenceInMinutes(end, start);
    const length = 0.1276 * differenceInMins;
    let startTime = getHours(start).toString() + ":";

    let minutes =
      getMinutes(start) < 10
        ? "0" + getMinutes(start).toString()
        : getMinutes(start).toString();
    startTime = startTime + minutes;

    let endTime = getHours(end).toString() + ":";
    let minutes2 =
      getMinutes(end) < 10
        ? "0" + getMinutes(end).toString()
        : getMinutes(end).toString();
    endTime = endTime + minutes2;
    this.segments.push({ avalible: available, length, startTime, endTime });
  }
}
