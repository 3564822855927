import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Events2Service } from 'src/app/shared/services/events2.service';

@Component({
  selector: 'app-event-selector-front',
  templateUrl: './event-selector-front.component.html',
  styleUrls: ['./event-selector-front.component.css'],
})
export class EventSelectorFrontComponent implements OnInit {
  eventId = '614d8e608432142f9fb30529';
  selectedEvent;
  sub: any;
  sub2: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private eventsService: Events2Service,
  ) {}

  ngOnInit(): void {
    console.log(
      '🚀 ~ file: event-selector-front.component.ts ~ line 32 ~ EventSelectorFrontComponent ~ ngOnInit ~ this.activatedRoute',
      this.activatedRoute,
    );

    this.sub2 = this.activatedRoute.queryParams.subscribe((params) => {
      this.eventId = params.eventId;
    });
    this.spinnerService.hide();
    this.sub = this.eventsService
      .eventsFoundByIdListener()
      .subscribe((event) => {
        this.selectedEvent = event;
        console.log(
          '🚀 ~ file: event-selector-front.component.ts ~ line 24 ~ EventSelectorFrontComponent ~ this.sub=this.eventsService.eventsFoundByIdListener ~ this.selectedEvent',
          this.selectedEvent,
        );
      });
    console.log(
      '🚀 ~ file: event-selector-front.component.ts ~ line 27 ~ EventSelectorFrontComponent ~ ngOnInit ~ this.eventId',
      this.eventId,
    );
    this.eventsService.findeventById(this.eventId);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
    this.sub2.unsubscribe();
  }
}
