<div  class="mt-4" style="width:100%" fxLayoutAlign="center center"fxLayout="row">


  <button (click)="previousCategory()" class=" pt-2 pb-2 pl-2 pr-3 mr-1" style="background-color: #ffa000; font-size:1.5em; border-radius: 15px; color:white; font-weight:900; "><</button>
  <div #leftSideRadio fxLayout="row" fxLayoutAlign="center center">


  <div class="p-1" fxLayout="column" fxLayoutAlign="center center" style=" height: 45vh">
  <div   *ngFor="let avatar of categorizedAvatars let i = index">
      <mat-radio-button [color]="'accent'" (change)="radioChecked($event)" #leftSideRadio class="mb-1" color="primary" fxLayout="column" *ngIf="i<4"  [value]="avatar">
        <img style="border: 5px solid #26c6da; background-color:white; border-radius:10px" width="60px" [src]="avatar"></mat-radio-button>

    </div>
</div>
  <div class="p-1" fxLayout="column" fxLayoutAlign="center center" style=" height: 45vh">
  <div   *ngFor="let avatar of categorizedAvatars let i = index">
      <mat-radio-button [color]="'accent'" (change)="radioChecked($event)" #leftSideRadio class="mb-1" color="primary" fxLayout="column" *ngIf="i>3"  [value]="avatar">
        <img style="border: 5px solid #26c6da; background-color:white; border-radius:10px" width="60px" [src]="avatar"></mat-radio-button>

    </div>
</div>
  <!-- <div class="p-1" fxLayout="column" fxLayoutAlign="center center" style=" height: 45vh">
  <div  *ngFor="let avatar of categorizedAvatars let i = index">
      <mat-radio-button [color]="'accent'" (change)="radioChecked($event)" #rightSideRadio class="mb-1" color="primary" fxLayout="column" *ngIf="i>3" [value]="avatar">
        <img style="border: 5px solid #26c6da; background-color:white; border-radius:10px" width="60px" [src]="avatar"></mat-radio-button>

    </div>
  </div> -->
</div>


  <button (click)="nextCategory()"  class=" pt-2 pb-2 pl-3 pr-2 ml-1" style="background-color: #ffa000; font-size:1.5em; border-radius: 15px; color:white; font-weight:900; ">></button>
</div>
