import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-repo-content',
  templateUrl: './video-repo-content.component.html',
  styleUrls: ['./video-repo-content.component.scss']
})
export class VideoRepoContentComponent {
  @Input() video: any;
  @Output() deleteVideo = new EventEmitter<string>();
  isInDialog = false;

  constructor(
    private dialogRef: MatDialogRef<VideoRepoContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.isInDialog = this.data?.fact
  }
  onDeleteClick() {
    this.deleteVideo.emit(this.video.id);
  }
  assignVideoToFact() {
    this.dialogRef.close(this.video.data.downloadLink);
  }
  copyVideoUrl() {
    navigator.clipboard.writeText(this.video.data.downloadLink);
  }
}