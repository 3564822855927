<div
  class="p-2 d-flex flex-column"
  style="
    gap: 10px;
    width: 100vw;
    height: calc(100vh - 64px);
    position: absolute;
  "
>
  <mat-card>
    <h3>Super Facts Creator</h3>
    <hr />
    <div style="gap: 10px" class="d-flex flex-row flex-wrap w-100">
      <input
        style="width: 30vw"
        placeholder="fact"
        matTooltip="fact"
        [(ngModel)]="selectedTopic"
        type="text"
        name=""
        id=""
      />
      <input
        placeholder="category"
        matTooltip="category"
        [(ngModel)]="selectedCategory"
        type="text"
        name=""
        id=""
      />
      <select
        matTooltip="select category"
        style="width: 20vw"
        (change)="selectCategory($event.target.value)"
      >
        <option>all</option>
        <option *ngFor="let category of allCategories">{{ category }}</option>
      </select>
      <button (click)="createFacts()" mat-raised-button color="primary">
        Create Facts
      </button>
      <button (click)="finshFactsSelection()" mat-raised-button color="primary">
        Select Facts
      </button>
    </div>
  </mat-card>
  <div
    class="w-100 p-2 mt-3 align-items-center justify-content-center d-flex flex-row"
  >
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
  </div>
  <div class="d-flex flex-column" style="gap: 10px; flex: 1">
    <mat-card style="height: 60vh; overflow-y: auto" class="d-flex flex-column">
      <div class="w-100 h-100" style="overflow-y: auto">
        <hr />
        <div
          *ngFor="
            let fact of facts
              | paginate: { itemsPerPage: 25, currentPage: page }
          "
        >
          <app-fact-to-edit
            [currentCategory]="selectedCategory"
            *ngIf="!fact?.audio?.length > 0"
            (removeFactEvent)="removeFact($event)"
            (updateFactEvent)="updateFact($event)"
            class="mt-2"
            [fact]="fact"
          ></app-fact-to-edit>
          <app-fact-full
            [select]="data?.select"
            (selectEvent)="selectFact($event)"
            [currentCategory]="selectedCategory"
            [fact]="fact"
            (updateFactEvent)="updateFact($event)"
            (removeFactEvent)="removeFact($event)"
            *ngIf="fact?.audio?.length > 0"
          >
          </app-fact-full>
        </div>
      </div>
      <!-- <mat-tab label="ABC FACTS">
          <div *ngFor="let fact of factsToEdit">
            <app-fact-full
              [fact]="fact"
              (updateFactEvent)="updateFact($event)"
              (removeFactEvent)="removeFact($event)"
              *ngIf="fact?.type?.length > 0"
            ></app-fact-full>
          </div>
        </mat-tab>
        <mat-tab label="TRUE/FALSE FACTS">
          <div *ngFor="let fact of trueFalseFacts">
            <app-fact-full
              [fact]="fact"
              (updateFactEvent)="updateFact($event)"
              (removeFactEvent)="removeFact($event)"
              *ngIf="fact?.type?.length > 0"
            ></app-fact-full>
          </div>
        </mat-tab>
        <mat-tab label="BARE FACTS">
          <div *ngFor="let fact of bareFacts">
            <app-fact-full
              [fact]="fact"
              (updateFactEvent)="updateFact($event)"
              (removeFactEvent)="removeFact($event)"
              *ngIf="fact?.type?.length > 0"
            ></app-fact-full>
          </div> -->
      <!-- </mat-tab> -->
    </mat-card>
  </div>
</div>
