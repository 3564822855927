<div
  class="p-3"
  style="height: calc(100vh - 64px); overflow-y: auto; width: 100%"
>
  <mat-card class="upload-card w-100">
    <mat-card-header>
      <mat-card-title>Upload Video</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form
        style="gap: 10px"
        class="d-flex flex-row align-items-center"
        [formGroup]="uploadForm"
        (ngSubmit)="uploadVideo()"
      >
        <input
          type="file"
          (change)="onFileSelected($event)"
          accept="video/*"
          style="display: none"
          #fileInput
        />
        <button
          class="mb-3"
          mat-raised-button
          color="primary"
          (click)="fileInput.click()"
        >
          <mat-icon>attach_file</mat-icon> Select Video
        </button>
        <mat-form-field appearance="outline">
          <mat-select
            placeholder="Filter by category"
            (valueChange)="filtefVideosByCategory($event)"
            name="item"
          >
            <mat-option value="All">All</mat-option>
            <mat-option
              *ngFor="let category of allCategories"
              [value]="category"
            >
              {{ category }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Folder Name</mat-label>
          <input
            matInput
            formControlName="folderName"
            placeholder="Folder Name (optional)"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Category</mat-label>
          <input matInput formControlName="category" placeholder="Category" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Tags</mat-label>
          <input
            style="flex: 1"
            matInput
            formControlName="tags"
            placeholder="Tags (comma-separated)"
          />
        </mat-form-field>
        <button
          class="mb-3"
          mat-raised-button
          color="accent"
          type="submit"
          [disabled]="!selectedFile || uploadProgress !== null"
        >
          Upload Video
        </button>
      </form>
      <mat-progress-bar
        *ngIf="uploadProgress !== null"
        mode="determinate"
        [value]="uploadProgress"
      ></mat-progress-bar>
      <mat-error *ngIf="uploadError">{{ uploadError }}</mat-error>
    </mat-card-content>
  </mat-card>
  <div
    class="w-100 d-flex flex-row align-items-center justify-content-center mt-4"
  >
    <pagination-controls (pageChange)="page = $event"></pagination-controls>
  </div>
  <div style="overflow-y: auto; height: 60vh">
    <app-video-repo-content
      *ngFor="
        let video of videos | paginate: { itemsPerPage: 25, currentPage: page }
      "
      [video]="video"
      (deleteVideo)="deleteVideo($event)"
    ></app-video-repo-content>
  </div>
</div>
