import { P } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { Report } from './components/create-report/report.model';

@Injectable({
  providedIn: 'root',
})
export class EvaluateStudentService {
  selectOptions: Array<{ value: number; viewValue: string }> = [
    { value: -1, viewValue: 'Nie oceniane' },
    { value: 1, viewValue: 'Nie najlepiej' },
    { value: 2, viewValue: 'Średnio' },
    { value: 3, viewValue: 'Dość dobrze' },
    { value: 4, viewValue: 'Bardzo dobrze' },
    { value: 5, viewValue: 'Wybitnie' },
  ];
  description = {
    male: {
      dopelniacz: 'niego',
      celownik: 'mu',
      przynaleznosc: 'jego',
      joiners: {
        additive: ['ponadto', 'oprócz tego', 'dodatkowo'],
        contrasting: ['jednak', 'z kolei', 'niemniej jednak'],
      },
      wholeYear: {
        description:
          'W trakcje tegorocznej nauki Uczeń (mianownik) udoskonalił swoje kompetencje językowe i zrobił znaczne postępy w większości aspektów nauki języka, choć są też umiejętności, które wymagają jeszcze odrobiny pracy.',
        startYearText: 'Na początku roku szkolnego',
        nowText: 'Teraz',
      },
      joinedLater: {
        description:
          'Pomimo tego, że Uczeń (mianownik) zaczął naukę w późniejszym terminie, udało się mu udoskonalić swoje kompetencje językowe i zrobić postępy w wielu aspektach nauki języka. Są jednak umiejętności, które wymagają jeszcze odrobiny pracy.',
        startYearText: 'Gdy zaczął naukę',
        nowText: 'Teraz',
      },
    },
    boy: {
      dopelniacz: 'niego',
      celownik: 'mu',
      przynaleznosc: 'jego',
      joiners: {
        additive: ['ponadto', 'oprócz tego', 'dodatkowo'],
        contrasting: ['jednak', 'z kolei', 'niemniej jednak'],
      },
      wholeYear: {
        description:
          'W trakcje tegorocznej nauki Uczeń (mianownik) udoskonalił swoje kompetencje językowe i zrobił znaczne postępy w większości aspektów nauki języka, choć są też umiejętności, które wymagają jeszcze odrobiny pracy.',
        startYearText: 'Na początku roku szkolnego',
        nowText: 'Teraz',
      },
      joinedLater: {
        description:
          'Pomimo tego, że Uczeń (mianownik) zaczął naukę w późniejszym terminie, udało się mu udoskonalić swoje kompetencje językowe i zrobić postępy w wielu aspektach nauki języka. Są jednak umiejętności, które wymagają jeszcze odrobiny pracy.',
        startYearText: 'Gdy zaczął naukę',
        nowText: 'Teraz',
      },
    },
    female: {
      dopelniacz: 'niej',
      celownik: 'jej',
      przynaleznosc: 'jej',
      joiners: {
        additive: ['ponadto', 'oprócz tego', 'dodatkowo'],
        contrasting: ['jednak', 'z kolei', 'niemniej jednak'],
      },
      wholeYear: {
        description:
          'W trakcje tegorocznej nauki Uczennica (mianownik) udoskonaliła swoje kompetencje językowe i zrobiła znaczne postępy w większości aspektów nauki języka, choć są też umiejętności, które wymagają jeszcze odrobiny pracy. ',
        startYearText: 'Na początku roku szkolnego',
        nowText: 'Teraz',
      },
      joinedLater: {
        description:
          'Pomimo tego, że Uczennica (mianownik) zaczęła naukę w późniejszym terminie, udało się jej udoskonalić swoje kompetencje językowe i zrobić postępy w wielu aspektach nauki języka. Są jednak umiejętności, które wymagają jeszcze odrobiny pracy.',
        startYearText: 'Gdy zaczęła naukę',
        nowText: 'Teraz',
      },
    },
    girl: {
      dopelniacz: 'niej',
      celownik: 'jej',
      przynaleznosc: 'jej',
      joiners: {
        additive: ['ponadto', 'oprócz tego', 'dodatkowo'],
        contrasting: ['jednak', 'z kolei', 'niemniej jednak'],
      },
      wholeYear: {
        description:
          'W trakcje tegorocznej nauki Uczennica (mianownik) udoskonaliła swoje kompetencje językowe i zrobiła znaczne postępy w większości aspektów nauki języka, choć są też umiejętności, które wymagają jeszcze odrobiny pracy. ',
        startYearText: 'Na początku roku szkolnego',
        nowText: 'Teraz',
      },
      joinedLater: {
        description:
          'Pomimo tego, że Uczennica (mianownik) zaczęła naukę w późniejszym terminie, udało się jej udoskonalić swoje kompetencje językowe i zrobić postępy w wielu aspektach nauki języka. Są jednak umiejętności, które wymagają jeszcze odrobiny pracy.',
        startYearText: 'Gdy zaczęła naukę',
        nowText: 'Teraz',
      },
    },
  };
  heading = 'RAPORT Z POSTĘPÓW W NAUCE W ROKU SZKOLNYM 2022/2023';
  additionalText = '';
  // "Zauważyliśmy również, że gdy po prawie dwóch latach lekcji zdalnych uczniowie wrócili do nauki w szkole, wielu z nich duże trudności zaczęła sprawiać forma sprawdzianów stacjonarnych. Stosunkowo często zdarzały się sytuacje, że uczniowie o bardzo dobrym poziomie języka ze sprawdzianów otrzymywali niższe oceny niż wskazywałaby na to ich wiedza. Po wglądzie do części prac często okazywało się, że wielu uczniów źle lub niedokładnie rozumiało polecenia i wykonywało zadania niezgodnie z instrukcją. W związku z tym od września uruchomimy panel dla uczniów i rodziców z dostępem do dodatkowych ćwiczeń i materiałów powtórkowych do szkolnych sprawdzianów.";
  readingTexts = {
    boy: {
      present: {
        1: 'krótkie teksty dalej stanowią dla Ucznia (dopełniacz) wyzwanie, chociaż widać znaczną różnicę i poprawę.',
        2: 'praca z krótkimi tekstami stanowi już dla Ucznia (dopełniacz) dużo mniejsze wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        3: 'krótkie teksty nie stanowią już dla Ucznia (dopełniacz) takiego wyzwania, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        4: 'krótkie teksty przestały już stanowić dla Ucznia (dopełniacz) wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        5: 'praca z krótkimi tekstami stała się już dla Ucznia (dopełniacz) stosunkowo prostym zadaniem, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować zwiększając stopień trudności tekstu.',
      },
      past: {
        1: 'Uczeń (mianownik) miał spore problemy z czytaniem ze względu na niepełną znajomość alfabetu.',
        2: 'Uczeń (mianownik) miał trudności z czytaniem ze względu na niepełną znajomość alfabetu.',
        3: 'Uczeń (mianownik) dość dobrze radził sobie z czytaniem, chociaż sprawiało to czasami problem.',
        4: 'Uczeń (mianownik) bardzo dobrze radził sobie z czytaniem, chociaż sprawiało to czasami problem.',
        5: 'Uczeń (mianownik) bardzo dobrze radził sobie z czytaniem, chociaż teksty, które składały się z bardziej złożonych wyrażeń stanowiły większy problem.',
      },
    },
    girl: {
      present: {
        1: 'krótkie teksty dalej stanowią dla Uczennicy (dopełniacz) wyzwanie, chociaż widać znaczną różnicę i poprawę.',
        2: 'praca z krótkimi tekstami stanowi już dla Uczennicy (dopełniacz) dużo mniejsze wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        3: 'krótkie teksty nie stanowią już dla Uczennicy (dopełniacz) takiego wyzwania, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        4: 'krótkie teksty przestały już stanowić dla Uczennicy (dopełniacz) wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        5: 'praca z krótkimi tekstami stała się już dla Uczennicy (dopełniacz) stosunkowo prostym zadaniem, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować zwiększając stopień trudności tekstu.',
      },
      past: {
        1: 'Uczennica (mianownik) miała spore problemy z czytaniem ze względu na niepełną znajomość alfabetu.',
        2: 'Uczennica (mianownik) miała trudności z czytaniem ze względu na niepełną znajomość alfabetu.',
        3: 'Uczennica (mianownik) dość dobrze radziła sobie z czytaniem, chociaż sprawiało to czasami problem.',
        4: 'Uczennica (mianownik) bardzo dobrze radziła sobie z czytaniem, chociaż sprawiało to czasami problem.',
        5: 'Uczennica (mianownik) bardzo dobrze radziła sobie z czytaniem, chociaż teksty, które składały się z bardziej złożonych wyrażeń stanowiły większy problem.',
      },
    },
    male: {
      past: {
        1: 'Uczeń (mianownik) miał spore problemy z tekstami czytanymi, szczególnie, gdy składały się one z kilku akapitów.',
        2: 'Uczeń (mianownik) miał trudności ze zrozumieniem tekstów czytanych, szczególnie, gdy składały się one z kilku akapitów.',
        3: 'Uczeń (mianownik) dość dobrze radził sobie z tekstami czytanymi, chociaż te które składały się z kilku akapitów stanowiły większy problem.',
        4: 'Uczeń (mianownik) bardzo dobrze radził sobie z tekstami czytanymi, chociaż te które składały się z kilku akapitów stanowiły większy problem.',
        5: 'Uczeń (mianownik) dość dobrze radził sobie z tekstami czytanymi, choć te które składały się z bardziej złożonych zdań stanowiły większy problem.',
      },
      present: {
        1: 'praca z tekstem dalej stanowi dla Ucznia (dopełniacz) wyzwanie, chociaż widać znaczną różnicę i poprawę.',
        2: 'praca z tekstem stanowi już dla Ucznia (dopełniacz) dużo mniejsze wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        3: 'teksty nie stanowią już dla Ucznia (dopełniacz) takiego wyzwania, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        4: 'teksty przestały już stanowić dla Ucznia (dopełniacz) wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        5: 'praca z tekstem pisanym stała się już dla Ucznia (dopełniacz) stosunkowo prostym zadaniem, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować zwiększając stopień trudności tekstu.',
      },
    },
    female: {
      past: {
        1: 'Uczennica (mianownik) miała spore problemy z tekstami czytanymi, szczególnie, gdy składały się one z kilku akapitów.',
        2: 'Uczennica (mianownik) miała trudności ze zrozumieniem tekstów czytanych, szczególnie, gdy składały się one z kilku akapitów.',
        3: 'Uczennica (mianownik) dość dobrze radziła sobie z tekstami czytanymi, chociaż te które składały się z kilku akapitów stanowiły większy problem.',
        4: 'Uczennica (mianownik) bardzo dobrze radziła sobie z tekstami czytanymi, chociaż te które składały się z kilku akapitów stanowiły większy problem.',
        5: 'Uczennica (mianownik) bardzo dobrze radziła sobie z tekstami czytanymi, choć te które składały się z bardziej złożonych zdań stanowiły większy problem.',
      },
      present: {
        1: 'praca z tekstem dalej stanowi dla Uczennicy (dopełniacz) wyzwanie, chociaż widać znaczną różnicę i poprawę.',
        2: 'praca z tekstem stanowi już dla Uczennicy (dopełniacz) dużo mniejsze wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować',
        3: 'teksty nie stanowią już dla Uczennicy (dopełniacz) takiego wyzwania, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        4: 'teksty przestały już stanowić dla Uczennicy (dopełniacz) wyzwanie, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować.',
        5: 'praca z tekstem pisanym stała się już dla Uczennicy (dopełniacz) stosunkowo prostym zadaniem, chociaż jest to umiejętność, nad którą dalej będzie trzeba pracować zwiększając stopień trudności tekstu.',
      },
    },
  };
  writingTexts = {
    boy: {
      present: {
        1: 'pisanie w języku angielskim nie sprawia uczniowi (celownik) już tak dużej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        2: 'pisanie w języku angielskim nie sprawia już uczniowi (celownik) zbytniej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        3: 'pisanie w języku angielskim nie sprawia już uczniowi (celownik) trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        4: 'pisanie w języku angielskim nie sprawia już uczniowi (celownik) większych trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu zredukowania błędów i nauki budowania poprawnych zdań.',
        5: 'pisanie w języku angielskim nie sprawia uczniowi (celownik) trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu zredukowania błędów i nauki budowania poprawnych zdań.',
      },
      past: {
        1: 'pisanie w języku angielskim sprawiało uczniowi (celownik) duży problem ze względu na niepełną znajomość alfabetu.',
        2: 'pisanie w języku angielskim było dla ucznia (dopełniacz) dość trudnym zadaniem ze względu na niepełną znajomość alfabetu.',
        3: 'z pisaniem w języku angielskim uczeń (mianownik) radził sobie dość dobrze, chociaż występowały problemy ze względu na niepełną znajomość alfabetu.',
        4: 'z pisaniem w języku angielskim uczeń (mianownik) radził sobie dobrze, chociaż występowały problemy ze względu na niepełną znajomość pisowni.',
        5: 'z pisaniem w języku angielskim uczeń (mianownik) radził sobie dobrze, chociaż występowały drobne problemy ze względu na niepełną znajomość pisowni.',
      },
    },
    girl: {
      present: {
        1: 'pisanie w języku angielskim nie sprawia Uczennicy (celownik) już tak dużej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        2: 'pisanie w języku angielskim nie sprawia już Uczennicy (celownik) zbytniej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        3: 'pisanie w języku angielskim nie sprawia już Uczennicy (celownik) trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        4: 'pisanie w języku angielskim nie sprawia już Uczennicy (celownik) większych trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu zredukowania błędów i nauki budowania poprawnych zdań.',
        5: 'pisanie w języku angielskim nie sprawia Uczennicy (celownik) trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu zredukowania błędów i nauki budowania poprawnych zdań.',
      },
      past: {
        1: 'pisanie w języku angielskim sprawiało uczennicy (celownik) duży problem ze względu na niepełną znajomość alfabetu.',
        2: 'pisanie w języku angielskim było dla uczennicy (dopełniacz) dość trudnym zadaniem ze względu na niepełną znajomość alfabetu.',
        3: 'z pisaniem w języku angielskim uczennica (mianownik) radziła sobie dość dobrze, chociaż występowały problemy ze względu na niepełną znajomość alfabetu.',
        4: 'z pisaniem w języku angielskim uczennica (mianownik) radziła sobie dobrze, chociaż występowały problemy ze względu na niepełną znajomość pisowni.',
        5: 'z pisaniem w języku angielskim uczennica (mianownik) radziła sobie dobrze, chociaż występowały drobne problemy ze względu na niepełną znajomość pisowni.',
      },
    },
    male: {
      past: {
        1: 'pisanie tekstów takich jak np. email czy opis sprawiało Uczniowi (celownik) duży problem, zarówno na poziomie budowy zdań jak i przekazywanej treści.',
        2: 'pisanie tekstów takich jak np. email czy opis było dla Ucznia (celownik) dość trudnym zadaniem, zarówno na poziomie budowy zdań jak i przekazywanej treści.',
        3: 'z pisaniem tekstów takich jak np. email czy opis Uczeń (mianownik) radził sobie dość dobrze, chociaż występowały problemy na poziomie budowy zdań jak i przekazywanej treści.',
        4: 'z pisaniem tekstów takich jak np. email czy opis Uczeń (mianownik) radził sobie dobrze, chociaż występowały problemy na poziomie budowy zdań lub przekazywanej treści.',
        5: 'z pisaniem tekstów takich jak np. email czy opis Uczeń (mianownik) radził sobie dobrze, chociaż występowały drobne problemy na poziomie budowy zdań jak i przekazywanej treści.',
      },
      present: {
        1: 'napisanie dłuższego tekstu nie sprawia Uczniowi (celownik) już tak dużej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        2: 'napisanie dłuższego tekstu nie sprawia już Uczniowi (celownik) zbytniej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        3: 'napisanie dłuższego tekstu nie sprawia już Uczniowi (celownik) trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        4: 'napisanie dłuższego tekstu nie sprawia już Uczniowi (celownik) większych trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu zredukowania błędów i poszerzenia wykorzystywanego słownictwa i struktur.',
        5: 'napisanie dłuższego tekstu nie sprawia Uczniowi (celownik) żadnej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu poszerzenia wykorzystywanego słownictwa i struktur.',
      },
    },
    female: {
      past: {
        1: 'pisanie tekstów takich jak np. email czy opis sprawiało uczennicy (celownik) duży problem, zarówno na poziomie budowy zdań jak i przekazywanej treści.',
        2: 'pisanie tekstów takich jak np. email czy opis było dla uczennicy (dopełniacz) dość trudnym zadaniem, zarówno na poziomie budowy zdań jak i przekazywanej treści.',
        3: 'z pisaniem tekstów takich jak np. email czy opis uczennica (mianownik) radziła sobie dość dobrze, chociaż występowały problemy na poziomie budowy zdań jak i przekazywanej treści.',
        4: 'z pisaniem tekstów takich jak np. email czy opis uczennica (mianownik) radziła sobie dobrze, chociaż występowały problemy na poziomie budowy zdań lub przekazywanej treści.',
        5: 'z pisaniem tekstów takich jak np. email czy opis uczennica (mianownik) radziła sobie dobrze, chociaż występowały drobne problemy na poziomie budowy zdań jak i przekazywanej treści.',
      },
      present: {
        1: 'napisanie dłuższego tekstu nie sprawia Uczennicy (celownik) już tak dużej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        2: 'napisanie dłuższego tekstu nie sprawia już Uczennicy (celownik) zbytniej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        3: 'napisanie dłuższego tekstu nie sprawia już Uczennicy (celownik) trudności, ale jest to zagadnienie, które wymaga dalszej uwagi.',
        4: 'napisanie dłuższego tekstu nie sprawia już Uczennicy (celownik) większych trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu zredukowania błędów i poszerzenia wykorzystywanego słownictwa i struktur.',
        5: 'napisanie dłuższego tekstu nie sprawia Uczennicy (celownik) żadnej trudności, ale jest to zagadnienie, które wymaga dalszej uwagi w celu poszerzenia wykorzystywanego słownictwa i struktur.',
      },
    },
  };
  listeningTexts = {
    boy: {
      present: {
        1: 'Uczeń (mianownik) potrafi już wychwycić dość dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        2: 'Uczeń (mianownik) potrafi już wychwycić dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        3: 'Uczeń (mianownik) potrafi już wychwycić bardzo dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        4: 'Uczeń (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        5: 'Uczeń (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szybką mowę, z którą mamy do czynienia wykorzystując obcy język w praktyce.',
      },
      past: {
        1: 'w zadaniach ze słuchu uczeń umiał wychwycić jedynie pojedyncze słowa, nie rozumiejąc całości wypowiedzi.',
        2: 'w zadaniach ze słuchu uczeń głównie był w stanie wychwycić jedynie pojedyncze słowa, nie rozumiejąc w pełni wypowiedzi.',
        3: 'w zadaniach ze słuchu uczeń dość dobrze rozumiał główną myśl wypowiedzi, ale nie był w stanie wychwycić szczegółów.',
        4: 'w zadaniach ze słuchu uczeń dobrze rozumiał główną myśl wypowiedzi, ale był w stanie wychwycić jedynie pojedyncze szczegóły.',
        5: 'w zadaniach ze słuchu uczeń bardzo dobrze rozumiał główną myśl wypowiedzi i był w stanie wychwycić niektóre szczegóły.',
      },
    },
    girl: {
      present: {
        1: 'Uczennica (mianownik) potrafi już wychwycić dość dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        2: 'Uczennica (mianownik) potrafi już wychwycić dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        3: 'Uczennica (mianownik) potrafi już wychwycić bardzo dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        4: 'Uczennica (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        5: 'Uczennica (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szybką mowę, z którą mamy do czynienia wykorzystując obcy język w praktyce.',
      },
      past: {
        1: 'w zadaniach ze słuchu uczennica umiała wychwycić jedynie pojedyncze słowa, nie rozumiejąc całości wypowiedzi.',
        2: 'w zadaniach ze słuchu uczennica głównie była w stanie wychwycić jedynie pojedyncze słowa, nie rozumiejąc w pełni wypowiedzi.',
        3: 'w zadaniach ze słuchu uczennica dość dobrze rozumiała główną myśl wypowiedzi, ale nie była w stanie wychwycić szczegółów.',
        4: 'w zadaniach ze słuchu uczennica dobrze rozumiała główną myśl wypowiedzi, ale była w stanie wychwycić jedynie pojedyncze szczegóły.',
        5: 'w zadaniach ze słuchu uczennica bardzo dobrze rozumiała główną myśl wypowiedzi i była w stanie wychwycić niektóre szczegóły.',
      },
    },
    male: {
      past: {
        1: 'w zadaniach ze słuchu uczeń umiał wychwycić jedynie pojedyncze słowa, nie rozumiejąc całości wypowiedzi.',
        2: 'w zadaniach ze słuchu uczeń głównie był w stanie wychwycić jedynie pojedyncze słowa, nie rozumiejąc w pełni wypowiedzi.',
        3: 'w zadaniach ze słuchu uczeń dość dobrze rozumiał główną myśl wypowiedzi, ale nie był w stanie wychwycić szczegółów.',
        4: 'w zadaniach ze słuchu uczeń dobrze rozumiał główną myśl wypowiedzi, ale był w stanie wychwycić jedynie pojedyncze szczegóły.',
        5: 'w zadaniach ze słuchu uczeń bardzo dobrze rozumiał główną myśl wypowiedzi i był w stanie wychwycić niektóre szczegóły.',
      },
      present: {
        1: 'Uczeń (mianownik) potrafi już wychwycić dość dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        2: 'Uczeń (mianownik) potrafi już wychwycić dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        3: 'Uczeń (mianownik) potrafi już wychwycić bardzo dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        4: 'Uczeń (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szerszy sens słuchanych tekstów.',
        5: 'Uczeń (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinien rozwijać, aby umiał zrozumieć szybką mowę, z którą mamy do czynienia wykorzystując obcy język w praktyce.',
      },
    },
    female: {
      past: {
        1: 'w zadaniach ze słuchu uczennica umiała wychwycić jedynie pojedyncze słowa, nie rozumiejąc całości wypowiedzi.',
        2: 'w zadaniach ze słuchu uczennica głównie była w stanie wychwycić jedynie pojedyncze słowa, nie rozumiejąc w pełni wypowiedzi.',
        3: 'w zadaniach ze słuchu uczennica dość dobrze rozumiała główną myśl wypowiedzi, ale nie była w stanie wychwycić szczegółów.',
        4: 'w zadaniach ze słuchu uczennica dobrze rozumiała główną myśl wypowiedzi, ale była w stanie wychwycić jedynie pojedyncze szczegóły.',
        5: 'w zadaniach ze słuchu uczennica bardzo dobrze rozumiała główną myśl wypowiedzi i była w stanie wychwycić niektóre szczegóły.',
      },
      present: {
        1: 'Uczennica (mianownik) potrafi już wychwycić dość dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        2: 'Uczennica (mianownik) potrafi już wychwycić dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        3: 'Uczennica (mianownik) potrafi już wychwycić bardzo dużo słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        4: 'Uczennica (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szerszy sens słuchanych tekstów.',
        5: 'Uczennica (mianownik) potrafi już wychwycić zdecydowaną większość słów i wyrażeń ze słuchanych wypowiedzi, lecz jest to kompetencja, którą powinna rozwijać, aby umiała zrozumieć szybką mowę, z którą mamy do czynienia wykorzystując obcy język w praktyce.',
      },
    },
  };
  speakingTexts = {
    boy: {
      present: {
        1: 'ucznia (przynależność) wymowa się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        2: 'ucznia (przynależność) wymowa znacznie się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        3: 'ucznia (przynależność) wymowa zauważalnie się poprawiła a wypowiedzi nabrały większej płynności, chociaż wciąż zdarzy mu się „zaciąć” i nad tym dalej należy pracować.',
        4: 'ucznia (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż zdarzy mu się „zaciąć” lub pewne słowa wymówić niepoprawnie i nad tym dalej należy pracować.',
        5: 'ucznia (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż czasami zdarzy mu się „zaciąć” i nad tym dalej należy pracować.',
      },
      past: {
        1: 'uczeń (mianownik) wielu słów nie wymawiał poprawnie, co znacznie utrudniało komunikację oraz często mieszał polski z angielskim.',
        2: 'uczeń (mianownik) części słów nie wymawiał poprawnie, co utrudniało komunikację oraz często mieszał polski z angielskim.',
        3: 'uczeń (mianownik) słowa zwykle wymawiał dość poprawnie, ale czasami mieszał polski z angielskim.',
        4: 'uczeń (mianownik) słowa wymawiał poprawnie, ale czasami mieszał polski z angielskim.',
        5: 'uczeń (mianownik) słowa wymawiał w większości poprawnie, choć wypowiedzi nie zawsze były płynne.',
      },
    },
    girl: {
      present: {
        1: 'uczennicy (przynależność) wymowa się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        2: 'uczennicy (przynależność) wymowa znacznie się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        3: 'uczennicy (przynależność) wymowa zauważalnie się poprawiła a wypowiedzi nabrały większej płynności, chociaż wciąż zdarzy jej się „zaciąć” i nad tym dalej należy pracować.',
        4: 'uczennicy (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż zdarzy jej się „zaciąć” lub pewne słowa wymówić niepoprawnie i nad tym dalej należy pracować.',
        5: 'uczennicy (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż czasami zdarzy jej się „zaciąć” i nad tym dalej należy pracować.',
      },
      past: {
        1: 'uczennica (mianownik) wielu słów nie wymawiała poprawnie, co znacznie utrudniało komunikację oraz często mieszała polski z angielskim.',
        2: 'uczennica (mianownik) części słów nie wymawiała poprawnie, co utrudniało komunikację oraz często mieszała polski z angielskim.',
        3: 'uczennica (mianownik) słowa zwykle wymawiała dość poprawnie, ale czasami mieszała polski z angielskim.',
        4: 'uczennica (mianownik) słowa wymawiała poprawnie, ale czasami mieszała polski z angielskim.',
        5: 'uczennica (mianownik) słowa wymawiała w większości poprawnie, choć wypowiedzi nie zawsze były płynne.',
      },
    },
    male: {
      past: {
        1: 'Uczeń (mianownik) wielu słów nie wymawiał poprawnie, co znacznie utrudniało komunikację, a sposób mówienia cechował brak płynności i częste pauzy.',
        2: 'Uczeń (mianownik) części słów nie wymawiał poprawnie, co utrudniało komunikację, a sposób mówienia cechował brak płynności i pauzy.',
        3: 'Uczeń (mianownik) słowa zwykle wymawiał dość poprawnie, ale sposób mówienia cechował brak płynności i pauzy, co utrudniało komunikację.',
        4: 'Uczeń (mianownik) słowa wymawiał poprawnie, ale często wypowiedzi brakowało płynności i pojawiały się pauzy, co utrudniało komunikację.',
        5: 'Uczeń (mianownik) słowa wymawiał w większości poprawnie i wypowiedzi były dość płynne, choć czasami pojawiały się pauzy, co utrudniało komunikację.',
      },
      present: {
        1: 'ucznia (przynależność) wymowa się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        2: 'ucznia (przynależność) wymowa znacznie się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        3: 'ucznia (przynależność) wymowa zauważalnie się poprawiła a wypowiedzi nabrały większej płynności, chociaż wciąż zdarzy mu się „zaciąć” i nad tym dalej należy pracować.',
        4: 'ucznia (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż zdarzy mu się „zaciąć” lub pewne słowa wymówić niepoprawnie i nad tym dalej należy pracować.',
        5: 'ucznia (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż zdarzy mu się „zaciąć” i nad tym dalej należy pracować.',
      },
    },
    female: {
      past: {
        1: 'uczennica (mianownik) wielu słów nie wymawiała poprawnie, co znacznie utrudniało komunikację, a sposób mówienia cechował brak płynności i częste pauzy.',
        2: 'uczennica (mianownik) części słów nie wymawiała poprawnie, co utrudniało komunikację, a sposób mówienia cechował brak płynności i pauzy.',
        3: 'uczennica (mianownik) słowa zwykle wymawiała dość poprawnie, ale sposób mówienia cechował brak płynności i pauzy, co utrudniało komunikację.',
        4: 'uczennica (mianownik) słowa wymawiała poprawnie, ale często wypowiedzi brakowało płynności i pojawiały się pauzy, co utrudniało komunikację.',
        5: 'uczennica (mianownik) słowa wymawiała w większości poprawnie wypowiedzi były płynne, choć czasami pojawiały się pauzy, co utrudniało komunikację.',
      },
      present: {
        1: 'uczennicy (przynależność) wymowa się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        2: 'uczennicy (przynależność) wymowa znacznie się poprawiła, chociaż należy dalej pracować nad poprawieniem płynności.',
        3: 'uczennicy (przynależność) wymowa zauważalnie się poprawiła a wypowiedzi nabrały większej płynności, chociaż wciąż zdarzy jej się „zaciąć” i nad tym dalej należy pracować.',
        4: 'uczennicy (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż zdarzy jej się „zaciąć” lub pewne słowa wymówić niepoprawnie i nad tym dalej należy pracować.',
        5: 'uczennicy (przynależność) wymowa bardzo się poprawiła a wypowiedzi nabrały płynności, chociaż wciąż zdarzy jej się „zaciąć” i nad tym dalej należy pracować.',
      },
    },
  };
  practicalSkillsTexts = {
    boy: {
      present: {
        1: 'łatwiej jest już uczniowi (celownik) komunikatywnie przekazać coś po angielsku i zmniejszyła się bariera mówienia i braki słownictwa.',
        2: 'dużo łatwiej jest już uczniowi (celownik) komunikatywnie przekazać coś po angielsku i znacznie zmniejszyła się bariera mówienia i braki słownictwa.',
        3: 'uczeń (mianownik) jest już w stanie komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        4: 'uczeń (mianownik) jest już w stanie dość swobodnie i komunikatywnie przekazać proste komunikaty po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        5: 'uczeń (mianownik) jest już w stanie dość swobodnie i komunikatywnie przekazać dość proste komunikaty po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
      },
      past: {
        1: 'bardzo trudno było uczniowi (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa uniemożliwiały praktyczne wykorzystanie języka.',
        2: 'trudno było uczniowi (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa często uniemożliwiały praktyczne wykorzystanie języka.',
        3: 'uczeń (mianownik) potrafił komunikatywnie przekazać coś po angielsku, choć w dużej części musiał polegać na gestach lub wtrącał polskie słówka.',
        4: 'uczeń (mianownik) potrafił komunikatywnie przekazać coś po angielsku posługując się dość trafnymi zwrotami, choć czasami ze względu na braki słownictwa musiał polegać na gestach lub wtrącał polskie słówka.',
        5: 'uczeń (mianownik) potrafił komunikatywnie przekazać coś po angielsku często posługując się dość trafnymi i naturalnymi zwrotami.',
      },
    },
    girl: {
      present: {
        1: 'łatwiej jest już uczennicy (celownik) komunikatywnie przekazać coś po angielsku i zmniejszyła się bariera mówienia i braki słownictwa.',
        2: 'dużo łatwiej jest już uczennicy (celownik) komunikatywnie przekazać coś po angielsku i znacznie zmniejszyła się bariera mówienia i braki słownictwa.',
        3: 'uczennica (mianownik) jest już w stanie komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        4: 'uczennica (mianownik) jest już w stanie dość swobodnie i komunikatywnie przekazać proste komunikaty po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        5: 'uczennica (mianownik) jest już w stanie dość swobodnie i komunikatywnie przekazać dość proste komunikaty po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
      },
      past: {
        1: 'bardzo trudno było uczennicy (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa uniemożliwiały praktyczne wykorzystanie języka.',
        2: 'trudno było uczennicy (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa często uniemożliwiały praktyczne wykorzystanie języka.',
        3: 'uczennica (mianownik) potrafiła komunikatywnie przekazać coś po angielsku, choć w dużej części musiała polegać na gestach lub wtrącała polskie słówka.',
        4: 'uczennica (mianownik) potrafiła komunikatywnie przekazać coś po angielsku posługując się dość trafnymi zwrotami, choć czasami ze względu na braki słownictwa musiała polegać na gestach lub wtrącała polskie słówka.',
        5: 'uczennica (mianownik) potrafiła komunikatywnie przekazać coś po angielsku często posługując się dość trafnymi i naturalnymi zwrotami.',
      },
    },
    male: {
      past: {
        1: 'bardzo trudno było uczniowi (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa uniemożliwiały praktyczne wykorzystanie języka.',
        2: 'trudno było uczniowi (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa często uniemożliwiały praktyczne wykorzystanie języka.',
        3: 'uczeń (mianownik) potrafił komunikatywnie przekazać coś po angielsku, choć w dużej części musiał polegać na gestach lub wtrącał polskie słówka.',
        4: 'uczeń (mianownik) potrafił komunikatywnie przekazać coś po angielsku posługując się dość trafnymi zwrotami, choć czasami ze względu na braki słownictwa musiał polegać na gestach lub wtrącał polskie słówka.',
        5: 'uczeń (mianownik) potrafił komunikatywnie przekazać coś po angielsku w większości posługując się dość trafnymi i naturalnymi zwrotami.',
      },
      present: {
        1: 'łatwiej jest już uczniowi (celownik) komunikatywnie przekazać coś po angielsku i zmniejszyła się bariera mówienia i braki słownictwa.',
        2: 'dużo łatwiej jest już uczniowi (celownik) komunikatywnie przekazać coś po angielsku i znacznie zmniejszyła się bariera mówienia i braki słownictwa.',
        3: 'uczeń (mianownik) jest już w stanie dość swobodnie i komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        4: 'uczeń (mianownik) jest już w stanie swobodnie i komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        5: 'uczeń (mianownik) jest już w stanie zupełnie swobodnie i komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
      },
    },
    female: {
      past: {
        1: 'bardzo trudno było uczennicy (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa uniemożliwiały praktyczne wykorzystanie języka.',
        2: 'trudno było uczennicy (celownik) komunikatywnie przekazać coś po angielsku a bariera mówienia i braki słownictwa często uniemożliwiały praktyczne wykorzystanie języka.',
        3: 'uczennica (mianownik) potrafiła komunikatywnie przekazać coś po angielsku, choć w dużej części musiała polegać na gestach lub wtrącała polskie słówka.',
        4: 'uczennica (mianownik) potrafiła komunikatywnie przekazać coś po angielsku posługując się dość trafnymi zwrotami, choć czasami ze względu na braki słownictwa musiała polegać na gestach lub wtrącała polskie słówka.',
        5: 'uczennica (mianownik) potrafiła komunikatywnie przekazać coś po angielsku w większości posługując się dość trafnymi i naturalnymi zwrotami.',
      },
      present: {
        1: 'łatwiej jest już uczennicy (celownik) komunikatywnie przekazać coś po angielsku i zmniejszyła się bariera mówienia i braki słownictwa.',
        2: 'dużo łatwiej jest już uczennicy (celownik) komunikatywnie przekazać coś po angielsku i znacznie zmniejszyła się bariera mówienia i braki słownictwa.',
        3: 'uczennica (mianownik) jest już w stanie dość swobodnie i komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        4: 'uczennica (mianownik) jest już w stanie swobodnie i komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
        5: 'uczennica (mianownik) jest już w stanie zupełnie swobodnie i komunikatywnie przekazać coś po angielsku, lecz warto popracować nad poszerzeniem słownictwa i struktur.',
      },
    },
  };
  schoolSkillsTexts = {
    boy: {
      present: {
        1: 'uczeń (mianownik) nadrobił sporą część braków, jednak wciąż sporo zagadnień sprawia mu większy problem i warto poświęcić im więcej uwagi',
        2: 'uczeń (mianownik) nadrobił przeważającą część braków, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
        3: 'uczeń (mianownik) nadrobił braki z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
        4: 'uczeń (mianownik) dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
        5: 'uczeń (mianownik) bardzo dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
      },
      past: {
        1: 'uczeń (mianownik) miał bardzo duże braki i nie miał opanowanych wielu zagadnień z podstawy programowej dla jego grupy wiekowej.',
        2: 'uczeń (mianownik) miał dość duże braki i nie miał opanowanych części zagadnień z podstawy programowej dla jego grupy wiekowej.',
        3: 'uczeń (mianownik) miał opanowaną dużą część zagadnień z podstawy programowej dla jego grupy wiekowej, choć z niektórymi miał trudności.',
        4: 'uczeń (mianownik) miał opanowaną większość zagadnień z podstawy programowej dla jego grupy wiekowej, choć z niektórymi miał trudności.',
        5: 'uczeń (mianownik) miał opanowaną większość zagadnień z podstawy programowej dla jego grupy wiekowej, i z większością nie miał zbyt dużych trudności.',
      },
    },
    girl: {
      present: {
        1: 'uczennica (mianownik) nadrobiła sporą część braków, jednak wciąż sporo zagadnień sprawia jej większy problem i warto poświęcić im więcej uwagi.',
        2: 'uczennica (mianownik) nadrobiła przeważającą część braków, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
        3: 'uczennica (mianownik) nadrobiła braki z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
        4: 'uczennica (mianownik) dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
        5: 'uczennica (mianownik) bardzo dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
      },
      past: {
        1: 'uczennica (mianownik) miała bardzo duże braki i nie miała opanowanych wielu zagadnień z podstawy programowej dla jej grupy wiekowej',
        2: 'uczennica (mianownik) miała dość duże braki i nie miała opanowanych części zagadnień z podstawy programowej dla jej grupy wiekowej.',
        3: 'uczennica (mianownik) miała opanowaną większość zagadnień z podstawy programowej dla jej grupy wiekowej, choć z niektórymi miała trudności.',
        4: 'uczennica (mianownik) dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
        5: 'uczennica (mianownik) miała opanowaną większość zagadnień z podstawy programowej dla jej grupy wiekowej, i z większością nie miała zbyt dużych trudności.',
      },
    },
    male: {
      past: {
        1: 'uczeń (mianownik) miał bardzo duże braki i nie miał opanowanych wielu zagadnień z podstawy programowej dla jego grupy wiekowej.',
        2: 'uczeń (mianownik) miał dość duże braki i nie miał opanowanych części zagadnień z podstawy programowej dla jego grupy wiekowej.',
        3: 'uczeń (mianownik) miał opanowaną dużą część zagadnień z podstawy programowej dla jego grupy wiekowej, choć z niektórymi miał trudności.',
        4: 'uczeń (mianownik) miał opanowaną większość zagadnień z podstawy programowej dla jego grupy wiekowej, choć z niektórymi miał trudności.',
        5: 'uczeń (mianownik) miał opanowaną większość zagadnień z podstawy programowej dla jego grupy wiekowej i z większością nie miał zbyt dużych trudności.',
      },
      present: {
        1: 'uczeń (mianownik) nadrobił sporą część braków, jednak wciąż sporo zagadnień sprawia mu większy problem i warto poświęcić im więcej uwagi.',
        2: 'uczeń (mianownik) nadrobił przeważającą część braków, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
        3: 'uczeń (mianownik) nadrobił braki z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
        4: 'uczeń (mianownik) dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
        5: 'uczeń (mianownik) bardzo dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają mu większy problem i warto poświęcić im więcej uwagi.',
      },
    },
    female: {
      past: {
        1: 'uczennica (mianownik) miała bardzo duże braki i nie miała opanowanych wielu zagadnień z podstawy programowej dla jej grupy wiekowej.',
        2: 'uczennica (mianownik) miała dość duże braki i nie miała opanowanych części zagadnień z podstawy programowej dla jej grupy wiekowej.',
        3: 'uczennica (mianownik) miała opanowaną dużą część zagadnień z podstawy programowej dla jej grupy wiekowej, choć z niektórymi miała trudności.',
        4: 'uczennica (mianownik) miała opanowaną większość zagadnień z podstawy programowej dla jej grupy wiekowej, choć z niektórymi miała trudności.',
        5: 'uczennica (mianownik) miała opanowaną większość zagadnień z podstawy programowej dla jej grupy wiekowej i z większością nie miała zbyt dużych trudności.',
      },
      present: {
        1: 'uczennica (mianownik) nadrobiła sporą część braków, jednak wciąż sporo zagadnień sprawia jej większy problem i warto poświęcić im więcej uwagi.',
        2: 'uczennica (mianownik) nadrobiła przeważającą część braków, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
        3: 'uczennica (mianownik) nadrobiła braki z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
        4: 'uczennica (mianownik) dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
        5: 'uczennica (mianownik) bardzo dobrze radzi sobie z zagadnieniami z podstawy programowej, jednak wciąż pewne zagadnienia sprawiają jej większy problem i warto poświęcić im więcej uwagi.',
      },
    },
  };
  homeworkTexts = {
    boy: {
      present: {
        1: 'Poza nauką podczas lekcji uczeń (mianownik) nie pracował w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        2: 'Poza nauką podczas lekcji uczeń (mianownik) bardzo rzadko pracował w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        3: 'Poza nauką podczas lekcji uczeń (mianownik) czasami pracował w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        4: 'Poza nauką podczas lekcji uczeń (mianownik) dość często pracował w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        5: 'Poza nauką podczas lekcji uczeń (mianownik) regularnie pracował w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
      },
    },
    girl: {
      present: {
        1: 'Poza nauką podczas lekcji uczennica (mianownik) nie pracowała w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        2: 'Poza nauką podczas lekcji uczennica (mianownik) bardzo rzadko pracowała w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        3: 'Poza nauką podczas lekcji uczennica (mianownik) czasami pracowała w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        4: 'Poza nauką podczas lekcji uczennica (mianownik) dość często pracowała w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        5: 'Poza nauką podczas lekcji uczennica (mianownik) regularnie pracowała w domu. Zachęcamy do odrabiania dodatkowych zadań domowych w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
      },
    },
    male: {
      present: {
        1: 'Poza nauką podczas lekcji uczeń (mianownik) nie pracował w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        2: 'Poza nauką podczas lekcji uczeń (mianownik) bardzo rzadko pracował w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        3: 'Poza nauką podczas lekcji uczeń (mianownik) czasami pracował w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        4: 'Poza nauką podczas lekcji uczeń (mianownik) dość często pracował w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        5: 'Poza nauką podczas lekcji uczeń (mianownik) regularnie pracował w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
      },
    },
    female: {
      present: {
        1: 'Poza nauką podczas lekcji uczennica (mianownik) nie pracowała w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        2: 'Poza nauką podczas lekcji uczennica (mianownik) bardzo rzadko pracowała w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        3: 'Poza nauką podczas lekcji uczennica (mianownik) czasami pracowała w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        4: 'Poza nauką podczas lekcji uczennica (mianownik) dość często pracowała w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
        5: 'Poza nauką podczas lekcji uczennica (mianownik) regularnie pracowała w domu. Zachęcamy do korzystania z aplikacji do nauki w celu utrwalenia treści poznawanych na lekcji oraz przyspieszenia procesu zapamiętywania.',
      },
    },
  };
  attendanceTexts = {
    male: {
      present: {
        1: 'uczeń (mianownik) bardzo często był nieobecny na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        2: 'uczeń (mianownik) dość często był nieobecny na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        3: 'uczeń (mianownik) zazwyczaj był obecny na zajęciach, choć wyższa frekwencja pomogłaby zwiększyć tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        4: 'uczeń (mianownik) zwykle był obecny na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez niego postępów, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        5: 'uczeń (mianownik) prawie zawsze był obecny na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez niego postępów, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
      },
    },
    female: {
      present: {
        1: 'Uczennica (mianownik) bardzo często była nieobecna na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        2: 'Uczennica (mianownik) dość często była nieobecna na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        3: 'Uczennica (mianownik) zazwyczaj była obecna na zajęciach, choć wyższa frekwencja pomogłaby zwiększyć tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        4: 'Uczennica (mianownik) zwykle była obecna na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez nią postępów języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        5: 'Uczennica (mianownik) prawie zawsze była obecna na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez nią postępów języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
      },
    },
    boy: {
      present: {
        1: 'uczeń (mianownik) bardzo często był nieobecny na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        2: 'uczeń (mianownik) dość często był nieobecny na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        3: 'uczeń (mianownik) zazwyczaj był obecny na zajęciach, choć wyższa frekwencja pomogłaby zwiększyć tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        4: 'uczeń (mianownik) zwykle był obecny na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez niego postępów, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        5: 'uczeń (mianownik) prawie zawsze był obecny na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez niego postępów, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
      },
    },
    girl: {
      present: {
        1: 'Uczennica (mianownik) bardzo często była nieobecna na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        2: 'Uczennica (mianownik) dość często była nieobecna na zajęciach, co mogło spowolnić tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        3: 'Uczennica (mianownik) zazwyczaj była obecna na zajęciach, choć wyższa frekwencja pomogłaby zwiększyć tempo przyswajania języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        4: 'Uczennica (mianownik) zwykle była obecna na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez nią postępów języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
        5: 'Uczennica (mianownik) prawie zawsze była obecna na zajęciach, co w znacznym stopniu przyczyniło się do poczynionych przez nią postępów języka, ponieważ systematyczność jest kluczowa dla osiągnięcia satysfakcjonujących efektów.',
      },
    },
  };
  activityTexts = {
    male: {
      present: {
        1: 'uczeń (mianownik) jest już mniej wycofany, choć bez zachęty wciąż rzadko się udziela.',
        2: 'uczeń (mianownik) nie jest już wycofany, choć bez zachęty wciąż dość rzadko się udziela.',
        3: 'uczeń (mianownik) aktywnie uczestniczy w lekcji i spontanicznie się udziela.',
        4: 'uczeń (mianownik) jest aktywny i chętnie zabiera głos.',
        5: 'uczeń (mianownik) jest bardzo aktywny i często spontanicznie zabiera głos.',
      },
      past: {
        1: 'uczeń (mianownik) bardzo był skryty i wycofany oraz dość niechętnie zabierał głos.',
        2: 'uczeń (mianownik) był dość skryty i wycofany oraz stosunkowo niechętnie zabierał głos.',
        3: 'uczeń (mianownik) był odrobinę skryty i wycofany, ale zachęcony dość chętnie zabierał głos.',
        4: 'uczeń (mianownik) był bardzo aktywny i zachęcony chętnie zabierał głos.',
        5: 'uczeń (mianownik) bardzo był bardzo aktywny i zachęcony chętnie zabierał głos.',
      },
      boy: {
        present: {
          1: 'uczeń (mianownik) jest już mniej wycofany, choć bez zachęty wciąż rzadko się udziela.',
          2: 'uczeń (mianownik) nie jest już wycofany, choć bez zachęty wciąż dość rzadko się udziela.',
          3: 'uczeń (mianownik) aktywnie uczestniczy w lekcji i spontanicznie się udziela.',
          4: 'uczeń (mianownik) jest aktywny i chętnie zabiera głos.',
          5: 'uczeń (mianownik) jest bardzo aktywny i często spontanicznie zabiera głos.',
        },
        past: {
          1: 'uczeń (mianownik) bardzo był skryty i wycofany oraz dość niechętnie zabierał głos.',
          2: 'uczeń (mianownik) był dość skryty i wycofany oraz stosunkowo niechętnie zabierał głos.',
          3: 'uczeń (mianownik) był odrobinę skryty i wycofany, ale zachęcony dość chętnie zabierał głos.',
          4: 'uczeń (mianownik) był bardzo aktywny i zachęcony chętnie zabierał głos.',
          5: 'uczeń (mianownik) bardzo był bardzo aktywny i zachęcony chętnie zabierał głos.',
        },
      },
    },
    female: {
      present: {
        1: 'uczennica (mianownik) jest już mniej wycofana, choć bez zachęty wciąż rzadko się udziela.',
        2: 'uczennica (mianownik) nie jest już wycofana, choć bez zachęty wciąż dość rzadko się udziela.',
        3: 'uczennica (mianownik) aktywnie uczestniczy w lekcji i spontanicznie się udziela.',
        4: 'uczennica (mianownik) jest aktywna i chętnie zabiera głos.',
        5: 'uczennica (mianownik) jest bardzo aktywna i często spontanicznie zabiera głos.',
      },
      past: {
        1: 'uczennica (mianownik) była bardzo skryta i wycofana oraz dość niechętnie zabierała głos.',
        2: 'uczennica (mianownik) była dość skryta i wycofana oraz stosunkowo niechętnie zabierała głos.',
        3: 'uczennica (mianownik) była odrobinę skryta i wycofana, ale zachęcona dość chętnie zabierała głos.',
        4: 'uczennica (mianownik) była bardzo aktywna i zachęcona chętnie zabierała głos.',
        5: 'uczennica (mianownik) bardzo była bardzo aktywna i zachęcona chętnie zabierała głos.',
      },
    },
    girl: {
      present: {
        1: 'uczennica (mianownik) jest już mniej wycofana, choć bez zachęty wciąż rzadko się udziela.',
        2: 'uczennica (mianownik) nie jest już wycofana, choć bez zachęty wciąż dość rzadko się udziela.',
        3: 'uczennica (mianownik) aktywnie uczestniczy w lekcji i spontanicznie się udziela.',
        4: 'uczennica (mianownik) jest aktywna i chętnie zabiera głos.',
        5: 'uczennica (mianownik) jest bardzo aktywna i często spontanicznie zabiera głos.',
      },
      past: {
        1: 'uczennica (mianownik) była bardzo skryta i wycofana oraz dość niechętnie zabierała głos.',
        2: 'uczennica (mianownik) była dość skryta i wycofana oraz stosunkowo niechętnie zabierała głos.',
        3: 'uczennica (mianownik) była odrobinę skryta i wycofana, ale zachęcona dość chętnie zabierała głos.',
        4: 'uczennica (mianownik) była bardzo aktywna i zachęcona chętnie zabierała głos.',
        5: 'uczennica (mianownik) bardzo była bardzo aktywna i zachęcona chętnie zabierała głos.',
      },
    },
  };
  constructor() {}
  createProgressbar(title, width: number, color: string, value: number) {
    let progressbarCode = '';
    let stars = '';
    for (let index = 0; index < value; index++) {
      stars = stars + '<span>⭐</span>';
    }

    progressbarCode =
      progressbarCode +
      '<table style="width:' +
      '100' +
      '%">' +
      '<span style="font-weight: bold; margin-top: 10px;" >' +
      title +
      '<span style="width:' +
      width +
      '%">' +
      '<tr><td style="width:';

    progressbarCode =
      progressbarCode +
      width +
      '%; height:10px; border:1px solid gray;"><div style="width:';

    progressbarCode =
      progressbarCode +
      value * 20 +
      '%; height:5px;box-sizing: border-box; border:6px solid' +
      color +
      '; background-color:' +
      color +
      ' "></div></td></table>';

    return progressbarCode;
  }
  createDoubleProgressbar(
    property,
    presentReport,
    pastReport,
    color1?,
    color2?
  ) {
    let doubleProgressBarCode =
      '<br>' +
      '<table style="width:80%"><tr><td style="width:45%"><div>' +
      this.createProgressbar('Wcześniej', 100, color2, pastReport[property]) +
      '</td><td style="width:45%"><div style="margin-left:2vw">' +
      this.createProgressbar('Teraz', 100, color1, presentReport[property]) +
      '</div></div></td></tr></table>';
    return doubleProgressBarCode;
  }
  createSingleProgressbar(property, presentReport, color?) {
    let singleProgressbarCode =
      '<br>' +
      '<table style="width:80%"><tr><td><div>' +
      this.createProgressbar('Teraz', 80, color, presentReport[property]) +
      '</div></td></tr></table>';
    return singleProgressbarCode;
  }
  generateHtml(
    pastReport: Report,
    presentReport: Report,
    studentName,
    smallChild,
    joinedLater,
    studentGender
  ) {
    let gender = '';
    if (studentGender == 'male') {
      if (smallChild) {
        gender = 'boy';
      } else {
        gender = 'male';
      }
    }
    if (studentGender == 'female') {
      if (smallChild) {
        gender = 'girl';
      } else {
        gender = 'female';
      }
    }
    if (!studentGender) {
      alert('Wybierz płeć!');
    }

    let html = '<h2>' + this.heading + '</h2>';
    let laterProperty = joinedLater ? 'joinedLater' : 'wholeYear';
    let generatedhtml = '';
    html =
      html +
      this.description[gender][laterProperty].description +
      ' ' +
      this.additionalText +
      '<hr>';

    // reading
    if (presentReport.reading && pastReport?.reading) {
      generatedhtml =
        generatedhtml + '<strong style="color:#00bcd4">Czytanie</strong><br>';
      generatedhtml =
        generatedhtml +
        this.description[gender][laterProperty].startYearText +
        ' ';
      generatedhtml =
        generatedhtml +
        this.readingTexts[gender]['past'][pastReport.reading] +
        ' ';
      generatedhtml =
        generatedhtml + this.description[gender][laterProperty].nowText + ' ';
      generatedhtml =
        generatedhtml +
        this.readingTexts[gender]['present'][presentReport.reading] +
        ' ';

      //progressbars
      generatedhtml =
        generatedhtml +
        this.createDoubleProgressbar(
          'reading',
          presentReport,
          pastReport,
          '#4dd0e1',
          '#80deea'
        );
    }
    //listening
    if (presentReport.listening && pastReport.listening) {
      generatedhtml =
        generatedhtml +
        '<hr><strong style="color:#9c27b0">Słuchanie</strong><br>';

      generatedhtml =
        generatedhtml +
        this.description[gender].joiners.additive[0][0].toUpperCase() +
        this.description[gender].joiners.additive[0].slice(1) +
        ' ';
      generatedhtml =
        generatedhtml +
        this.listeningTexts[gender]['past'][pastReport.listening] +
        ' ';
      generatedhtml =
        generatedhtml + this.description[gender][laterProperty].nowText + ' ';
      generatedhtml =
        generatedhtml +
        this.listeningTexts[gender]['present'][presentReport.listening];
      //progressbars
      generatedhtml =
        generatedhtml +
        this.createDoubleProgressbar(
          'listening',
          presentReport,
          pastReport,
          '#9c27b0',
          '#ce93d8'
        );
    }

    //writing
    if (presentReport.writing && pastReport.writing) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#4caf50">Pisanie</strong><br>';
      generatedhtml =
        generatedhtml +
        this.description[gender][laterProperty].startYearText +
        ' ';
      generatedhtml =
        generatedhtml +
        this.writingTexts[gender]['past'][pastReport.writing] +
        ' ';
      generatedhtml =
        generatedhtml + this.description[gender][laterProperty].nowText + ' ';
      generatedhtml =
        generatedhtml +
        this.readingTexts[gender]['present'][presentReport.writing] +
        ' ';
      //progressbars
      generatedhtml =
        generatedhtml +
        this.createDoubleProgressbar(
          'writing',
          presentReport,
          pastReport,
          '#4caf50',
          '#a5d6a7'
        );
    }
    //speaking
    if (presentReport.speaking && pastReport.speaking) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#ef6c00">Mówienie</strong><br>';

      generatedhtml =
        generatedhtml +
        this.description[gender].joiners.additive[1][0].toUpperCase() +
        this.description[gender].joiners.additive[1].slice(1) +
        ' ';
      generatedhtml =
        generatedhtml +
        this.speakingTexts[gender]['past'][pastReport.speaking] +
        ' ';
      generatedhtml =
        generatedhtml + this.description[gender][laterProperty].nowText + ' ';
      generatedhtml =
        generatedhtml +
        this.speakingTexts[gender]['present'][presentReport.speaking];
      //progressbars
      generatedhtml =
        generatedhtml +
        this.createDoubleProgressbar(
          'speaking',
          presentReport,
          pastReport,
          '#ff9800',
          '#ffcc80'
        );
    }
    //practical skills
    if (presentReport.practicalSkills && pastReport.practicalSkills) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#2196f3">Umiejętności praktyczne</strong><br>';
      generatedhtml =
        generatedhtml +
        this.description[gender].joiners.additive[0][0].toUpperCase() +
        this.description[gender].joiners.additive[0].slice(1) +
        ' ';
      generatedhtml =
        generatedhtml +
        this.practicalSkillsTexts[gender]['past'][pastReport.practicalSkills] +
        ' ';
      generatedhtml =
        generatedhtml + this.description[gender][laterProperty].nowText + ' ';
      generatedhtml =
        generatedhtml +
        this.practicalSkillsTexts[gender]['present'][
          presentReport.practicalSkills
        ] +
        ' ';
      //progressbars
      generatedhtml =
        generatedhtml +
        this.createDoubleProgressbar(
          'practicalSkills',
          presentReport,
          pastReport,
          '#2196f3',
          '#90caf9'
        );
    }
    //school skills
    if (presentReport.schoolSkills && pastReport.schoolSkills) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#e91e63">Materiał szkolny</strong><br>';
      generatedhtml =
        generatedhtml +
        this.description[gender].joiners.additive[2][0].toUpperCase() +
        this.description[gender].joiners.additive[2].slice(1) +
        ' ';
      generatedhtml =
        generatedhtml +
        this.schoolSkillsTexts[gender]['past'][pastReport.schoolSkills];
      generatedhtml =
        generatedhtml +
        this.schoolSkillsTexts[gender]['present'][presentReport.schoolSkills];
      //progressbars
      generatedhtml =
        generatedhtml +
        this.createDoubleProgressbar(
          'schoolSkills',
          presentReport,
          pastReport,
          '#e91e63',
          '#f48fb1'
        );
    }

    //activity
    if (presentReport.activity && pastReport.activity) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#009688">Aktywność na zajęciach</strong><br>';
      generatedhtml =
        generatedhtml +
        this.description[gender][laterProperty].startYearText +
        ' ';
      generatedhtml =
        generatedhtml +
        this.activityTexts[gender]['past'][pastReport.activity] +
        ' ';
      generatedhtml =
        generatedhtml + this.description[gender][laterProperty].nowText + ' ';

      generatedhtml =
        generatedhtml +
        this.activityTexts[gender]['present'][presentReport.activity] +
        ' ';
      //progressbars
      generatedhtml =
        generatedhtml +
        this.createDoubleProgressbar(
          'activity',
          presentReport,
          pastReport,
          '#009688',
          '#80cbc4'
        );
    }
    //attendance
    if (presentReport.attendance && pastReport.attendance) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#7e57c2">Obecność</strong><br>';
      generatedhtml =
        generatedhtml +
        this.attendanceTexts[gender]['present'][presentReport.attendance] +
        ' ';

      //progressbars
      generatedhtml =
        generatedhtml +
        this.createSingleProgressbar('attendance', presentReport, '#7e57c2');
    }
    //homework
    if (presentReport.homework && pastReport.homework) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#5c6bc0">Zadania domowe</strong><br>';
      generatedhtml =
        generatedhtml +
        this.homeworkTexts[gender]['present'][presentReport.homework] +
        ' ';

      //progressbars
      generatedhtml =
        generatedhtml +
        this.createSingleProgressbar('homework', presentReport, '#5c6bc0');
    }
    //teacherComments
    if (pastReport.teachersComment?.length > 2) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#03a9f4">Dodatkowe informacje (wcześniej)</strong><br>';
      generatedhtml = generatedhtml + pastReport.teachersComment;
    }
    if (presentReport.teachersComment?.length > 2) {
      generatedhtml =
        generatedhtml +
        '<br><hr><strong style="color:#01579b">Dodatkowe informacje (teraz)</strong><br>';
      generatedhtml = generatedhtml + presentReport.teachersComment;
    }

    generatedhtml = generatedhtml
      .replace(/[{()}]/g, '')
      .replace(/Uczeń/gi, '')
      .replace(/Ucznia/gi, '')
      .replace(/Uczniowi/gi, '')
      .replace(/Uczennica/gi, '')
      .replace(/Uczennicy/gi, '')
      .replace(/mianownik/gi, studentName)
      .replace(/celownik/gi, this.description[gender].celownik + ' ')
      .replace(/dopełniacz/gi, this.description[gender].dopelniacz + ' ')
      .replace(/przynależność/gi, this.description[gender].przynaleznosc + ' ');
    html =
      html
        .replace(/[{()}]/g, '')
        .replace(/Uczennica/gi, '')
        .replace(/Uczeń/gi, '')
        .replace(/mianownik/gi, studentName)
        .replace(/undefined/gi, 'nie dotyczy ') +
      generatedhtml +
      '<hr> Pozdrawiamy serdecznie,<br>Centrum Języków Obcych Ling King';
    return html;
  }
}
