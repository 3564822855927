<div style="height: calc(100vh - 64px)" fxLayout="column" class="m-3">
  <mat-card fxLayout="row wrap" style="width: 100%" class="mb-3">
    <div fxLayout="row wrap" class="" fxLayoutAlign="start center">
      <mat-form-field
        *ngIf="editMode"
        style="margin-bottom: -1.25em"
        appearance="outline"
      >
        <mat-label>Lektor</mat-label>
        <mat-select [(value)]="selectedTeacher">
          <mat-option [value]="teacher" *ngFor="let teacher of teachers">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <img height="30px" [src]="teacher.avatar" alt="" />
              <span>
                {{ teacher.name }}
              </span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="d-flex flex-row" style="width: 88vw">
        <mat-form-field
          appearance="outline"
          class="ml-2"
          style="margin-bottom: -1.25em; width: 40%"
        >
          <mat-label>Dni</mat-label>
          <mat-select multiple (selectionChange)="setVisibleDays($event)">
            <mat-option value="1">Poniedziałek</mat-option>
            <mat-option value="2">Wtorek</mat-option>
            <mat-option value="3">Środa</mat-option>
            <mat-option value="4">Czwartek</mat-option>
            <mat-option value="5">Piątek</mat-option>
          </mat-select>
        </mat-form-field>
        <div
          fxLayout="row wrap"
          class="ml-2"
          style="overflow-x: auto; white-space: nowrap; width: 50%"
        >
          <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Lektorzy</mat-label>
            <mat-select
              multiple
              (selectionChange)="filterEventsForSelectedTeachers($event)"
            >
              <mat-option
                *ngFor="let teacher of activeTeachers"
                [value]="teacher.teacherId"
              >
                <div
                  fxLayout="row"
                  fxLayoutGap="5px"
                  fxLayoutAlign="center center"
                >
                  <img
                    style="width: 30px; height: 30px"
                    [src]="teacher.avatar"
                  />
                  <span>{{ teacher.name }}</span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          class="mt-2"
          mat-icon-button
          color="warn"
          *ngIf="editMode"
          (click)="setEditMode(true)"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          class="mt-2"
          mat-icon-button
          color="primary"
          *ngIf="!editMode"
          (click)="setEditMode(false)"
        >
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div class="ml-3">
        <mat-checkbox
          [checked]="true"
          (change)="showBydgoszczGroups()"
          id="bydgoszcz-checkbox"
          class="mt-2"
        >
          Bydgoszcz
        </mat-checkbox>

        <mat-checkbox
          (change)="showBialeBlotaGroups()"
          [checked]="true"
          id="biale-blota-checkbox"
          class="mt-2 ml-2"
        >
          Białe Błota
        </mat-checkbox>
        <button
          *ngIf="userDetailsService.owner || userDetailsService.office"
          color="primary"
          class="mb-2 ml-2"
          (click)="sendSMS()"
          mat-flat-button
        >
          Wyślij SMS
        </button>
      </div>
    </div>
  </mat-card>
  <div style="height: 50vh">
    <ng-template
      #weekViewHourSegmentTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
    >
      <div
        #segmentElement
        class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
        (mousedown)="startDragToCreate(segment, $event, segmentElement)"
      >
        <div class="cal-time" *ngIf="isTimeLabel">
          {{ segment.date | calendarDate: "weekViewHour" : locale }}
        </div>
      </div>
    </ng-template>
    <mwl-calendar-week-view
      (eventClicked)="eventClicked($event)"
      [hourSegments]="2"
      [eventSnapSize]="15"
      [dayStartHour]="8"
      [dayEndHour]="21"
      [viewDate]="viewDate"
      [events]="events"
      [hourSegmentTemplate]="weekViewHourSegmentTemplate"
      [excludeDays]="excludeDays"
      [refresh]="refresh2"
      (eventTimesChanged)="eventTimesChanged($event)"
    >
    </mwl-calendar-week-view>
  </div>
</div>
