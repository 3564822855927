<button
  (click)="this._bottomSheet.dismiss()"
  style="position: absolute; top: 0; right: 0; color: white;"
  mat-icon-button
>
  <mat-icon>home</mat-icon>
</button>
<iframe
  src="https://linget.it"
  style="border: none; width: 100%; height: 100%;"
></iframe>
