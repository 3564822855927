import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HelpersService } from '../shared/services/helpers.service';
import { AuthenticationService } from '../shared/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  userDetails = new Subject();
  smallChild = false;
  mediumChild = false;
  largeChild = false;
  _userDetails: any;
  owner = false;
  teacher = false;
  office = false;
  student = false;
  constructor(private helpers: HelpersService) {
    this.checkIfSuperTeacher();
  }

  storeUserDetails(userDetails) {
    this._userDetails = userDetails;
    console.log(
      '🚀 ~ file: user-details.service.ts:25 ~ UserDetailsService ~ storeUserDetails ~  this._userDetails',
      this._userDetails,
    );
    this.checkRole();
    // console.log("userDetails: ", userDetails);
    // if (userDetails.userData.role != "customer ") {
    //   sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
    //   this.userDetails.next(userDetails);
    //   this._userDetails = userDetails;
    // }
  }
  //
  getUserDetails() {
    // let userDetails = sessionStorage.getItem("userDetails");
    // userDetails = JSON.parse(userDetails);

    return this._userDetails;
  }
  checkAgeStatus() {
    const ud: any = this.getUserDetails();
    if (ud) {
      switch (ud.userData?.ageStatus) {
        case 'smallChild':
          this.smallChild = true;
          break;
        case 'mediumChild':
          this.mediumChild = true;
          break;
        case 'largeChild':
          this.largeChild = true;
          break;

        default:
          break;
      }

      return ud.userData?.ageStatus;
    } else {
      return null;
    }
  }
  getSimpleUserDetails() {
    const ud: any = this.getUserDetails();
    if (ud) {
      return {
        id: ud.id ? ud.id : ud._id,
        name: ud.name,
        role: ud.role,
        avatar: ud.avatar ? ud.avatar : null,
      };
    } else {
      return null;
    }
  }
  checkIfSuperTeacher() {
    let userDetails: any = sessionStorage.getItem('userDetails');

    userDetails = JSON.parse(userDetails);
    console.log('userDetails: ', userDetails);
    if (
      userDetails?.name === 'Krzysztof Jacek' ||
      userDetails?.name === 'Agnieszka Jacek'
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkRole() {
    console.log('userDetails: ', this._userDetails);
    if (this._userDetails?.role === 'owner') {
      this.owner = true;
      this.teacher = false;
      this.office = false;
      this.student = false;
    }
    if (this._userDetails?.role === 'teacher') {
      this.owner = false;
      this.teacher = true;
      this.office = false;
      this.student = false;
    }
    if (this._userDetails?.role === 'office') {
      this.owner = false;
      this.teacher = false;
      this.office = true;
      this.student = false;
    }
    if (this._userDetails?.role === 'student') {
      this.owner = false;
      this.teacher = false;
      this.office = false;
      this.student = true;
    }
  }
}
