<div
  fxLayout="column"
  fxFlex
  style="width: 100%; height: 100%"
  fxLayoutAlign="center center"
>
  <h2>{{ data.title }}</h2>
  <div fxLayout="row" fxFlex *ngIf="data.mode == 'withEnd'">
    <div fxLayout="column"></div>
  </div>
  <mat-form-field fxFlex style="width: 100%" fxFlex appearance="outline">
    <mat-label *ngIf="data.mode === 'withEnd'">Początek</mat-label>

    <input
      [(ngModel)]="selectedDateTime"
      #input
      type="datetime-local"
      matInput
      placeholder="placeholder"
    />
  </mat-form-field>
  <mat-form-field
    *ngIf="data.mode === 'withEnd'"
    fxFlex
    style="width: 100%"
    fxFlex
    appearance="outline"
  >
    <mat-label *ngIf="data.mode === 'withEnd'">Koniec</mat-label>
    <input
      [(ngModel)]="selectedDateTime2"
      #input
      type="datetime-local"
      matInput
      placeholder="placeholder"
    />
  </mat-form-field>
  <mat-form-field
    *ngIf="data.mode === 'cancel'"
    fxFlex
    style="width: 100%"
    fxFlex
    appearance="outline"
  >
    <input
      [(ngModel)]="reason"
      #input2
      type="text"
      matInput
      placeholder="Powód odwołania"
    />
  </mat-form-field>
  <mat-form-field
    fxFlex
    *ngIf="data.mode === 'redoChunk'"
    appearance="outline"
    class="ml-3 mr-1"
    (click)="$event.stopPropagation()"
  >
    <mat-select
      [(ngModel)]="selectedLength"
      matInput
      placeholder="Czas trwania (min)"
    >
      <mat-option value="5">5 minut</mat-option>
      <mat-option value="10">10 minut</mat-option>
      <mat-option value="15">15 minut</mat-option>
      <mat-option value="20">20 minut</mat-option>
      <mat-option value="25">25 minut</mat-option>
      <mat-option value="30">30 minut</mat-option>
      <mat-option value="35">35 minut</mat-option>
      <mat-option value="40">40 minut</mat-option>
      <mat-option value="45">45 minut</mat-option>
      <mat-option value="45">60 minut</mat-option>
      <mat-option value="45">90 minut</mat-option>
    </mat-select>
  </mat-form-field>
  <div
    fxLayoutGap="10px"
    style="width: 100%"
    fxFlex
    fxLayout="row"
    fxLayout.lt-md="column"
  >
    <button mat-stroked-button fxFlex color="primary" matDialogClose>
      anuluj
    </button>
    <button
      mat-flat-button
      fxFlex
      color="primary"
      [matDialogClose]="{
        dateTime: selectedDateTime,
        dateTime2: selectedDateTime2,
        length: selectedLength,
        reason: reason
      }"
    >
      ok
    </button>
  </div>
</div>
