<mat-card class="mt-2 d-flex flex-row flex-wrap align-items-center">
  <div class="d-flexx flex-column">
    <p>Category: {{ video.data.category || "Uncategorized" }}</p>
    <p class="mt-2">
      Tags: {{ video.data.tags ? video.data.tags.join(", ") : "No tags" }}
    </p>
  </div>
  <div style="flex: 1"></div>
  <div class="d-flex flex-row align-items-center">
    <button
      matTooltip="Copy video URL"
      (click)="copyVideoUrl()"
      mat-icon-button
      color="primary"
      class="mt-2 mb-2 mr-2"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
    <button
      *ngIf="data.fact"
      matTooltip="Assign video to fact"
      (click)="assignVideoToFact()"
      mat-icon-button
      color="primary"
      class="mt-2 mb-2 mr-2"
    >
      <span class="material-symbols-outlined"> add_task </span>
    </button>
    <video style="height: 150px" controls [src]="video.data.downloadLink">
      Your browser does not support the video tag.
    </video>
    <button mat-icon-button color="warn" (dblclick)="onDeleteClick()">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</mat-card>
