<div
  class="p-2"
  style="height: 100%; overflow-y: hidden; overflow-x: hidden"
  fxLayout="column"
>
  <div style="overflow-x: hidden; height: 100%" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="5px">
      <span style="font-size: 1.3em; font-weight: bold"
        >{{ task.created | date: "dd.MM.yyyy HH:mm" }} |
        {{ task?.author.name + ":" }}
      </span>
      <!--   <img
        *ngFor="let user of task?.seenBy"
        width="20px"
        height="20px"
        [src]="workersAvatarsService.getAvatarURLById(user)"
        (longpress)="$event.preventDefault(); $event.stopPropagation()"
        (touchstart)="$event.preventDefault(); $event.stopPropagation()"
        (click)="$event.stopPropagation()"
        [matTooltip]="workersAvatarsService.getNameFromId(user)"
        matTooltipTouchGestures="true"
      /> -->
    </div>
    <mat-chip-list style="width: 100%" class="mt-4">
      <div
        style="width: 100%"
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="start start"
        fxLayoutAlign.gt-sm="start start"
      >
        <div style="width: 4px"></div>
        <img
          (longpress)="$event.preventDefault(); $event.stopPropagation()"
          (touchstart)="$event.preventDefault(); $event.stopPropagation()"
          (click)="$event.stopPropagation()"
          [matTooltip]="task?.author.name"
          matTooltipTouchGestures="true"
          width="40px"
          height="40px"
          [src]="workersAvatarsService.getAvatarURL(task?.author.name)"
        />
        <mat-chip
          [style.background-color]="data.colorDark"
          selected
          style="font-weight: bolder; overflow-x: hidden; color: white"
          >{{ task?.title + ": " + task?.text }}
        </mat-chip>
      </div>
    </mat-chip-list>
    <span
      class="mt-2 mb-2"
      style="
        font-size: 1.2em;
        font-weight: 500;

        overflow: hidden;
      "
    >
    </span>
    <div
      class="mt-3"
      style="width: 100%"
      fxLayout="column"
      #comments
      tabindex="1"
      *ngFor="let comment of task?.comments"
    >
      <div
        style="width: 100%"
        class="mb-1 mt-1"
        style="font-weight: bolder"
        fxLayout="row"
        fxFlex
        fxLayoutGap="10px"
      >
        <span>{{ comment?.author.name }}</span>
        <span>{{ comment?.date | date: "EEEEEE, dd.MM.yy HH:mm" }} </span>
        <div fxLayout="row" fxLayoutGap="5px">
          <img
            [src]="workersAvatarsService.getAvatarURLById(user)"
            *ngFor="let user of comment?.lastSeenBy"
            (longpress)="$event.preventDefault(); $event.stopPropagation()"
            (touchstart)="$event.preventDefault(); $event.stopPropagation()"
            (click)="$event.stopPropagation()"
            [matTooltip]="workersAvatarsService.getNameFromId(user)"
            matTooltipTouchGestures="true"
            width="25px"
            height="25px"
          />
        </div>
        <span fxFlex></span>
        <!-- <mat-icon
          *ngIf="comment.author.id === userDetailsService.getSimpleUserDetails().id || this.userDetailsService.getSimpleUserDetails().role === 'owner'"
          (click)="removeComment(comment, task)"
          class="pointer"
          color="warn"
          >remove_circle_outline</mat-icon
        > -->
      </div>
      <mat-divider class="mb-3"></mat-divider>
      <mat-chip-list style="width: 100%">
        <div style="width: 100%" fxLayout="row" fxLayoutGap="10px">
          <div
            fxLayout="row"
            fxLayoutGap="10px"
            style="width: 100%"
            [fxLayoutAlign]="
              comment?.author?.id === task?.author?.id
                ? 'start start'
                : 'end start'
            "
          >
            <div
              fxHide
              [fxShow]="comment?.author?.id === task?.author.id"
              fxLayout="row"
            >
              <div style="width: 4px"></div>
              <img
                (longpress)="$event.preventDefault(); $event.stopPropagation()"
                (touchstart)="$event.preventDefault(); $event.stopPropagation()"
                (click)="$event.stopPropagation()"
                [matTooltip]="comment?.author.name"
                matTooltipTouchGestures="true"
                width="40px"
                height="40px"
                [src]="workersAvatarsService.getAvatarURL(comment?.author.name)"
              />
            </div>
            <mat-chip
              class="ml-1"
              [ngClass]="!comment?.completion ? 'comment-finished' : 'comment'"
              [style.backgroundColor]="
                task.author.id == comment.author.id ? data.colorDark : '#bdbdbd'
              "
              style="
                position: relative;
                line-height: 1.4;
                font-size: 1.1em;
                font-weight: 500;
                max-width: 79%;
                color: white;
                border-radius: 15px;
                display: inline-flex;
              "
            >
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-items-center justify-center">
                  <span [innerHTML]="comment?.text"></span>
                  <!-- Badge with dynamic color and icon -->
                  <span
                    class="custom-badge"
                    *ngIf="
                      comment.author.id ===
                        userDetailsService.getSimpleUserDetails().id ||
                      userDetailsService.getSimpleUserDetails().role === 'owner'
                    "
                    class="material-icons pointer ml-3"
                    [matTooltip]="
                      comment.completion
                        ? 'Oznacz jako niezakończone'
                        : 'Oznacz jako zakończone'
                    "
                    style="
                      position: absolute;
                      top: -10px;
                      right: -10px;
                      border-radius: 50%;
                      padding: 1px;
                      background-color: gray;
                    "
                    (click)="
                      setCommentsCompletion(comment, !comment?.completion)
                    "
                    [style.backgroundColor]="
                      task.author.id == comment.author.id && comment.completion
                        ? data.colorDark
                        : '#bdbdbd'
                    "
                  >
                    <span class="material-icons-outlined">
                      <!-- Dynamic icon based on completion -->
                      {{
                        comment.completion
                          ? "remove_circle_outline"
                          : "published_with_changes"
                      }}
                    </span>
                  </span>
                </div>
                <div
                  *ngIf="comment?.description"
                  class="mt-1 mb-1"
                  style="border-bottom: 1px solid white; width: 100%"
                ></div>
                <div class="d-flex flex-row">
                  <span>{{ comment?.description }}</span>
                </div>
              </div>
            </mat-chip>

            <div
              fxHide
              [fxShow]="comment?.author?.id !== task?.author?.id"
              fxLayout="row"
            >
              <img
                (longpress)="$event.preventDefault(); $event.stopPropagation()"
                (touchstart)="$event.preventDefault(); $event.stopPropagation()"
                (click)="$event.stopPropagation()"
                [matTooltip]="comment?.author?.name"
                matTooltipTouchGestures="true"
                width="40px"
                height="40px"
                [src]="
                  workersAvatarsService.getAvatarURL(comment?.author?.name)
                "
              />
              <div style="width: 4px"></div>
            </div>
          </div>
        </div>
      </mat-chip-list>
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <div>
      <div class="pt-3 pb-3" style="min-width: 250px" fxLayout="column">
        <div
          *ngFor="let worker of workers"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <div
            fxLayoutAlign="start center"
            class="mr-3 ml-3 p-1 mt-1 mb-1"
            (click)="
              $event.stopPropagation();
              $event.preventDefault();
              addToConversation(task, worker)
            "
            [style.color]="
              task?.recipients?.includes(worker.id) ? 'white' : data.colorDark
            "
            [style.background-color]="
              task?.recipients?.includes(worker.id) ? data.colorDark : 'white'
            "
            style="border-radius: 15px; min-width: 200px"
          >
            <img class="mr-2" style="width: 35px" [src]="worker.avatar" alt />{{
              worker.name
            }}
          </div>
          <span fxFlex></span>
          <span
            (click)="
              $event.stopPropagation();
              $event.preventDefault();
              task?.completion?.includes(worker.id)
                ? removeFromPinned(worker.id)
                : addToPinned(worker.id)
            "
            fxLayoutAlign="center center"
            style="width: 40px; height: 40px; border-radius: 10px"
            [style.background-color]="
              task?.completion?.includes(worker.id) ? data.colorDark : 'white'
            "
            [style.color]="
              task?.completion?.includes(worker.id) ? 'white' : data.colorDark
            "
            class="pointer mr-3"
            [style.border]="2 + 'px solid ' + data.colorDark"
          >
            <span class="material-symbols-outlined">person_pin_circle</span>
          </span>
        </div>
      </div>
    </div>
  </mat-menu>
  <!-- <mat-radio-group
        color="primary"
        [(ngModel)]="task.completion"
        (change)="taskCompletionChanged(task)"
    >
        <mat-radio-button value="todo">Do zrobienia &nbsp; </mat-radio-button>
        <mat-radio-button value="pinned">Przypięte &nbsp; </mat-radio-button>
        <mat-radio-button value="done">Wykonane &nbsp; </mat-radio-button>
    </mat-radio-group> -->
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    style="width: 100%; height: 40px; border-radius: 10px"
    class="mt-2 mb-2"
    fxLayoutAlign="space-between center"
  >
    <span
      class="pointer"
      fxLayoutAlign="center center"
      [style.border]="3 + 'px solid ' + data.colorDark"
      style="border-radius: 10px; padding: 5px; width: 24%"
      (click)="taskCompletionChanged('todo')"
      [style.color]="
        task?.completion === 'todo' ? data.colorDark : data.colorDark
      "
      [style.background-color]="
        task?.completion === 'todo' ? data.colorMediumLight : 'white'
      "
      [style.font-weight]="task?.completion === 'todo' ? 'bolder' : 'normal'"
      >Aktualne
    </span>
    <span
      class="pointer"
      fxLayoutAlign="center center"
      style="border-radius: 10px; padding: 5px; width: 24%"
      [style.border]="3 + 'px solid ' + data.colorDark"
      (click)="taskCompletionChanged('pinned')"
      [style.color]="task?.completion === 'pinned' ? 'white' : data.colorDark"
      [style.background-color]="
        task?.completion.includes(userDetailsService.getSimpleUserDetails().id)
          ? data.colorMediumLight
          : 'white'
      "
      [style.font-weight]="task?.completion === 'pinned' ? 'bolder' : 'normal'"
      >Przypięte
    </span>
    <span
      class="pointer"
      fxLayoutAlign="center center"
      style="border-radius: 10px; padding: 5px; width: 24%"
      [style.border]="3 + 'px solid ' + data.colorDark"
      [matMenuTriggerFor]="menu2"
      [style.color]="task?.completion === 'pinned' ? 'white' : data.colorDark"
      [style.font-weight]="task?.completion === 'pinned' ? 'bolder' : 'normal'"
      >Zaplanuj
    </span>
    <span
      class="pointer"
      fxLayoutAlign="center center"
      style="border-radius: 10px; padding: 5px; width: 24%"
      [style.border]="3 + 'px solid ' + data.colorDark"
      (click)="taskCompletionChanged('done')"
      [style.color]="task?.completion === 'done' ? 'white' : data.colorDark"
      >Wykonane
    </span>
  </div>
  <div style="width: 100%" fxLayoutAlign="center center">
    <textarea
      placeholder="Wpisz treść komentarza..."
      [style.border]="'3px solid ' + data.colorDark"
      rows="3"
      style="
        width: 100%;
        overflow-y: auto;
        border-radius: 15px;
        font-size: larger;
        padding: 10px;
        resize: vertical;
      "
      #input
      [(ngModel)]="commentToAdd"
    ></textarea>
    <!-- <button
            *ngIf="userDetailsService.owner"
            (click)="correctText()"
            color="primary"
            mat-icon-button
        >
            <mat-icon>help</mat-icon>
        </button> -->
    <span
      style="width: 100px; height: 60px; border-radius: 15px; color: white"
      (click)="commentToAdd?.length > 0 ? addComment(task) : null"
      class="pointer ml-2"
      [style.background-color]="data.colorDark"
      fxLayoutAlign="center center"
    >
      <span class="material-symbols-outlined">send</span>
    </span>
  </div>
  <div fxLayout="row" style="width: 100%" class="mt-2" fxLayoutGap="10px">
    <!-- <button
            matTooltip="Usuń"
            *ngIf="
        task?.author.id === userDetailsService.getSimpleUserDetails().id ||
        this.userDetailsService.getSimpleUserDetails().role === 'owner'
      "
            class="mt-2"
            (click)="removeTask(task)"
            color="warn"
            mat-icon-button
        >
            <mat-icon>delete_outline</mat-icon>
        </button>
        <button
            matTooltip="Edytuj"
            *ngIf="
        task?.author.id === userDetailsService.getSimpleUserDetails().id ||
        this.userDetailsService.getSimpleUserDetails().role === 'owner'
      "
            class="mt-2"
            (click)="editTask(task)"
            color="primary"
            mat-icon-button
        >
            <mat-icon>edit</mat-icon>
        </button>
        <button
            (click)="closeDialog()"
            fxFlex
            class="mt-2"
            color="primary"
            mat-flat-button
        >Zamknij
        </button>
       
        <button
            [matMenuTriggerFor]="menu"
            matTooltip="Dodaj osobę do rozmowy"
            class="mt-2"
            color="primary"
            mat-icon-button
        >
            <mat-icon>add_circle_outline</mat-icon>
        </button> -->
    <span
      [matMenuTriggerFor]="menu"
      style="width: 100%; height: 40px; width: 50%; border-radius: 10px"
      [style.color]="data.colorDark"
      [style.border]="3 + 'px solid ' + data.colorDark"
      class="pointer"
      fxLayoutAlign="space-around center"
    >
      <span>Dodaj osobę</span>
    </span>
    <span
      (click)="closeDialog()"
      style="
        width: 100%;
        height: 40px;
        width: 50%;
        border-radius: 10px;
        color: white;
      "
      class="pointer"
      [style.background-color]="data.colorDark"
      fxLayoutAlign="space-around center"
    >
      <span>Zamknij</span>
    </span>
  </div>
</div>
<mat-menu #menu2>
  <span class="mt-2 mb-2">Kiedy zaplanować zadanie?</span>
  <div *ngFor="let day of futureDays">
    <button
      [style.color]="data.colorDark"
      fxLayoutAlign="stat center"
      mat-menu-item
      (click)="selectCustomDate(day.date)"
    >
      <span class="material-symbols-outlined mr-2">calendar_add_on</span>
      <span>{{ day.dayName }} ({{ day.date | date: "dd.MM" }}) </span>
    </button>
  </div>

  <!-- Button for custom date selection -->
  <button
    (click)="$event.stopPropagation(); dateInput.click()"
    fxLayoutAlign="stat center"
    mat-menu-item
  >
    <span class="material-symbols-outlined mr-2">edit_calendar</span>
    <span>Inna data |</span>
    <input
      style="border: none"
      type="date"
      (change)="selectCustomDate($event)"
      #dateInput
    />
  </button>
</mat-menu>
