import { Component, OnInit } from '@angular/core';
import { PdfService } from './pdf.service';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css']
})
export class PdfComponent implements OnInit {
functionSub
  constructor(

  ) { }

  ngOnInit(): void {

  }

}
