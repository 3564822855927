import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class InternetConnectionService {
  internetConnectionState = new Subject();
  connectedToServerState = new Subject();
  connected: boolean;
  connectionLost: boolean;
  connectedAgain: boolean;
  url: string;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private versionService: VersionService,
  ) {
    window.addEventListener('offline', () => {
      this.internetConnectionState.next('offline');
    });

    window.addEventListener('online', () => {
      this.internetConnectionState.next('online');
    });
    this.url = this.versionService.url;
  }
  monitorConnectionToServer() {
    setInterval(() => {
      this.http
        .get(this.url + '/api/health', {
          headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        })
        .subscribe(
          (response: any) => {
            if (response.message.toString() == 'Success!') {
              if (!this.connectionLost) {
                this.connected = true;
                this.connectedToServerState.next('connected');
              }
              if (this.connectionLost) {
                this.connectionLost = true;
                this.connectedAgain = true;
                this.connectionLost = false;
                this.connectedToServerState.next('connectedAgain');
                this.connectedAgain = false;
              }
            } else {
              this.connectionLost = true;
              this.connectedToServerState.next('connectionLost');
            }
          },
          (error) => {
            this.connectionLost = true;
            this.connectedToServerState.next('connectionLost');
          },
        );
    }, 1000);
  }

  internetConnectionStateChanged() {
    return this.internetConnectionState.asObservable();
  }
  connectedToServerStateChanged() {
    return this.connectedToServerState.asObservable();
  }
}
