<div class="d-flex flex-column w-100 h-100">
  <h2>Wyślij SMS/Email</h2>
  <span class="mat-small"
    >Ten komponent słuzy do wysyłki smsów do wielu klientów. Nieprawidłowe
    telefony oraz duplikaty zostaną odfiltrowane. Wiadomość zostanie wysłana do
    grup, które były widoczne na planie. Numery telefonów pobierają się z listy
    signup.
  </span>
  <hr />
  <span class="mat-small">Wybierz lub wpisz treść wiadomości</span>
  <div class="d-flex flex-row">
    <div class="w-25 d-flex flex-row justify-center mr-2">
      <select
        class="w-100"
        placehholder="Wybierz terść wiadomości"
        [(ngModel)]="selectedMessage"
      >
        <option *ngFor="let option of messages" [value]="option.message">
          {{ option.displayName }}
        </option>
      </select>
    </div>
    <textarea
      style="width: 75%"
      rows="2"
      [(ngModel)]="selectedMessage"
    ></textarea>
  </div>

  <span style="font-size: large" class="mt-3"
    >Wiadomość zostanie wysłana do następujących osób:</span
  >
  <div style="overflow-y: auto" class="w-100 h-100">
    <span *ngFor="let group of data.groups">
      <hr />
      <h4 style="font-weight: bold">
        Grupa:
        {{ group.name }}
      </h4>
      <span
        *ngFor="let student of group.students"
        class="d-flex flex-row justify-center align-content-center"
      >
        <mat-checkbox
          style="margin-top: 2px"
          class="mr-2"
          [(ngModel)]="student.selected"
        ></mat-checkbox>
        <span class="w-25">Uczeń: | {{ student.student }} | </span>
        <span class="w-25">Klient: | {{ student.customer }} |</span>

        <span style="color: gray; font-weight: 700" class="w-25"
          >Telefon | {{ student.phone }} |
        </span>
      </span>
    </span>
  </div>
  <hr />
  <div class="d-flex justify-content-end">
    <div class="d-flex flex-row w-100">
      <button
        class="w-100"
        mat-dialog-close=""
        mat-stroked-button
        color="primary"
      >
        Zamknij
      </button>
      <button
        (click)="sendSMS()"
        class="w-100 ml-2"
        mat-flat-button
        color="primary"
      >
        Wyślij
      </button>
    </div>
  </div>
</div>
