import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VideosTypesService {

  videoTypes = [
    {
      displayName: 'SentenceShowcase',
      type: 'word-sentence-picture',
      mechanics: 'The video is a kind of faceless presentation where there is played a following sequence: Word and then sentence with real-life usage. There is audio and subtitles in various languages. There are interesting and engaging visuals and animations. The intention of the video is to show real-life usage of words.',
      component: 'VideoWordSentencePictureComponent',
      teacherName: 'Emily',
      teachersVoice: 'Michelle',
      videoColor: '#03A9F4',
      questionsForVideo: [],
      answersForVideo: [],
      introData: {
        introImages: [
        ],

        introAvatar: '/assets/video-intros/avatars/v3-Emily-Michelle.png',
        introAudios: [
          '/assets/video-intros/audio/emily/introPart3Emily1.mp3',
          '/assets/video-intros/audio/emily/introPart3Emily2.mp3',
          '/assets/video-intros/audio/emily/introPart3Emily3.mp3',
          '/assets/video-intros/audio/emily/introPart3Emily4.mp3',
          '/assets/video-intros/audio/emily/introPart3Emily5.mp3',
          '/assets/video-intros/audio/emily/introPart3Emily6.mp3',
        ],
        introTexts: [
          // {
          //   "English": "Hi there! My name is Emily and you're watching English Expanse, a channel in which you can learn English in context.",
          //   "German": "Hallo zusammen! Mein Name ist Emily und ihr schaut English Expanse, einen Kanal, auf dem ihr Englisch im Kontext lernen könnt.",
          //   "Spanish": "¡Hola a todos! Mi nombre es Emily y estás viendo English Expanse, un canal en el que puedes aprender inglés en contexto.",
          //   "PortugueseBrazil": "Olá a todos! Meu nome é Emily e você está assistindo English Expanse, um canal onde você pode aprender inglês em contexto.",
          //   "Turkish": "Herkese merhaba! Benim adım Emily ve English Expanse'i izliyorsunuz, burada bağlam içinde İngilizce öğrenebilirsiniz.",
          //   "French": "Bonjour à tous ! Je m'appelle Emily et vous regardez English Expanse, une chaîne où vous pouvez apprendre l'anglais en contexte.",
          //   "Polish": "Cześć wszystkim! Nazywam się Emily i oglądacie English Expanse, kanał, na którym możecie uczyć się angielskiego w kontekście.",
          //   "Vietnamese": "Xin chào tất cả các bạn! Tôi là Emily và bạn đang xem English Expanse, một kênh giúp bạn học tiếng Anh trong ngữ cảnh.",
          //   "Thai": "สวัสดีทุกคน! ฉันชื่อเอมิลี่และคุณกำลังดู English Expanse ซึ่งเป็นช่องที่คุณสามารถเรียนภาษาอังกฤษในบริบทได้",
          //   "Indonesian": "Halo semuanya! Nama saya Emily dan Anda sedang menonton English Expanse, sebuah saluran di mana Anda bisa belajar bahasa Inggris dalam konteks.",
          //   "Hindi": "सभी को नमस्कार! मेरा नाम एमिली है और आप English Expanse देख रहे हैं, एक चैनल जहां आप संदर्भ में अंग्रेजी सीख सकते हैं।"
          // },
          // {
          //   "English": "Every day of the week we post a different type of video, helping you learn the words that will be used in a story that we post at the end of the week.",
          //   "German": "Jeden Tag der Woche posten wir eine andere Art von Video, die euch hilft, die Wörter zu lernen, die in einer Geschichte verwendet werden, die wir am Ende der Woche posten.",
          //   "Spanish": "Todos los días de la semana publicamos un tipo diferente de video, ayudándote a aprender las palabras que se utilizarán en una historia que publicamos al final de la semana.",
          //   "PortugueseBrazil": "Todos os dias da semana postamos um tipo diferente de vídeo, ajudando você a aprender as palavras que serão usadas em uma história que postamos no final da semana.",
          //   "Turkish": "Haftanın her günü farklı bir video türü yayınlıyoruz, hafta sonunda yayınlayacağımız hikayede kullanılacak kelimeleri öğrenmenize yardımcı oluyoruz.",
          //   "French": "Chaque jour de la semaine, nous publions un type de vidéo différent, vous aidant à apprendre les mots qui seront utilisés dans une histoire que nous publions à la fin de la semaine.",
          //   "Polish": "Każdego dnia tygodnia publikujemy inny rodzaj wideo, pomagając wam nauczyć się słów, które zostaną użyte w historii, którą publikujemy na koniec tygodnia.",
          //   "Vietnamese": "Mỗi ngày trong tuần, chúng tôi đăng một loại video khác nhau, giúp bạn học các từ sẽ được sử dụng trong một câu chuyện mà chúng tôi đăng vào cuối tuần.",
          //   "Thai": "ทุกวันของสัปดาห์เราจะโพสต์วิดีโอประเภทต่าง ๆ ที่ช่วยให้คุณเรียนรู้คำศัพท์ที่จะใช้ในเรื่องราวที่เราจะโพสต์ในตอนท้ายของสัปดาห์",
          //   "Indonesian": "Setiap hari dalam seminggu kami memposting jenis video yang berbeda, membantu Anda mempelajari kata-kata yang akan digunakan dalam cerita yang kami posting di akhir minggu.",
          //   "Hindi": "सप्ताह के हर दिन हम एक अलग प्रकार का वीडियो पोस्ट करते हैं, जो आपको उन शब्दों को सीखने में मदद करता है जो हम सप्ताह के अंत में पोस्ट की जाने वाली कहानी में उपयोग करेंगे।"
          // },
          // {
          //   "English": "Remember that all our videos have subtitles in your language and we recommend that you watch each video a few times, first with the subtitles, and then again without them to see how much you can understand.",
          //   "German": "Denkt daran, dass alle unsere Videos Untertitel in eurer Sprache haben und wir empfehlen, dass ihr jedes Video ein paar Mal anschaut, zuerst mit Untertiteln und dann nochmal ohne, um zu sehen, wie viel ihr verstehen könnt.",
          //   "Spanish": "Recuerda que todos nuestros videos tienen subtítulos en tu idioma y te recomendamos que veas cada video varias veces, primero con los subtítulos y luego nuevamente sin ellos para ver cuánto puedes entender.",
          //   "PortugueseBrazil": "Lembre-se que todos os nossos vídeos têm legendas no seu idioma e recomendamos que você assista a cada vídeo algumas vezes, primeiro com as legendas e depois novamente sem elas para ver o quanto você pode entender.",
          //   "Turkish": "Tüm videolarımızın sizin dilinizde altyazıları olduğunu ve her videoyu birkaç kez izlemenizi tavsiye ederiz, önce altyazılarla, sonra ne kadar anlayabildiğinizi görmek için altyazısız olarak tekrar izleyin.",
          //   "French": "Rappelez-vous que toutes nos vidéos ont des sous-titres dans votre langue et nous vous recommandons de regarder chaque vidéo plusieurs fois, d'abord avec les sous-titres, puis à nouveau sans eux pour voir combien vous pouvez comprendre.",
          //   "Polish": "Pamiętaj, że wszystkie nasze filmy mają napisy w twoim języku i zalecamy, abyś oglądał każdy film kilka razy, najpierw z napisami, a potem ponownie bez nich, aby sprawdzić, ile rozumiesz.",
          //   "Vietnamese": "Hãy nhớ rằng tất cả các video của chúng tôi đều có phụ đề bằng ngôn ngữ của bạn và chúng tôi khuyên bạn nên xem mỗi video vài lần, đầu tiên là với phụ đề, sau đó xem lại không có phụ đề để xem bạn hiểu được bao nhiêu.",
          //   "Thai": "จำไว้ว่าวิดีโอทั้งหมดของเรามีคำบรรยายในภาษาของคุณ และเราขอแนะนำให้คุณดูวิดีโอแต่ละรายการหลาย ๆ ครั้ง ครั้งแรกกับคำบรรยาย และจากนั้นอีกครั้งโดยไม่มีคำบรรยาย เพื่อดูว่าคุณเข้าใจได้มากแค่ไหน",
          //   "Indonesian": "Ingatlah bahwa semua video kami memiliki subtitle dalam bahasa Anda dan kami merekomendasikan agar Anda menonton setiap video beberapa kali, pertama dengan subtitle, dan kemudian lagi tanpa mereka untuk melihat seberapa banyak yang Anda bisa pahami.",
          //   "Hindi": "याद रखें कि हमारे सभी वीडियो में आपकी भाषा में उपशीर्षक होते हैं और हम अनुशंसा करते हैं कि आप प्रत्येक वीडियो को कुछ बार देखें, पहले उपशीर्षक के साथ, और फिर उन्हें बिना देखें कि आप कितना समझ सकते हैं।"
          // },
          // {
          //   "English": "Today's video is Sentence Showcase, in which we look at how the words that we learned in the first video can be used in sentences.",
          //   "German": "Das heutige Video ist Sentence Showcase, in dem wir uns ansehen, wie die Wörter, die wir im ersten Video gelernt haben, in Sätzen verwendet werden können.",
          //   "Spanish": "El video de hoy es Sentence Showcase, en el que veremos cómo se pueden usar las palabras que aprendimos en el primer video en oraciones.",
          //   "PortugueseBrazil": "O vídeo de hoje é Sentence Showcase, onde veremos como as palavras que aprendemos no primeiro vídeo podem ser usadas em frases.",
          //   "Turkish": "Bugünkü video Sentence Showcase, ilk videoda öğrendiğimiz kelimelerin cümlelerde nasıl kullanılabileceğine bakıyoruz.",
          //   "French": "La vidéo d'aujourd'hui est Sentence Showcase, où nous voyons comment les mots que nous avons appris dans la première vidéo peuvent être utilisés dans des phrases.",
          //   "Polish": "Dzisiejsze wideo to Sentence Showcase, w którym przyglądamy się, jak słowa, których nauczyliśmy się w pierwszym filmie, mogą być używane w zdaniach.",
          //   "Vietnamese": "Video hôm nay là Sentence Showcase, trong đó chúng ta xem cách các từ mà chúng ta đã học trong video đầu tiên có thể được sử dụng trong câu.",
          //   "Thai": "วิดีโอวันนี้คือ Sentence Showcase ซึ่งเราจะมาดูว่าคำที่เราได้เรียนรู้ในวิดีโอแรกสามารถใช้ในประโยคได้อย่างไร",
          //   "Indonesian": "Video hari ini adalah Sentence Showcase, di mana kita melihat bagaimana kata-kata yang kita pelajari di video pertama dapat digunakan dalam kalimat.",
          //   "Hindi": "आज का वीडियो Sentence Showcase है, जिसमें हम देखेंगे कि पहले वीडियो में सीखे गए शब्दों का वाक्यों में कैसे उपयोग किया जा सकता है।"
          // },

          // {
          //   "English": "Let's dive in!",
          //   "German": "Lasst uns eintauchen!",
          //   "Spanish": "¡Vamos a sumergirnos!",
          //   "PortugueseBrazil": "Vamos mergulhar!",
          //   "Turkish": "Haydi başlayalım!",
          //   "French": "Plongeons-y !",
          //   "Polish": "Zanurzmy się w to!",
          //   "Vietnamese": "Hãy cùng khám phá!",
          //   "Thai": "มาเริ่มกันเลย!",
          //   "Indonesian": "Mari kita mulai!",
          //   "Hindi": "आइए शुरू करते हैं!"
          // },
          // {
          //   "English": "And, remember that if you like what we are doing click like and subscribe. You can also support us on patreon, so that we can continue making new videos for you every day.",
          //   "German": "Und denkt daran, wenn euch gefällt, was wir tun, klickt auf Like und abonniert uns. Ihr könnt uns auch auf Patreon unterstützen, damit wir weiterhin jeden Tag neue Videos für euch machen können.",
          //   "Spanish": "Y recuerda que si te gusta lo que estamos haciendo, dale me gusta y suscríbete. También puedes apoyarnos en Patreon, para que podamos seguir haciendo nuevos videos para ti todos los días.",
          //   "PortugueseBrazil": "E lembre-se, se você gosta do que estamos fazendo, clique em curtir e se inscreva. Você também pode nos apoiar no Patreon, para que possamos continuar fazendo novos vídeos para você todos os dias.",
          //   "Turkish": "Ve, yaptıklarımızı beğeniyorsanız beğen ve abone ol butonuna tıklamayı unutmayın. Ayrıca Patreon'da bizi destekleyebilirsiniz, böylece sizin için her gün yeni videolar yapmaya devam edebiliriz.",
          //   "French": "Et, souvenez-vous que si vous aimez ce que nous faisons, cliquez sur j'aime et abonnez-vous. Vous pouvez également nous soutenir sur Patreon, afin que nous puissions continuer à créer de nouvelles vidéos pour vous chaque jour.",
          //   "Polish": "I pamiętajcie, że jeśli podoba wam się to, co robimy, kliknijcie like i subskrybujcie. Możecie nas również wspierać na Patreon, abyśmy mogli codziennie tworzyć dla was nowe filmy.",
          //   "Vietnamese": "Và, hãy nhớ rằng nếu bạn thích những gì chúng tôi đang làm, hãy nhấn thích và đăng ký. Bạn cũng có thể ủng hộ chúng tôi trên Patreon, để chúng tôi có thể tiếp tục làm video mới cho bạn mỗi ngày.",
          //   "Thai": "และอย่าลืมว่า ถ้าคุณชอบสิ่งที่เรากำลังทำอยู่ คลิกถูกใจและสมัครสมาชิก คุณยังสามารถสนับสนุนเราบน Patreon เพื่อให้เราสามารถทำวิดีโอใหม่ ๆ ให้คุณทุกวัน",
          //   "Indonesian": "Dan, ingatlah bahwa jika Anda menyukai apa yang kami lakukan, klik suka dan berlangganan. Anda juga bisa mendukung kami di Patreon, sehingga kami bisa terus membuat video baru untuk Anda setiap hari.",
          //   "Hindi": "और, याद रखें कि यदि आपको हमारा काम पसंद आता है तो लाइक करें और सब्सक्राइब करें। आप हमें Patreon पर भी समर्थन दे सकते हैं, ताकि हम हर दिन आपके लिए नए वीडियो बना सकें।"
          // }

        ]
      },
      pdfs: [
        {
          freeset: "false",
          type: "normal-set",
          mixeditems: false,
          orientation: "portrait",
          fullPage: false,
          removeItems: [],
          customColor: '009688',
          customBranding: 'EnglishExpanse',
          customLang: false,
          allLangs: true,

          pages: [[
            {
              name: "sentences-text-write-sentences-in-badges-from-translations-clues-50",
              exercises: [{ lang: 'English', text: 'Order the chunk of text to create sentences' }],
              setId: null,
              itemsPack: null,
              numberOfItems: 1,
              descriptionPl: "",
              displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
            }
          ],


          ],
          selected: false
        },
        // {
        //   freeset: "false",
        //   type: "normal-set",
        //   mixeditems: false,
        //   orientation: "portrait",
        //   fullPage: false,
        //   removeItems: [],
        //   customColor: '009688',
        //   customBranding: 'EnglishExpanse',
        //   customLang: false,
        //   allLangs: true,
        //   pages: [
        //     [
        //       {
        //         name: "sentences-text-write-sentences-in-badges-from-translations-clues-50",
        //         exercises: [{ lang: 'English', text: 'Order the chunk of text to create sentences' }],
        //         setId: null,
        //         itemsPack: 1,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       }
        //     ],
        //     [
        //       {
        //         name: "sentences-text-write-sentences-in-badges-from-translations-clues-50",
        //         exercises: [{ lang: 'English', text: 'Order the chunk of text to create sentences' }],
        //         setId: null,
        //         itemsPack: 2,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       }
        //     ],
        //     [
        //       {
        //         name: "sentences-text-write-sentences-in-badges-from-translations-clues-50",
        //         exercises: [{ lang: 'English', text: 'Order the chunk of text to create sentences' }],
        //         setId: null,
        //         itemsPack: 3,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       }
        //     ],
        //     [
        //       {
        //         name: "sentences-text-write-sentences-in-badges-from-translations-clues-50",
        //         exercises: [{ lang: 'English', text: 'Order the chunk of text to create sentences' }],
        //         setId: null,
        //         itemsPack: 4,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       }
        //     ],


        //   ],
        //   selected: false
        // },
        // {
        //   freeset: "false",
        //   type: "normal-set",
        //   mixeditems: false,
        //   orientation: "portrait",
        //   fullPage: false,
        //   removeItems: [],
        //   customColor: '009688',
        //   customBranding: 'EnglishExpanse',
        //   customLang: false,
        //   allLangs: false,
        //   pages: [[
        //     {
        //       name: "sentences-fill-gaps-clues",
        //       exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
        //       setId: null,
        //       itemsPack: null,
        //       numberOfItems: 10,
        //       descriptionPl: "",
        //       displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //     }],


        //   ],
        //   selected: false
        // },
        // {
        //   freeset: "false",
        //   type: "normal-set",
        //   mixeditems: false,
        //   orientation: "portrait",
        //   fullPage: false,
        //   removeItems: [],
        //   customColor: '009688',
        //   customBranding: 'EnglishExpanse',
        //   customLang: false,
        //   allLangs: false,
        //   pages: [
        //     [
        //       {
        //         name: "sentences-fill-gaps-clues",
        //         exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
        //         setId: null,
        //         itemsPack: 1,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       },

        //     ],
        //     [
        //       {
        //         name: "sentences-fill-gaps-clues",
        //         exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
        //         setId: null,
        //         itemsPack: 2,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       },

        //     ],
        //     [
        //       {
        //         name: "sentences-fill-gaps-clues",
        //         exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
        //         setId: null,
        //         itemsPack: 3,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       },

        //     ],
        //     [
        //       {
        //         name: "sentences-fill-gaps-clues",
        //         exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
        //         setId: null,
        //         itemsPack: 4,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       },

        //     ],


        //   ],
        //   selected: false
        // },
      ]
    },
    {
      displayName: 'DefinitionDive',
      type: 'word-definition-picture',
      mechanics: 'The video is a kind of faceless presentation where there is played a following sequence: Word and then a definition of this word. There is audio and subtitles in various languages. There are interesting and engaging visuals and animations. The intention of the video is to understand the meaning of the word to better remember the word.',
      teacherName: 'Chris',
      teachersVoice: 'Brandon',
      videoColor: '#ff9800',
      questionsForVideo: [],
      answersForVideo: [],
      component: 'VideoWordSentencePictureComponent',
      introData: {
        introImages: [
        ],
        introAvatar: '/assets/video-intros/avatars/v2-Chris-Brandon.png',

        introAudios: [
          '/assets/video-intros/audio/chris/introPart2Chris1.mp3',
          '/assets/video-intros/audio/chris/introPart2Chris2.mp3',
          '/assets/video-intros/audio/chris/introPart2Chris3.mp3',
          '/assets/video-intros/audio/chris/introPart2Chris4.mp3',
          '/assets/video-intros/audio/chris/introPart2Chris5.mp3',
          '/assets/video-intros/audio/chris/introPart2Chris6.mp3',
          '/assets/video-intros/audio/chris/introPart2Chris7.mp3',
        ],
        introTexts: [
          // {
          //   "English": "Hello everyone! My name is Chris and you're watching English Expanse, a channel in which you can learn English in context.",
          //   "German": "Hallo zusammen! Mein Name ist Chris und ihr schaut English Expanse, einen Kanal, auf dem ihr Englisch im Kontext lernen könnt.",
          //   "Spanish": "¡Hola a todos! Mi nombre es Chris y estás viendo English Expanse, un canal en el que puedes aprender inglés en contexto.",
          //   "PortugueseBrazil": "Olá a todos! Meu nome é Chris e você está assistindo English Expanse, um canal onde você pode aprender inglês em contexto.",
          //   "Turkish": "Herkese merhaba! Benim adım Chris ve English Expanse'i izliyorsunuz, burada bağlam içinde İngilizce öğrenebilirsiniz.",
          //   "French": "Bonjour à tous ! Je m'appelle Chris et vous regardez English Expanse, une chaîne où vous pouvez apprendre l'anglais en contexte.",
          //   "Polish": "Cześć wszystkim! Nazywam się Chris i oglądacie English Expanse, kanał, na którym możecie uczyć się angielskiego w kontekście.",
          //   "Vietnamese": "Xin chào tất cả các bạn! Tôi là Chris và bạn đang xem English Expanse, một kênh giúp bạn học tiếng Anh trong ngữ cảnh.",
          //   "Thai": "สวัสดีทุกคน! ฉันชื่อคริสและคุณกำลังดู English Expanse ซึ่งเป็นช่องที่คุณสามารถเรียนภาษาอังกฤษในบริบทได้",
          //   "Indonesian": "Halo semuanya! Nama saya Chris dan Anda sedang menonton English Expanse, sebuah saluran di mana Anda bisa belajar bahasa Inggris dalam konteks.",
          //   "Hindi": "सभी को नमस्कार! मेरा नाम क्रिस है और आप English Expanse देख रहे हैं, एक चैनल जहां आप संदर्भ में अंग्रेजी सीख सकते हैं।"
          // },
          // {
          //   "English": "Every day of the week we post a different type of video, helping you learn the words that will be used in a story that we post at the end of the week.",
          //   "German": "Jeden Tag der Woche posten wir eine andere Art von Video, die euch hilft, die Wörter zu lernen, die in einer Geschichte verwendet werden, die wir am Ende der Woche posten.",
          //   "Spanish": "Todos los días de la semana publicamos un tipo diferente de video, ayudándote a aprender las palabras que se utilizarán en una historia que publicamos al final de la semana.",
          //   "PortugueseBrazil": "Todos os dias da semana postamos um tipo diferente de vídeo, ajudando você a aprender as palavras que serão usadas em uma história que postamos no final da semana.",
          //   "Turkish": "Haftanın her günü farklı bir video türü yayınlıyoruz, hafta sonunda yayınlayacağımız hikayede kullanılacak kelimeleri öğrenmenize yardımcı oluyoruz.",
          //   "French": "Chaque jour de la semaine, nous publions un type de vidéo différent, vous aidant à apprendre les mots qui seront utilisés dans une histoire que nous publions à la fin de la semaine.",
          //   "Polish": "Każdego dnia tygodnia publikujemy inny rodzaj wideo, pomagając wam nauczyć się słów, które zostaną użyte w historii, którą publikujemy na koniec tygodnia.",
          //   "Vietnamese": "Mỗi ngày trong tuần, chúng tôi đăng một loại video khác nhau, giúp bạn học các từ sẽ được sử dụng trong một câu chuyện mà chúng tôi đăng vào cuối tuần.",
          //   "Thai": "ทุกวันของสัปดาห์เราจะโพสต์วิดีโอประเภทต่าง ๆ ที่ช่วยให้คุณเรียนรู้คำศัพท์ที่จะใช้ในเรื่องราวที่เราจะโพสต์ในตอนท้ายของสัปดาห์",
          //   "Indonesian": "Setiap hari dalam seminggu kami memposting jenis video yang berbeda, membantu Anda mempelajari kata-kata yang akan digunakan dalam cerita yang kami posting di akhir minggu.",
          //   "Hindi": "सप्ताह के हर दिन हम एक अलग प्रकार का वीडियो पोस्ट करते हैं, जो आपको उन शब्दों को सीखने में मदद करता है जो हम सप्ताह के अंत में पोस्ट की जाने वाली कहानी में उपयोग करेंगे।"
          // },
          // {
          //   "English": "Remember that all our videos have subtitles in your language and we recommend that you watch each video a few times, first with the subtitles, and then again without them to see how much you can understand.",
          //   "German": "Denkt daran, dass alle unsere Videos Untertitel in eurer Sprache haben und wir empfehlen, dass ihr jedes Video ein paar Mal anschaut, zuerst mit Untertiteln und dann nochmal ohne, um zu sehen, wie viel ihr verstehen könnt.",
          //   "Spanish": "Recuerda que todos nuestros videos tienen subtítulos en tu idioma y te recomendamos que veas cada video varias veces, primero con los subtítulos y luego nuevamente sin ellos para ver cuánto puedes entender.",
          //   "PortugueseBrazil": "Lembre-se que todos os nossos vídeos têm legendas no seu idioma e recomendamos que você assista a cada vídeo algumas vezes, primeiro com as legendas e depois novamente sem elas para ver o quanto você pode entender.",
          //   "Turkish": "Tüm videolarımızın sizin dilinizde altyazıları olduğunu ve her videoyu birkaç kez izlemenizi tavsiye ederiz, önce altyazılarla, sonra ne kadar anlayabildiğinizi görmek için altyazısız olarak tekrar izleyin.",
          //   "French": "Rappelez-vous que toutes nos vidéos ont des sous-titres dans votre langue et nous vous recommandons de regarder chaque vidéo plusieurs fois, d'abord avec les sous-titres, puis à nouveau sans eux pour voir combien vous pouvez comprendre.",
          //   "Polish": "Pamiętaj, że wszystkie nasze filmy mają napisy w twoim języku i zalecamy, abyś oglądał każdy film kilka razy, najpierw z napisami, a potem ponownie bez nich, aby sprawdzić, ile rozumiesz.",
          //   "Vietnamese": "Hãy nhớ rằng tất cả các video của chúng tôi đều có phụ đề bằng ngôn ngữ của bạn và chúng tôi khuyên bạn nên xem mỗi video vài lần, đầu tiên là với phụ đề, sau đó xem lại không có phụ đề để xem bạn hiểu được bao nhiêu.",
          //   "Thai": "จำไว้ว่าวิดีโอทั้งหมดของเรามีคำบรรยายในภาษาของคุณ และเราขอแนะนำให้คุณดูวิดีโอแต่ละรายการหลาย ๆ ครั้ง ครั้งแรกกับคำบรรยาย และจากนั้นอีกครั้งโดยไม่มีคำบรรยาย เพื่อดูว่าคุณเข้าใจได้มากแค่ไหน",
          //   "Indonesian": "Ingatlah bahwa semua video kami memiliki subtitle dalam bahasa Anda dan kami merekomendasikan agar Anda menonton setiap video beberapa kali, pertama dengan subtitle, dan kemudian lagi tanpa mereka untuk melihat seberapa banyak yang Anda bisa pahami.",
          //   "Hindi": "याद रखें कि हमारे सभी वीडियो में आपकी भाषा में उपशीर्षक होते हैं और हम अनुशंसा करते हैं कि आप प्रत्येक वीडियो को कुछ बार देखें, पहले उपशीर्षक के साथ, और फिर उन्हें बिना देखें कि आप कितना समझ सकते हैं।"
          // },
          // {
          //   "English": "Today's video is Definition Dive, in which we learn the meanings of the words that we learned in the first video.",
          //   "German": "Das heutige Video ist Definition Dive, in dem wir die Bedeutungen der Wörter lernen, die wir im ersten Video gelernt haben.",
          //   "Spanish": "El video de hoy es Definition Dive, en el que aprenderemos los significados de las palabras que aprendimos en el primer video.",
          //   "PortugueseBrazil": "O vídeo de hoje é Definition Dive, onde aprendemos os significados das palavras que aprendemos no primeiro vídeo.",
          //   "Turkish": "Bugünkü video Definition Dive, ilk videoda öğrendiğimiz kelimelerin anlamlarını öğreniyoruz.",
          //   "French": "La vidéo d'aujourd'hui est Definition Dive, où nous apprenons les significations des mots que nous avons appris dans la première vidéo.",
          //   "Polish": "Dzisiejsze wideo to Definition Dive, w którym uczymy się znaczeń słów, których nauczyliśmy się w pierwszym filmie.",
          //   "Vietnamese": "Video hôm nay là Definition Dive, trong đó chúng ta học nghĩa của những từ mà chúng ta đã học trong video đầu tiên.",
          //   "Thai": "วิดีโอวันนี้คือ Definition Dive ซึ่งเราจะได้เรียนรู้ความหมายของคำที่เราได้เรียนรู้ในวิดีโอแรก",
          //   "Indonesian": "Video hari ini adalah Definition Dive, di mana kita belajar arti kata-kata yang kita pelajari di video pertama.",
          //   "Hindi": "आज का वीडियो Definition Dive है, जिसमें हम पहले वीडियो में सीखे गए शब्दों के अर्थ सीखते हैं।"
          // },
          // {
          //   "English": "Check out the video description for a free PDF worksheet with exercises from this lesson.",
          //   "German": "Schaut in die Videobeschreibung für ein kostenloses PDF-Arbeitsblatt mit Übungen aus dieser Lektion.",
          //   "Spanish": "Consulta la descripción del video para obtener una hoja de ejercicios en PDF gratuita con ejercicios de esta lección.",
          //   "PortugueseBrazil": "Confira a descrição do vídeo para um PDF gratuito com exercícios desta lição.",
          //   "Turkish": "Bu dersteki alıştırmalar için ücretsiz PDF çalışma sayfası için video açıklamasına göz atın.",
          //   "French": "Consultez la description de la vidéo pour un PDF gratuit avec des exercices de cette leçon.",
          //   "Polish": "Sprawdź opis wideo, aby pobrać bezpłatny arkusz PDF z ćwiczeniami z tej lekcji.",
          //   "Vietnamese": "Hãy xem phần mô tả video để tải xuống tệp PDF bài tập miễn phí từ bài học này.",
          //   "Thai": "ตรวจสอบคำอธิบายวิดีโอสำหรับแผ่นงาน PDF ฟรีพร้อมแบบฝึกหัดจากบทเรียนนี้",
          //   "Indonesian": "Lihat deskripsi video untuk lembar kerja PDF gratis dengan latihan dari pelajaran ini.",
          //   "Hindi": "इस पाठ के अभ्यासों के साथ मुफ्त PDF वर्कशीट के लिए वीडियो विवरण देखें।"
          // },
          // {
          //   "English": "Let's dive in!",
          //   "German": "Lasst uns eintauchen!",
          //   "Spanish": "¡Vamos a sumergirnos!",
          //   "PortugueseBrazil": "Vamos mergulhar!",
          //   "Turkish": "Haydi başlayalım!",
          //   "French": "Plongeons-y !",
          //   "Polish": "Zanurzmy się w to!",
          //   "Vietnamese": "Hãy cùng khám phá!",
          //   "Thai": "มาเริ่มกันเลย!",
          //   "Indonesian": "Mari kita mulai!",
          //   "Hindi": "आइए शुरू करते हैं!"
          // },
          // {
          //   "English": "And, remember that if you like what we are doing click like and subscribe. You can also support us on patreon, so that we can continue making new videos for you every day.",
          //   "German": "Und denkt daran, wenn euch gefällt, was wir tun, klickt auf Like und abonniert uns. Ihr könnt uns auch auf Patreon unterstützen, damit wir weiterhin jeden Tag neue Videos für euch machen können.",
          //   "Spanish": "Y recuerda que si te gusta lo que estamos haciendo, dale me gusta y suscríbete. También puedes apoyarnos en Patreon, para que podamos seguir haciendo nuevos videos para ti todos los días.",
          //   "PortugueseBrazil": "E lembre-se, se você gosta do que estamos fazendo, clique em curtir e se inscreva. Você também pode nos apoiar no Patreon, para que possamos continuar fazendo novos vídeos para você todos os dias.",
          //   "Turkish": "Ve, yaptıklarımızı beğeniyorsanız beğen ve abone ol butonuna tıklamayı unutmayın. Ayrıca Patreon'da bizi destekleyebilirsiniz, böylece sizin için her gün yeni videolar yapmaya devam edebiliriz.",
          //   "French": "Et, souvenez-vous que si vous aimez ce que nous faisons, cliquez sur j'aime et abonnez-vous. Vous pouvez également nous soutenir sur Patreon, afin que nous puissions continuer à créer de nouvelles vidéos pour vous chaque jour.",
          //   "Polish": "I pamiętajcie, że jeśli podoba wam się to, co robimy, kliknijcie like i subskrybujcie. Możecie nas również wspierać na Patreon, abyśmy mogli codziennie tworzyć dla was nowe filmy.",
          //   "Vietnamese": "Và, hãy nhớ rằng nếu bạn thích những gì chúng tôi đang làm, hãy nhấn thích và đăng ký. Bạn cũng có thể ủng hộ chúng tôi trên Patreon, để chúng tôi có thể tiếp tục làm video mới cho bạn mỗi ngày.",
          //   "Thai": "และอย่าลืมว่า ถ้าคุณชอบสิ่งที่เรากำลังทำอยู่ คลิกถูกใจและสมัครสมาชิก คุณยังสามารถสนับสนุนเราบน Patreon เพื่อให้เราสามารถทำวิดีโอใหม่ ๆ ให้คุณทุกวัน",
          //   "Indonesian": "Dan, ingatlah bahwa jika Anda menyukai apa yang kami lakukan, klik suka dan berlangganan. Anda juga bisa mendukung kami di Patreon, sehingga kami bisa terus membuat video baru untuk Anda setiap hari.",
          //   "Hindi": "और, याद रखें कि यदि आपको हमारा काम पसंद आता है तो लाइक करें और सब्सक्राइब करें। आप हमें Patreon पर भी समर्थन दे सकते हैं, ताकि हम हर दिन आपके लिए नए वीडियो बना सकें।"
          // }

        ]
      },
      pdfs: [
        // {
        //   freeset: "false",
        //   type: "normal-set",
        //   mixeditems: false,
        //   orientation: "portrait",
        //   fullPage: false,
        //   customColor: '1976d2',
        //   removeItems: [],
        //   customBranding: 'EnglishExpanse',
        //   customLang: false,
        //   allLangs: false,
        //   pages: [[
        //     {
        //       name: "sentences-wordsearch",
        //       exercises: [{ lang: 'English', text: 'Find the words.' }],
        //       setId: null,
        //       itemsPack: null,
        //       numberOfItems: 10,
        //       descriptionPl: "",
        //       displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //     }],


        //   ],
        //   selected: false
        // },
        // {
        //   freeset: "false",
        //   type: "normal-set",
        //   mixeditems: false,
        //   orientation: "portrait",
        //   fullPage: false,
        //   customColor: '1976d2',
        //   removeItems: ['b3fd4'],
        //   customBranding: 'EnglishExpanse',
        //   customLang: false,
        //   allLangs: false,
        //   pages: [
        //     [
        //       {
        //         name: "sentences-wordsearch",
        //         exercises: [{ lang: 'English', text: 'Find the words.' }],
        //         setId: null,
        //         itemsPack: 1,
        //         numberOfItems: 10,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       }],
        //     [{
        //       name: "sentences-wordsearch",
        //       exercises: [{ lang: 'English', text: 'Find the words2.' }],
        //       setId: null,
        //       itemsPack: 2,
        //       numberOfItems: 10,
        //       descriptionPl: "",
        //       displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //     }],
        //     [{
        //       name: "sentences-wordsearch",
        //       exercises: [{ lang: 'English', text: 'Find the words3.' }],
        //       setId: null,
        //       itemsPack: 3,
        //       numberOfItems: 10,
        //       descriptionPl: "",
        //       displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //     }],
        //     [{
        //       name: "sentences-wordsearch",
        //       exercises: [{ lang: 'English', text: 'Find the words4.' }],
        //       setId: null,
        //       itemsPack: 4,
        //       numberOfItems: 10,
        //       descriptionPl: "",
        //       displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //     }],



        //   ],
        //   selected: false
        // },
        {
          freeset: "false",
          type: "normal-set",

          mixeditems: false,
          orientation: "portrait",
          fullPage: false,
          removeItems: [],
          customColor: '1976d2',
          customBranding: 'EnglishExpanse',
          customLang: false,
          allLangs: true,
          pages: [[
            {
              name: "sentences-picture-dictionary-single-20",
              exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
              setId: null,
              itemsPack: null,
              numberOfItems: 20,
              descriptionPl: "",
              displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
            }],


          ],
          selected: false
        },
        // {
        //   freeset: "false",
        //   type: "normal-set",
        //   mixeditems: false,
        //   orientation: "portrait",
        //   fullPage: false,
        //   removeItems: [],
        //   customColor: '1976d2',
        //   customBranding: 'EnglishExpanse',
        //   customLang: false,
        //   allLangs: true,
        //   pages: [
        //     [
        //       {
        //         name: "sentences-picture-dictionary-single-20",
        //         exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
        //         setId: null,
        //         itemsPack: 1,
        //         numberOfItems: 20,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       }],
        //     [
        //       {
        //         name: "sentences-picture-dictionary-single-20",
        //         exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
        //         setId: null,
        //         itemsPack: 2,
        //         numberOfItems: 20,
        //         descriptionPl: "",
        //         displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
        //       }],


        //   ],
        //   selected: false
        // },
      ],
      selected: false
    },
    {
      displayName: 'EchoWords',
      type: 'word-repeat',
      mechanics: 'The video is a kind of faceless presentation where there is played a following sequence: Word and then again the same word. There is audio and subtitles in various languages. There are interesting and engaging visuals and animations. The intention of the video is to understand the meaning of the word via the subtitles and also to practce pronunciation.',

      component: 'VideoWordRepeatComponent',
      teacherName: 'Ashley',
      teachersVoice: 'Ashley',
      introData: {
        introImages: [
        ],
        introAvatar: '/assets/video-intros/avatars/v1-Ashley-Ashley.png',
        introAudios: [
          '/assets/video-intros/audio/ashley/introPart1Ashley1.mp3',
          '/assets/video-intros/audio/ashley/introPart1Ashley3.mp3',
          '/assets/video-intros/audio/ashley/introPart1Ashley2.mp3',
          '/assets/video-intros/audio/ashley/introPart1Ashley4.mp3',
          '/assets/video-intros/audio/ashley/introPart1Ashley5.mp3',
          '/assets/video-intros/audio/ashley/introPart1Ashley6.mp3',
        ],
        introTexts: [
          // {
          //   "English": "Hi guys! My name is Ashley and you're watching English Expanse, a channel in which you can learn English in context.",
          //   "German": "Hallo zusammen! Mein Name ist Ashley und ihr schaut English Expanse, einen Kanal, auf dem ihr Englisch im Kontext lernen könnt.",
          //   "Spanish": "¡Hola a todos! Mi nombre es Ashley y estás viendo English Expanse, un canal en el que puedes aprender inglés en contexto.",
          //   "PortugueseBrazil": "Olá pessoal! Meu nome é Ashley e você está assistindo English Expanse, um canal onde você pode aprender inglês em contexto.",
          //   "Turkish": "Herkese merhaba! Benim adım Ashley ve English Expanse'i izliyorsunuz, burada bağlam içinde İngilizce öğrenebilirsiniz.",
          //   "French": "Bonjour à tous ! Je m'appelle Ashley et vous regardez English Expanse, une chaîne où vous pouvez apprendre l'anglais en contexte.",
          //   "Polish": "Cześć wszystkim! Nazywam się Ashley i oglądacie English Expanse, kanał, na którym możecie uczyć się angielskiego w kontekście.",
          //   "Vietnamese": "Xin chào tất cả các bạn! Tôi là Ashley và bạn đang xem English Expanse, một kênh giúp bạn học tiếng Anh trong ngữ cảnh.",
          //   "Thai": "สวัสดีทุกคน! ฉันชื่อแอชลีย์และคุณกำลังดู English Expanse ซึ่งเป็นช่องที่คุณสามารถเรียนภาษาอังกฤษในบริบทได้",
          //   "Indonesian": "Halo semuanya! Nama saya Ashley dan Anda sedang menonton English Expanse, sebuah saluran di mana Anda bisa belajar bahasa Inggris dalam konteks.",
          //   "Hindi": "सभी को नमस्कार! मेरा नाम एशले है और आप English Expanse देख रहे हैं, एक चैनल जहां आप संदर्भ में अंग्रेजी सीख सकते हैं।"
          // },
          // {
          //   "English": "Every day of the week we post a different type of video, helping you learn the words that will be used in a story that we post at the end of the week.",
          //   "German": "Jeden Tag der Woche posten wir eine andere Art von Video, die euch hilft, die Wörter zu lernen, die in einer Geschichte verwendet werden, die wir am Ende der Woche posten.",
          //   "Spanish": "Todos los días de la semana publicamos un tipo diferente de video, ayudándote a aprender las palabras que se utilizarán en una historia que publicamos al final de la semana.",
          //   "PortugueseBrazil": "Todos os dias da semana postamos um tipo diferente de vídeo, ajudando você a aprender as palavras que serão usadas em uma história que postamos no final da semana.",
          //   "Turkish": "Haftanın her günü farklı bir video türü yayınlıyoruz, hafta sonunda yayınlayacağımız hikayede kullanılacak kelimeleri öğrenmenize yardımcı oluyoruz.",
          //   "French": "Chaque jour de la semaine, nous publions un type de vidéo différent, vous aidant à apprendre les mots qui seront utilisés dans une histoire que nous publions à la fin de la semaine.",
          //   "Polish": "Każdego dnia tygodnia publikujemy inny rodzaj wideo, pomagając wam nauczyć się słów, które zostaną użyte w historii, którą publikujemy na koniec tygodnia.",
          //   "Vietnamese": "Mỗi ngày trong tuần, chúng tôi đăng một loại video khác nhau, giúp bạn học các từ sẽ được sử dụng trong một câu chuyện mà chúng tôi đăng vào cuối tuần.",
          //   "Thai": "ทุกวันของสัปดาห์เราจะโพสต์วิดีโอประเภทต่าง ๆ ที่ช่วยให้คุณเรียนรู้คำศัพท์ที่จะใช้ในเรื่องราวที่เราจะโพสต์ในตอนท้ายของสัปดาห์",
          //   "Indonesian": "Setiap hari dalam seminggu kami memposting jenis video yang berbeda, membantu Anda mempelajari kata-kata yang akan digunakan dalam cerita yang kami posting di akhir minggu.",
          //   "Hindi": "सप्ताह के हर दिन हम एक अलग प्रकार का वीडियो पोस्ट करते हैं, जो आपको उन शब्दों को सीखने में मदद करता है जो हम सप्ताह के अंत में पोस्ट की जाने वाली कहानी में उपयोग करेंगे।"
          // },
          // {
          //   "English": "Remember that all our videos have subtitles in your language and we recommend that you watch each video a few times, first with the subtitles, and then again without them to see how much you can understand.",
          //   "German": "Denkt daran, dass alle unsere Videos Untertitel in eurer Sprache haben und wir empfehlen, dass ihr jedes Video ein paar Mal anschaut, zuerst mit Untertiteln und dann nochmal ohne, um zu sehen, wie viel ihr verstehen könnt.",
          //   "Spanish": "Recuerda que todos nuestros videos tienen subtítulos en tu idioma y te recomendamos que veas cada video varias veces, primero con los subtítulos y luego nuevamente sin ellos para ver cuánto puedes entender.",
          //   "PortugueseBrazil": "Lembre-se que todos os nossos vídeos têm legendas no seu idioma e recomendamos que você assista a cada vídeo algumas vezes, primeiro com as legendas e depois novamente sem elas para ver o quanto você pode entender.",
          //   "Turkish": "Tüm videolarımızın sizin dilinizde altyazıları olduğunu ve her videoyu birkaç kez izlemenizi tavsiye ederiz, önce altyazılarla, sonra ne kadar anlayabildiğinizi görmek için altyazısız olarak tekrar izleyin.",
          //   "French": "Rappelez-vous que toutes nos vidéos ont des sous-titres dans votre langue et nous vous recommandons de regarder chaque vidéo plusieurs fois, d'abord avec les sous-titres, puis à nouveau sans eux pour voir combien vous pouvez comprendre.",
          //   "Polish": "Pamiętaj, że wszystkie nasze filmy mają napisy w twoim języku i zalecamy, abyś oglądał każdy film kilka razy, najpierw z napisami, a potem ponownie bez nich, aby sprawdzić, ile rozumiesz.",
          //   "Vietnamese": "Hãy nhớ rằng tất cả các video của chúng tôi đều có phụ đề bằng ngôn ngữ của bạn và chúng tôi khuyên bạn nên xem mỗi video vài lần, đầu tiên là với phụ đề, sau đó xem lại không có phụ đề để xem bạn hiểu được bao nhiêu.",
          //   "Thai": "จำไว้ว่าวิดีโอทั้งหมดของเรามีคำบรรยายในภาษาของคุณ และเราขอแนะนำให้คุณดูวิดีโอแต่ละรายการหลาย ๆ ครั้ง ครั้งแรกกับคำบรรยาย และจากนั้นอีกครั้งโดยไม่มีคำบรรยาย เพื่อดูว่าคุณเข้าใจได้มากแค่ไหน",
          //   "Indonesian": "Ingatlah bahwa semua video kami memiliki subtitle dalam bahasa Anda dan kami merekomendasikan agar Anda menonton setiap video beberapa kali, pertama dengan subtitle, dan kemudian lagi tanpa mereka untuk melihat seberapa banyak yang Anda bisa pahami.",
          //   "Hindi": "याद रखें कि हमारे सभी वीडियो में आपकी भाषा में उपशीर्षक होते हैं और हम अनुशंसा करते हैं कि आप प्रत्येक वीडियो को कुछ बार देखें, पहले उपशीर्षक के साथ, और फिर उन्हें बिना देखें कि आप कितना समझ सकते हैं।"
          // },
          // {
          //   "English": "Today's video is Echo Words, in which we look at some new words and practice pronouncing them correctly.",
          //   "German": "Das heutige Video ist Echo Words, in dem wir uns einige neue Wörter ansehen und deren richtige Aussprache üben.",
          //   "Spanish": "El video de hoy es Echo Words, en el que veremos algunas palabras nuevas y practicaremos pronunciarlas correctamente.",
          //   "PortugueseBrazil": "O vídeo de hoje é Echo Words, onde veremos algumas palavras novas e praticaremos a pronúncia correta delas.",
          //   "Turkish": "Bugünkü video Echo Words, bazı yeni kelimelere bakıp doğru telaffuzlarını pratik yapıyoruz.",
          //   "French": "La vidéo d'aujourd'hui est Echo Words, où nous regardons de nouveaux mots et pratiquons leur prononciation correcte.",
          //   "Polish": "Dzisiejsze wideo to Echo Words, w którym przyglądamy się nowym słowom i ćwiczymy ich poprawną wymowę.",
          //   "Vietnamese": "Video hôm nay là Echo Words, trong đó chúng ta xem một số từ mới và luyện phát âm chúng đúng cách.",
          //   "Thai": "วิดีโอวันนี้คือ Echo Words ซึ่งเราจะมาดูคำใหม่ ๆ และฝึกการออกเสียงให้ถูกต้อง",
          //   "Indonesian": "Video hari ini adalah Echo Words, di mana kita melihat beberapa kata baru dan berlatih mengucapkannya dengan benar.",
          //   "Hindi": "आज का वीडियो Echo Words है, जिसमें हम कुछ नए शब्दों को देखते हैं और उन्हें सही तरीके से उच्चारण करने का अभ्यास करते हैं।"
          // },
          // {
          //   "English": "Let's dive in!",
          //   "German": "Lasst uns eintauchen!",
          //   "Spanish": "¡Vamos a sumergirnos!",
          //   "PortugueseBrazil": "Vamos mergulhar!",
          //   "Turkish": "Haydi başlayalım!",
          //   "French": "Plongeons-y !",
          //   "Polish": "Zanurzmy się w to!",
          //   "Vietnamese": "Hãy cùng khám phá!",
          //   "Thai": "มาเริ่มกันเลย!",
          //   "Indonesian": "Mari kita mulai!",
          //   "Hindi": "आइए शुरू करते हैं!"
          // },
          // {
          //   "English": "And, remember that if you like what we are doing click like and subscribe. You can also support us on patreon, so that we can continue making new videos for you every day.",
          //   "German": "Und denkt daran, wenn euch gefällt, was wir tun, klickt auf Like und abonniert uns. Ihr könnt uns auch auf Patreon unterstützen, damit wir weiterhin jeden Tag neue Videos für euch machen können.",
          //   "Spanish": "Y recuerda que si te gusta lo que estamos haciendo, dale me gusta y suscríbete. También puedes apoyarnos en Patreon, para que podamos seguir haciendo nuevos videos para ti todos los días.",
          //   "PortugueseBrazil": "E lembre-se, se você gosta do que estamos fazendo, clique em curtir e se inscreva. Você também pode nos apoiar no Patreon, para que possamos continuar fazendo novos vídeos para você todos os dias.",
          //   "Turkish": "Ve, yaptıklarımızı beğeniyorsanız beğen ve abone ol butonuna tıklamayı unutmayın. Ayrıca Patreon'da bizi destekleyebilirsiniz, böylece sizin için her gün yeni videolar yapmaya devam edebiliriz.",
          //   "French": "Et, souvenez-vous que si vous aimez ce que nous faisons, cliquez sur j'aime et abonnez-vous. Vous pouvez également nous soutenir sur Patreon, afin que nous puissions continuer à créer de nouvelles vidéos pour vous chaque jour.",
          //   "Polish": "I pamiętajcie, że jeśli podoba wam się to, co robimy, kliknijcie like i subskrybujcie. Możecie nas również wspierać na Patreon, abyśmy mogli codziennie tworzyć dla was nowe filmy.",
          //   "Vietnamese": "Và, hãy nhớ rằng nếu bạn thích những gì chúng tôi đang làm, hãy nhấn thích và đăng ký. Bạn cũng có thể ủng hộ chúng tôi trên Patreon, để chúng tôi có thể tiếp tục làm video mới cho bạn mỗi ngày.",
          //   "Thai": "และอย่าลืมว่า ถ้าคุณชอบสิ่งที่เรากำลังทำอยู่ คลิกถูกใจและสมัครสมาชิก คุณยังสามารถสนับสนุนเราบน Patreon เพื่อให้เราสามารถทำวิดีโอใหม่ ๆ ให้คุณทุกวัน",
          //   "Indonesian": "Dan, ingatlah bahwa jika Anda menyukai apa yang kami lakukan, klik suka dan berlangganan. Anda juga bisa mendukung kami di Patreon, sehingga kami bisa terus membuat video baru untuk Anda setiap hari.",
          //   "Hindi": "और, याद रखें कि यदि आपको हमारा काम पसंद आता है तो लाइक करें और सब्सक्राइब करें। आप हमें Patreon पर भी समर्थन दे सकते हैं, ताकि हम हर दिन आपके लिए नए वीडियो बना सकें।"
          // }

        ]
      },
      pdfs: [
        {
          freeset: "false",
          type: "normal-set",
          mixeditems: false,
          orientation: "portrait",
          fullPage: false,
          removeItems: [],
          customColor: '1976d2',
          customBranding: 'EnglishExpanse',
          customLang: false,
          allLangs: true,
          pages: [[
            {
              name: "sentences-picture-dictionary-single-20",
              exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
              setId: null,
              itemsPack: null,
              numberOfItems: 1,
              descriptionPl: "",
              displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
            }],


          ],
          selected: false
        },
      ],
      selected: false
    },
    {
      displayName: 'EchoSentences',
      mechanics: 'The video is a kind of faceless presentation where there is played a following sequence: A sentence and again the same sentence. There is audio and subtitles in various languages. There are interesting and engaging visuals and animations. The intention of the video is to understand the meaning of the sentence via the subtitles and to practice pronunciation.',

      type: 'sentence-repeat',
      component: 'VideoWordRepeatComponent',
      teacherName: 'Matt',
      teachersVoice: 'Guy',
      questionsForVideo: [],
      answersForVideo: [],
      introData: {
        introImages: [
        ],
        introAvatar: '/assets/video-intros/avatars/v4-Matt-Guy.png',
        introAudios: [
          '/assets/video-intros/audio/matt/introPart4Matt1.mp3',
          '/assets/video-intros/audio/matt/introPart4Matt2.mp3',
          '/assets/video-intros/audio/matt/introPart4Matt3.mp3',
          '/assets/video-intros/audio/matt/introPart4Matt4.mp3',
          '/assets/video-intros/audio/matt/introPart4Matt5.mp3',
          '/assets/video-intros/audio/matt/introPart4Matt6.mp3',
          '/assets/video-intros/audio/matt/introPart4Matt7.mp3',
        ],
        introTexts: [
          // {
          //   "English": "What's up guys! My name is Matt and you're watching English Expanse, a channel in which you can learn English in context.",
          //   "German": "Was geht ab, Leute! Mein Name ist Matt und ihr schaut English Expanse, einen Kanal, auf dem ihr Englisch im Kontext lernen könnt.",
          //   "Spanish": "¡Qué tal, chicos! Mi nombre es Matt y estás viendo English Expanse, un canal en el que puedes aprender inglés en contexto.",
          //   "PortugueseBrazil": "E aí, pessoal! Meu nome é Matt e você está assistindo English Expanse, um canal onde você pode aprender inglês em contexto.",
          //   "Turkish": "Naber millet! Benim adım Matt ve English Expanse'i izliyorsunuz, burada bağlam içinde İngilizce öğrenebilirsiniz.",
          //   "French": "Salut tout le monde ! Je m'appelle Matt et vous regardez English Expanse, une chaîne où vous pouvez apprendre l'anglais en contexte.",
          //   "Polish": "Co tam, ludzie! Nazywam się Matt i oglądacie English Expanse, kanał, na którym możecie uczyć się angielskiego w kontekście.",
          //   "Vietnamese": "Chào các bạn! Tôi là Matt và bạn đang xem English Expanse, một kênh giúp bạn học tiếng Anh trong ngữ cảnh.",
          //   "Thai": "สวัสดีทุกคน! ฉันชื่อแมตต์และคุณกำลังดู English Expanse ซึ่งเป็นช่องที่คุณสามารถเรียนภาษาอังกฤษในบริบทได้",
          //   "Indonesian": "Apa kabar teman-teman! Nama saya Matt dan Anda sedang menonton English Expanse, sebuah saluran di mana Anda bisa belajar bahasa Inggris dalam konteks.",
          //   "Hindi": "क्या हाल है दोस्तों! मेरा नाम मैट है और आप English Expanse देख रहे हैं, एक चैनल जहां आप संदर्भ में अंग्रेजी सीख सकते हैं।"
          // },
          // {
          //   "English": "Every day of the week we post a different type of video, helping you learn the words that will be used in a story that we post at the end of the week.",
          //   "German": "Jeden Tag der Woche posten wir eine andere Art von Video, die euch hilft, die Wörter zu lernen, die in einer Geschichte verwendet werden, die wir am Ende der Woche posten.",
          //   "Spanish": "Todos los días de la semana publicamos un tipo diferente de video, ayudándote a aprender las palabras que se utilizarán en una historia que publicamos al final de la semana.",
          //   "PortugueseBrazil": "Todos os dias da semana postamos um tipo diferente de vídeo, ajudando você a aprender as palavras que serão usadas em uma história que postamos no final da semana.",
          //   "Turkish": "Haftanın her günü farklı bir video türü yayınlıyoruz, hafta sonunda yayınlayacağımız hikayede kullanılacak kelimeleri öğrenmenize yardımcı oluyoruz.",
          //   "French": "Chaque jour de la semaine, nous publions un type de vidéo différent, vous aidant à apprendre les mots qui seront utilisés dans une histoire que nous publions à la fin de la semaine.",
          //   "Polish": "Każdego dnia tygodnia publikujemy inny rodzaj wideo, pomagając wam nauczyć się słów, które zostaną użyte w historii, którą publikujemy na koniec tygodnia.",
          //   "Vietnamese": "Mỗi ngày trong tuần, chúng tôi đăng một loại video khác nhau, giúp bạn học các từ sẽ được sử dụng trong một câu chuyện mà chúng tôi đăng vào cuối tuần.",
          //   "Thai": "ทุกวันของสัปดาห์เราจะโพสต์วิดีโอประเภทต่าง ๆ ที่ช่วยให้คุณเรียนรู้คำศัพท์ที่จะใช้ในเรื่องราวที่เราจะโพสต์ในตอนท้ายของสัปดาห์",
          //   "Indonesian": "Setiap hari dalam seminggu kami memposting jenis video yang berbeda, membantu Anda mempelajari kata-kata yang akan digunakan dalam cerita yang kami posting di akhir minggu.",
          //   "Hindi": "सप्ताह के हर दिन हम एक अलग प्रकार का वीडियो पोस्ट करते हैं, जो आपको उन शब्दों को सीखने में मदद करता है जो हम सप्ताह के अंत में पोस्ट की जाने वाली कहानी में उपयोग करेंगे।"
          // },
          // {
          //   "English": "Remember that all our videos have subtitles in your language and we recommend that you watch each video a few times, first with the subtitles, and then again without them to see how much you can understand.",
          //   "German": "Denkt daran, dass alle unsere Videos Untertitel in eurer Sprache haben und wir empfehlen, dass ihr jedes Video ein paar Mal anschaut, zuerst mit Untertiteln und dann nochmal ohne, um zu sehen, wie viel ihr verstehen könnt.",
          //   "Spanish": "Recuerda que todos nuestros videos tienen subtítulos en tu idioma y te recomendamos que veas cada video varias veces, primero con los subtítulos y luego nuevamente sin ellos para ver cuánto puedes entender.",
          //   "PortugueseBrazil": "Lembre-se que todos os nossos vídeos têm legendas no seu idioma e recomendamos que você assista a cada vídeo algumas vezes, primeiro com as legendas e depois novamente sem elas para ver o quanto você pode entender.",
          //   "Turkish": "Tüm videolarımızın sizin dilinizde altyazıları olduğunu ve her videoyu birkaç kez izlemenizi tavsiye ederiz, önce altyazılarla, sonra ne kadar anlayabildiğinizi görmek için altyazısız olarak tekrar izleyin.",
          //   "French": "Rappelez-vous que toutes nos vidéos ont des sous-titres dans votre langue et nous vous recommandons de regarder chaque vidéo plusieurs fois, d'abord avec les sous-titres, puis à nouveau sans eux pour voir combien vous pouvez comprendre.",
          //   "Polish": "Pamiętaj, że wszystkie nasze filmy mają napisy w twoim języku i zalecamy, abyś oglądał każdy film kilka razy, najpierw z napisami, a potem ponownie bez nich, aby sprawdzić, ile rozumiesz.",
          //   "Vietnamese": "Hãy nhớ rằng tất cả các video của chúng tôi đều có phụ đề bằng ngôn ngữ của bạn và chúng tôi khuyên bạn nên xem mỗi video vài lần, đầu tiên là với phụ đề, sau đó xem lại không có phụ đề để xem bạn hiểu được bao nhiêu.",
          //   "Thai": "จำไว้ว่าวิดีโอทั้งหมดของเรามีคำบรรยายในภาษาของคุณ และเราขอแนะนำให้คุณดูวิดีโอแต่ละรายการหลาย ๆ ครั้ง ครั้งแรกกับคำบรรยาย และจากนั้นอีกครั้งโดยไม่มีคำบรรยาย เพื่อดูว่าคุณเข้าใจได้มากแค่ไหน",
          //   "Indonesian": "Ingatlah bahwa semua video kami memiliki subtitle dalam bahasa Anda dan kami merekomendasikan agar Anda menonton setiap video beberapa kali, pertama dengan subtitle, dan kemudian lagi tanpa mereka untuk melihat seberapa banyak yang Anda bisa pahami.",
          //   "Hindi": "याद रखें कि हमारे सभी वीडियो में आपकी भाषा में उपशीर्षक होते हैं और हम अनुशंसा करते हैं कि आप प्रत्येक वीडियो को कुछ बार देखें, पहले उपशीर्षक के साथ, और फिर उन्हें बिना देखें कि आप कितना समझ सकते हैं।"
          // },
          // {
          //   "English": "Today's video is Echo Sentences, in which we practice pronouncing whole sentences correctly.",
          //   "German": "Das heutige Video ist Echo Sentences, in dem wir das korrekte Aussprechen ganzer Sätze üben.",
          //   "Spanish": "El video de hoy es Echo Sentences, en el que practicamos la pronunciación correcta de oraciones completas.",
          //   "PortugueseBrazil": "O vídeo de hoje é Echo Sentences, onde praticamos a pronúncia correta de frases inteiras.",
          //   "Turkish": "Bugünkü video Echo Sentences, tüm cümleleri doğru telaffuz etme pratiği yapıyoruz.",
          //   "French": "La vidéo d'aujourd'hui est Echo Sentences, où nous pratiquons la prononciation correcte de phrases entières.",
          //   "Polish": "Dzisiejsze wideo to Echo Sentences, w którym ćwiczymy poprawną wymowę całych zdań.",
          //   "Vietnamese": "Video hôm nay là Echo Sentences, trong đó chúng ta thực hành phát âm đúng cả câu.",
          //   "Thai": "วิดีโอวันนี้คือ Echo Sentences ซึ่งเราฝึกการออกเสียงประโยคทั้งหมดให้ถูกต้อง",
          //   "Indonesian": "Video hari ini adalah Echo Sentences, di mana kita berlatih mengucapkan seluruh kalimat dengan benar.",
          //   "Hindi": "आज का वीडियो Echo Sentences है, जिसमें हम पूरे वाक्यों को सही तरीके से उच्चारण करने का अभ्यास करते हैं।"
          // },
          // {
          //   "English": "Check out the video description for a free PDF worksheet with exercises from this lesson.",
          //   "German": "Schaut in die Videobeschreibung für ein kostenloses PDF-Arbeitsblatt mit Übungen aus dieser Lektion.",
          //   "Spanish": "Consulta la descripción del video para obtener una hoja de ejercicios en PDF gratuita con ejercicios de esta lección.",
          //   "PortugueseBrazil": "Confira a descrição do vídeo para um PDF gratuito com exercícios desta lição.",
          //   "Turkish": "Bu dersteki alıştırmalar için ücretsiz PDF çalışma sayfası için video açıklamasına göz atın.",
          //   "French": "Consultez la description de la vidéo pour un PDF gratuit avec des exercices de cette leçon.",
          //   "Polish": "Sprawdź opis wideo, aby pobrać bezpłatny arkusz PDF z ćwiczeniami z tej lekcji.",
          //   "Vietnamese": "Hãy xem phần mô tả video để tải xuống tệp PDF bài tập miễn phí từ bài học này.",
          //   "Thai": "ตรวจสอบคำอธิบายวิดีโอสำหรับแผ่นงาน PDF ฟรีพร้อมแบบฝึกหัดจากบทเรียนนี้",
          //   "Indonesian": "Lihat deskripsi video untuk lembar kerja PDF gratis dengan latihan dari pelajaran ini.",
          //   "Hindi": "इस पाठ के अभ्यासों के साथ मुफ्त PDF वर्कशीट के लिए वीडियो विवरण देखें।"
          // },
          // {
          //   "English": "Let's dive in!",
          //   "German": "Lasst uns eintauchen!",
          //   "Spanish": "¡Vamos a sumergirnos!",
          //   "PortugueseBrazil": "Vamos mergulhar!",
          //   "Turkish": "Haydi başlayalım!",
          //   "French": "Plongeons-y !",
          //   "Polish": "Zanurzmy się w to!",
          //   "Vietnamese": "Hãy cùng khám phá!",
          //   "Thai": "มาเริ่มกันเลย!",
          //   "Indonesian": "Mari kita mulai!",
          //   "Hindi": "आइए शुरू करते हैं!"
          // },
          // {
          //   "English": "And, remember that if you like what we are doing click like and subscribe. You can also support us on patreon, so that we can continue making new videos for you every day.",
          //   "German": "Und denkt daran, wenn euch gefällt, was wir tun, klickt auf Like und abonniert uns. Ihr könnt uns auch auf Patreon unterstützen, damit wir weiterhin jeden Tag neue Videos für euch machen können.",
          //   "Spanish": "Y recuerda que si te gusta lo que estamos haciendo, dale me gusta y suscríbete. También puedes apoyarnos en Patreon, para que podamos seguir haciendo nuevos videos para ti todos los días.",
          //   "PortugueseBrazil": "E lembre-se, se você gosta do que estamos fazendo, clique em curtir e se inscreva. Você também pode nos apoiar no Patreon, para que possamos continuar fazendo novos vídeos para você todos os dias.",
          //   "Turkish": "Ve, yaptıklarımızı beğeniyorsanız beğen ve abone ol butonuna tıklamayı unutmayın. Ayrıca Patreon'da bizi destekleyebilirsiniz, böylece sizin için her gün yeni videolar yapmaya devam edebiliriz.",
          //   "French": "Et, souvenez-vous que si vous aimez ce que nous faisons, cliquez sur j'aime et abonnez-vous. Vous pouvez également nous soutenir sur Patreon, afin que nous puissions continuer à créer de nouvelles vidéos pour vous chaque jour.",
          //   "Polish": "I pamiętajcie, że jeśli podoba wam się to, co robimy, kliknijcie like i subskrybujcie. Możecie nas również wspierać na Patreon, abyśmy mogli codziennie tworzyć dla was nowe filmy.",
          //   "Vietnamese": "Và, hãy nhớ rằng nếu bạn thích những gì chúng tôi đang làm, hãy nhấn thích và đăng ký. Bạn cũng có thể ủng hộ chúng tôi trên Patreon, để chúng tôi có thể tiếp tục làm video mới cho bạn mỗi ngày.",
          //   "Thai": "และอย่าลืมว่า ถ้าคุณชอบสิ่งที่เรากำลังทำอยู่ คลิกถูกใจและสมัครสมาชิก คุณยังสามารถสนับสนุนเราบน Patreon เพื่อให้เราสามารถทำวิดีโอใหม่ ๆ ให้คุณทุกวัน",
          //   "Indonesian": "Dan, ingatlah bahwa jika Anda menyukai apa yang kami lakukan, klik suka dan berlangganan. Anda juga bisa mendukung kami di Patreon, sehingga kami bisa terus membuat video baru untuk Anda setiap hari.",
          //   "Hindi": "और, याद रखें कि यदि आपको हमारा काम पसंद आता है तो लाइक करें और सब्सक्राइब करें। आप हमें Patreon पर भी समर्थन दे सकते हैं, ताकि हम हर दिन आपके लिए नए वीडियो बना सकें।"
          // }

        ]
      },
      pdfs: [
        {
          freeset: "false",
          type: "normal-set",
          mixeditems: false,
          orientation: "portrait",
          fullPage: false,
          removeItems: [],
          customColor: '009688',
          customBranding: 'EnglishExpanse',
          customLang: false,
          allLangs: false,
          pages: [[
            {
              name: "sentences-fill-gaps-clues",
              exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
              setId: null,
              itemsPack: null,
              numberOfItems: 15,
              descriptionPl: "",
              displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
            }],


          ],
          selected: false
        },

      ],
      selected: false
    },
    {
      displayName: 'MissingWordQuiz',
      mechanics: 'The video is a kind of faceless presentation where there is a quiz. There is a sentence with one missing word and 4 answers where one is correct . There is audio and subtitles in various languages. There are interesting and engaging visuals and animations. The intention of the video is to practice or challenge yourself if you can choose the correct word.',

      type: 'sentence-missing-word-quiz',
      component: 'VideoWordRepeatComponent',
      teacherName: 'Andrew',
      teachersVoice: 'Christopher',
      questionsForVideo: [],
      answersForVideo: [],
      introData: {
        introImages: [
        ],
        introAvatar: '/assets/video-intros/avatars/v6-Andrew-Christopher.png',
        introAudios: [
          '/assets/video-intros/audio/matt/introPart4Andrew1.mp3',
          '/assets/video-intros/audio/matt/introPart4Andrew2.mp3',
          '/assets/video-intros/audio/matt/introPart4Andrew3.mp3',
          '/assets/video-intros/audio/matt/introPart4Andrew4.mp3',
          '/assets/video-intros/audio/matt/introPart4Andrew5.mp3',
          '/assets/video-intros/audio/matt/introPart4Andrew6.mp3',
        ],
        introTexts: [
          // {
          //   "English": "Hello to all of you! My name is Andrew and you're watching English Expanse, a channel in which you can learn English in context.",
          //   "German": "Hallo an euch alle! Mein Name ist Andrew und ihr schaut English Expanse, einen Kanal, auf dem ihr Englisch im Kontext lernen könnt.",
          //   "Spanish": "¡Hola a todos! Mi nombre es Andrew y estás viendo English Expanse, un canal en el que puedes aprender inglés en contexto.",
          //   "PortugueseBrazil": "Olá a todos! Meu nome é Andrew e você está assistindo English Expanse, um canal onde você pode aprender inglês em contexto.",
          //   "Turkish": "Herkese merhaba! Benim adım Andrew ve English Expanse'i izliyorsunuz, burada bağlam içinde İngilizce öğrenebilirsiniz.",
          //   "French": "Bonjour à tous ! Je m'appelle Andrew et vous regardez English Expanse, une chaîne où vous pouvez apprendre l'anglais en contexte.",
          //   "Polish": "Cześć wszystkim! Nazywam się Andrew i oglądacie English Expanse, kanał, na którym możecie uczyć się angielskiego w kontekście.",
          //   "Vietnamese": "Xin chào tất cả các bạn! Tôi là Andrew và bạn đang xem English Expanse, một kênh giúp bạn học tiếng Anh trong ngữ cảnh.",
          //   "Thai": "สวัสดีทุกคน! ผมชื่อแอนดรูว์และคุณกำลังดู English Expanse ซึ่งเป็นช่องที่คุณสามารถเรียนภาษาอังกฤษในบริบทได้",
          //   "Indonesian": "Halo semuanya! Nama saya Andrew dan Anda sedang menonton English Expanse, sebuah saluran di mana Anda bisa belajar bahasa Inggris dalam konteks.",
          //   "Hindi": "सभी को नमस्कार! मेरा नाम एंड्रयू है और आप English Expanse देख रहे हैं, एक चैनल जहां आप संदर्भ में अंग्रेजी सीख सकते हैं।"
          // },
          // {
          //   "English": "Every day of the week we post a different type of video, helping you learn the words that will be used in a story that we post at the end of the week.",
          //   "German": "Jeden Tag der Woche posten wir eine andere Art von Video, die euch hilft, die Wörter zu lernen, die in einer Geschichte verwendet werden, die wir am Ende der Woche posten.",
          //   "Spanish": "Todos los días de la semana publicamos un tipo diferente de video, ayudándote a aprender las palabras que se utilizarán en una historia que publicamos al final de la semana.",
          //   "PortugueseBrazil": "Todos os dias da semana postamos um tipo diferente de vídeo, ajudando você a aprender as palavras que serão usadas em uma história que postamos no final da semana.",
          //   "Turkish": "Haftanın her günü farklı bir video türü yayınlıyoruz, hafta sonunda yayınlayacağımız hikayede kullanılacak kelimeleri öğrenmenize yardımcı oluyoruz.",
          //   "French": "Chaque jour de la semaine, nous publions un type de vidéo différent, vous aidant à apprendre les mots qui seront utilisés dans une histoire que nous publions à la fin de la semaine.",
          //   "Polish": "Każdego dnia tygodnia publikujemy inny rodzaj wideo, pomagając wam nauczyć się słów, które zostaną użyte w historii, którą publikujemy na koniec tygodnia.",
          //   "Vietnamese": "Mỗi ngày trong tuần, chúng tôi đăng một loại video khác nhau, giúp bạn học các từ sẽ được sử dụng trong một câu chuyện mà chúng tôi đăng vào cuối tuần.",
          //   "Thai": "ทุกวันของสัปดาห์เราจะโพสต์วิดีโอประเภทต่าง ๆ ที่ช่วยให้คุณเรียนรู้คำศัพท์ที่จะใช้ในเรื่องราวที่เราจะโพสต์ในตอนท้ายของสัปดาห์",
          //   "Indonesian": "Setiap hari dalam seminggu kami memposting jenis video yang berbeda, membantu Anda mempelajari kata-kata yang akan digunakan dalam cerita yang kami posting di akhir minggu.",
          //   "Hindi": "सप्ताह के हर दिन हम एक अलग प्रकार का वीडियो पोस्ट करते हैं, जो आपको उन शब्दों को सीखने में मदद करता है जो हम सप्ताह के अंत में पोस्ट की जाने वाली कहानी में उपयोग करेंगे।"
          // },
          // {
          //   "English": "Remember that all our videos have subtitles in your language and we recommend that you watch each video a few times, first with the subtitles, and then again without them to see how much you can understand.",
          //   "German": "Denkt daran, dass alle unsere Videos Untertitel in eurer Sprache haben und wir empfehlen, dass ihr jedes Video ein paar Mal anschaut, zuerst mit Untertiteln und dann nochmal ohne, um zu sehen, wie viel ihr verstehen könnt.",
          //   "Spanish": "Recuerda que todos nuestros videos tienen subtítulos en tu idioma y te recomendamos que veas cada video varias veces, primero con los subtítulos y luego nuevamente sin ellos para ver cuánto puedes entender.",
          //   "PortugueseBrazil": "Lembre-se que todos os nossos vídeos têm legendas no seu idioma e recomendamos que você assista a cada vídeo algumas vezes, primeiro com as legendas e depois novamente sem elas para ver o quanto você pode entender.",
          //   "Turkish": "Tüm videolarımızın sizin dilinizde altyazıları olduğunu ve her videoyu birkaç kez izlemenizi tavsiye ederiz, önce altyazılarla, sonra ne kadar anlayabildiğinizi görmek için altyazısız olarak tekrar izleyin.",
          //   "French": "Rappelez-vous que toutes nos vidéos ont des sous-titres dans votre langue et nous vous recommandons de regarder chaque vidéo plusieurs fois, d'abord avec les sous-titres, puis à nouveau sans eux pour voir combien vous pouvez comprendre.",
          //   "Polish": "Pamiętaj, że wszystkie nasze filmy mają napisy w twoim języku i zalecamy, abyś oglądał każdy film kilka razy, najpierw z napisami, a potem ponownie bez nich, aby sprawdzić, ile rozumiesz.",
          //   "Vietnamese": "Hãy nhớ rằng tất cả các video của chúng tôi đều có phụ đề bằng ngôn ngữ của bạn và chúng tôi khuyên bạn nên xem mỗi video vài lần, đầu tiên là với phụ đề, sau đó xem lại không có phụ đề để xem bạn hiểu được bao nhiêu.",
          //   "Thai": "จำไว้ว่าวิดีโอทั้งหมดของเรามีคำบรรยายในภาษาของคุณ และเราขอแนะนำให้คุณดูวิดีโอแต่ละรายการหลาย ๆ ครั้ง ครั้งแรกกับคำบรรยาย และจากนั้นอีกครั้งโดยไม่มีคำบรรยาย เพื่อดูว่าคุณเข้าใจได้มากแค่ไหน",
          //   "Indonesian": "Ingatlah bahwa semua video kami memiliki subtitle dalam bahasa Anda dan kami merekomendasikan agar Anda menonton setiap video beberapa kali, pertama dengan subtitle, dan kemudian lagi tanpa mereka untuk melihat seberapa banyak yang Anda bisa pahami.",
          //   "Hindi": "याद रखें कि हमारे सभी वीडियो में आपकी भाषा में उपशीर्षक होते हैं और हम अनुशंसा करते हैं कि आप प्रत्येक वीडियो को कुछ बार देखें, पहले उपशीर्षक के साथ, और फिर उन्हें बिना देखें कि आप कितना समझ सकते हैं।"
          // },
          // {
          //   "English": "Today's video is Quiz Show, in which you can test yourself, and see how many words you remember.",
          //   "German": "Das heutige Video ist Quiz Show, bei dem ihr euch selbst testen und sehen könnt, wie viele Wörter ihr euch gemerkt habt.",
          //   "Spanish": "El video de hoy es Quiz Show, en el que puedes ponerte a prueba y ver cuántas palabras recuerdas.",
          //   "PortugueseBrazil": "O vídeo de hoje é Quiz Show, onde você pode testar a si mesmo e ver quantas palavras você se lembra.",
          //   "Turkish": "Bugünkü video Quiz Show, kendinizi test edebileceğiniz ve kaç kelime hatırladığınızı görebileceğiniz bir video.",
          //   "French": "La vidéo d'aujourd'hui est Quiz Show, où vous pouvez vous tester et voir combien de mots vous vous souvenez.",
          //   "Polish": "Dzisiejsze wideo to Quiz Show, w którym możecie się sprawdzić i zobaczyć, ile słów pamiętacie.",
          //   "Vietnamese": "Video hôm nay là Quiz Show, nơi bạn có thể tự kiểm tra và xem bạn nhớ được bao nhiêu từ.",
          //   "Thai": "วิดีโอวันนี้คือ Quiz Show ที่คุณสามารถทดสอบตัวเองและดูว่าคุณจำคำศัพท์ได้มากน้อยแค่ไหน",
          //   "Indonesian": "Video hari ini adalah Quiz Show, di mana Anda bisa menguji diri sendiri dan melihat berapa banyak kata yang Anda ingat.",
          //   "Hindi": "आज का वीडियो है Quiz Show, जिसमें आप खुद को परख सकते हैं और देख सकते हैं कि आपको कितने शब्द याद हैं।"
          // },
          // {
          //   "English": "Let's dive in!",
          //   "German": "Lasst uns eintauchen!",
          //   "Spanish": "¡Vamos a sumergirnos!",
          //   "PortugueseBrazil": "Vamos mergulhar!",
          //   "Turkish": "Haydi başlayalım!",
          //   "French": "Plongeons-y !",
          //   "Polish": "Zanurzmy się w to!",
          //   "Vietnamese": "Hãy cùng khám phá!",
          //   "Thai": "มาเริ่มกันเลย!",
          //   "Indonesian": "Mari kita mulai!",
          //   "Hindi": "आइए शुरू करते हैं!"
          // },
          // {
          //   "English": "And, remember that if you like what we are doing click like and subscribe. You can also support us on patreon, so that we can continue making new videos for you every day.",
          //   "German": "Und denkt daran, wenn euch gefällt, was wir tun, klickt auf Like und abonniert uns. Ihr könnt uns auch auf Patreon unterstützen, damit wir weiterhin jeden Tag neue Videos für euch machen können.",
          //   "Spanish": "Y recuerda que si te gusta lo que estamos haciendo, dale me gusta y suscríbete. También puedes apoyarnos en Patreon, para que podamos seguir haciendo nuevos videos para ti todos los días.",
          //   "PortugueseBrazil": "E lembre-se, se você gosta do que estamos fazendo, clique em curtir e se inscreva. Você também pode nos apoiar no Patreon, para que possamos continuar fazendo novos vídeos para você todos os dias.",
          //   "Turkish": "Ve, yaptıklarımızı beğeniyorsanız beğen ve abone ol butonuna tıklamayı unutmayın. Ayrıca Patreon'da bizi destekleyebilirsiniz, böylece sizin için her gün yeni videolar yapmaya devam edebiliriz.",
          //   "French": "Et, souvenez-vous que si vous aimez ce que nous faisons, cliquez sur j'aime et abonnez-vous. Vous pouvez également nous soutenir sur Patreon, afin que nous puissions continuer à créer de nouvelles vidéos pour vous chaque jour.",
          //   "Polish": "I pamiętajcie, że jeśli podoba wam się to, co robimy, kliknijcie like i subskrybujcie. Możecie nas również wspierać na Patreon, abyśmy mogli codziennie tworzyć dla was nowe filmy.",
          //   "Vietnamese": "Và, hãy nhớ rằng nếu bạn thích những gì chúng tôi đang làm, hãy nhấn thích và đăng ký. Bạn cũng có thể ủng hộ chúng tôi trên Patreon, để chúng tôi có thể tiếp tục làm video mới cho bạn mỗi ngày.",
          //   "Thai": "และอย่าลืมว่า ถ้าคุณชอบสิ่งที่เรากำลังทำอยู่ คลิกถูกใจและสมัครสมาชิก คุณยังสามารถสนับสนุนเราบน Patreon เพื่อให้เราสามารถทำวิดีโอใหม่ ๆ ให้คุณทุกวัน",
          //   "Indonesian": "Dan, ingatlah bahwa jika Anda menyukai apa yang kami lakukan, klik suka dan berlangganan. Anda juga bisa mendukung kami di Patreon, sehingga kami bisa terus membuat video baru untuk Anda setiap hari.",
          //   "Hindi": "और, याद रखें कि यदि आपको हमारा काम पसंद आता है तो लाइक करें और सब्सक्राइब करें। आप हमें Patreon पर भी समर्थन दे सकते हैं, ताकि हम हर दिन आपके लिए नए वीडियो बना सकें।"
          // }

        ]
      },

      pdfs: [

        {
          freeset: "false",
          type: "normal-set",
          mixeditems: false,
          orientation: "portrait",
          fullPage: false,
          removeItems: [],
          customColor: '009688',
          customBranding: 'EnglishExpanse',
          customLang: false,
          allLangs: false,



          pages: [[
            {
              name: "sentences-fill-gaps-clues",
              exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
              setId: null,
              itemsPack: null,
              numberOfItems: 1,
              descriptionPl: "",
              displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
            }],


          ],
          selected: false
        },
      ],
      selected: false
    },
    {
      displayName: 'sentenceReorder',
      mechanics: 'The video is a kind of faceless presentation where there is played a following sequence: A senntence with mixed chunks and then the same sentence ordered correctly. There is audio and subtitles in various languages. There are interesting and engaging visuals and animations. The intention of the video is to practice or challenge yourself to order the sentence.',

      type: 'sentence-reorder',
      teacherName: 'Sam',
      teachersVoice: 'Sara',
      questionsForVideo: [],
      answersForVideo: [],
      component: 'VideoWordRepeatComponent',
      introData: {
        introImages: [
        ],
        introAvatar: '/assets/video-intros/avatars/v5-Sam-Sara.png',
        introAudios: [
          '/assets/video-intros/audio/sam/introPart5Sam1.mp3',
          '/assets/video-intros/audio/sam/introPart5Sam2.mp3',
          '/assets/video-intros/audio/sam/introPart5Sam3.mp3',
          '/assets/video-intros/audio/sam/introPart5Sam4.mp3',
          '/assets/video-intros/audio/sam/introPart5Sam5.mp3',
          '/assets/video-intros/audio/sam/introPart5Sam6.mp3',
        ],
        introTexts: [
          // {
          //   "English": "Hi everyone! My name is Sam and you're watching English Expanse, a channel in which you can learn English in context.",
          //   "German": "Hallo zusammen! Mein Name ist Sam und ihr schaut English Expanse, einen Kanal, auf dem ihr Englisch im Kontext lernen könnt.",
          //   "Spanish": "¡Hola a todos! Mi nombre es Sam y estás viendo English Expanse, un canal en el que puedes aprender inglés en contexto.",
          //   "PortugueseBrazil": "Olá a todos! Meu nome é Sam e você está assistindo English Expanse, um canal onde você pode aprender inglês em contexto.",
          //   "Turkish": "Herkese merhaba! Benim adım Sam ve English Expanse'i izliyorsunuz, burada bağlam içinde İngilizce öğrenebilirsiniz.",
          //   "French": "Bonjour à tous ! Je m'appelle Sam et vous regardez English Expanse, une chaîne où vous pouvez apprendre l'anglais en contexte.",
          //   "Polish": "Cześć wszystkim! Nazywam się Sam i oglądacie English Expanse, kanał, na którym możecie uczyć się angielskiego w kontekście.",
          //   "Vietnamese": "Xin chào tất cả các bạn! Tôi là Sam và bạn đang xem English Expanse, một kênh giúp bạn học tiếng Anh trong ngữ cảnh.",
          //   "Thai": "สวัสดีทุกคน! ฉันชื่อแซมและคุณกำลังดู English Expanse ซึ่งเป็นช่องที่คุณสามารถเรียนภาษาอังกฤษในบริบทได้",
          //   "Indonesian": "Halo semuanya! Nama saya Sam dan Anda sedang menonton English Expanse, sebuah saluran di mana Anda bisa belajar bahasa Inggris dalam konteks.",
          //   "Hindi": "सभी को नमस्कार! मेरा नाम सैम है और आप English Expanse देख रहे हैं, एक चैनल जहां आप संदर्भ में अंग्रेजी सीख सकते हैं।"
          // },
          // {
          //   "English": "Every day of the week we post a different type of video, helping you learn the words that will be used in a story that we post at the end of the week.",
          //   "German": "Jeden Tag der Woche posten wir eine andere Art von Video, die euch hilft, die Wörter zu lernen, die in einer Geschichte verwendet werden, die wir am Ende der Woche posten.",
          //   "Spanish": "Todos los días de la semana publicamos un tipo diferente de video, ayudándote a aprender las palabras que se utilizarán en una historia que publicamos al final de la semana.",
          //   "PortugueseBrazil": "Todos os dias da semana postamos um tipo diferente de vídeo, ajudando você a aprender as palavras que serão usadas em uma história que postamos no final da semana.",
          //   "Turkish": "Haftanın her günü farklı bir video türü yayınlıyoruz, hafta sonunda yayınlayacağımız hikayede kullanılacak kelimeleri öğrenmenize yardımcı oluyoruz.",
          //   "French": "Chaque jour de la semaine, nous publions un type de vidéo différent, vous aidant à apprendre les mots qui seront utilisés dans une histoire que nous publions à la fin de la semaine.",
          //   "Polish": "Każdego dnia tygodnia publikujemy inny rodzaj wideo, pomagając wam nauczyć się słów, które zostaną użyte w historii, którą publikujemy na koniec tygodnia.",
          //   "Vietnamese": "Mỗi ngày trong tuần, chúng tôi đăng một loại video khác nhau, giúp bạn học các từ sẽ được sử dụng trong một câu chuyện mà chúng tôi đăng vào cuối tuần.",
          //   "Thai": "ทุกวันของสัปดาห์เราจะโพสต์วิดีโอประเภทต่าง ๆ ที่ช่วยให้คุณเรียนรู้คำศัพท์ที่จะใช้ในเรื่องราวที่เราจะโพสต์ในตอนท้ายของสัปดาห์",
          //   "Indonesian": "Setiap hari dalam seminggu kami memposting jenis video yang berbeda, membantu Anda mempelajari kata-kata yang akan digunakan dalam cerita yang kami posting di akhir minggu.",
          //   "Hindi": "सप्ताह के हर दिन हम एक अलग प्रकार का वीडियो पोस्ट करते हैं, जो आपको उन शब्दों को सीखने में मदद करता है जो हम सप्ताह के अंत में पोस्ट की जाने वाली कहानी में उपयोग करेंगे।"
          // },
          // {
          //   "English": "Remember that all our videos have subtitles in your language and we recommend that you watch each video a few times, first with the subtitles, and then again without them to see how much you can understand.",
          //   "German": "Denkt daran, dass alle unsere Videos Untertitel in eurer Sprache haben und wir empfehlen, dass ihr jedes Video ein paar Mal anschaut, zuerst mit Untertiteln und dann nochmal ohne, um zu sehen, wie viel ihr verstehen könnt.",
          //   "Spanish": "Recuerda que todos nuestros videos tienen subtítulos en tu idioma y te recomendamos que veas cada video varias veces, primero con los subtítulos y luego nuevamente sin ellos para ver cuánto puedes entender.",
          //   "PortugueseBrazil": "Lembre-se que todos os nossos vídeos têm legendas no seu idioma e recomendamos que você assista a cada vídeo algumas vezes, primeiro com as legendas e depois novamente sem elas para ver o quanto você pode entender.",
          //   "Turkish": "Tüm videolarımızın sizin dilinizde altyazıları olduğunu ve her videoyu birkaç kez izlemenizi tavsiye ederiz, önce altyazılarla, sonra ne kadar anlayabildiğinizi görmek için altyazısız olarak tekrar izleyin.",
          //   "French": "Rappelez-vous que toutes nos vidéos ont des sous-titres dans votre langue et nous vous recommandons de regarder chaque vidéo plusieurs fois, d'abord avec les sous-titres, puis à nouveau sans eux pour voir combien vous pouvez comprendre.",
          //   "Polish": "Pamiętaj, że wszystkie nasze filmy mają napisy w twoim języku i zalecamy, abyś oglądał każdy film kilka razy, najpierw z napisami, a potem ponownie bez nich, aby sprawdzić, ile rozumiesz.",
          //   "Vietnamese": "Hãy nhớ rằng tất cả các video của chúng tôi đều có phụ đề bằng ngôn ngữ của bạn và chúng tôi khuyên bạn nên xem mỗi video vài lần, đầu tiên là với phụ đề, sau đó xem lại không có phụ đề để xem bạn hiểu được bao nhiêu.",
          //   "Thai": "จำไว้ว่าวิดีโอทั้งหมดของเรามีคำบรรยายในภาษาของคุณ และเราขอแนะนำให้คุณดูวิดีโอแต่ละรายการหลาย ๆ ครั้ง ครั้งแรกกับคำบรรยาย และจากนั้นอีกครั้งโดยไม่มีคำบรรยาย เพื่อดูว่าคุณเข้าใจได้มากแค่ไหน",
          //   "Indonesian": "Ingatlah bahwa semua video kami memiliki subtitle dalam bahasa Anda dan kami merekomendasikan agar Anda menonton setiap video beberapa kali, pertama dengan subtitle, dan kemudian lagi tanpa mereka untuk melihat seberapa banyak yang Anda bisa pahami.",
          //   "Hindi": "याद रखें कि हमारे सभी वीडियो में आपकी भाषा में उपशीर्षक होते हैं और हम अनुशंसा करते हैं कि आप प्रत्येक वीडियो को कुछ बार देखें, पहले उपशीर्षक के साथ, और फिर उन्हें बिना देखें कि आप कितना समझ सकते हैं।"
          // },
          // {
          //   "English": "Today's video is Sentence Reorder, in which we practice listening and also building sentences.",
          //   "German": "Das heutige Video ist Sentence Reorder, in dem wir das Zuhören und auch das Bauen von Sätzen üben.",
          //   "Spanish": "El video de hoy es Sentence Reorder, en el que practicamos escuchar y también construir oraciones.",
          //   "PortugueseBrazil": "O vídeo de hoje é Sentence Reorder, onde praticamos a escuta e também a construção de frases.",
          //   "Turkish": "Bugünkü video Sentence Reorder, dinleme ve cümle kurma pratiği yapıyoruz.",
          //   "French": "La vidéo d'aujourd'hui est Sentence Reorder, où nous pratiquons l'écoute et aussi la construction de phrases.",
          //   "Polish": "Dzisiejsze wideo to Sentence Reorder, w którym ćwiczymy słuchanie i również budowanie zdań.",
          //   "Vietnamese": "Video hôm nay là Sentence Reorder, trong đó chúng ta thực hành nghe và cũng xây dựng câu.",
          //   "Thai": "วิดีโอวันนี้คือ Sentence Reorder ซึ่งเราฝึกการฟังและการสร้างประโยค",
          //   "Indonesian": "Video hari ini adalah Sentence Reorder, di mana kita berlatih mendengarkan dan juga menyusun kalimat.",
          //   "Hindi": "आज का वीडियो Sentence Reorder है, जिसमें हम सुनने और वाक्य बनाने का अभ्यास करते हैं।"
          // },
          // {
          //   "English": "Let's dive in!",
          //   "German": "Lasst uns eintauchen!",
          //   "Spanish": "¡Vamos a sumergirnos!",
          //   "PortugueseBrazil": "Vamos mergulhar!",
          //   "Turkish": "Haydi başlayalım!",
          //   "French": "Plongeons-y !",
          //   "Polish": "Zanurzmy się w to!",
          //   "Vietnamese": "Hãy cùng khám phá!",
          //   "Thai": "มาเริ่มกันเลย!",
          //   "Indonesian": "Mari kita mulai!",
          //   "Hindi": "आइए शुरू करते हैं!"
          // },
          // {
          //   "English": "And, remember that if you like what we are doing click like and subscribe. You can also support us on patreon, so that we can continue making new videos for you every day.",
          //   "German": "Und denkt daran, wenn euch gefällt, was wir tun, klickt auf Like und abonniert uns. Ihr könnt uns auch auf Patreon unterstützen, damit wir weiterhin jeden Tag neue Videos für euch machen können.",
          //   "Spanish": "Y recuerda que si te gusta lo que estamos haciendo, dale me gusta y suscríbete. También puedes apoyarnos en Patreon, para que podamos seguir haciendo nuevos videos para ti todos los días.",
          //   "PortugueseBrazil": "E lembre-se, se você gosta do que estamos fazendo, clique em curtir e se inscreva. Você também pode nos apoiar no Patreon, para que possamos continuar fazendo novos vídeos para você todos os dias.",
          //   "Turkish": "Ve, yaptıklarımızı beğeniyorsanız beğen ve abone ol butonuna tıklamayı unutmayın. Ayrıca Patreon'da bizi destekleyebilirsiniz, böylece sizin için her gün yeni videolar yapmaya devam edebiliriz.",
          //   "French": "Et, souvenez-vous que si vous aimez ce que nous faisons, cliquez sur j'aime et abonnez-vous. Vous pouvez également nous soutenir sur Patreon, afin que nous puissions continuer à créer de nouvelles vidéos pour vous chaque jour.",
          //   "Polish": "I pamiętajcie, że jeśli podoba wam się to, co robimy, kliknijcie like i subskrybujcie. Możecie nas również wspierać na Patreon, abyśmy mogli codziennie tworzyć dla was nowe filmy.",
          //   "Vietnamese": "Và, hãy nhớ rằng nếu bạn thích những gì chúng tôi đang làm, hãy nhấn thích và đăng ký. Bạn cũng có thể ủng hộ chúng tôi trên Patreon, để chúng tôi có thể tiếp tục làm video mới cho bạn mỗi ngày.",
          //   "Thai": "และอย่าลืมว่า ถ้าคุณชอบสิ่งที่เรากำลังทำอยู่ คลิกถูกใจและสมัครสมาชิก คุณยังสามารถสนับสนุนเราบน Patreon เพื่อให้เราสามารถทำวิดีโอใหม่ ๆ ให้คุณทุกวัน",
          //   "Indonesian": "Dan, ingatlah bahwa jika Anda menyukai apa yang kami lakukan, klik suka dan berlangganan. Anda juga bisa mendukung kami di Patreon, sehingga kami bisa terus membuat video baru untuk Anda setiap hari.",
          //   "Hindi": "और, याद रखें कि यदि आपको हमारा काम पसंद आता है तो लाइक करें और सब्सक्राइब करें। आप हमें Patreon पर भी समर्थन दे सकते हैं, ताकि हम हर दिन आपके लिए नए वीडियो बना सकें।"
          // }

        ]
      },
      pdfs: [
        {
          freeset: "false",
          type: "normal-set",
          mixeditems: false,
          orientation: "portrait",
          fullPage: false,
          removeItems: [],
          customColor: '009688',
          customBranding: 'EnglishExpanse',
          customLang: false,
          allLangs: false,
          pages: [[
            {
              name: "sentences-fill-gaps-clues",
              exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
              setId: null,
              itemsPack: null,
              numberOfItems: 1,
              descriptionPl: "",
              displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
            }],


          ],
          selected: false
        },
      ],
      selected: false
    },
    {
      displayName: 'StoryTime',
      type: 'story',
      mechanics: 'The video is a kind of faceless presentation where there is played a story. The story has multiole slides and engaging moving videos. There is audio and subtitles in various languages. There are interesting and engaging visuals and animations. The intention of the video is to learn thru story in context.',

      component: '',
      introData: {
        introImages: [
        ],
        teacherName: 'Briana',
        teachersVoice: 'Amber',
        questionsForVideo: [],
        answersForVideo: [],
        introAvatar: '/assets/video-intros/avatars/v7-Brianna-Amber.png',
        introAudios: [
          '/assets/video-intros/audio/briana/introPart7Briana1.mp3',
          '/assets/video-intros/audio/briana/introPart7Briana2.mp3',
          '/assets/video-intros/audio/briana/introPart7Briana3.mp3',
          '/assets/video-intros/audio/briana/introPart7Briana4.mp3',
          '/assets/video-intros/audio/briana/introPart7Briana5.mp3',
          '/assets/video-intros/audio/briana/introPart7Briana6.mp3',
        ],
        introTexts: [
          // {
          //   "English": "Hey everyone! My name is Brianna and you're watching English Expanse, a channel in which you can learn English in context.",
          //   "German": "Hey an euch alle! Mein Name ist Brianna und ihr schaut English Expanse, einen Kanal, auf dem ihr Englisch im Kontext lernen könnt.",
          //   "Spanish": "¡Hola a todos! Mi nombre es Brianna y estás viendo English Expanse, un canal en el que puedes aprender inglés en contexto.",
          //   "PortugueseBrazil": "Olá a todos! Meu nome é Brianna e você está assistindo English Expanse, um canal onde você pode aprender inglês em contexto.",
          //   "Turkish": "Herkese merhaba! Benim adım Brianna ve English Expanse'i izliyorsunuz, burada bağlam içinde İngilizce öğrenebilirsiniz.",
          //   "French": "Bonjour à tous ! Je m'appelle Brianna et vous regardez English Expanse, une chaîne où vous pouvez apprendre l'anglais en contexte.",
          //   "Polish": "Cześć wszystkim! Nazywam się Brianna i oglądacie English Expanse, kanał, na którym możecie uczyć się angielskiego w kontekście.",
          //   "Vietnamese": "Xin chào tất cả các bạn! Tôi là Brianna và bạn đang xem English Expanse, một kênh giúp bạn học tiếng Anh trong ngữ cảnh.",
          //   "Thai": "สวัสดีทุกคน! ผมชื่อเบรียนนาและคุณกำลังดู English Expanse ซึ่งเป็นช่องที่คุณสามารถเรียนภาษาอังกฤษในบริบทได้",
          //   "Indonesian": "Halo semuanya! Nama saya Brianna dan Anda sedang menonton English Expanse, sebuah saluran di mana Anda bisa belajar bahasa Inggris dalam konteks.",
          //   "Hindi": "सभी को नमस्कार! मेरा नाम ब्रियाना है और आप English Expanse देख रहे हैं, एक चैनल जहां आप संदर्भ में अंग्रेजी सीख सकते हैं।"
          // },
          // {
          //   "English": "Every day of the week we post a different type of video, helping you learn the words that will be used in a story that we post at the end of the week.",
          //   "German": "Jeden Tag der Woche posten wir eine andere Art von Video, die euch hilft, die Wörter zu lernen, die in einer Geschichte verwendet werden, die wir am Ende der Woche posten.",
          //   "Spanish": "Todos los días de la semana publicamos un tipo diferente de video, ayudándote a aprender las palabras que se utilizarán en una historia que publicamos al final de la semana.",
          //   "PortugueseBrazil": "Todos os dias da semana postamos um tipo diferente de vídeo, ajudando você a aprender as palavras que serão usadas em uma história que postamos no final da semana.",
          //   "Turkish": "Haftanın her günü farklı bir video türü yayınlıyoruz, hafta sonunda yayınlayacağımız hikayede kullanılacak kelimeleri öğrenmenize yardımcı oluyoruz.",
          //   "French": "Chaque jour de la semaine, nous publions un type de vidéo différent, vous aidant à apprendre les mots qui seront utilisés dans une histoire que nous publions à la fin de la semaine.",
          //   "Polish": "Każdego dnia tygodnia publikujemy inny rodzaj wideo, pomagając wam nauczyć się słów, które zostaną użyte w historii, którą publikujemy na koniec tygodnia.",
          //   "Vietnamese": "Mỗi ngày trong tuần, chúng tôi đăng một loại video khác nhau, giúp bạn học các từ sẽ được sử dụng trong một câu chuyện mà chúng tôi đăng vào cuối tuần.",
          //   "Thai": "ทุกวันของสัปดาห์เราจะโพสต์วิดีโอประเภทต่าง ๆ ที่ช่วยให้คุณเรียนรู้คำศัพท์ที่จะใช้ในเรื่องราวที่เราจะโพสต์ในตอนท้ายของสัปดาห์",
          //   "Indonesian": "Setiap hari dalam seminggu kami memposting jenis video yang berbeda, membantu Anda mempelajari kata-kata yang akan digunakan dalam cerita yang kami posting di akhir minggu.",
          //   "Hindi": "सप्ताह के हर दिन हम एक अलग प्रकार का वीडियो पोस्ट करते हैं, जो आपको उन शब्दों को सीखने में मदद करता है जो हम सप्ताह के अंत में पोस्ट की जाने वाली कहानी में उपयोग करेंगे।"
          // },
          // {
          //   "English": "Remember that all our videos have subtitles in your language and we recommend that you watch each video a few times, first with the subtitles, and then again without them to see how much you can understand.",
          //   "German": "Denkt daran, dass alle unsere Videos Untertitel in eurer Sprache haben und wir empfehlen, dass ihr jedes Video ein paar Mal anschaut, zuerst mit Untertiteln und dann nochmal ohne, um zu sehen, wie viel ihr verstehen könnt.",
          //   "Spanish": "Recuerda que todos nuestros videos tienen subtítulos en tu idioma y te recomendamos que veas cada video varias veces, primero con los subtítulos y luego nuevamente sin ellos para ver cuánto puedes entender.",
          //   "PortugueseBrazil": "Lembre-se que todos os nossos vídeos têm legendas no seu idioma e recomendamos que você assista a cada vídeo algumas vezes, primeiro com as legendas e depois novamente sem elas para ver o quanto você pode entender.",
          //   "Turkish": "Tüm videolarımızın sizin dilinizde altyazıları olduğunu ve her videoyu birkaç kez izlemenizi tavsiye ederiz, önce altyazılarla, sonra ne kadar anlayabildiğinizi görmek için altyazısız olarak tekrar izleyin.",
          //   "French": "Rappelez-vous que toutes nos vidéos ont des sous-titres dans votre langue et nous vous recommandons de regarder chaque vidéo plusieurs fois, d'abord avec les sous-titres, puis à nouveau sans eux pour voir combien vous pouvez comprendre.",
          //   "Polish": "Pamiętaj, że wszystkie nasze filmy mają napisy w twoim języku i zalecamy, abyś oglądał każdy film kilka razy, najpierw z napisami, a potem ponownie bez nich, aby sprawdzić, ile rozumiesz.",
          //   "Vietnamese": "Hãy nhớ rằng tất cả các video của chúng tôi đều có phụ đề bằng ngôn ngữ của bạn và chúng tôi khuyên bạn nên xem mỗi video vài lần, đầu tiên là với phụ đề, sau đó xem lại không có phụ đề để xem bạn hiểu được bao nhiêu.",
          //   "Thai": "จำไว้ว่าวิดีโอทั้งหมดของเรามีคำบรรยายในภาษาของคุณ และเราขอแนะนำให้คุณดูวิดีโอแต่ละรายการหลาย ๆ ครั้ง ครั้งแรกกับคำบรรยาย และจากนั้นอีกครั้งโดยไม่มีคำบรรยาย เพื่อดูว่าคุณเข้าใจได้มากแค่ไหน",
          //   "Indonesian": "Ingatlah bahwa semua video kami memiliki subtitle dalam bahasa Anda dan kami merekomendasikan agar Anda menonton setiap video beberapa kali, pertama dengan subtitle, dan kemudian lagi tanpa mereka untuk melihat seberapa banyak yang Anda bisa pahami.",
          //   "Hindi": "याद रखें कि हमारे सभी वीडियो में आपकी भाषा में उपशीर्षक होते हैं और हम अनुशंसा करते हैं कि आप प्रत्येक वीडियो को कुछ बार देखें, पहले उपशीर्षक के साथ, और फिर उन्हें बिना देखें कि आप कितना समझ सकते हैं।"
          // },
          // {
          //   "English": "Today's video is Story Time, in which we read and listen to a story, which uses the words we have learned throughout the week.",
          //   "German": "Das heutige Video ist Story Time, in dem wir eine Geschichte lesen und hören, die die Wörter verwendet, die wir während der Woche gelernt haben.",
          //   "Spanish": "El video de hoy es Story Time, en el que leemos y escuchamos una historia que utiliza las palabras que hemos aprendido durante la semana.",
          //   "PortugueseBrazil": "O vídeo de hoje é Story Time, no qual lemos e ouvimos uma história que usa as palavras que aprendemos durante a semana.",
          //   "Turkish": "Bugünkü video Hikaye Zamanı, haftanın boyunca öğrendiğimiz kelimeleri kullanan bir hikaye okuyup dinliyoruz.",
          //   "French": "La vidéo d'aujourd'hui est Story Time, dans laquelle nous lisons et écoutons une histoire qui utilise les mots que nous avons appris tout au long de la semaine.",
          //   "Polish": "Dzisiejsze wideo to Story Time, w którym czytamy i słuchamy historii, która używa słów, których nauczyliśmy się w ciągu tygodnia.",
          //   "Vietnamese": "Video hôm nay là Story Time, trong đó chúng ta đọc và nghe một câu chuyện, sử dụng các từ mà chúng ta đã học suốt tuần qua.",
          //   "Thai": "วิดีโอของวันนี้คือ Story Time ซึ่งเราจะอ่านและฟังเรื่องราวที่ใช้คำศัพท์ที่เราได้เรียนรู้ตลอดสัปดาห์ที่ผ่านมา",
          //   "Indonesian": "Video hari ini adalah Story Time, di mana kami membaca dan mendengarkan cerita yang menggunakan kata-kata yang telah kami pelajari sepanjang minggu.",
          //   "Hindi": "आज का वीडियो स्टोरी टाइम है, जिसमें हम एक कहानी पढ़ते और सुनते हैं, जिसमें हम पूरे सप्ताह के दौरान सीखे गए शब्दों का उपयोग करते हैं।"
          // },
          // {
          //   "English": "Let's dive in!",
          //   "German": "Lasst uns eintauchen!",
          //   "Spanish": "¡Vamos a sumergirnos!",
          //   "PortugueseBrazil": "Vamos mergulhar!",
          //   "Turkish": "Haydi başlayalım!",
          //   "French": "Plongeons-y !",
          //   "Polish": "Zanurzmy się w to!",
          //   "Vietnamese": "Hãy cùng khám phá!",
          //   "Thai": "มาเริ่มกันเลย!",
          //   "Indonesian": "Mari kita mulai!",
          //   "Hindi": "आइए शुरू करते हैं!"
          // },
          // {
          //   "English": "And, remember that if you like what we are doing click like and subscribe. You can also support us on patreon, so that we can continue making new videos for you every day.",
          //   "German": "Und denkt daran, wenn euch gefällt, was wir tun, klickt auf Like und abonniert uns. Ihr könnt uns auch auf Patreon unterstützen, damit wir weiterhin jeden Tag neue Videos für euch machen können.",
          //   "Spanish": "Y recuerda que si te gusta lo que estamos haciendo, dale me gusta y suscríbete. También puedes apoyarnos en Patreon, para que podamos seguir haciendo nuevos videos para ti todos los días.",
          //   "PortugueseBrazil": "E lembre-se, se você gosta do que estamos fazendo, clique em curtir e se inscreva. Você também pode nos apoiar no Patreon, para que possamos continuar fazendo novos vídeos para você todos os dias.",
          //   "Turkish": "Ve, yaptıklarımızı beğeniyorsanız beğen ve abone ol butonuna tıklamayı unutmayın. Ayrıca Patreon'da bizi destekleyebilirsiniz, böylece sizin için her gün yeni videolar yapmaya devam edebiliriz.",
          //   "French": "Et, souvenez-vous que si vous aimez ce que nous faisons, cliquez sur j'aime et abonnez-vous. Vous pouvez également nous soutenir sur Patreon, afin que nous puissions continuer à créer de nouvelles vidéos pour vous chaque jour.",
          //   "Polish": "I pamiętajcie, że jeśli podoba wam się to, co robimy, kliknijcie like i subskrybujcie. Możecie nas również wspierać na Patreon, abyśmy mogli codziennie tworzyć dla was nowe filmy.",
          //   "Vietnamese": "Và, hãy nhớ rằng nếu bạn thích những gì chúng tôi đang làm, hãy nhấn thích và đăng ký. Bạn cũng có thể ủng hộ chúng tôi trên Patreon, để chúng tôi có thể tiếp tục làm video mới cho bạn mỗi ngày.",
          //   "Thai": "และอย่าลืมว่า ถ้าคุณชอบสิ่งที่เรากำลังทำอยู่ คลิกถูกใจและสมัครสมาชิก คุณยังสามารถสนับสนุนเราบน Patreon เพื่อให้เราสามารถทำวิดีโอใหม่ ๆ ให้คุณทุกวัน",
          //   "Indonesian": "Dan, ingatlah bahwa jika Anda menyukai apa yang kami lakukan, klik suka dan berlangganan. Anda juga bisa mendukung kami di Patreon, sehingga kami bisa terus membuat video baru untuk Anda setiap hari.",
          //   "Hindi": "और, याद रखें कि यदि आपको हमारा काम पसंद आता है तो लाइक करें और सब्सक्राइब करें। आप हमें Patreon पर भी समर्थन दे सकते हैं, ताकि हम हर दिन आपके लिए नए वीडियो बना सकें।"
          // }

        ]
      },
      pdfs: [
        {
          freeset: "false",
          type: "normal-set",
          mixeditems: false,
          orientation: "portrait",
          fullPage: false,
          removeItems: [],
          customColor: '009688',
          customBranding: 'EnglishExpanse',
          customLang: false,
          allLangs: false,

          pages: [[
            {
              name: "sentences-fill-gaps-clues",
              exercises: [{ lang: 'English', text: 'Fill in the gaps with right words' }],
              setId: null,
              itemsPack: null,
              numberOfItems: 1,
              descriptionPl: "",
              displayName: "Utwórz zdania z rozsypanki z podpowiedziami"
            }],


          ],
          selected: false
        },
      ],
      selected: false
    }
  ]

  constructor() { }

}
