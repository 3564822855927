import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import html2canvas from 'html2canvas';
import { QuizHelperService } from 'src/app/profile/student/quiz/quiz-helper.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';


@Component({
  selector: 'app-fact-post-1',
  templateUrl: './fact-post-1.component.html',
  styleUrls: ['./fact-post-1.component.scss']
})
export class FactPost1Component implements OnInit {
  @Input() fact
  @Input() downloadPost
  sentenceWithDashes = ''
  base64SmallPicture = ''
  selectedPicture = 1
  disassembledSentence = []
  constructor(
    private helpers: HelpersService,
    private quizHelpers: QuizHelperService
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.downloadPost && changes.downloadPost.currentValue) {
      this._downloadPost()
    }
  }

  _downloadPost() {
    console.log('Downloading post')
    const element = document.querySelector('#post') as HTMLElement;
    html2canvas(element, {
      scale: 4, // Adjusts the resolution of the image
      width: 1080, // Set the width of the canvas
      height: 1080, // Set the height of the canvas
      scrollX: 0,
      scrollY: 0,
      windowWidth: 1080,
      windowHeight: 1080,
    }).then(canvas => {
      const link = document.createElement('a');
      link.download = (this.fact.word1 + ' ' + this.fact.word2).trim() + '.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  }
  ngOnInit() {
    this.convertImageToBase64(this.fact.smallPicture);

    this.selectedPicture = this.helpers.getRandomNumber(1, 8)
    console.log(this.fact)
    let tempItem = [];
    let correctAnswer = this.fact.disassembledSentence.filter(
      (word) => word.blank,
    );
    correctAnswer.map((wordBlank) => {
      tempItem.push(
        wordBlank.word
          .replace('.', '')
          .replace(',', '')
          .replace('?', '')
          .replace('!', '')
          .replace(':', ''),
      );
    });
    correctAnswer = tempItem.join(' ').toLowerCase();
    this.sentenceWithDashes = this.fact.disassembledSentence
      .map((word) => {
        return word.word;
      })
      .join(' ')
      .replace('  ', ' ')
      .replace(
        new RegExp(correctAnswer, 'i'),
        (match) => match[0] + match.slice(1).replace(/[a-zA-Z]/g, '_'),
      );
    console.log(this.sentenceWithDashes)
    this.disassembledSentence = this.sentenceWithDashes.split(' '); console.log("🚀 ~ FactPost1Component ~ ngOnInit ~ this.disassembledSentence:", this.disassembledSentence)
  }
  convertImageToBase64(url: string) {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      this.base64SmallPicture = canvas.toDataURL();
    };
    img.src = url;
  }
}
