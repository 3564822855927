import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-dictionary-web',
  templateUrl: './dictionary-web.component.html',
  styleUrls: ['./dictionary-web.component.css']
})
export class DictionaryWebComponent implements OnInit {

  constructor(
    public _bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
  }

}
