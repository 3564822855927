import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VideoRepoService } from './video-repo.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-videos-repo',
  templateUrl: './videos-repo.component.html',
  styleUrls: ['./videos-repo.component.scss']
})
export class VideosRepoComponent implements OnInit {
  uploadForm: FormGroup;
  selectedFile: File | null = null;
  uploadProgress: number | null = null;
  uploadError: string | null = null;
  videos: any[] = [];
  allCategories: string[] = [];
  allVideos: any[] = [];
  page = 1;

  constructor(
    private fb: FormBuilder,
    private videoRepoService: VideoRepoService,
    private helpersService: HelpersService
  ) {
    this.uploadForm = this.fb.group({
      folderName: ['cinematic'],
      category: ['cinematic'],
      tags: [''],
      quantity: [1, [Validators.required, Validators.min(1)]]
    });
  }

  ngOnInit() {
    this.loadVideos();
  }

  loadVideos() {
    this.videoRepoService.getAllVideos().subscribe(
      response => {
        if (response.success) {

          this.videos = response.videos
          this.allVideos = response.videos
          // sort the videos by data.uploadDate
          this.videos.sort((a, b) => {
            return new Date(b.data.uploadDate).getTime() - new Date(a.data.uploadDate).getTime();
          });
          this.allVideos.sort((a, b) => {
            return new Date(b.data.uploadDate).getTime() - new Date(a.data.uploadDate).getTime();
          }
          );
          this.allCategories = this.videos.map(video => video.data.category).filter((value, index, self) => self.indexOf(value) === index);
          console.log("🚀 ~ VideosRepoComponent ~ loadVideos ~ this.allCategories:", this.allCategories)
          console.log("🚀 ~ VideosRepoComponent ~ loadVideos ~ this.videos:", this.videos)
        } else {
          console.error('Failed to load videos');
        }
      },
      error => console.error('Error loading videos', error)
    );

  }
  filtefVideosByCategory(category: string) {
    if (category === 'All') {
      this.videos = this.helpersService.clone(this.allVideos);
    }
    else {
      this.videos = this.helpersService.clone(this.allVideos.filter(video => video.data.category === category));
    }
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  uploadVideo() {
    if (this.selectedFile && this.uploadForm.valid) {
      this.uploadProgress = 0;
      this.uploadError = null;
      const formValue = this.uploadForm.value;
      const metadata = {
        folderName: formValue.folderName,
        category: formValue.category,
        tags: formValue.tags.split(',').map((tag: string) => tag.trim()),
        quantity: formValue.quantity
      };

      this.videoRepoService.uploadVideo(this.selectedFile, metadata).subscribe(
        event => {
          if (event.status === 'progress') {
            this.uploadProgress = +event.message.replace('Uploaded ', '').replace('%', '');
          } else if (event.success) {
            this.loadVideos();
            this.resetForm();
          }
        },
        error => {
          console.error('Error uploading video', error);
          this.uploadError = error;
          this.uploadProgress = null;
        },
        () => {
          this.uploadProgress = null;
        }
      );
    }
  }

  uploadRandomVideo() {
    // Implement logic for uploading a random video if needed
    console.log('Uploading random video');
  }

  deleteVideo(id: string) {
    this.videoRepoService.deleteVideo(id).subscribe(
      response => {
        console.log('Video deleted successfully', response);
        this.loadVideos();
      },
      error => console.error('Error deleting video', error)
    );
  }

  resetForm() {
    const formValue = this.uploadForm.value;
    this.uploadForm.reset({
      folderName: 'cinematic',
      category: this.uploadForm.value.category,
      tags: '',
      quantity: 1
    });
    this.selectedFile = null;
    this.uploadProgress = null;
    this.uploadError = null;
  }
}