<div style="width: 1080px; height: 1080px" id="post">
  <div
    [style.background-image]="'url(' + base64SmallPicture + ')'"
    style="
      width: 1080px;
      height: 70%;
      position: relative;
      z-index: 2;
      background-size: cover;
    "
    class="d-flex flex-column align-items-center justify-content-around"
  >
    <div
      class="w-100"
      style="
        height: 1080px;
        background-size: cover;
        position: absolute;
        top: 0;
        z-index: 1;
      "
      [style.background-image]="
        'url(' + '/assets/posts_imgs/' + selectedPicture + '.png' + ')'
      "
    ></div>
  </div>
  <div
    class="w-100 f-flex flex-column"
    style="
      height: 30%;
      background-color: rgba(255, 255, 155, 0);
      position: relative;
      z-index: 5;
    "
  >
    <div class="w-100 h-50 p-4 align-items-center justify-content-center">
      <div
        style="flex-wrap: wrap; font-family: &quot;Quicksand&quot;, sans-serif"
        class="d-flex flex-row w-100 h-100 align-items-center justify-content-center"
      >
        <span
          *ngFor="let part of disassembledSentence"
          style="font-size: 3em"
          [class]="part.includes('_') ? 'p-3 m-2 pb-4' : 'p-1'"
          class="p-1"
          [style.color]="part.includes('_') ? '#4e535c' : 'white'"
          [style.letter-spacing]="part.includes('_') ? '0.5em' : ''"
          [style.background-color]="
            part.includes('_') ? 'white' : 'transparent'
          "
          [style.border-radius]="part.includes('_') ? '20px' : ''"
          >{{ part }}</span
        >
      </div>
    </div>
    <div class="w-100 h-50"></div>
  </div>
</div>
