import { Injectable } from '@angular/core';
import { VersionService } from '../shared/services/version.service';
import { HelpersService } from '../shared/services/helpers.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../shared/services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { VideosTypesService } from './video-generator/videos-types/videos-types.service';
import { GenerateAudioService } from '../shared/services/database/generate-audio.service';
import { ReadTextService } from '../shared/services/read-text.service';
import { catchError } from 'rxjs/operators';
import { ChatService } from '../shared/services/chat.service';
import { tr } from 'date-fns/locale';
import { DictionaryService } from '../shared/services/dictionary.service';
import { SetsService } from '../shared/services/sets.service';
import { SrtManipulatorService } from './srt-manipulator.service';
import { VideoMakerVariablesService } from './video-maker-variables.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { error } from 'console';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleService } from '../shared/services/database/google.service';
import { J } from '@angular/cdk/keycodes';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class VideoMakerService {
  url: string;
  creatingVideo: boolean;
  currentSet;
  downloadedItemsCount = 0;
  downloadingLogs = [];
  setsForVideosUpdated = new Subject<any>();
  nextTypeUpdated = new Subject<any>();
  currentVideoTypeNumber = -1;
  currentItemNumber = 0;
  currentSetNumber = -1;
  setsForVideos = [];
  typesForVideos = [];
  chunks = []
  allVideoTypes = [];
  propertiesToDownload = []
  translationsToDownload = []
  interval: NodeJS.Timeout;
  titleDescriptionRecieved: boolean;

  constructor(
    private dictionaryService: DictionaryService,
    private versionService: VersionService,
    private router: Router,
    private http: HttpClient,
    private authService: AuthenticationService,
    private videoTypeService: VideosTypesService,
    private chatService: ChatService,
    private setsService: SetsService,
    private videoMakerVariables: VideoMakerVariablesService,
    private indexedDbService: NgxIndexedDBService,
    private generateAudioService: GenerateAudioService,
    private snackBar: MatSnackBar,
    private googleService: GoogleService,
    private sanitizer: DomSanitizer

  ) {
    this.url = 'https://backend-int.linget.it/'
    this.typesForVideos = this.videoTypeService.videoTypes;
    this.recieveMessagesFromChat()
    this.propertiesToDownload = this.videoMakerVariables.propertiesToDownload
    this.translationsToDownload = this.videoMakerVariables.translationsToDownload
    this.recieveGeneratedAudio()

  }
  downloadFacsVideo(fact) {
    let url = fact.video
    console.log("🚀 ~ VideoMakerService ~ downloadFacsVideo ~ url:", url

    )
    this.http.get(url, { responseType: 'blob' }).pipe(
      catchError((error) => {
        console.error('Error occurred while downloading:', error);
        return throwError(error);
      })
    ).subscribe((res) => {
      console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res);
      const blob = new Blob([res], { type: 'application/octet-stream' });
      let blobURL = URL.createObjectURL(blob);
      let safeUrl = this.sanitizer.bypassSecurityTrustUrl(blobURL);
      fact['videoDownloaded'] = safeUrl;

    });
  }
  generateSegments(total: number, segmentSize: number): Array<{
    indexOfQuestion: number;
    indexOfAnswer: number;
  }> {
    const numSegments = Math.floor(total / segmentSize);
    const segments: Array<{
      indexOfQuestion: number;
      indexOfAnswer: number;
    }> = [];

    for (let i = 0; i < numSegments; i++) {
      const start = i * segmentSize;
      const end = Math.min(start + segmentSize, total);
      const questionIndex = i === 0 ? 0 : Math.round(start);
      const answerIndex = Math.round(end - 1);

      segments.push({
        indexOfQuestion: questionIndex,
        indexOfAnswer: answerIndex
      });
    }

    return segments;
  }

  recieveGeneratedAudio() {
    let sub2 = this.generateAudioService.savedFreeAudioItemListener().subscribe((res: any) => {

      console.log("🚀 ~ VideoMakerService ~ recieveGeneratedAudio ~ res:", res)
      if (res.prop == 'descriptionAudio' || res.prop == 'wordAudio' || res.prop == 'sentenceAudio' || res.prop == 'wordPolishAudio' || res.prop == 'sentencePolishAudio') {
        // update descriptionAudio in the item
        // set the blob as descriptionAudioDownloaded
        this.dictionaryService.setMeaningsProperty(res.itemId, res.prop, res.url)
        let itemToUpdate = this.currentSet.items.find((item) => item.id === res.itemId)
        // there is an arraybuffer in the response
        let blob = new Blob([res.buffer], { type: 'application/octet-stream' });
        itemToUpdate[res.prop + 'Downloaded'] = blob;
        console.log('utworzono nagranie słowa ', itemToUpdate.word1 + ' ' + itemToUpdate.word2 + 'parametr ' + res.prop,)
        console.log(itemToUpdate)
        this.downloadingLogs.unshift('utworzono nagranie słowa ' + itemToUpdate.word1 + ' ' + itemToUpdate.word2 + 'parametr ' + res.prop)
      }
      if (res.prop == 'dialogueAudio') {
        // update sentenceAudio in the item
        // set the blob as dialogueAudioDownloaded
        this.setsService.updateDialoguesProperty(this.currentSet.id, res.itemId, 'audio', res.url)
        let itemToUpdate = this.currentSet.dialogue.find((item) => item.id === res.itemId)
        const blob = new Blob([res.buffer], { type: 'application/octet-stream' });
        itemToUpdate['audioDownloaded'] = blob;
        console.log('utworzono nagranie dialogu ', itemToUpdate.text)
      }
      if (res.prop == 'storyAudio') {
        // update storyAudio in the item
        // set the blob as storyAudioDownloaded
        this.setsService.updateStoriesProperty(this.currentSet.id, res.itemId, 'audio', res.url)
        let itemToUpdate = this.currentSet.textChunks.find((item) => item.id === res.itemId)
        const blob = new Blob([res.buffer], { type: 'application/octet-stream' });
        itemToUpdate['audioDownloaded'] = blob;
        this.downloadingLogs.unshift('utworzono nagranie historii ' + itemToUpdate.text)
      }
    })
  }
  // resolve addig chunks after each type and set and next set

  createYoutubeShort(
    backgroundVideoUrl: string,
    topTextContent: string,
    bottomTextContent: string,
    topTextAudioUrl: string,
    bottomTextAudioUrl: string,
    outputFileName: string,
    imageUrl, title?
    , reel?

  ): Observable<any> {
    const payload = {
      imageUrl,
      backgroundVideoUrl,
      topTextContent,
      bottomTextContent,
      topTextAudioUrl,
      bottomTextAudioUrl,
      outputFileName,
      title: JSON.stringify(title),
    };
    const addition = reel ? 'create-reel' : 'create-short'
    return this.http.post('https://backend-videos3.linget.it/api/videos/' + addition, payload);
  }
  generateVideo(sets, shorts?, types?) {
    console.log("🚀 ~ VideoMakerService ~ generateVideo ~ sets:", sets)
    if (types) {
      this.typesForVideos = types

      localStorage.setItem('selectedTypes', JSON.stringify(types))
      console.log("🚀 ~ VideoMakerService ~ generateVideo ~ selectedTypes:", types)

    }
    // store: 'downloadedSetsForVideos',
    // storeConfig: { keyPath: 'id', autoIncrement: true },
    // storeSchema: [
    //   { name: 'id', keypath: 'id', options: { unique: false } },
    //   { name: 'setContent', keypath: 'setContent', options: { unique: false } },
    // ],

    //check if there is a set in indexeddb sets[0].id
    this.indexedDbService.getByKey('downloadedSetsForVideos', sets[0].id).subscribe((res) => {
      console.log("🚀 ~ VideoMakerService ~ this.indexedDbService.getByKey ~ res:", res)
      if (res) {
        this.setsForVideos = [res.setContent]
        console.log('found downloaded set in the db')
        console.log("🚀 ~ VideoMakerService ~ this.indexedDbService.getByKey ~ this.setsForVideos:", this.setsForVideos)
        setTimeout(() => {
          this.setsForVideosUpdated.next([res.setContent]);
          // localStorage.setItem('startVideo', 'true');
          // this.nextTypeUpdated.next({ set: this.setsForVideos[0], type: this.typesForVideos[0] });
        }, 1000);
        this.router.navigate(['/make-video/generate']);

      }
      else {
        alert('Nie znaleziono zestawu w bazie danych')


      }

    }
    )


  }
  nextType(download?) {
    // if (download) {
    //   // this.downloadContent()
    // }
    // else {
    // get the srt data
    // if (!download) {
    //   // console.log('stopping recording')
    //   // localStorage.setItem('stopVideo', 'true');
    // }
    let selectedTypes = JSON.parse(localStorage.getItem('selectedTypes') || '[]')
    console.log("🚀 ~ VideoMakerService ~ nextType ~ selectedTypes:", selectedTypes)
    this.typesForVideos = selectedTypes
    // Get the current video type number
    const currentVideoTypeNumber = this.currentVideoTypeNumber;

    // Check if the current video type number is less than the length of typesForVideos array
    if (currentVideoTypeNumber < this.typesForVideos.length - 1) {
      // If true, increment the current video type number
      this.currentVideoTypeNumber++;

      // Emit the next type update with the current set and type
      if (!localStorage.getItem('startVideo')) {

        this.nextTypeUpdated.next({ set: this.setsForVideos[0], type: this.typesForVideos[this.currentVideoTypeNumber] });
        localStorage.setItem('startVideo', 'true');
      }
    }
    // else {
    //   // If false, reset the current video type number to 0
    //   this.currentVideoTypeNumber = 0;

    //   // Check if the current set number is less than or equal to the length of setsForVideos array
    //   this.currentSetNumber++;
    //   this.currentSet = this.setsForVideos[this.currentSetNumber];
    //   if (this.currentSet) {
    //     // If true and the current set number is not 0, increment the current set number


    //     // Reset the current video type number to 0
    //     this.currentVideoTypeNumber = 0;

    //     // Set the current set to the set at the current set number index
    //     this.currentSet = this.setsForVideos[this.currentSetNumber];

    //     // Emit the next type update with the current set and type
    //     this.nextTypeUpdated.next({ set: this.currentSet, type: 'dummy' });
    //     setTimeout(() => {
    //       this.nextTypeUpdated.next({ set: this.currentSet, type: this.typesForVideos[this.currentVideoTypeNumber] });

    //     }, 100);
    //   }
    //   else {
    //     this.currentSetNumber = 0;
    //     this.currentVideoTypeNumber = 0;
    //     // If false, alert that all sets are done
    //     // alert('All sets are done');
    //   }
    // }
    // }
  }
  addOrUpdateChunk(propertyName, value) {
    // Check if the chunk already exists
    const existingChunk = this.chunks.find(
      (chunk) => chunk.name === 'asd',
    );

    if (existingChunk) {
      // If it exists, update the property
      existingChunk[propertyName] = value;
    } else {
      // If it doesn't exist, create a new chunk
      const newChunk = { name: 'asd' };
      newChunk[propertyName] = value;
      this.chunks.push(newChunk);
    }
  }
  downlaodAudioAndImages() {
    this.downloadItemsAudioAndImages()
    // this.downloadStoriesAudiosAndImages()
    // this.downloadDialoguesAudiosAndImages()
  }
  saveSetToDb() {


    // add checking progress


    //  store: 'downloadedSetsForVideos',
    // storeConfig: { keyPath: 'id', autoIncrement: true },
    // storeSchema: [
    //   { name: 'id', keypath: 'id', options: { unique: false } },
    //   { name: 'setContent', keypath: 'setContent', options: { unique: false } },
    // ],


    // add this.currentSet to ngxIndexeddbService 
    let set = {
      id: this.currentSet.id,
      setContent: this.currentSet
    }
    this.indexedDbService.add('downloadedSetsForVideos', set).subscribe((res) => {

      console.log("🚀 ~ VideoMakerService ~ this.indexedDbService.add ~ res:", res)
      setTimeout(() => {
        window.location.reload()
      }, 1000);
    })
    // this.router.navigate(['/make-video/generate']);
    // setTimeout(() => {
    //   this.setsForVideosUpdated.next([this.currentSet]);
    //   this.setsForVideos = [this.currentSet];
    //   console.log("🚀 ~ VideoMakerService ~ setTimeout ~ this.setsForVideos:", this.setsForVideos)
    // }, 1000);

    // handle when I want to download the set again in case something changed
    // this.nextType();




  }
  removeSetFromDb(id) {
    this.indexedDbService.delete('downloadedSetsForVideos', id).subscribe((res) => {
      console.log("🚀 ~ VideoMakerService ~ this.indexedDbService.delete ~ res:", res)

    })


  }
  checkIfSetIsInDb(id) {
    this.indexedDbService.getByKey('downloadedSetsForVideos', id).subscribe((res) => {
      console.log("🚀 ~ VideoMakerService ~ this.indexedDbService.getByKey ~ res:", res)
      if (res) {
        return true
      }
      else {
        return false
      }
    }
    )
  }

  downloadStoriesAudiosAndImages() {
    this.setsForVideos.forEach((set) => {
      set.textChunks.forEach(chunk => {


        let imgUrl = chunk.picture;
        if (imgUrl) {
          if (imgUrl.includes('?')) {
            imgUrl = imgUrl.split('?')[0]
          }
          this.http.get(imgUrl, { responseType: 'blob' }).pipe(
            catchError((error) => {
              console.error('Error occurred while downloading:', error);
              return throwError(error);
            })
          ).subscribe((res) => {
            console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res);
            const blob = new Blob([res], { type: 'application/octet-stream' });
            chunk['imageDownloaded'] = blob;
            console.log('Downloaded image for story: ', chunk.text);
            this.downloadingLogs.unshift('Downloaded story image: ' + chunk.text);
          });
        }






        // let imgUrl = chunk.picture;
        // imgUrl = imgUrl.replace('https://drive.google.com/file/d/', 'https://drive.google.com/uc?id=')
        // imgUrl = imgUrl.replace('/view?usp=sharing', '')
        // console.log("🚀 ~ VideoMakerService ~ this.setsForVideos.forEach ~ imgUrl:", imgUrl)
        // if (imgUrl) {
        //   this.http.get(imgUrl, { responseType: 'blob' }).pipe(
        //     catchError((error) => {
        //       console.error('Error occurred while downloading:', error);
        //       return throwError(error);
        //     })
        //   ).subscribe((res) => {
        //     console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res);
        //     const blob = new Blob([res], { type: 'application/octet-stream' });
        //     chunk['imageDownloaded'] = blob;
        //     console.log('Downloaded image for story: ', chunk.text);
        //     this.downloadingLogs.unshift('Downloaded story image: ' + chunk.text);
        //   });


        // Download the audio
        let url = 'https://backend-dev.linget.it/api/read/getaudiodata?url=' + chunk.audio;
        if (!chunk.audio || chunk.audio.startsWith('data:text')) {
          console.error('Error occurred while downloading: Invalid audio');
          let textToRecord = chunk.text;
          this.generateAudioService.saveNewAudioToRepo(chunk.id, textToRecord, 'english', null, 'en-US-ChristopherNeural', null, 'storyAudio');
        } else {
          this.http.get(url, { responseType: 'blob' }).pipe(
            catchError((error) => {
              console.error('Error occurred while downloading:', error);
              return throwError(error);
            })
          ).subscribe(
            (res) => {
              console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res);
              const blob = new Blob([res], { type: 'audio/mp3' });
              chunk['audioDownloaded'] = blob;
              console.log('Downloaded audio for story: ', chunk.text);
              this.downloadingLogs.unshift('Downloaded story audio: ' + chunk.text);
            },
            (error) => {
              console.error('Error occurred while downloading:', error);
              let textToRecord = chunk.text;
              this.generateAudioService.saveNewAudioToRepo(chunk.id, textToRecord, 'english', null, null, null, 'storyAudio');
            }
          );
        }
      });

    });
  }

  downloadDialoguesAudiosAndImages() {
    this.setsForVideos.forEach((set) => {
      set.dialogue.forEach(chunk => {
        // Download the image
        let imgUrl = chunk.picture;
        if (imgUrl) {
          this.http.get(imgUrl, { responseType: 'blob' }).pipe(
            catchError((error) => {
              console.error('Error occurred while downloading:', error);
              return throwError(error);
            })
          ).subscribe((res) => {
            const blob = new Blob([res], { type: 'application/octet-stream' });
            chunk['imageDownloaded'] = blob;
            console.log('Downloaded image for dialogue: ', chunk.text);
            this.downloadingLogs.unshift('Downloaded dialogue image: ' + chunk.text);
          });
        }

        // Download the audio
        let url = 'https://backend-dev.linget.it/api/read/getaudiodata?url=' + chunk.audio;
        if (!chunk.audio || chunk.audio.startsWith('data:text')) {
          console.error('Error occurred while downloading: Invalid audio');
          let textToRecord = chunk.text;
          this.generateAudioService.saveNewAudioToRepo(chunk.id, textToRecord, 'english', null, null, null, 'dialogueAudio');
        } else {
          this.http.get(url, { responseType: 'blob' }).pipe(
            catchError((error) => {
              console.error('Error occurred while downloading:', error);
              return throwError(error);
            })
          ).subscribe(
            (res) => {
              const blob = new Blob([res], { type: 'application/octet-stream' });
              chunk['audioDownloaded'] = blob;
              console.log('Downloaded audio for dialogue: ', chunk.text);
              this.downloadingLogs.unshift('Downloaded dialogue audio: ' + chunk.text);
            },
            (error) => {
              console.error('Error occurred while downloading:', error);
              let textToRecord = chunk.text;
              this.generateAudioService.saveNewAudioToRepo(chunk.id, textToRecord, 'english', null, null, null, 'dialogueAudio');
            }
          );
        }
      });
    });
  }





  downloadItemsAudioAndImages() {
    this.setsForVideos.forEach((set) => {
      set.items.forEach((item: any, index) => {
        this.propertiesToDownload.forEach((property: any) => {
          console.log("🚀 ~ VideoMakerService ~ propertiesToDownload.forEach ~ property:", property)
          if (item) {
            let url
            let errorDownloading = false
            let downloadUrl
            if (property.includes('Audio') || property.includes('audio')) {
              downloadUrl = this.url + 'api/read/getaudiodata?url=' + item[property];
              console.log("🚀 ~ VideoMakerService ~ propertiesToDownload.forEach ~ downloadUrl:", downloadUrl)
            }
            else {
              url = item[property];
              downloadUrl = url



            }
            let propertiesTexts = [
              { property: 'wordAudio', text: "word1" },
              { property: 'sentenceAudio', text: "sentence" },
              { property: 'descriptionAudio', text: "description" },
              { property: 'blankAudio', text: '' },

            ]
            // create blob and save in item[property + 'downloaded']
            this.http.get(downloadUrl, { responseType: 'blob' }).pipe(
              catchError((error) => {
                errorDownloading = true
                console.log("🚀 ~ VideoMakerService ~ catchError ~ errorDownloading:", errorDownloading)
                let textToRecord = item[propertiesTexts.find((t) => t.property === property).text]
                console.log("🚀 ~ VideoMakerService ~ catchError ~ textToRecord:", textToRecord)
                let time = setTimeout(() => {

                  this.generateAudioService.saveNewAudioToRepo(item.id, textToRecord, 'english', null, null, null, property)
                }, 1000 * index);

                // console.log("🚀 ~ VideoMakerService ~ this.http.get ~ textTtRecord:", textToRecord)
                // let url =
                //   this.url +
                //   'api/read/readfromazureenglish?textToRead=' +
                //   textToRecord +
                //   '&token=' +
                //   this.authService.getToken();

                // console.log("🚀 ~ VideoMakerService ~ this.http.get ~ url:", url)
                // this.http.get(url, { responseType: 'blob' }).pipe(
                //   catchError((error) => {
                //     console.error('Error occurred while downloading:', error);
                //     return throwError(error);
                //   })
                // ).subscribe((res) => {
                //   console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res)
                //   const blob = new Blob([res], { type: 'application/octet-stream' });
                //   item[property + 'Downloaded'] = blob;
                //   console.log('pobrano nagranie słowa ', item.word1 + ' ' + item.word2 + 'parametr ' + property,)
                //   this.downloadingLogs.unshift('pobrano nagranie słowa ' + item.word1 + ' ' + item.word2 + 'parametr ' + property)
                // });







                return throwError(error);
              })
            ).subscribe((res) => {
              console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res)
              const blob = new Blob([res], { type: 'application/octet-stream' });
              const url = blob
              item[property + 'Downloaded'] = url;
              if (property.includes('Audio')) {
                console.log('pobrano nagranie słowa ', item.word1 + ' ' + item.word2 + 'parametr ' + property,)
                this.downloadingLogs.unshift('pobrano nagranie słowa ' + item.word1 + ' ' + item.word2 + 'parametr ' + property)
              }
              else {
                console.log('pobrano obrazek słowa ', item.word1 + ' ' + item.word2 + 'parametr ' + property,)
                this.downloadingLogs.unshift('pobrano obrazek słowa ' + item.word1 + ' ' + item.word2 + 'parametr ' + property)
              }

              // Check if the downloaded file is valid (size is more than 5kb)
              if (blob.size > 9000 && !errorDownloading) {
                console.log("The downloaded file is valid.");

              } else {
                console.log("The downloaded file is not valid. It needs to be recorded again.");
                //create a file from reading from azure

                let textToRecord = propertiesTexts.find((t) => t.property === property).text
                console.log("🚀 ~ VideoMakerService ~ this.http.get ~ textTtRecord:", textToRecord)
                let url =
                  this.url +
                  'api/read/readfromazureenglish?textToRead=' +
                  textToRecord +
                  '&token=' +
                  this.authService.getToken();

                console.log("🚀 ~ VideoMakerService ~ this.http.get ~ url:", url)
                this.http.get(url, { responseType: 'blob' }).pipe(
                  catchError((error) => {
                    console.error('Error occurred while downloading:', error);
                    return throwError(error);
                  })
                ).subscribe((res) => {
                  console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res)
                  const blob = new Blob([res], { type: 'application/octet-stream' });
                  item[property + 'Downloaded'] = blob;
                  console.log('pobrano nagranie słowa ', item.word1 + ' ' + item.word2 + 'parametr ' + property,)
                  this.downloadingLogs.unshift('pobrano nagranie słowa ' + item.word1 + ' ' + item.word2 + 'parametr ' + property)

                });
              }


            }

            );
          }
        });


      });

    })
  }
  downloadPolishAudio() {
    console.log('Downloading polish audio...')
    this.setsForVideos.forEach((set) => {
      set.items.forEach((item: any, index) => {
        this.propertiesToDownload.forEach((property: any) => {
          console.log("🚀 ~ VideoMakerService ~ propertiesToDownload.forEach ~ property:", property)
          if (item) {
            let url
            let errorDownloading = false
            let downloadUrl
            if (property.includes('Audio') || property.includes('audio')) {
              downloadUrl = this.url + 'api/read/getaudiodata?url=' + item[property];
              console.log("🚀 ~ VideoMakerService ~ propertiesToDownload.forEach ~ downloadUrl:", downloadUrl)
            }
            else {
              url = item[property];
              downloadUrl = url



            }
            let propertiesTexts = [
              { property: 'wordPolishAudio', text: "wordPolish" },
              { property: 'sentencePolishAudio', text: "sentencePolish" },


            ]
            // create blob and save in item[property + 'downloaded']
            this.http.get(downloadUrl, { responseType: 'blob' }).pipe(
              catchError((error) => {
                errorDownloading = true
                console.log("🚀 ~ VideoMakerService ~ catchError ~ errorDownloading:", errorDownloading)
                let textToRecord = item[propertiesTexts.find((t) => t.property === property).text]
                console.log("🚀 ~ VideoMakerService ~ catchError ~ textToRecord:", textToRecord)
                let time = setTimeout(() => {

                  this.generateAudioService.saveNewAudioToRepo(item.id, textToRecord, 'polish', null, null, null, property)
                }, 1000 * index);







                return throwError(error);
              })
            ).subscribe((res) => {
              console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res)
              const blob = new Blob([res], { type: 'application/octet-stream' });
              const url = blob
              item[property + 'Downloaded'] = url;
              if (property.includes('Audio')) {
                console.log('pobrano nagranie słowa ', item.word1 + ' ' + item.word2 + 'parametr ' + property,)
                this.downloadingLogs.unshift('pobrano nagranie słowa ' + item.word1 + ' ' + item.word2 + 'parametr ' + property)
              }
              else {
                console.log('pobrano obrazek słowa ', item.word1 + ' ' + item.word2 + 'parametr ' + property,)
                this.downloadingLogs.unshift('pobrano obrazek słowa ' + item.word1 + ' ' + item.word2 + 'parametr ' + property)
              }

              // Check if the downloaded file is valid (size is more than 5kb)
              if (blob.size > 9000 && !errorDownloading) {
                console.log("The downloaded file is valid.");

              } else {
                console.log("The downloaded file is not valid. It needs to be recorded again.");
                //create a file from reading from azure

                let textToRecord = propertiesTexts.find((t) => t.property === property).text
                console.log("🚀 ~ VideoMakerService ~ this.http.get ~ textTtRecord:", textToRecord)
                let url =
                  this.url +
                  'api/read/readfromazureenglish?textToRead=' +
                  textToRecord +
                  '&token=' +
                  this.authService.getToken();

                console.log("🚀 ~ VideoMakerService ~ this.http.get ~ url:", url)
                this.http.get(url, { responseType: 'blob' }).pipe(
                  catchError((error) => {
                    console.error('Error occurred while downloading:', error);
                    return throwError(error);
                  })
                ).subscribe((res) => {
                  console.log("🚀 ~ VideoMakerService ~ this.http.get ~ res:", res)
                  const blob = new Blob([res], { type: 'application/octet-stream' });
                  item[property + 'Downloaded'] = blob;
                  console.log('pobrano nagranie słowa ', item.word1 + ' ' + item.word2 + 'parametr ' + property,)
                  this.downloadingLogs.unshift('pobrano nagranie słowa ' + item.word1 + ' ' + item.word2 + 'parametr ' + property)

                });
              }


            }

            );
          }
        });


      });

    })
  }
  recieveMessagesFromChat() {
    let sub = this.chatService.promptChatMessagesListener().subscribe((res: any) => {
      console.log("🚀 ~ VideoMakerService ~ downloadContent ~ res:", res)
      let chatResponse;
      try {
        if (res.res.choices[0].message.content.includes('```json\n')) {
          chatResponse = JSON.parse(
            res.res.choices[0].message.content
              .replace(/```json\n/g, '')
              .replace(/```/g, '')
          );
        } else {
          chatResponse = JSON.parse(res.res.choices[0].message.content);
        }
      } catch (error) {
        console.error("JSON Parsing Error:", error);
      }

      if (!chatResponse[0]) {
        chatResponse = [chatResponse]
        console.log("🚀 ~ VideoMakerService ~ sub ~ chatResponse:", chatResponse)
        if (!chatResponse[0].id) {
          throwError('no id in chat response')
        }
      }

      // if (chatResponse[0].type === 'titleDescription') {

      //   localStorage.setItem('titleDescription', JSON.stringify(chatResponse))
      //   this.titleDescriptionRecieved = true

      // }
      console.log("🚀 ~ VideoMakerService ~ sub ~ chatResponse:", chatResponse)
      if (chatResponse[0].type === 'item') {
        let setsWithItem = this.setsForVideos.filter((set) => set.items.find((item) => item.id === chatResponse[0].id))
        console.log("🚀 ~ VideoMakerService ~ sub ~ setsWithItem:", setsWithItem)
        setsWithItem.forEach((set) => {
          set.items.forEach((item) => {
            if (item.id === chatResponse[0].id) {
              console.log('pobrano tłumaczenie słowa ', item.word1 + ' ' + item.word2)
              this.downloadingLogs.unshift('pobrano tłumaczenie słowa ' + item.word1 + ' ' + item.word2)
              chatResponse.forEach((response) => {
                item[response.property] = response.text
                this.dictionaryService.setMeaningsProperty(item.id, response.property, response.text)
              })
            }
          })
        }
        )

      }
      if (chatResponse[0].type === 'story') {
        let setsWithItem = this.setsForVideos.filter((set) => set.textChunks.find((item) => item.id === chatResponse[0].id))
        console.log("🚀 ~ VideoMakerService ~ sub ~ setsWithItem:", setsWithItem)
        setsWithItem.forEach((set) => {
          set.textChunks.forEach((item) => {
            const translatedChunk = chatResponse.filter((response) => response.id === item.id)

            console.log('pobrano tłumaczenie historii ', item.text)
            this.downloadingLogs.unshift('pobrano tłumaczenie historii ' + item.text)
            translatedChunk.forEach((response) => {
              if (response.text.length > 0) {
                item[response.property] = response.text

                this.setsService.updateStoriesProperty(set.id, item.id, response.property, response.text)
              }
            })
          }


          )
        }

        )

      }
      if (chatResponse[0].type == 'dialogue') {
        let setsWithItem = this.setsForVideos.filter((set) => set.dialogue.find((item) => item.id === chatResponse[0].id))
        console.log("🚀 ~ VideoMakerService ~ sub ~ setsWithItem:", setsWithItem)
        setsWithItem.forEach((set) => {
          set.dialogue.forEach((item) => {
            const translatedDialogueChunk = chatResponse.filter((response) => response.id === item.id)

            console.log('pobrano tłumaczenie dilaogu ', item.text)
            this.downloadingLogs.unshift('pobrano tłumaczenie dialogu ' + item.text)
            translatedDialogueChunk.forEach((response) => {
              if (response.text.length > 0) {
                item[response.property] = response.text

                this.setsService.updateDialoguesProperty(set.id, item.id, response.property, response.text)
              }
            })

          })
        })
      }
    }
    )
  }


  downloadItemsTranslations() {
    this.setsForVideos.forEach((set) => {
      set.items.forEach((item, index) => {
        if (item) {
          const textsToTranslate = [
            { text: (item['word1'] + ' ' + item['word2']).trim(), property: 'word', lang: null },
            { text: item['sentence'], property: 'sentence', lang: null },
            { text: item['description'], property: 'description', lang: null },
          ];


          let translationsData = []
          textsToTranslate.forEach((textToTranslate) => {
            translationsData.push({ id: item.id, text: textToTranslate.text, property: 'textToTranslate', type: 'item' });
          });

          this.translationsToDownload.forEach((lang) => {
            textsToTranslate.forEach((textToTranslate) => {
              // if (lang == 'Polish') {

              if (!item['word' + lang] || !item['sentence' + lang] || !item['description' + lang]) {

                translationsData.push({ id: item.id, text: '', property: textToTranslate.property + lang, type: 'item' });
              }
              // }
            });

          });

          // now lets download the translations
          const prompt = "Fill in the array by translating each text to respective language and remove entries in english. Write the text as a native speaker of the language would say it and it should make sense in the target language. You must output a valid JSON array and each entry must have an id. Do not include oryginal text with textToTranslate property. Do not repeat entries. " + JSON.stringify(translationsData)

          console.log("🚀 ~ ).subscribe ~ translationsData:", translationsData)
          console.log('item', item)
          if (translationsData.length > 1) {
            setTimeout(() => {
              this.chatService.sendPrompt(prompt);
            }, 1000 * index);
          }


        }
      })

    })
  }
  downloadStoryTranslations() {
    this.setsForVideos.forEach((set) => {
      set.textChunks.forEach((chunk, index) => {
        let translationsData = [
          { id: chunk.id, text: chunk.text, property: 'storyToTranslate', type: 'story' }
        ];

        this.translationsToDownload.forEach((lang) => {
          if (!chunk['story' + lang]) {

            translationsData.push({ id: chunk.id, text: '', property: 'story' + lang, type: 'story' });
          }
        });

        // Prepare the prompt for each chunk
        const prompt = "Translate each text to respective language. Keep the same array of objects format. Do not output anything except for the array. " + JSON.stringify(translationsData);

        // Send the prompt for each chunk
        if (translationsData.length > 1) {
          setTimeout(() => {
            this.chatService.sendPrompt(prompt)
          }, 1000 * index);
        }

      });
    });
  }

  downloadDialogueTranslations() {
    console.log("🚀 ~ VideoMakerService ~ this.setsForVideos.forEach ~ this.setsForVideos:", this.setsForVideos)
    this.setsForVideos.forEach((set) => {
      set.dialogue.forEach((chunk, index) => {
        let translationsData = [
          { id: chunk.id, text: chunk.text, property: 'dialogueToTranslate', type: 'dialogue' }
        ];

        this.translationsToDownload.forEach((lang) => {
          if (!chunk['dialogue' + lang]) {

            translationsData.push({ id: chunk.id, text: '', property: 'dialogue' + lang, type: 'dialogue' });
          }
        });

        // Prepare the prompt for each chunk
        const prompt = "Translate each text to respective language. Keep the same array of objects format. Do not output anything except for the array. " + JSON.stringify(translationsData);

        // Send the prompt for each chunk
        if (translationsData.length > 1) {
          setTimeout(() => {
            this.chatService.sendPrompt(prompt)
          }, 1000 * index);
        }

      });
    });
  }


  checkDownloadingCompletion() {
    let storyTranslationsDownloaded = false;
    let dialogueTranslationsDownloaded = false;
    let itemsTranslationsDownloaded = false;
    let storyAudiosDownloaded = false;
    let dialogueAudiosDownloaded = false;
    let itemsAudiosDownloaded = false;
    let storyImagesDownloaded = false;
    let dialogueImagesDownloaded = false;
    let itemsImagesDownloaded = false;

    let set = this.currentSet;
    if (set) {
      if (set?.dialogue?.length > 0) {
        dialogueAudiosDownloaded = !set.dialogue.some((dialogueItem) => !dialogueItem.audioDownloaded);
        dialogueImagesDownloaded = !set.dialogue.some((dialogueItem) => !dialogueItem.imageDownloaded && dialogueItem.picture?.length > 0);

        dialogueTranslationsDownloaded = this.translationsToDownload.every((lang) => {
          return !set.dialogue.some((dialogueItem) => !dialogueItem['dialogue' + lang]);
        });
      }

      if (set?.textChunks?.length > 0) {
        storyAudiosDownloaded = !set.textChunks.some((chunk) => !chunk.audioDownloaded);
        storyImagesDownloaded = !set.textChunks.some((chunk) => !chunk.imageDownloaded && chunk.picture?.length > 0);

        storyTranslationsDownloaded = this.translationsToDownload.every((lang) => {
          return !set.textChunks.some((chunk) => !chunk['story' + lang]);
        });
      }

      itemsAudiosDownloaded = set.items.every((item: any) => {
        if (item && item.id) {

          const audiosDownloaded = this.propertiesToDownload.every((property: any) => {
            if (!(item[property + 'Downloaded']?.size > 0)) {
              console.log('missing property for item', property + ' in item ', item.word1 + ' ' + item.word2);
              return false;
            }
            return true;
          });

          return audiosDownloaded;
        }
        return false;
      });

      itemsTranslationsDownloaded = set.items.every((item: any) => {
        if (item && item.id) {
          return this.translationsToDownload.every((lang) => {
            if (!item['word' + lang] || !item['sentence' + lang] || !item['description' + lang]) {
              console.log('missing translation for ', lang + ' in item ', item.word1 + ' ' + item.word2);
              return false;
            }
            return item['word' + lang] && item['sentence' + lang] && item['description' + lang];

          });
        }
        return false;
      });
    }

    const allDownloaded = storyAudiosDownloaded && dialogueAudiosDownloaded && itemsAudiosDownloaded &&
      storyImagesDownloaded && dialogueImagesDownloaded && itemsImagesDownloaded &&
      storyTranslationsDownloaded && dialogueTranslationsDownloaded && itemsTranslationsDownloaded;

    return {
      finished: allDownloaded,
      logs: this.downloadingLogs,
      data: [
        { text: 'Tłumaczenia historii', value: storyTranslationsDownloaded },
        { text: 'Tłumaczenia słów', value: itemsTranslationsDownloaded },
        { text: 'Tłumaczenia dialogów', value: dialogueTranslationsDownloaded },
        { text: 'Audio historii', value: storyAudiosDownloaded },
        { text: 'Audio dialogów', value: dialogueAudiosDownloaded },
        { text: 'Audio słów', value: itemsAudiosDownloaded },
        { text: 'Obrazy historii', value: storyImagesDownloaded },
        { text: 'Obrazy dialogów', value: dialogueImagesDownloaded },
        { text: 'Obrazy słów', value: itemsImagesDownloaded }
      ]
    };
  }


  processVideo(videoFile, subtitles) {
    console.log('procrssing video');
    const chunks = JSON.parse(localStorage.getItem('chunks') || '[]');
    console.log('chunks', chunks);
    localStorage.removeItem('chunks');
    const formData = new FormData();
    this.addOrUpdateChunk('start', 0);
    this.addOrUpdateChunk('end', 5);
    formData.append('video', videoFile);
    //play the video file 



    // formData.append('video', 'asd');
    const introTime = localStorage.getItem('introTime');
    formData.append('introTime', introTime);
    formData.append('chunks', JSON.stringify(this.chunks));
    formData.append('setName', localStorage.getItem('setName').toUpperCase());
    formData.append('typeOfVideo', localStorage.getItem('typeofVideo'));
    formData.append('subtitles', localStorage.getItem('subtitles'));
    const pdfs = localStorage.getItem('pdfsToCreate')
    console.log("🚀 ~ VideoMakerService ~ processVideo ~ pdfs:", pdfs)
    formData.append('pdfsToCreate', pdfs);

    formData.append('size', JSON.stringify({ width: 1920, height: 1080 }));
    console.log("🚀 ~ VideoMakerService ~ processVideo ~ formData:", formData)
    console.log("🚀 ~ VideoMakerService ~ processVideo ~ videoFile:", videoFile)
    // let interval = setInterval(() => {
    // const titleDescriptionRecieved = JSON.parse(localStorage.getItem('titleDescription'))
    // console.log("🚀 ~ VideoMakerService ~ interval ~ titleDescriptionRecieved:", titleDescriptionRecieved)
    // if (titleDescriptionRecieved) {
    formData.append('titleDescription', 'null');
    setTimeout(() => {
      console.log('sending video to backend')
      this.http
        .post('https://backend-videos3.linget.it/api/videos/process-video', formData, {
          headers: { Authorization: `Bearer ${this.authService.getToken()}` },
        })
        .subscribe((res: any) => {
          if (res.allOk) {
            localStorage.removeItem('titleDescription');
            // window.location.href = 'https://int2.linget.it/make-video/create';
            // window.location.reload();
            // window.close()
          }


        });
    }, 3000);
    // clearInterval(interval)
    // return
    // }


    // }, 1000)
    //close the current tab
    setTimeout(() => {
      // window.close()
    }, 3000);
  }
  generateVideoListener() {
    return this.setsForVideosUpdated.asObservable();
  }
  nextTypeListener() {
    return this.nextTypeUpdated.asObservable();
  }
}
