import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackbar: MatSnackBar) {

  }

  showSnackBar(message: string, type: 'info' | 'success' | 'error', action?: string, duration?: number) {
    this.snackbar.open(message, action, {
      duration: duration ? duration : 2000,
      panelClass: type == 'info' ? 'mat-snack-bar-container-purple-500' : type == 'success' ? 'mat-snack-bar-container-green-500' : 'mat-snack-bar-container-red-500'
    });
  }


}
