import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as io from 'socket.io-client';
import { VersionService } from './version.service';
@Injectable({
  providedIn: 'root',
})
export class RealTimeCommunicationService {
  socket = null;
  messagesForWorkersUpdated = new Subject();
  rtcMessagesUpdated = new Subject();

  url: string;
  constructor(private versionService: VersionService) {
    this.url = this.versionService.url;

    if (!this.socket) {
      this.socket = io(this.url, {
        rejectUnauthorized: false,
      });

      this.socket.on('connect', () => {
        console.log('Connected!');
      });

      this.socket.on('message', (data) => {
        console.log(data);
        if (data.updateTasks) {
          this.messagesForWorkersUpdated.next('updateTasks');
        }
        if (data === 'refreshWordsList') {
          this.rtcMessagesUpdated.next('refreshWordsList');
        } else {
          this.rtcMessagesUpdated.next(data);
          this.messagesForWorkersUpdated.next(data);
        }
      });
      this.socket.on('connect_error', (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
    }
  }
  sendFile(dataUri) {
    this.socket.emit('send-file', dataUri);
  }
  messagesForWorkersListener() {
    return this.messagesForWorkersUpdated.asObservable();
  }
  rtcMessagesUpdatedListener() {
    return this.rtcMessagesUpdated.asObservable();
  }
}
