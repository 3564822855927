import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { PoorInternetIndicatorComponent } from '../components/poor-internet-indicator/poor-internet-indicator.component';

@Injectable({
  providedIn: 'root'
})
export class PoorInternetIndicatorService {

  constructor(private _snackBar: MatSnackBar)
  {

   }
   showIndicator(){
    this._snackBar.openFromComponent(PoorInternetIndicatorComponent, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: 'poor-connection-indicator'
    });
   }
}
