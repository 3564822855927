<div
  fxLayout="row wrap"
  style="
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  "
  [style.background-image]="chosenMap"
>
  <div
    [style.width]="true ? tileSize : null"
    fxLayoutAlign="center center"
    *ngFor="let tile of grid; let i = index"
  >
    <div
      (click)="openTile(tile)"
      *ngIf="!tile.opened"
      style="
        position: absolute;
        z-index: 10;
        background-color: rgba(244, 99, 71, 0);
        font-size: 1.1em;
        font-weight: bold;
      "
      [style.width]="true ? tileSize : null"
      [style.height]="true ? tileSize : null"
      fxLayoutAlign="center center"
    >
      <span
        style="
          width: 3vw;
          height: 3vw;
          background-color: rgba(244, 244, 244, 0.7);
          border-radius: 40%;
          color: rgba(0, 0, 0, 0.8);
        "
        fxLayoutAlign="center center"
        >{{ i }}
      </span>
    </div>
    <span
      [style.background-color]="
        tile.opened && tile.value !== 0 ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.0)'
      "
      style="
        width: 3vw;
        height: 3vw;
        border-radius: 40%;
        color: rgba(0, 0, 0, 0.99);
      "
      fxLayoutAlign="center center"
    >
      <img
        *ngIf="tile.value == 0 || !tile.opened"
        style="width: 4vw; height: 4vh; opacity: 0"
        src="/assets/game-elements/pirates/treasure/1.svg"
        alt
      />
      <img
        *ngIf="tile.value == 1 && tile.opened && !tile.showBigPicture"
        style="width: 4vw; height: 4vh"
        src="/assets/game-elements/pirates/treasure/1.svg"
        alt
      />
      <img
        *ngIf="tile.value == 2 && tile.opened && !tile.showBigPicture"
        style="width: 4vw; height: 4vh"
        src="/assets/game-elements/pirates/treasure/2.svg"
        alt
      />
      <img
        *ngIf="tile.value == 3 && tile.opened && !tile.showBigPicture"
        style="width: 4vw; height: 4vh"
        src="/assets/game-elements/pirates/treasure/3.svg"
        alt
      />
      <img
        *ngIf="tile.value == -1 && tile.opened && !tile.showBigPicture"
        style="width: 5vw; height: 5vh"
        src="/assets/game-elements/pirates/pirate_bad.svg"
        alt
      />
    </span>
    <div
      *ngIf="tile.opened && tile.showBigPicture"
      class="animate__animated animate__zoomIn"
      style="position: absolute; z-index: 14"
    >
      <span
        class="p-2"
        *ngIf="tile.value !== 0"
        style="
          border-radius: 10px;
          position: absolute;
          z-index: 15;
          top: 50%;
          left: 30%;
          font-size: 1.5em;
          font-weight: bold;
          color: white;
          background-color: rgba(0, 0, 0, 0.4);
        "
        >{{ this._points }}
      </span>
      <img
        class="animate__animated animate__zoomIn"
        *ngIf="tile.value == 1 && tile.showBigPicture"
        style="width: 20vw; height: 20vh"
        src="/assets/game-elements/pirates/treasure/1.svg"
        alt
      />
      <img
        class="animate__animated animate__zoomIn"
        *ngIf="tile.value == 2 && tile.showBigPicture"
        style="width: 20vw; height: 20vh"
        src="/assets/game-elements/pirates/treasure/2.svg"
        alt
      />
      <img
        class="animate__animated animate__zoomIn"
        *ngIf="tile.value == 3 && tile.showBigPicture"
        style="width: 20vw; height: 20vh"
        src="/assets/game-elements/pirates/treasure/3.svg"
        alt
      />
      <img
        class="animate__animated animate__zoomIn"
        *ngIf="tile.value == -1 && tile.showBigPicture"
        style="width: 20vw; height: 20vh"
        src="/assets/game-elements/pirates/pirate_bad.svg"
        alt
      />
    </div>
    <!-- <span (click)="tile.opened = !tile.opened">{{tile.value}}</span> -->
  </div>
</div>
