<div
  fxLayout="column"
  fxLayoutAlign="start center"
  style="width: 75vw; height: 80vh;"
>
  <div
    fxFlex
    style="height: 50%; width: 100%;"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <div
      *ngFor="let item of chosenItems let i = index"
      fxLayoutAlign="center center"
    >
      <div
        (click)="chooseItem(item)"
        fxLayoutAlign="center center"
        *ngIf="i <5"
        fxLayout="column"
        style="width: 13vw;"
        class="mr-3"
      >
        <img
          class="animate__animated animate__pulse animate__infinite infinite"
          [src]="'../../../../assets/emojis/' + item.emoji.toLowerCase() + '.svg'"
          alt=""
        />
        <h2
          class="mt-4"
          [style.color]="i == 0 ? '#fff176' : i==1? '#ffd54f' : i==2? '#f06292' : i==3? '#4dd0e1' : i==4? '#4db6ac' :null "
          style="
            font-size: 2.2em;
            letter-spacing: 1px;
            text-align: center;
            font-family: 'Anton', sans-serif;
          "
        >
          {{item.name}}
        </h2>
      </div>
    </div>
  </div>
  <div
    fxFlex
    style="height: 50%; width: 100%;"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <div
      *ngFor="let item of chosenItems let i = index"
      fxLayoutAlign="center center"
    >
      <div
        (click)="chooseItem(item)"
        fxLayoutAlign="center center"
        *ngIf="i >=5"
        fxLayout="column"
        style="width: 13vw;"
        class="mr-3"
      >
        <img
          class="animate__animated animate__pulse animate__infinite infinite"
          [src]="'../../../../assets/emojis/' + item.emoji.toLowerCase() + '.svg'"
          alt=""
        />
        <h2
          class="mt-4"
          [style.color]="i == 5 ? '#64b5f6' : i==6? '#aed581' : i==7? '#e57373' : i==8? '#e57373' : i==9? '#ba68c8' :null "
          style="
            font-size: 2.2em;
            letter-spacing: 1px;
            text-align: center;
            font-family: 'Anton', sans-serif;
          "
        >
          {{item.name}}
        </h2>
      </div>
    </div>
  </div>
</div>
