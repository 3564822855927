import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VersionService } from 'src/app/shared/services/version.service';
import { map } from 'rxjs/operators';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class FactsService {
  private apiUrl = 'http://localhost:3000/api/facts'; // Adjust this URL to match your backend

  constructor(private http: HttpClient, private versionService: VersionService, private helpers: HelpersService) {
    this.apiUrl = this.versionService.url + '/api/facts';
  }

  getAllFacts(): Observable<any[]> {

    return this.http.get<any[]>(this.apiUrl).pipe(
      map(response => response.map(item => ({ ...item, ...item.data })))
    );
  }

  getFactById(id: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  createFact(fact): Observable<any> {
    return this.http.post<any>(this.apiUrl, { fact });
  }
  getFactsByType(type: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/type`).pipe(
      map(response => response.map(item => ({ ...item, ...item.data })))
    );
  }

  updateFact(id: string, fact): Observable<any> {
    let factToSend = this.helpers.clone(fact)
    factToSend.selected = false
    return this.http.put<any>(`${this.apiUrl}/${id}`, fact);
  }

  deleteFact(id: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/${id}`);
  }
}