import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DigitalOceanService } from 'src/app/shared/services/digitalocean.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-digitalocean',
  templateUrl: './digitalocean.component.html',
  styleUrls: ['./digitalocean.component.scss']
})
export class DigitaloceanComponent implements OnInit {
  currentPath: string = '';
  files: any[] = [];
  folders: any[] = [];
  downloadLink: string | null = null;

  constructor(private digitalOceanService: DigitalOceanService, private helpers: HelpersService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.listFilesAndFolders(); // List files and folders on component initialization
  }

  // Utility function to add a folder to the current path
  addToPath(folderName: string): string {
    // Remove the current path prefix from the folder name if it exists
    let sanitizedFolderName = folderName;
    if (sanitizedFolderName.startsWith(this.currentPath)) {
      sanitizedFolderName = sanitizedFolderName.substring(this.currentPath.length);
    }

    if (!sanitizedFolderName.endsWith('/')) {
      sanitizedFolderName += '/';
    }

    return this.currentPath ? `${this.currentPath}${sanitizedFolderName}` : sanitizedFolderName;
  }

  // Utility function to remove the last folder from the current path
  removeFromPath(): string {
    const pathParts = this.currentPath.split('/');
    pathParts.pop(); // Remove the empty part after the last '/'
    pathParts.pop(); // Remove the last folder
    let newPath = pathParts.join('/');
    if (newPath.length > 0) {
      newPath += '/'; // Ensure there's a trailing slash if not at the root
    }
    return newPath;
  }

  // Utility function to get the folder name without the path
  getFolderName(folderPath: string): string {
    const pathParts = folderPath.split('/');
    return pathParts[pathParts.length - 2] || ''; // Get the second last part which is the folder name
  }

  // Utility function to get the file name without the path
  getFileName(filePath: string): string {
    const pathParts = filePath.split('/');
    return pathParts[pathParts.length - 1]; // Get the last part which is the file name
  }

  // Method to upload a file
  uploadFiles(event: any) {
    const files = event.target.files;
    let folderName = this.currentPath || '';
    const share = true;
    const bucketName = 'englishexpanse';

    // Ensure folderName ends with a slash if it's not empty
    if (folderName && !folderName.endsWith('/')) {
      folderName += '/';
    }

    for (let file of files) {
      this.digitalOceanService.uploadFileToFolder(file, folderName, share, bucketName).subscribe(response => {
        console.log(response);
        if (response.success) {
          this.snackBar.open('File uploaded successfully', null, { duration: 3000 });
        } else {
          alert('Failed to upload file');
        }
      });
    }
    setTimeout(() => {
      this.listFilesAndFolders(); // Refresh the list after all uploads
    }, 1000);
  }
  createFolder() {
    const newFolderName = prompt('Enter folder name');
    const bucketName = 'englishexpanse';
    // Ensure currentPath ends with a slash if it's not empty
    let folderPath = this.currentPath || '';
    if (folderPath && !folderPath.endsWith('/')) {
      folderPath += '/';
    }

    // Append new folder name to the current path
    folderPath += newFolderName.replace(/ /g, '%20');

    this.digitalOceanService.createFolder(folderPath, bucketName).subscribe(response => {
      console.log(response);
      if (response.success) {
        this.snackBar.open('Folder created successfully', null, { duration: 3000 });
      } else {
        alert('Failed to create folder');
      }
    });
    setTimeout(() => {
      this.listFilesAndFolders(); // Refresh the list after creating the folder
    }, 1000);
  }

  // Method to list files and folders
  listFilesAndFolders() {
    const folderName = this.currentPath || '';
    const bucketName = 'englishexpanse';
    const share = true;

    this.digitalOceanService.listFilesInFolder(folderName, bucketName, share).subscribe(response => {
      console.log('List files response:', response);
      if (response.success) {
        this.files = response.files.sort((a, b) => new Date(b.LastModified).getTime() - new Date(a.LastModified).getTime()); // Sort files by LastModified in descending order
        this.folders = response.folders;
      } else {
        alert('Failed to list files and folders');
      }
    });

  }

  // Method to navigate to a folder
  navigateToFolder(folderName: string) {
    this.currentPath = this.addToPath(folderName);
    this.listFilesAndFolders();
  }

  // Method to go back to the parent folder
  navigateBack() {
    this.currentPath = this.removeFromPath();
    this.listFilesAndFolders();
  }
  showSnackbar(message: string, action: string = '', duration: number = 3000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }
  // Method to download a file
  downloadFile(fileName: string) {
    const folderName = this.currentPath || '';
    const bucketName = 'englishexpanse';

    this.digitalOceanService.downloadFile(fileName, folderName, bucketName).subscribe(response => {
      console.log(response);
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Download error:', error);
      alert('Failed to download file');
    });
  }

  // Method to delete a file
  deleteFile(fileName: string) {
    const folderName = this.currentPath || '';
    const bucketName = 'englishexpanse';

    this.digitalOceanService.deleteFile(fileName, folderName, bucketName).subscribe(response => {
      console.log(response);
      if (response.success) {
        alert('File deleted successfully');
        this.listFilesAndFolders(); // Refresh the list after deletion
      } else {
        alert('Failed to delete file');
      }
    });
  }

  // Method to get a download link (pre-signed URL)
  getDownloadLink(fileName: string, file) {
    const folderName = ''
    const bucketName = 'englishexpanse';
    const expiresIn = null; // Link expiry time in seconds

    this.digitalOceanService.getDownloadLink(fileName, folderName, bucketName, expiresIn).subscribe(response => {
      console.log(response);
      if (response.success) {
        this.downloadLink = response.downloadLink;
        // copy the link to the clipboard
        window.navigator.clipboard.writeText(this.downloadLink);
        // notify the user

        this.showSnackbar('Link copied to clipboard', null, 3000);
      } else {
        alert('Failed to get download link');
      }
    });
  }

  // Method to list all buckets
  listBuckets() {
    this.digitalOceanService.listBuckets().subscribe(response => {
      console.log(response);
      if (response.success) {
        alert(`Buckets: ${response.buckets.map((bucket: any) => bucket.Name).join(', ')}`);
      } else {
        alert('Failed to list buckets');
      }
    });
  }
}