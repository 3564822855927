<div class="d-flex flex-column w-100 h-100">
  <div class="d-flex flex-row m-2">
    <h2>Video data creator</h2>
  </div>
  <button
    mat-flat-button
    color="primary"
    class="ml-3 mr-3"
    (click)="downloadTextFile()"
  >
    Pobierz plik z danymi
  </button>
  <div class="d-flex-flex-column w-100 h-100">
    <div class="w-100 p-2">
      <mat-accordion>
        <mat-expansion-panel
          [expanded]="currentStepIndex == i"
          class="mt-2"
          *ngFor="let step of steps; let i = index"
        >
          <mat-expansion-panel-header>
            {{ step.promptForUser }}
          </mat-expansion-panel-header>
          <div class="d-flex flex-column w-100">
            <div style="gap: 10px" class="d-flex flex-row w-100 flex-wrap">
              <div style="display: flex" *ngIf="step.type == 'choice'">
                <mat-card
                  style="cursor: pointer; background-color: #ede7f6"
                  (click)="nextStep(step, option)"
                  class="m-2 mt-1 p-2"
                  *ngFor="let option of step.options"
                >
                  {{ option }}
                </mat-card>
              </div>
              <div *ngIf="step.type == 'input'">
                <input
                  #input
                  (keyup.enter)="nextStep(step, input.value)"
                  class="ml-2 mt-1 p-2"
                  matInput
                  placeholder="Type here..."
                />
                <button
                  class="ml-2"
                  (click)="nextStep(step, input.value)"
                  mat-flat-button
                  color="primary"
                >
                  ok
                </button>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
