import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-choose-points-item",
  templateUrl: "./choose-points-item.component.html",
  styleUrls: ["./choose-points-item.component.css"],
})
export class ChoosePointsItemComponent implements OnInit {
  colors: [
    "#64b5f6",
    "#e57373",
    "#aed581",
    "#ba68c8",
    "#ff8a65",
    "#fff176",
    "#ffd54f",
    "#f06292",
    "#4dd0e1",
    "#4db6ac"
  ];
  items = [
    { name: "money faces", emoji: "1F911" },

    { name: "alien dollars", emoji: "1F47D" },

    { name: "sparkling hearts", emoji: "1F496" },

    { name: "odd footprints", emoji: "1F463" },

    { name: "unique unicorns", emoji: "1F984" },

    { name: "pretty pigs", emoji: "1F437" },

    { name: "pixel monsters", emoji: "1F47E" },

    { name: "grumpy ghosts", emoji: "1F47B" },

    { name: "creepy clowns", emoji: "1F921" },

    { name: "spider webs", emoji: "1F578" },

    { name: "gigantic gorillas", emoji: "1F98D" },

    { name: "typical tulips", emoji: "1F337" },

    { name: "wild watermelons", emoji: "1F349" },

    { name: "pricey pineapples", emoji: "1F34D" },

    { name: "poison mushrooms", emoji: "1F344" },

    { name: "party pizzas", emoji: "1F355" },

    { name: "dinosaur eggs", emoji: "1F95A" },

    { name: "crunchy cookies", emoji: "1F36A" },

    { name: "cool cupcakes", emoji: "1F9C1" },

    { name: "crazy candy", emoji: "1F36C" },

    { name: "lovely lollipops", emoji: "1F36D" },

    { name: "gold honey pots", emoji: "1F36F" },

    { name: "icy ice cubes", emoji: "1F9CA" },

    { name: "Greek amphoras", emoji: "1F3FA" },

    { name: "golden compasses", emoji: "1F9ED" },

    { name: "beach umbrellas", emoji: "26F1" },

    { name: "basic bricks", emoji: "1F9F1" },

    { name: "random rocks", emoji: "1FAA8" },

    { name: "tiny trains", emoji: "1F682" },

    { name: "yellow cabs", emoji: "1F696" },

    { name: "trendy tractors", emoji: "1F69C" },

    { name: "ancient anchors", emoji: "2693" },

    { name: "rusty rockets", emoji: "1F680" },

    { name: "flying saucers", emoji: "1F6F8" },

    { name: "crescent moons", emoji: "1F319" },

    { name: "shiny suns", emoji: "2600" },

    { name: "sparkling stars", emoji: "2B50" },

    { name: "terrifying tornados", emoji: "1F32A" },

    { name: "royal rainbows", emoji: "1F308" },

    { name: "lightning bolts", emoji: "26A1" },

    { name: "silver snowflakes", emoji: "2744" },

    { name: "flaming fireballs", emoji: "1F525" },

    { name: "party pumpkins", emoji: "1F383" },

    { name: "brilliant balloons", emoji: "1F388" },

    { name: "pricey presents", emoji: "1F381" },

    { name: "master medals", emoji: "1F3C5" },

    { name: "tacky trophies", emoji: "1F3C6" },

    { name: "big basketballs", emoji: "1F3C0" },

    { name: "boxing gloves", emoji: "1F94A" },

    { name: "crystal balls", emoji: "1F52E" },

    { name: "teddy bears", emoji: "1F9F8" },

    { name: "wooden dolls", emoji: "1FA86" },

    { name: "joker cards", emoji: "1F0CF" },

    { name: "spools of thread", emoji: "1F9F5" },

    { name: "cool sunglasses", emoji: "1F576" },

    { name: "money purses", emoji: "1F45B" },

    { name: "flip-flops", emoji: "1FA74" },

    { name: "classy crowns", emoji: "1F451" },

    { name: "diamond rings", emoji: "1F48D" },

    { name: "precious gems", emoji: "1F48E" },

    { name: "antique telephones", emoji: "260E" },

    { name: "best batteries", emoji: "1F50B" },

    { name: "light bulbs", emoji: "1F4A1" },

    { name: "books of knowledge", emoji: "1F4D2" },

    { name: "money bags", emoji: "1F4B0" },

    { name: "curious coins", emoji: "1FA99" },

    { name: "dollar banknotes", emoji: "1F4B5" },

    { name: "euro banknotes", emoji: "1F4B6" },

    { name: "flying money", emoji: "1F4B8" },

    { name: "magic crayons", emoji: "1F58D" },

    { name: "magic keys", emoji: "1F5DD" },

    { name: "mighty swords", emoji: "2694" },

    { name: "sturdy shields", emoji: "1F6E1" },

    { name: "secret potions", emoji: "1F9EA" },

    { name: "magic mirrors", emoji: "1FA9E" },

    { name: "strange sculptures", emoji: "1F5FF" },

    { name: "mysterious marks", emoji: "2753" },
  ];
  chosenItems = [];
  constructor(private dialogRef: MatDialogRef<ChoosePointsItemComponent>) {}

  ngOnInit(): void {
    for (let index = 0; index < 10; index++) {
      this.chosenItems.push(
        this.items[Math.floor(Math.random() * this.items.length)]
      );
    }
  }
  chooseItem(item) {
    item.emoji =
      "../../../../assets/emojis/" + item.emoji.toLowerCase() + ".svg";
    this.dialogRef.close(item);
  }
}
