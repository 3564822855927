<mat-card
    *ngIf="apperance === 'static'"
    class="m-3 p-2"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="10px grid"
    style="overflow-x: auto; overflow-y: hidden; height: 73px;"
>
    <mat-form-field
        *ngIf="input"
        [fxFlex]="inputSize"
        appearance="outline"
        class="pl-2"
        fxFlex.xs="40"
    >
        <mat-label>{{inputLabel}}</mat-label>
        <input
            (ngModelChange)="this.textToSendUpdate.next($event)"
            [(ngModel)]="textToSend"
            [placeholder]="inputPlaceholder"
            matInput
         />
        <mat-icon matSuffix>{{inputIcon}}</mat-icon>
        <mat-hint>{{inputHint}}</mat-hint>
    </mat-form-field>
    <mat-form-field
        *ngIf="select && selectOptions.length > 1"
        appearance="outline"
        fxFlex.xs="40"
    >
        <mat-select
            (selectionChange)="selectChanged($event)"
            [placeholder]="selectPlaceholder"
        >
            <mat-option
                *ngFor="let option of selectOptions"
                [value]="option.value"
            >{{option.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button
        [matMenuTriggerFor]="menu"
        class="ml-3 mt-2 pl-1"
        fxHide.gt-xs
        fxLayoutAlign="center start"
        mat-icon-button
    >
        <mat-icon>more_vert</mat-icon>
    </button>
<mat-menu #menu="matMenu">
        <div *ngFor="let control of controls">
            <div
                *ngIf="control.type === 'checkBox' && control.mode === 'group'"
                aria-label="Select an option"
                class="ml-2 mr-2 mt-1"
                fxLayout="column"
            >
                <span class="p-0">
                    <mat-checkbox
                        (change)="onCheckBoxChanged(control.text1, control.boxValue1)"
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="control.boxValue1"
                        [color]="control.color"
                    >{{control.text1}}
                    </mat-checkbox>
                </span>
                <span style="margin-top: -5px;">
                    <mat-checkbox
                        (change)="onCheckBoxChanged(control.text2, control.boxValue2)"
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="control.boxValue2"
                    >{{control.text2}}
                    </mat-checkbox>
                </span>
            </div>
            <div *ngIf="control?.type === 'radio' && control?.mode === 'group'">
                <mat-radio-group
                    [(ngModel)]="control.radioValue"
                    class="ml-1 pl-1 mt-1 mr-1"
                    fxLayout="column"
                >
                    <mat-radio-button
                        (change)="onRadioClicked(control.text1)"
                        [color]="control.color"
                        value="1"
                    >{{control.text1}}
                    </mat-radio-button>
                    <mat-radio-button
                        (change)="onRadioClicked(control.text2)"
                        [color]="control.color"
                        value="2"
                    >{{control.text2}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <span
                *ngIf="control.type === 'checkBox' && control.mode === 'single'"
                class="ml-2"
                style="border: none; background-color: transparent;"
            >
                <mat-checkbox
                    (change)="onCheckBoxChanged(control.text, control.boxValue)"
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="control.boxValue"
                    [color]="control.color"
                >{{control.text}}
                </mat-checkbox>
            </span>
<button
                (click)="this.onButtonClick(control.text)"
                *ngIf="control.type === 'button'"
                mat-menu-item
            >
                <mat-icon *ngIf="control.icon">{{control.icon}}</mat-icon>
                <span>{{control.text}}</span>
            </button>
        </div>
    </mat-menu>

    <!-- <div fxHide.xs fxFlex class="pt-2 mt-1"  *ngFor="let control of barControls" >
   <button *ngIf="control?.type === 'button' && control?.mode === 'icon'" mat-icon-button [color]="control.color" ><mat-icon>{{control.icon}}</mat-icon></button>
   <button *ngIf="control?.type === 'button' && control?.mode === 'fab'" mat-fab [color]="control.color"><mat-icon>{{control?.icon}}</mat-icon></button>
   <button *ngIf="control?.type === 'button' && control?.mode === 'fab-mini'" mat-mini-fab [color]="control.color" ><mat-icon>{{control.icon}}</mat-icon></button>
  </div> -->
<div
        *ngFor="let control of barControls"
        class="pt-2 mt-1"
        fxFlex
        fxHide.xs
    >
        <div
            *ngIf="control?.type === 'checkBox' && control?.mode === 'group'"
            aria-label="Select an option"
            class="ml-2 mr-2"
            fxLayout="column"
            style="margin-top: -8px;"
        >
            <span class="p-0">
                <mat-checkbox
                    (change)="onCheckBoxChanged(control.text1, control.boxValue1)"
                    [(ngModel)]="control.boxValue1"
                    [color]="control.color"
                >{{control.text1}}
                </mat-checkbox>
            </span>
            <span style="margin-top: -5px;">
                <mat-checkbox
                    (change)="onCheckBoxChanged(control.text2, control.boxValue2)"
                    [(ngModel)]="control.boxValue2"
                    [color]="control.color"
                >{{control.text2}}
                </mat-checkbox>
            </span>
        </div>
        <div *ngIf="control?.type === 'radio' && control?.mode === 'group'">
            <mat-radio-group
                [(ngModel)]="control.radioValue"
                class="ml-2 mr-2"
                fxLayout="column"
                style="margin-top: -7px;"
            >
                <mat-radio-button
                    (change)="onRadioClicked(control.text1)"
                    [color]="control.color"
                    value="1"
                >{{control.text1}}
                </mat-radio-button>
                <mat-radio-button
                    (change)="onRadioClicked(control.text2)"
                    [color]="control.color"
                    value="2"
                >{{control.text2}}
                </mat-radio-button>
            </mat-radio-group>
        </div>
<button
            *ngIf="control?.type === 'checkBox' && control?.mode === 'single'"
            class="pt-1"
            style="border: none; background-color: transparent;"
        >
            <mat-checkbox
                (change)="onCheckBoxChanged(control.text, control.boxValue)"
                (change)="onCheckBoxChanged(control.text)"
                [(ngModel)]="control.boxValue"
                [color]="control.color"
            >{{control.text}}
            </mat-checkbox>
        </button>
<mat-radio-button
            (change)="onRadioClicked(control.text)"
            *ngIf="control?.type === 'radio' && control?.mode === 'single'"
            class="ml-2 mt-2"
            value="1"
        >{{control.text}}
        </mat-radio-button>
<button
            (click)="this.onButtonClick(control.text)"
            *ngIf="control?.type === 'button' && control?.mode === 'raised' && control?.icon"
            [color]="control.color"
            flex-direction:
            fxFlex
            fxLayout
            mat-raised-button
            row
        >
            <mat-icon class="mr-3">{{control.icon}}</mat-icon>
            <span>{{control.text}}</span>
        </button>
        <button
            (click)="this.onButtonClick(control.text)"
            *ngIf="control?.type === 'button' && control?.mode === 'raised' && !control?.icon"
            [color]="control.color"
            fxFlex
            mat-raised-button
        >{{control.text}}
        </button>
        <button
            (click)="this.onButtonClick(control.text)"
            *ngIf="control?.type === 'button' && control?.mode === 'stroked' && control?.icon"
            [color]="control.color"
            flex-direction:
            fxFlex
            fxLayout
            mat-stroked-button
            row
        >
            <mat-icon class="mr-3">{{control.icon}}</mat-icon>
            <span>{{control.text}}</span>
        </button>
        <button
            (click)="this.onButtonClick(control.text)"
            *ngIf="control?.type === 'button' && control?.mode === 'stroked' && !control?.icon"
            [color]="control.color"
            fxFlex
            mat-stroked-button
        >{{control.text}}
        </button>
        <button
            (click)="this.onButtonClick(control.text)"
            *ngIf="control?.type === 'button' && control?.mode === 'flat' && control?.icon"
            [color]="control.color"
            flex-direction:
            fxFlex
            fxLayout
            mat-flat-button
            row
        >
            <mat-icon class="mr-3">{{control.icon}}</mat-icon>
            <span>{{control.text}}</span>
        </button>
        <button
            (click)="this.onButtonClick(control.text)"
            *ngIf="control?.type === 'button' && control?.mode === 'flat'&& !control?.icon"
            [color]="control?.color"
            fxFlex
            mat-flat-button
        >{{control.text}}
        </button>
    </div>
    <button
        *ngIf="controls.length > 5"
        [matMenuTriggerFor]="moreMenu"
        class="ml-3 mt-2 pl-1"
        fxHide.xs
        fxLayoutAlign="center start"
        mat-icon-button
    >
        <mat-icon>more_vert</mat-icon>
    </button>
<mat-menu #moreMenu="matMenu">
        <div *ngFor="let control of moreControls">
            <div
                *ngIf="control.type === 'checkBox' && control.mode === 'group'"
                aria-label="Select an option"
                class="ml-3 mr-2 mt-1"
                fxLayout="column"
            >
                <span class="p-0">
                    <mat-checkbox
                        (change)="onCheckBoxChanged(control.text1, control.boxValue1)"
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="control.boxValue1"
                        [color]="control.color"
                    >{{control.text1}}
                    </mat-checkbox>
                </span>
                <span style="margin-top: -5px;">
                    <mat-checkbox
                        (change)="onCheckBoxChanged(control.text2, control.boxValue2)"
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="control.boxValue2"
                        [color]="control.color"
                    >{{control.text2}}
                    </mat-checkbox>
                </span>
            </div>
<mat-radio-group
                *ngIf="control.type === 'radio' && control.mode === 'group'"
                class="ml-2 pl-1 mt-1 mr-1"
                fxLayout="column"
            >
                <mat-radio-button
                    [color]="control.color"
                    value="1"
                >{{control.text1}}
                </mat-radio-button>
                <mat-radio-button
                    [color]="control.color"
                    value="2"
                >{{control.text2}}
                </mat-radio-button>
            </mat-radio-group>
<span
                *ngIf="control.type === 'checkBox' && control.mode === 'single'"
                class="ml-3"
                style="border: none; background-color: transparent;"
            >
                <mat-checkbox
                    (change)="onCheckBoxChanged(control.text, control.boxValue)"
                    (click)="$event.stopPropagation()"
                    [(ngModel)]="control.boxValue"
                    [color]="control.color"
                >{{control.text}}
                </mat-checkbox>
            </span>
<button
                *ngIf="control.type === 'button'"
                mat-menu-item
            >
                <span>{{control.text}}</span>
            </button>
        </div>
    </mat-menu>
</mat-card>
