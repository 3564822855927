<div fxLayout="row" fxFlex>
  <div
    fxLayout="row"
    fxFlex
    style="height: 20px; color: #ef6c00; font-weight: bold;"
    [style.border]="segments.length>0 ? '1px solid black': '1px dashed gray'"
  >
    <div
      *ngFor="let segment of segments"
      [style.width]="segment.length + '%'"
      [style.background-color]="segment.avalible? '#673ab7': '#fff'"
      [style.color]="segment.avalible? '#ffb74d;': '#fff'"
      fxLayoutAlign="space-between center"
    >
      <div class="ml-1 mr-1">{{segment.startTime}}</div>
      <div class="ml-1 mr-1">{{segment.endTime}}</div>
    </div>
  </div>
</div>
