import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subject } from 'rxjs';
import { StudentComponent } from 'src/app/profile/student/student.component';
import { CustomersService } from '../services/customers.service';
import { HelpersService } from '../services/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class StarSetterService {
userDetails = null
updatedSet = null
starsUpdated = new Subject()
  constructor(
    private customersService: CustomersService,
    private helper: HelpersService,
    private dbService: NgxIndexedDBService
  ) { }

  updateStars(updatedSet){
    this.dbService.update('other', {id:'currentSet', value:updatedSet})
    if(updatedSet?.starredItems){

  updatedSet.starredItems = updatedSet.starredItems.filter(e=> e)
  updatedSet.starredItems.forEach(element => {
let firstId = element.wordId + element.meaningId
    updatedSet.items.forEach((e, index)=>{
      const secondId = e.wordId + e.meaningId
      if(firstId == secondId){
        e.star = true
console.log(updatedSet.items[index]);
    }
        else{
          e.star = false
        }
      }
    )


  });

    }
    else{
      if(updatedSet.starredItems){
       updatedSet.starredItems = []
      }
    }
    return updatedSet

  }
  setStar(elementId, updatedSet?){

    console.log('elementId: ', elementId);
    const item = updatedSet.items.filter(e=>e.meaningId === elementId)[0]
    console.log('item: ', item);
    updatedSet.starredItems.push(item)
    updatedSet.starredItems = this.helper.removeDuplicatesObj(updatedSet.starredItems)
    console.log('updatedSet.starredItems: ', updatedSet.starredItems);
    this.customersService.updateStarredItems(this.userDetails._id, updatedSet.id, updatedSet.starredItems)
    this.starsUpdated.next({starredItems: updatedSet.starredItems, singleQuizParams: updatedSet.singleQuizParams})

  }
  resetStar(elementId, updatedSet?){

const firstStarredItems = this.helper.clone(updatedSet.starredItems)
    console.log('updatedSet.starredItems: ', updatedSet.starredItems);

    const item = updatedSet.items.filter(e=>e.meaningId === elementId)[0]
    console.log('item: ', item);
    let itemToRemove = updatedSet.items.filter(e=>
      e.item?.id == item.item.id

      )[0]
      console.log('itemToRemove: ', itemToRemove);
    let starredItemToRemove = updatedSet.starredItems.filter(e=>
      e.item.id == item.item.id
      )[0]
      starredItemToRemove = updatedSet.starredItems.indexOf(starredItemToRemove)
      console.log('starredItemToRemove: ', starredItemToRemove);

    if(itemToRemove){
      updatedSet.starredItems = this.helper.removeElementFromArray(updatedSet.starredItems, item)
      this.customersService.updateStarredItems(this.userDetails._id, updatedSet.id, updatedSet.starredItems)
      item.star = false
    }
    let starredItemsProgress = updatedSet?.stats?.quizParams.singleQuizParams?.starredItemsNumber

    console.log('starredItemsProgress: ', starredItemsProgress);
    if(starredItemToRemove && starredItemsProgress){
      if(starredItemToRemove < starredItemsProgress){
        console.log('lower starredItemProgress!');
        if(updatedSet.stats.quizParams.singleQuizParams.starredItemsNumber > 0){
          updatedSet.stats.quizParams.singleQuizParams.starredItemsNumber --
          console.log('starredItemsProgress: ', starredItemsProgress);
          this.customersService.updateStats(this.userDetails._id, updatedSet.stats, updatedSet.id,
            null, null,updatedSet.stats.weeklyProgress, false,null ,updatedSet.stats.quizParams);
          }
        }
      }
      let result = false
      if(updatedSet.starredItems.length < firstStarredItems.length){
          result = true
          this.starsUpdated.next({starredItems: updatedSet.starredItems, singleQuizParams: updatedSet.singleQuizParams})
      }
      else{
        result = true
      }
      return result
  }
  starsUpdatedListener(){
    return this.starsUpdated.asObservable()
  }
}
