import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { addMinutes, isWithinInterval, sub } from 'date-fns';
import { subMinutes } from 'date-fns/esm';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class SmallDataService {
  private tempGroupsBearer = new Subject();
  tempGroups = [];
  url;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }
  checkAvalibility(students, start, end) {
    let result = false;
    students.forEach((student) => {
      if (
        student.avalibility.some(
          (avaibility) =>
            isWithinInterval(new Date(start), {
              start: subMinutes(new Date(avaibility.start), 5),
              end: addMinutes(new Date(avaibility.end), 5),
            }) &&
            isWithinInterval(new Date(end), {
              start: subMinutes(new Date(avaibility.start), 5),
              end: addMinutes(new Date(avaibility.end), 5),
            }),
        )
      ) {
        result = true;
      } else {
        let studentName = student.childFirstName + ' ' + student.childLastName;
        if (student.adultFirstName) {
          studentName = student.adultFirstName + ' ' + student.adultLastName;
        }
        alert(studentName + ' niedostępny');
      }
    });

    return result;
  }
  // saveTempGroupStudent(tempGroup) {
  //   this.http
  //     .post(this.url + "/api/small-data/save-temp-group", tempGroup)
  //     .subscribe((response) => {
  //       console.log(
  //         "🚀 ~ file: small-data.service.ts ~ line 63 ~ SmallDataService ~ .subscribe ~ response",
  //         response
  //       );
  //     });
  //   console.log("-> this.url", this.url);
  // }

  saveNewTempGroup(tempGroup) {
    this.http
      .post(this.url + '/api/small-data/add-item', {
        tempGroup: tempGroup,
        type: 'tempGroup',
      })
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: small-data.service.ts ~ line 76 ~ SmallDataService ~ .subscribe ~ response',
          response,
        );
      });
    this.getTempGroups();
  }
  addSmallDataItemItem(item, type) {
    this.http
      .post(this.url + '/api/small-data/add-item', {
        tempGroup: item,
        type: 'tempGroup',
      })
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: small-data.service.ts ~ line 76 ~ SmallDataService ~ .subscribe ~ response',
          response,
        );
      });
    this.getTempGroups();
  }
  getTempGroups() {
    this.http
      .get(this.url + '/api/small-data/get-temp-groups', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let groupsArray = Object.keys(response).map((i) => response[i]);
        groupsArray = groupsArray[0].map((group) => {
          if (group.data.tempGroup) {
            group.data.tempGroup.dbId = group._id;
            return group.data.tempGroup;
          }
        });
        groupsArray = groupsArray.filter((group) => group?.start);
        console.log(
          '🚀 ~ file: small-data.service.ts:128 ~ SmallDataService ~ .subscribe ~ groupsArray:',
          groupsArray,
        );
        this.tempGroups = groupsArray;

        this.tempGroupsBearer.next(groupsArray);
      });
  }
  checkNumberOfGroupsForStudent(studentId) {
    if (this.tempGroups.length > 0) {
      let groupsWithStudent = this.tempGroups.filter(
        (group) => group?.meta?.studentsIds?.includes(studentId),
      );
      if (groupsWithStudent.length > 0) {
      }
      return groupsWithStudent;
    }
  }
  updateTempGroup(groupToReplace) {
    if (groupToReplace.dbId) {
      this.http
        .put(
          this.url + '/api/small-data/update-item/' + groupToReplace.dbId,
          { item: groupToReplace, type: 'tempGroup' },

          {
            headers: { Authorization: `Bearer ${this.authService.getToken()}` },
          },
        )
        .subscribe((response) => {
          console.log(
            '🚀 ~ file: small-data.service.ts ~ line 139 ~ SmallDataService ~ .subscribe ~ response',
            response,
          );
          // this.getTempGroups();
        });
    }
  }

  removeTempGroup(id) {
    this.http
      .delete(this.url + '/api/small-data/remove-item/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        this.getTempGroups();
      });
  }
  tempGeoupsUpdatedListener() {
    return this.tempGroupsBearer.asObservable();
  }
}
