import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanvasService {
message = new Subject()
  constructor() { }


passMessage(message){
  this.message.next(message)
}

  messagesListener(){
    return this.message.asObservable()
  }
}
