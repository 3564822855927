import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-plan-dayview",
  templateUrl: "./plan-dayview.component.html",
  styleUrls: ["./plan-dayview.component.css"],
})
export class PlanDayviewComponent implements OnInit {
  @Input() studentsForGroup;
  @Input() day;
  times = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

  constructor() {}

  ngOnInit(): void {
    this.studentsForGroup;
  }
}
