import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-datetime-picker-dialog",
  templateUrl: "./datetime-picker-dialog.component.html",
  styleUrls: ["./datetime-picker-dialog.component.css"],
})
export class DatetimePickerDialogComponent implements OnInit {
  @ViewChild("input") input;
  selectedDateTime;
  selectedLength;
  reason;
  selectedDateTime2: any;
  constructor(
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    console.log(
      "🚀 ~ file: datetime-picker-dialog.component.ts ~ line 21 ~ DatetimePickerDialogComponent ~ ngAfterViewInit ~ this.input",
      this.input
    );
    setTimeout(() => {
      this.input.nativeElement.click();
    }, 10);
  }
  ngOnInit(): void {
    this.reason = this.data.reason;
    this.spinner.hide();
    if (this.data.selectedDateTime) {
      this.selectedDateTime = this.data.selectedDateTime;
    }
    console.log(
      "🚀 ~ file: datetime-picker-dialog.component.ts ~ line 20 ~ DatetimePickerDialogComponent ~ ngOnInit ~ this.data",
      this.data
    );
  }
  addAvalibility(event) {
    let avlalibilitiesToAdd = [];
    console.log(
      "🚀 ~ file: datetime-picker-dialog.component.ts ~ line 51 ~ DatetimePickerDialogComponent ~ buttonGroupChanged ~ event",
      event
    );
  }
}
