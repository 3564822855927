<div fxLayout="column" style="width: 100vw;">
  <!-- <mat-form-field appearance="outline">
    <mat-label>{{displayProperty}}</mat-label>
    <mat-select
      [disabled]="reportSent"
      (selectionChange)="selectValueChanged($event)"
      [placeholder]="displayProperty"
      name="item"
    >
      <mat-option *ngFor="let option of selectOptions" [value]="option.value">
        {{option.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field> -->
  <div layout="row">
    <div>
      <div fxFlex="20">
        <mat-label style="font-weight: bold;">{{displayProperty}}</mat-label>
      </div>

      <mat-radio-group fxLayoutGap="10px" (change)="selectValueChanged($event)">
        <mat-radio-button
          *ngFor="let option of selectOptions"
          [value]="option.value"
          [checked]="checkIfRadioSelected(option)"
        >
          {{option.viewValue}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <mat-divider class="mb-2"></mat-divider>
</div>
