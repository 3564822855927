<div
  [style.background-color]="checkIfMarkedByUserAsDone(fact) ? '#e8f5e9' : ''"
  class="d-flex flex-column"
  style="overflow-y: auto"
>
  <div class="d-flex flex-row align-items-center">
    <span class="ml-2 mr-3 mb-2">{{ fact.level }}</span>
    <button
      class="mb-1"
      color="primary"
      mat-icon-button
      *ngIf="!editMode"
      (click)="editMode = true"
    >
      <mat-icon>edit</mat-icon>
    </button>
    <button
      color="primary"
      class="mb-1"
      mat-icon-button
      *ngIf="editMode"
      (click)="editFinished()"
    >
      <mat-icon>done</mat-icon>
    </button>
    <mat-checkbox
      [(ngModel)]="fact.selected"
      *ngIf="select"
      class="mr-3 mr-2"
    ></mat-checkbox>
    <div class="d-flex flex-column" style="width: 100%">
      <!-- <div class="d-flex flex-row align-items-center">
        <span class="ml-2 mr-2">{{ fact.level }}</span>
        <div class="">
          <span style="color: #673ab7">{{ fact.texts.English }}</span>
          <span class="ml-2 mr-2"> {{ fact.answers.English }}</span>
          <span *ngIf="fact.correctAnswer.English">-></span>
          <span style="font-weight: bold" class="ml-2 mr-2"
            >{{ fact.correctAnswer.English }}
          </span>
        </div>
        <hr />
        <span style="flex: 1"></span>
      </div> -->
      <span
        *ngIf="!editMode"
        style="font-weight: bold; color: #673ab7"
        class="mt-3 ml-2 mr-2"
        >{{ fact?.category }}</span
      >
      <input
        style="font-weight: bold; color: #673ab7; flex: 1"
        *ngIf="editMode && fact?.category"
        [(ngModel)]="fact.category"
      />
      <div class="d-flex flex-row align-items-center">
        <span style="flex: 1" *ngIf="!editMode" class="mt-1 ml-2">{{
          fact.texts.English
        }}</span>
        <input
          style="flex: 1"
          *ngIf="editMode && fact?.texts?.English"
          [(ngModel)]="fact.texts.English"
        />

        <button
          (click)="generateQuestionsAudio()"
          matTooltip="Record question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>record_voice_over</mat-icon>
        </button>
        <audio
          style="width: 150px"
          [src]="fact?.audio"
          controls
          class="ml-2"
        ></audio>
        <button
          [disabled]="fact?.texts.Hindi"
          (click)="translateProperty('texts')"
          [disabled]="fact?.texts.Hindi"
          matTooltip="Translate ABC Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>translate</mat-icon>
        </button>
      </div>
      <div class="d-flex flex-row align-items-center">
        <span style="flex: 1" *ngIf="!editMode" class="mt-1 ml-2 mr-2">{{
          fact.explanationOfTheAnswer.English
        }}</span>
        <input
          style="flex: 1"
          *ngIf="editMode"
          [(ngModel)]="fact.explanationOfTheAnswer.English"
        />

        <button
          (click)="generateDescriptionsAudio()"
          matTooltip="Record explanation"
          mat-icon-button
          color="primary"
        >
          <mat-icon>record_voice_over</mat-icon>
        </button>
        <audio
          style="width: 150px"
          [src]="fact?.explanationAudio"
          controls
          class="ml-2"
        ></audio>
        <button
          (click)="translateProperty('explanationOfTheAnswer')"
          [disabled]="fact?.explanationOfTheAnswer.Hindi"
          matTooltip="Translate ABC Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>translate</mat-icon>
        </button>
      </div>
      <div
        *ngIf="fact?.ABCQuestionWithAnswers?.English"
        class="d-flex flex-row align-items-center"
      >
        <span
          *ngIf="!editMode"
          style="color: rgb(27, 126, 27); flex: 1"
          class="mt-1 ml-2 mr-2"
          >{{ fact?.ABCQuestionWithAnswers.English }}</span
        >
        <input
          style="color: rgb(27, 126, 27); flex: 1"
          *ngIf="editMode && fact?.ABCQuestionWithAnswers?.English"
          [(ngModel)]="fact?.ABCQuestionWithAnswers.English"
        />
        <button
          (click)="generateABCQuestionAudio()"
          matTooltip="Record ABC Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>record_voice_over</mat-icon>
        </button>

        <audio
          style="width: 150px"
          [src]="fact?.ABCQuestionWithAnswersAudio"
          controls
          class="ml-2"
        ></audio>
        <button
          (click)="translateProperty('ABCQuestionWithAnswers')"
          [disabled]="fact?.ABCQuestionWithAnswers.Hindi"
          matTooltip="Translate ABC Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>translate</mat-icon>
        </button>
      </div>
      <div
        *ngIf="fact?.ABCExplanation?.English"
        class="d-flex flex-row align-items-center"
      >
        <span
          *ngIf="!editMode"
          style="color: rgb(27, 126, 27); flex: 1"
          class="mt-1 ml-2 mr-2"
          >{{ fact.ABCExplanation?.English }}</span
        >
        <input
          style="color: rgb(27, 126, 27); flex: 1"
          *ngIf="editMode && fact?.ABCExplanation?.English"
          [(ngModel)]="fact?.ABCExplanation.English"
        />
        <button
          (click)="generateABCExplanationAudio()"
          matTooltip="Record ABC Explanation"
          mat-icon-button
          color="primary"
        >
          <mat-icon>record_voice_over</mat-icon>
        </button>
        <audio
          style="width: 150px"
          [src]="fact?.ABCExplanationAudio"
          controls
          class="ml-2"
        ></audio>
        <button
          (click)="translateProperty('ABCExplanation')"
          [disabled]="fact?.ABCExplanation.Hindi"
          matTooltip="Translate ABC Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>translate</mat-icon>
        </button>
      </div>
      <div
        *ngIf="fact?.TFQuestion?.English"
        class="d-flex flex-row align-items-center"
      >
        <span
          *ngIf="!editMode"
          style="color: rgb(20, 100, 204); flex: 1"
          class="mt-1 ml-2 mr-2"
          >{{ fact?.TFQuestion.English }}</span
        >
        <input
          style="color: rgb(20, 100, 204); flex: 1"
          *ngIf="editMode && fact?.TFQuestion?.English"
          [(ngModel)]="fact?.TFQuestion.English"
        />
        <span style="flex: 1"></span>
        <button
          (click)="generateTFQuestionAudio()"
          matTooltip="Record TF Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>record_voice_over</mat-icon>
        </button>
        <audio
          style="width: 150px"
          [src]="fact?.TFQuestionAudio"
          controls
          class="ml-2"
        ></audio>
        <button
          (click)="translateProperty('TFQuestion')"
          [disabled]="fact?.TFQuestion.Hindi"
          matTooltip="Translate ABC Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>translate</mat-icon>
        </button>
      </div>
      <div
        *ngIf="fact?.TFExplanation?.English"
        class="d-flex flex-row align-items-center"
      >
        <span
          *ngIf="!editMode"
          style="color: rgb(20, 100, 204)"
          class="mt-1 ml-2 mr-2"
          >{{ fact?.TFExplanation.English }}</span
        >
        <input
          style="color: rgb(20, 100, 204)"
          *ngIf="editMode && fact?.TFExplanation?.English"
          [(ngModel)]="fact.TFExplanation.English"
        />
        <!-- ... -->
        <button
          (click)="generateTFExplanationAudio()"
          matTooltip="Record TF Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>record_voice_over</mat-icon>
        </button>
        <audio
          style="width: 150px"
          [src]="fact?.TFExplanationAudio"
          controls
          class="ml-2"
        ></audio>
        <button
          (click)="translateProperty('TFExplanation')"
          [disabled]="fact?.TFExplanation.Hindi"
          matTooltip="Translate ABC Question"
          mat-icon-button
          color="primary"
        >
          <mat-icon>translate</mat-icon>
        </button>
      </div>

      <span *ngIf="!editMode" style="color: #ff5722" class="mt-3 ml-2 mr-2">{{
        fact?.imageTag
      }}</span>
      <input
        style="color: #ff5722"
        type="text"
        *ngIf="editMode && fact?.imageTag"
        [(ngModel)]="fact.imageTag"
        name=""
        id=""
      />
    </div>
    <span style="flex: 1"></span>

    <div
      class="d-flex flex-column"
      style="width: 20vw"
      style="margin-left: 40px"
    >
      <div class="d-flex flex-row">
        <div style="height: 100px; width: 175px; border: 3px solid #673ab7">
          <video
            class="mr-3"
            style="height: 100%; width: 100%"
            *ngIf="fact?.video"
            controls
            [src]="fact?.video"
          ></video>
        </div>
        <img
          style="border: 3px solid #673ab7; width: 100px; height: 100px"
          [src]="fact?.image"
          alt=""
        />
      </div>
      <div class="f-flex flex-row">
        <button
          matTooltip="Create Image"
          (click)="createImage()"
          class="ml-1 mr-1"
          mat-icon-button
          color="primary"
        >
          <mat-icon>add_a_photo</mat-icon>
        </button>
        <button
          matTooltip="Add video to fact"
          (click)="addVideoToFact()"
          class="ml-1 mr-1"
          mat-icon-button
          color="primary"
        >
          <span class="material-symbols-outlined"> hangout_video </span>
        </button>
        <button
          matTooltip="Create ABC question "
          (click)="createAbcQuestion()"
          class="ml-1 mr-1"
          mat-icon-button
          color="primary"
        >
          <span class="material-symbols-outlined"> question_mark </span>
        </button>
        <button
          matTooltip="Create TF question "
          (click)="createTfQuestion()"
          class="ml-1 mr-1"
          mat-icon-button
          color="primary"
        >
          <span class="material-symbols-outlined">
            indeterminate_question_box
          </span>
        </button>
        <button
          *ngIf="!creatingVideo"
          matTooltip="Create short"
          (click)="createVideo()"
          mat-icon-button
          color="primary"
        >
          <mat-icon>videocam</mat-icon>
        </button>
        <button
          *ngIf="!creatingVideo"
          matTooltip="Create reel"
          (click)="createVideo(true)"
          mat-icon-button
          color="accent"
        >
          <mat-icon>videocam</mat-icon>
        </button>
        <button
          *ngIf="fact?.shortVideo"
          matTooltip="Download short"
          (click)="downloadFactsShort()"
          mat-icon-button
          color="primary"
        >
          <mat-icon>download</mat-icon>
        </button>
        <mat-spinner *ngIf="creatingVideo" [diameter]="20"></mat-spinner>

        <button
          (dblclick)="removeFact()"
          matTooltip="Double click to remove"
          mat-icon-button
          color="warn"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          matTooltip="Mark as done/undone"
          (click)="
            checkIfMarkedByUserAsDone(fact)
              ? markFactAsUndone(fact)
              : markFactAsDone(fact)
          "
          mat-icon-button
          color="primary"
        >
          <span class="material-symbols-outlined"> done_outline </span>
        </button>
      </div>
    </div>
  </div>
</div>
<hr />
