import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { HelpersService } from '../helpers.service';
import { ReadTextService } from '../read-text.service';
import { VersionService } from '../version.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateAudioService {
  url;
  generateFinished = new Subject();
  generatedItems: any;
  audioItemUpdated = new Subject();
  savedFreeAudioItem = new Subject();
  audiosRepoList = new Subject();
  generatedItemsTarget: number;
  streamBusy: boolean;
  constructor(
    private readTextService: ReadTextService,
    private http: HttpClient,
    private helpers: HelpersService,
    private authService: AuthenticationService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url + '/api/read/';
  }
  generateFreeSetItemsBulk(
    target,
    items,
    questionLang,
    answersLang,
    setId,
    recreate?,
  ) {
    this.generatedItems = 0;
    this.generatedItemsTarget = target;
    items.forEach((item) => {
      // setInterval(()=>{
      this.generateFreeSetsItems(
        item.question.trim(),
        questionLang,
        setId,
        item.id,
        'termAudioUS',
        null,
        null,
        recreate,
      );
      // },500)
      //  setInterval(()=>{
      this.generateFreeSetsItems(
        item.correctAnswer.trim(),
        answersLang,
        setId,
        item.id,
        'definitionAudioUS',
        null,
        null,
        recreate,
      );

      // },500)
      if (item.question.includes('_'))
        //  setInterval(()=>{
        this.generateFreeSetsItems(
          item.question.trim(),
          answersLang,
          setId,
          item.id,
          'termAndDefinitionAudioUS',
          true,
          item.correctAnswer.trim(),
          recreate,
        );

      // },500)
    });
  }

  generateFreeSetsItems(
    item,
    lang,
    setId,
    itemId,
    itemProperty,
    blank?,
    answer?,
    recreate?,
  ) {
    if (!lang) {
      alert('Wybierz język!');
    }
    let itemsToSave = {
      id:
        'text=begin-' +
        item.replace('+', '8').trim().replace('%', 'percent') +
        '-end-' +
        'lang=' +
        lang.replace('+', '8'),
      textToRecord: item
        .replace(/(_)\w+/g, 'blank')
        .replace(/\(.*?\)/g, '')
        .replace('%', 'percent')
        .replace('%', 'percent')
        .replace('%', 'percent')
        .replace('%', 'percent')
        .replace('%', 'percent')

        .trim(),
    };
    if (blank && itemProperty === 'termAndDefinitionAudioUS') {
      let answersArray = answer.split(';');
      answersArray.forEach((_answer) => {
        itemsToSave.textToRecord = itemsToSave.textToRecord.replace(
          'blank',
          _answer.trim(),
        );
      });

      itemsToSave.id =
        'text=begin-' + itemsToSave.textToRecord.replace('+', '8');
      +'-end-' + 'lang=' + lang.replace('+', '8');
    }
    (itemsToSave.textToRecord = itemsToSave.textToRecord.replaceAll('  ', ' ')),
      console.log(
        '🚀 ~ file: generate-audio.service.ts ~ line 128 ~ GenerateAudioService ~ answersArray.forEach ~ itemsToSave.textToRecord',
        itemsToSave.textToRecord,
      );

    this.streamBusy = true;
    this.http
      .post(
        this.url + 'saveaudiodataforfreeitems/',
        {
          text: itemsToSave.textToRecord,
          setId: setId,
          itemId: itemId,
          itemProperty: itemProperty,
          lang: lang,
          id: itemsToSave.id,
          recreate: recreate,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response: any) => {
        this.streamBusy = false;
        this.audioItemUpdated.next(response);
        console.log('response: ', response);
        if (response.filename) {
          console.log(
            '🚀 ~ file: generate-audio.service.ts ~ line 44 ~ GenerateAudioService ~ .subscribe ~ filename',
            response.filename,
          );
        }
        this.generatedItems++;
        if (this.generatedItems === this.generatedItemsTarget) {
          alert('audio done!');
        }
        this.generateFinished.next({ generatingWordFinished: true });
      });
  }

  generateBlank(item) {
    const itemToReadArray = item.map((word) => {
      if (word.blank) {
        const splitWord = word.toString().split('');
        if (
          splitWord[splitWord.length] === '.' ||
          splitWord[splitWord.length] === '!' ||
          splitWord[splitWord.length] === '?' ||
          splitWord[splitWord.length] === ','
        ) {
          return '____________' + splitWord[splitWord.length];
        } else {
          return '____________';
        }
      } else {
        return word.word;
      }
    });

    item = itemToReadArray
      .join(' ')
      .replace(" '", "'")
      .replace(/(_)\w+/g, '__')
      .replace('_ _', '_')
      .replace('_ _', '_')
      .replace('_ _', '_')
      .replace('_ _', '_');
    item = item
      .replace(/(_)\w+/g, 'blank ')
      .replace(/(_)\w+/g, 'blank ')
      .replace(/(_)\w+/g, 'blank ')
      .replace(/(_)\w+/g, 'blank ')
      .replace(/(_)\w+/g, 'blank ');
    item = item
      .replace(/ 's/g, "'s")
      .replace(/ 'll/g, "'ll")
      .replace(/ 've/g, "'ve")
      .replace(/ 're/g, "'re")
      .replace(/ 'd/g, "'d")
      .replace(/ 'm/g, "'m")
      .trim();
    return item;
  }
  generateSentence(item) {
    const itemToReadArray = item.map((word) => {
      return word.word;
    });

    item = itemToReadArray.join(' ');
    item = item
      .replace(/ 's/g, "'s")
      .replace(/ 'll/g, "'ll")
      .replace(/ 've/g, "'ve")
      .replace(/ 're/g, "'re")
      .replace(/ 'd/g, "'d")
      .replace(/ 'm/g, "'m");

    console.log(
      '🚀 ~ file: generate-audio.service.ts ~ line 223 ~ GenerateAudioService ~ generateSentence ~ item',
      item,
    );
    return item.trim();
  }

  generateAudio(wordId, meaning, singleProp?) {
    console.log(
      '🚀 ~ file: generate-audio.service.ts:235 ~ GenerateAudioService ~ generateAudio ~ meaning:',
      meaning,
    );
    console.log(
      '🚀 ~ file: generate-audio.service.ts:235 ~ GenerateAudioService ~ generateAudio ~ wordId:',
      wordId,
    );
    let itemsToSave;
    let urls;
    if (!singleProp) {
      itemsToSave = {
        id:
          'wordId=' +
          wordId +
          '!' +
          'meaningId=' +
          meaning.id +
          '!' +
          'date=' +
          Date.now(),
        wordAudio: (meaning.word1 + ' ' + meaning.word2).trim(),
        sentenceAudio: this.generateSentence(meaning.disassembledSentence),
        blankAudio: this.generateBlank(meaning.disassembledSentence),
        polishAudio: meaning.translation.trim(),
        childrenSentenceAudio: null,
        childrenBlankAudio: null,
      };
      if (meaning.disassembledChildrenSentence.length > 0) {
        itemsToSave.childrenSentenceAudio = this.generateSentence(
          meaning.disassembledChildrenSentence,
        );
        itemsToSave.childrenBlankAudio = this.generateBlank(
          meaning.disassembledChildrenSentence,
        );
      }
      urls = [
        meaning.polishAudio,
        meaning.wordAudio,
        meaning.sentenceAudio,
        meaning.blankAudio,
        meaning.childrenSentenceAudio,
        meaning.childrenBlankAudio,
      ];
    } else {
      itemsToSave = {
        id:
          'wordId=' +
          wordId +
          '!' +
          'meaningId=' +
          meaning.id +
          '!' +
          'date=' +
          Date.now(),
      };
      const text = this.findTextForProp(meaning, singleProp);
      itemsToSave[singleProp] = text;
      urls = [meaning[singleProp]];
    }

    this.http
      .post(
        this.url + 'saveaudiodata/',
        {
          itemsToSave: itemsToSave,
          wordId: wordId,
          meaningId: meaning.id,
          singleProp: singleProp,
          urls: urls,
          lang: meaning?.tags.some((tag) => tag.tag == 'lang:spanish')
            ? 'spanish'
            : null,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response: any) => {
        console.log('response: ', response);
        if (response.filenames) {
          response.filenames.forEach((filename) => {
            meaning[filename.property] = filename.fileName;
          });
        }
        console.log('meaning: ', meaning);
        this.generateFinished.next({ generatedWord: meaning });
      });
    console.log('itemsToSave: ', itemsToSave);
    console.log('generate audio');
  }
  findTextForProp(meaning, prop) {
    let text;
    switch (prop) {
      case 'polishAudio':
        text = meaning.translation.trim();
        break;
      case 'wordAudio':
      case 'wordAudioGB':
      case 'wordAudioES':
        text = (meaning.word1 + ' ' + meaning.word2).trim();
        break;
      case 'sentenceAudio':
      case 'sentenceAudioGB':
      case 'sentenceAudioES':
        text = this.generateSentence(meaning.disassembledSentence);
        break;
      case 'blankAudio':
      case 'blankAudioGB':
      case 'blankAudioES':
        text = this.generateBlank(meaning.disassembledSentence);
        break;
      case 'childrenSentenceAudio':
      case 'childrenSentenceAudioGB':
        text = this.generateSentence(meaning.disassembledChildrenSentence);
        break;
      case 'childrenBlankAudio':
      case 'childrenBlankAudioGB':
        text = this.generateBlank(meaning.disassembledChildrenSentence);
        break;
      case 'definitionAudio':
        text = meaning.definition;
        break;
      default:
        text = null;
    }

    return text;
  }

  generateQuestionsAudio(wordId, meaning) {
    let itemsToSave = {
      id:
        'wordId=' +
        wordId +
        '!' +
        'meaningId=' +
        meaning.id +
        '!' +
        'date=' +
        Date.now(),
      //array?
      question1Audio: meaning.qa.question1.question.trim(),
      question2Audio: meaning.qa.question1.question.trim(),
      blankAudio: this.generateBlank(meaning.disassembledSentence),
      polishAudio: meaning.translation.trim(),
      childrenSentenceAudio: null,
      childrenBlankAudio: null,
    };

    const urls = [
      meaning.polishAudio,
      meaning.wordAudio,
      meaning.sentenceAudio,
      meaning.blankAudio,
      meaning.childrenSentenceAudio,
      meaning.childrenBlankAudio,
    ];
    this.http
      .post(
        this.url + 'saveaudiodata/',
        {
          itemsToSave: itemsToSave,
          wordId: wordId,
          meaningId: meaning.id,
          urls: urls,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response: any) => {
        console.log('response: ', response);
        let meaningToSend = this.helpers.clone(meaning);
        if (response.filenames) {
          response.filenames.forEach((filename) => {
            meaningToSend[filename.property] = filename.fileName;
          });
        }
        console.log('meaning: ', meaningToSend);
        this.generateFinished.next({ generatedWord: meaningToSend });
      });
    console.log('itemsToSave: ', itemsToSave);
    console.log('generate audio');
  }
  getLatestAudiosFromRepo() {
    this.http.get(this.url + 'get-latest-audios/').subscribe(
      (response: any) => {
        console.log('response: ', response);
        this.audiosRepoList.next(response);
      },
      (error) => {
        console.log('error: ', error);
      },
    );
  }
  findAudioByText(text) {
    this.http
      .post(this.url + 'find-audio-by-text/', { text: text })
      .subscribe((response: any) => {
        console.log('response: ', response);
        this.audiosRepoList.next(response);
      });
  }

  saveNewAudioToRepo(itemId, text, lang, gender?, customVoice?, emotion?, prop?, speed?) {
    console.log("🚀 ~ GenerateAudioService ~ saveNewAudioToRepo ~ speed:", speed)
    this.http.post(this.url + 'save-new-free-audio-to-repo/', {
      itemId: itemId,
      text: text,
      gender: gender,
      customVoice: customVoice,
      lang: lang,
      emotion: emotion,
      prop: prop,
      speed: speed,
    }).subscribe((response: any) => {
      console.log('response: ', response);
      this.savedFreeAudioItem.next(response);
    }
    );
  }
  generateFinishedListener() {
    return this.generateFinished.asObservable();
  }
  audioItemUpdatedListener() {
    return this.audioItemUpdated.asObservable();
  }
  savedFreeAudioItemListener() {
    return this.savedFreeAudioItem.asObservable();
  }
  getAudiosRepoListListener() {
    return this.audiosRepoList.asObservable();
  }
}
