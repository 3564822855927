import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SignupFormComponent } from "../../public-access/signup-form/signup-form.component";
import { ConfirmationComponent } from "../../public-access/signup-form/confirmation/confirmation.component";
import { AdultsTestComponent } from "../../public-access/signup-form/adults-test/adults-test.component";
import { RouterModule } from "@angular/router";
import { CoursesComponent } from "../../courses/courses.component";
import { SharedModule } from "../../shared/shared.module";

@NgModule({
  declarations: [
    SignupFormComponent,
    ConfirmationComponent,
    AdultsTestComponent,
  ],

  imports: [
    CommonModule,
    RouterModule.forChild([{ path: "", component: SignupFormComponent }]),
    SharedModule,
  ],
  exports: [SignupFormComponent],
})
export class SignupModule {}
