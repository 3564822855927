<form [formGroup]="dataToEdit" (ngSubmit)="onSubmit()">
  <div style="overflow-y: auto; overflow-x: hidden">
    <div class="mb-0" class="mat-subheading-1">Dane Klienta</div>
    <div fxLayout="row" fxLayout.lt-md="col" fxLayoutGap="1%">
      <mat-form-field fxFlex="20" appearance="outline">
        <mat-label>Imię Klienta</mat-label>
        <input formControlName="customerFirstName" matInput />
        <mat-error>Pole wymagane!</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="20" appearance="outline">
        <mat-label>Nazwisko Klienta</mat-label>
        <input formControlName="customerLastName" matInput />
        <mat-error>Pole wymagane!</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="20" appearance="outline">
        <mat-label>Telefon Klienta</mat-label>
        <input formControlName="customerPhone" matInput />
        <mat-error>Pole wymagane!</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="20" appearance="outline">
        <mat-label>E-mail Klienta</mat-label>
        <input formControlName="customerEmail" matInput />
        <mat-error>Pole wymagane!</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="20" appearance="outline">
        <mat-label>Lokalizacja</mat-label>
        <mat-select placeholder="Wybierz..." formControlName="location">
          <mat-option value="Bydgoszcz">Bydgoszcz</mat-option>
          <mat-option value="bialeblota">Biale Blota</mat-option>
          <mat-option value="Online">Online</mat-option>
          <mat-option value="nieznana">Nieznana</mat-option>
        </mat-select>
        <mat-error>Pole wymagane!</mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <mat-form-field fxFlex="15" appearance="outline">
        <mat-label>Cena w zeszłym roku</mat-label>
        <input
          (keyup)="countStudentsPrice()"
          type="number"
          formControlName="lastYearsPrice"
          matInput
        />
      </mat-form-field>
      <mat-form-field fxFlex="15" appearance="outline">
        <mat-label>Cena wyjściowa w tym roku</mat-label>
        <input
          (keyup)="countStudentsPrice()"
          type="number"
          formControlName="thisYearsPrice"
          matInput
        />
      </mat-form-field>
      <mat-form-field fxFlex="15" appearance="outline">
        <mat-label>Podwyżka</mat-label>
        <input
          (keyup)="countStudentsPrice()"
          type="number"
          formControlName="priceAddition"
          matInput
        />
      </mat-form-field>
      <button
        (click)="countStudentsPrice(true)"
        mat-flat-button
        color="primary"
      >
        odejmij 5zł
      </button>
      <div
        fxLayoutAlign="center center"
        class="mat-h3 ml-4"
        fxLayout="row"
        fxLayoutGap="15px"
      >
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-label>Zniżka kwotowa</mat-label>
          {{ priceDeduction | number: "1.0-0" }}zł
        </div>
        <mat-form-field fxFlex="45" appearance="outline">
          <mat-label>Cena na umowie</mat-label>
          <input type="number" formControlName="contractPrice" matInput />
        </mat-form-field>
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-label>Cena miesięczna</mat-label>
          {{ finalPrice | number: "1.0-0" }}zł
        </div>
      </div>
    </div>
    <div class="mat-subheading-2">Dane Słuchacza</div>

    <div *ngIf="data.studentData.child">
      <div fxLayout="row" fxLayout.lt-md="col" fxLayoutGap="1%">
        <mat-form-field fxFlex="24" appearance="outline">
          <mat-label>Imię Dziecka</mat-label>
          <input formControlName="childFirstName" matInput />
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20" appearance="outline">
          <mat-label>Nazwisko Dziecka</mat-label>
          <input formControlName="childLastName" matInput />
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="19" appearance="outline">
          <mat-label>Klasa</mat-label>
          <mat-select formControlName="childGrade">
            <mat-option value="przedszkole">przedszkole</mat-option>
            <mat-option value="zerówka">zerówka</mat-option>
            <mat-option value="1 klasa">1 klasa</mat-option>
            <mat-option value="2 klasa">2 klasa</mat-option>
            <mat-option value="3 klasa">3 klasa</mat-option>
            <mat-option value="4 klasa">4 klasa</mat-option>
            <mat-option value="5 klasa">5 klasa</mat-option>
            <mat-option value="6 klasa">6 klasa</mat-option>
            <mat-option value="7 klasa">7 klasa</mat-option>
            <mat-option value="8 klasa">8 klasa</mat-option>
            <mat-option value="1 klasa liceum/technikum"
              >1 klasa liceum/technikum</mat-option
            >
            <mat-option value="2 klasa liceum/technikum"
              >2 klasa liceum/technikum</mat-option
            >
            <mat-option value="3 klasa liceum/technikum"
              >3 klasa liceum/technikum</mat-option
            >
            <mat-option value="4 klasa liceum/technikum"
              >4 klasa liceum/technikum</mat-option
            >
          </mat-select>
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="14" appearance="outline">
          <mat-label>Poziom Dziecka</mat-label>
          <mat-select formControlName="childLevel">
            <mat-option value="Bardzo dobrze (5-6)"
              >Bardzo dobrze (5-6)</mat-option
            >
            <mat-option value="Dobrze (4)"> Dobrze (4)</mat-option>
            <mat-option value="Średnio(2-3)">Średnio(2-3)</mat-option>
            <mat-option value="Słabiej(1)">Słabiej(1)</mat-option>
          </mat-select>
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="10" appearance="outline">
          <mat-label>Zajęcia</mat-label>
          <mat-select formControlName="childPreviousClasses">
            <mat-option value="tak">tak</mat-option>
            <mat-option value="nie">nie</mat-option>
          </mat-select>
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>
        <mat-form-field
          matTooltip="Iloć lekcji w tygodniu"
          fxFlex="10"
          appearance="outline"
        >
          <mat-label>Lekcje</mat-label>
          <input matInput type="number" formControlName="weeklyLessons" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayout.lt-md="col" fxLayoutGap="20px">
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>Informacje dodatkowe</mat-label>
          <textarea
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
            formControlName="additionalData"
            matInput
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <!-- adult -->

    <div *ngIf="!data.studentData.child">
      <div fxLayout.lt-md="col" fxLayout="row" fxLayoutGap="1%">
        <mat-form-field fxFlex="24" appearance="outline">
          <mat-label>Imię Słuchacza</mat-label>
          <input formControlName="adultFirstName" matInput />
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="24" appearance="outline">
          <mat-label>Nazwisko Słuchacza</mat-label>
          <input formControlName="adultLastName" matInput />
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="24" appearance="outline">
          <mat-label>E-mail Słuchacza</mat-label>
          <input formControlName="adultPhone" matInput />
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="25" appearance="outline">
          <mat-label>Telefon Słuchacza</mat-label>
          <input formControlName="adultEmail" matInput />
          <mat-error>Pole wymagane!</mat-error>
        </mat-form-field>
        <mat-form-field
          matTooltip="Iloć lekcji w tygodniu"
          fxFlex="10"
          appearance="outline"
        >
          <mat-label>Lekcje</mat-label>
          <input matInput type="number" formControlName="weeklyLessons" />
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="1%">
        <mat-form-field fxFlex="100" appearance="outline">
          <mat-label>Informacje dodatkowe</mat-label>
          <textarea
            formControlName="adultAdditionalData"
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <h3>Status</h3>
    <mat-button-toggle-group
      fxLayout="row wrap"
      formControlName="status"
      class="mb-2"
    >
      <mat-button-toggle value="decided">Zdecydowany</mat-button-toggle>
      <mat-button-toggle value="readyToSendSchedule"
        >Gotowy do wysłania planu</mat-button-toggle
      >
      <mat-button-toggle value="decidedProblemWithSchedule"
        >Zdecydowany, problem z planem</mat-button-toggle
      >
      <mat-button-toggle value="undecided">Niezdecydowany</mat-button-toggle>
      <mat-button-toggle value="scheduleSent">SMS Wysłany</mat-button-toggle>
      <mat-button-toggle value="problemAfterSMS"
        >Problem po SMS</mat-button-toggle
      >
      <mat-button-toggle value="confirmedAfterSMS"
        >Potwierdzenie po SMS</mat-button-toggle
      >
      <mat-button-toggle value="resigned">Nie kontynuuje</mat-button-toggle>
      <mat-button-toggle value="callAgain"
        >Nie udało się dodzwonić</mat-button-toggle
      >
      <mat-button-toggle value="willComeBackLater"
        >Wróci później</mat-button-toggle
      >
      <mat-button-toggle value="contractSigned">Jest umowa</mat-button-toggle>
      <mat-button-toggle value="didNotShow">Nie zjawił się</mat-button-toggle>
      <mat-button-toggle value="movedToCustomersWithContract"
        >przepisany z umową</mat-button-toggle
      >
      <mat-button-toggle value="movedToCustomersWithoutContract"
        >przepisany bez umowy</mat-button-toggle
      >
    </mat-button-toggle-group>

    <h3>Grupy</h3>
    <h4 style="font-weight: bold">
      Powinien być w {{ data.studentData.weeklyLessons }}
      {{ data.studentData.weeklyLessons > 1 ? "grupach" : "grupie" }}
    </h4>
    <h4>Przypisane grupy:</h4>
    <div fxLayout="row" style="width: 100%">
      <div
        fxFlex
        *ngFor="let group of data.studentData.tempGroups"
        class="p-2 m-2"
        style="border: 3px solid #5e35b1; border-radius: 5px"
      >
        <!-- <button
          mat-stroked-button
          color="warn"
          (click)="removeGroupFromStudent(data?.studentData, group)"
        >
          Usuń grupę uczniowi
        </button> -->
        <div fxLayout="row">
          <span style="font-weight: bold">
            {{ group.title }}
          </span>
          <span style="width: 5px"></span>
          <span>|</span>
          <span style="width: 5px"></span>
          <span> {{ group.meta.teacher.name + " | " }} &nbsp; </span>

          <span>
            {{
              data?.studentData.childGrade
                ? " " + data?.studentData?.childGrade
                : data?.studentData.adultFirstName
                ? "Dorosły"
                : "brak"
            }}
          </span>
          <span style="width: 5px"></span>
          <span>|</span>
          <span style="width: 5px"></span>
        </div>
        <br />
        <span class="ml-1 mt-3">
          {{
            group.meta.location == "bialeblota"
              ? "Białe Błota"
              : group.meta.location
          }}
        </span>
        <hr />
        <div fxLayout="row">
          <span
            >{{ group.start | date: "EEEE, HH:mm" }} -
            {{ group.end | date: "HH:mm" }}</span
          >
        </div>
      </div>
    </div>
    <h5>Treść SMS:</h5>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div *ngIf="data?.studentData?.childFirstName">
        Dzień dobry, informujemy, że zgodnie z terminami, które Państwo podali
        {{ data?.studentData?.childFirstName }} będzie mieć zajęcia z
        angielskiego w następujące dni:
        {{ data?.studentData?.tempGroups[0]?.start | date: "EEEE o HH:mm" }}
        {{
          data.studentData.tempGroups[0]?.meta?.studentsIds?.length === 1
            ? "(indywidualnie)"
            : ""
        }}
        {{ data?.studentData?.tempGroups[1] ? "i" : "" }}
        {{
          data.studentData.tempGroups[1]?.start | date: "EEEE o HH:mm" : null
        }}
        {{
          data.studentData.tempGroups[1]?.meta?.studentsIds?.length === 1
            ? "(indywidualnie)"
            : ""
        }}
        {{ data?.studentData?.tempGroups[2] ? "oraz" : "" }}
        {{
          data.studentData.tempGroups[2]?.start | date: "EEEE o HH:mm" : null
        }}
        {{
          data?.studentData?.tempGroups[2]?.meta?.studentsIds?.length === 1
            ? "(indywidualnie)"
            : ""
        }}
        {{ data?.studentData?.tempGroups[3] ? "oraz" : "" }}
        {{
          data.studentData?.tempGroups[3]?.start | date: "EEEE o HH:mm" : null
        }}
        {{
          data.studentData?.tempGroups[3]?.meta?.studentsIds?.length === 1
            ? "(indywidualnie)"
            : ""
        }}. Zajęcia rozpoczynają się od najbliższego tygodnia, wtedy prześlemy
        tez link do zawarcia umowy online. Prosimy o potwierdzenie odebrania
        wiadomości. Pozdrawiamy serdecznie, Ling King
      </div>
      <button
        (click)="
          $event.preventDefault(); $event.stopPropagation(); constructMessage()
        "
        mat-icon-button
        color="primary"
        matTooltip="Skopiuj treść SMS"
      >
        <mat-icon>content_copy</mat-icon>
      </button>
      <div *ngIf="!data?.studentData?.childFirstName">
        Zadzwoń do osoby dorosłej i poinformuj o terminach telefonicznie.
      </div>
    </div>
    <hr />
    <h3>Dostępność</h3>
    <hr />
    <!-- <button (click)="openDateTimePicker()" mat-flat-button color="primary">
      Dodaj dostępność
    </button> -->
    <div fxLayout="column" fxLayoutAlign="start start">
      <mat-button-toggle-group
        class="mb-2"
        (change)="selectedDay = $event.value"
        (keydown.enter)="addAvalibility()"
      >
        <mat-button-toggle value="2022-09-05T">Poniedziałek</mat-button-toggle>
        <mat-button-toggle value="2022-09-06T">Wtorek</mat-button-toggle>
        <mat-button-toggle value="2022-09-07T">Środa</mat-button-toggle>
        <mat-button-toggle value="2022-09-08T">Czwartek</mat-button-toggle>
        <mat-button-toggle value="2022-09-09T">Piątek</mat-button-toggle>
        <mat-button-toggle value="2022-09-10T">Sobota</mat-button-toggle>
      </mat-button-toggle-group>
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-form-field fxFlex="50" appearance="outline">
          <mat-label>Godzina początek</mat-label>
          <input
            type="time"
            (input)="enteredStartTime = $event.target.value"
            (keydown.enter)="addAvalibility(); $event.preventDefault()"
            matInput
          />
        </mat-form-field>
        <mat-form-field fxFlex="50" appearance="outline">
          <mat-label>Godzina koniec</mat-label>
          <input
            type="time"
            (change)="enteredEndTime = $event.target.value"
            matInput
            (keydown.enter)="addAvalibility(); $event.preventDefault()"
          />
        </mat-form-field>
        <button
          class="mb-3"
          mat-icon-button
          color="primary"
          (click)="addAvalibility(); $event.preventDefault()"
        >
          <mat-icon class="mat-18">add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
    <button (click)="setAdult()" mat-button>adult</button>
    <div
      *ngFor="let aval of studentsAvalibility"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <div style="width: 30vw">
        <span>{{ aval.start | date: "EEEE H:mm " }} - </span>
        <span> {{ aval.end | date: "H:mm " }} </span>
      </div>

      <button
        color="warn"
        mat-icon-button
        (click)="removeStudentsAvalibility(aval.id)"
      >
        <mat-icon>remove_circle_outline</mat-icon>
      </button>
    </div>
    <button
      class="ml-2"
      (click)="checkStudentAvailability()"
      mat-flat-button
      color="primary"
    >
      Sprawdź dostępność
    </button>
  </div>
  <div fxLayoutAlign="end" mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>anuluj</button>
    <button mat-raised-button color="primary" type="submit">Zapisz</button>
  </div>
</form>
