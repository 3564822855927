<div class="d-flex flex-column w-100 h-100">
  <div class="d-flex flex-row w-100 h-100">
    <div class="d-flex flex-column">
      <input type="number" name="" [(ngModel)]="selectedPostType" id="" />
      <div style="flex: 1"></div>
      <button mat-raised-button (click)="download()">download post</button>
      <div style="flex: 1"></div>
      <button mat-raised-button mat-dialog-close color="primary">close</button>
    </div>
    <div class="w-100 h-100">
      <div style="overflow: auto" class="w-100 h-100">
        <app-fact-post-1
          style="overflow-y: auto"
          [downloadPost]="downloadPost"
          [fact]="data?.item?.item"
          *ngIf="selectedPostType == 1"
        ></app-fact-post-1>
        <app-fact-post-2 *ngIf="selectedPostType == 2"></app-fact-post-2>
      </div>
    </div>
  </div>
</div>
