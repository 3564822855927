
<div    style="width:90vw; height:90vh">
<div #area style="width:90%; height:90%">

  <mat-card style="position:absolute; margin-left:5%; margin-top:1%; opacity:0.9; width: 90%; height:90%">


    <div  style="opacity:1 "  fxLayout="column">
      <div #area    fxLayout="row">
        <div  fxLayout="column">

          <div *ngFor="let player of multiPlayer?.players">
            <div *ngIf="player.display || player.collaborative">


              <!-- <mat-progress-bar mode="buffer"></mat-progress-bar> -->
              <span cdkDragDisabled [cdkDragFreeDragPosition]="{x:20, y: player?.currentPoint?.position?.y }"  cdkDrag style=" position:absolute; z-index:2; font-size:1.3em; font-weight: bolder;">
                {{player.name}}  </span>

                <img   (@go.done)="animationEnd($event, player?.id)"    [@go]="{value: player?.animationState, params: {positionX: player?.currentPoint?.position?.x -40, positionY: player?.currentPoint?.position?.y -40}}"
                [src]="player.avatar" [cdkDragFreeDragPosition]="player.currentPoint?.position"  cdkDrag style="width: 80px;height: 80px;  position:absolute; z-index:2; ">

                <img cdkDragDisabled
                [src]="player.prize" [cdkDragFreeDragPosition]="{x:width - 60, y: player?.currentPoint?.position?.y - 20}" cdkDrag style="width: 50px;height: 50px;  position:absolute; z-index:2; ">
                <div cdkDragDisabled [cdkDragFreeDragPosition]="point.position" cdkDrag *ngFor="let point of player.path" style="width: 10px;height: 10px; background-color: black; position:absolute;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </mat-card>
  </div>
  </div>
