import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { HelpersService } from "../../services/helpers.service";

@Component({
  selector: "app-choose-avatar",
  templateUrl: "./choose-avatar.component.html",
  styleUrls: ["./choose-avatar.component.css"],
})
export class ChooseAvatarComponent implements OnInit {
  @Output() avatarChosen = new EventEmitter();
  avatarsCategory = 1;
  categorySize = 8;
  categorizedAvatars = [];
  avatars = [
    "assets/avatars/animals-in-clothes/b1.png",
    "assets/avatars/animals-in-clothes/b2.png",
    "assets/avatars/animals-in-clothes/b3.png",
    "assets/avatars/animals-in-clothes/b4.png",
    "assets/avatars/animals-in-clothes/b5.png",
    "assets/avatars/animals-in-clothes/b6.png",
    "assets/avatars/animals-in-clothes/b7.png",
    "assets/avatars/animals-in-clothes/b8.png",
    "assets/avatars/monsters/b1.png",
    "assets/avatars/monsters/b2.png",
    "assets/avatars/monsters/b3.png",
    "assets/avatars/monsters/b4.png",
    "assets/avatars/monsters/b5.png",
    "assets/avatars/monsters/b6.png",
    "assets/avatars/monsters/b7.png",
    "assets/avatars/monsters/b8.png",
    "assets/avatars/oldschool_wizard/b1.png",
    "assets/avatars/oldschool_wizard/b2.png",
    "assets/avatars/oldschool_wizard/b3.png",
    "assets/avatars/oldschool_wizard/b4.png",
    "assets/avatars/oldschool_wizard/b5.png",
    "assets/avatars/oldschool_wizard/b6.png",
    "assets/avatars/oldschool_wizard/b7.png",
    "assets/avatars/oldschool_wizard/b8.png",
    "assets/avatars/cactus-succulents/b1.png",
    "assets/avatars/cactus-succulents/b2.png",
    "assets/avatars/cactus-succulents/b3.png",
    "assets/avatars/cactus-succulents/b4.png",
    "assets/avatars/cactus-succulents/b5.png",
    "assets/avatars/cactus-succulents/b6.png",
    "assets/avatars/cactus-succulents/b7.png",
    "assets/avatars/cactus-succulents/b8.png",
    "assets/avatars/pug_in_disguise/b1.png",
    "assets/avatars/pug_in_disguise/b2.png",
    "assets/avatars/pug_in_disguise/b3.png",
    "assets/avatars/pug_in_disguise/b4.png",
    "assets/avatars/pug_in_disguise/b5.png",
    "assets/avatars/pug_in_disguise/b6.png",
    "assets/avatars/pug_in_disguise/b7.png",
    "assets/avatars/pug_in_disguise/b8.png",
    "assets/avatars/unicorns_in_disguise/b1.png",
    "assets/avatars/unicorns_in_disguise/b2.png",
    "assets/avatars/unicorns_in_disguise/b3.png",
    "assets/avatars/unicorns_in_disguise/b4.png",
    "assets/avatars/unicorns_in_disguise/b5.png",
    "assets/avatars/unicorns_in_disguise/b6.png",
    "assets/avatars/unicorns_in_disguise/b7.png",
    "assets/avatars/unicorns_in_disguise/b8.png",
    "assets/avatars/animals-in-clothes/p1.png",
    "assets/avatars/animals-in-clothes/p2.png",
    "assets/avatars/animals-in-clothes/p3.png",
    "assets/avatars/animals-in-clothes/p4.png",
    "assets/avatars/animals-in-clothes/p5.png",
    "assets/avatars/animals-in-clothes/p6.png",
    "assets/avatars/animals-in-clothes/p7.png",
    "assets/avatars/animals-in-clothes/p8.png",
    "assets/avatars/monsters/p1.png",
    "assets/avatars/monsters/p2.png",
    "assets/avatars/monsters/p3.png",
    "assets/avatars/monsters/p4.png",
    "assets/avatars/monsters/p5.png",
    "assets/avatars/monsters/p6.png",
    "assets/avatars/monsters/p7.png",
    "assets/avatars/monsters/p8.png",
    "assets/avatars/oldschool_wizard/p1.png",
    "assets/avatars/oldschool_wizard/p2.png",
    "assets/avatars/oldschool_wizard/p3.png",
    "assets/avatars/oldschool_wizard/p4.png",
    "assets/avatars/oldschool_wizard/p5.png",
    "assets/avatars/oldschool_wizard/p6.png",
    "assets/avatars/oldschool_wizard/p7.png",
    "assets/avatars/oldschool_wizard/p8.png",
    "assets/avatars/cactus-succulents/p1.png",
    "assets/avatars/cactus-succulents/p2.png",
    "assets/avatars/cactus-succulents/p3.png",
    "assets/avatars/cactus-succulents/p4.png",
    "assets/avatars/cactus-succulents/p5.png",
    "assets/avatars/cactus-succulents/p6.png",
    "assets/avatars/cactus-succulents/p7.png",
    "assets/avatars/cactus-succulents/p8.png",
    "assets/avatars/pug_in_disguise/p1.png",
    "assets/avatars/pug_in_disguise/p2.png",
    "assets/avatars/pug_in_disguise/p3.png",
    "assets/avatars/pug_in_disguise/p4.png",
    "assets/avatars/pug_in_disguise/p5.png",
    "assets/avatars/pug_in_disguise/p6.png",
    "assets/avatars/pug_in_disguise/p7.png",
    "assets/avatars/pug_in_disguise/p8.png",
    "assets/avatars/unicorns_in_disguise/p1.png",
    "assets/avatars/unicorns_in_disguise/p2.png",
    "assets/avatars/unicorns_in_disguise/p3.png",
    "assets/avatars/unicorns_in_disguise/p4.png",
    "assets/avatars/unicorns_in_disguise/p5.png",
    "assets/avatars/unicorns_in_disguise/p6.png",
    "assets/avatars/unicorns_in_disguise/p7.png",
    "assets/avatars/unicorns_in_disguise/p8.png",
  ];
  chosenPlayerAvatar: any;
  constructor(
    public dialogRef: MatDialogRef<ChooseAvatarComponent>,
    private helpers: HelpersService
  ) {}

  ngOnInit(): void {
    this.updateAvatarsCategory();
  }
  nextCategory() {
    if (this.avatarsCategory < this.avatars.length / this.categorySize) {
      this.avatarsCategory = this.avatarsCategory + 1;
    }
    this.updateAvatarsCategory();
  }
  previousCategory() {
    if (this.avatarsCategory > 1) {
      this.avatarsCategory = this.avatarsCategory - 1;
      this.updateAvatarsCategory();
    }
  }
  radioChecked(event) {
    this.chosenPlayerAvatar = event.value;
    this.avatarChosen.emit(this.chosenPlayerAvatar);
    this.dialogRef.close(this.chosenPlayerAvatar);
  }
  updateAvatarsCategory() {
    this.categorizedAvatars = [];
    this.categorizedAvatars = this.avatars.slice(
      (this.avatarsCategory - 1) * this.categorySize,
      this.avatarsCategory * this.categorySize
    );
  }
  chooseRandomAvatar() {
    this.chosenPlayerAvatar = this.helpers.getRandomItemFromArray(
      this.avatars,
      false
    );
  }
}
