import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Pdf2Service } from 'src/app/pdf2.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { PdfService } from '../pdf.service';
import { Pdf2ExercisesService } from '../../pdf2/pdf2-exercises.service';
import { SendEmailComponent } from 'src/app/groups/send-email/send-email.component';
import { FilesToSendService } from 'src/app/shared/services/files-to-send.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.css'],
})
export class CreateComponent implements OnInit {
  numberOfItems = 10;
  requestType: 'open' | 'saveFileToSend';
  selectedPdfType;
  selectedPage;
  fullPage = false;
  currentItemsPack = 1;
  itemsPacksOptions = [];
  selectedTask = {
    name: '',
    setId: '',
    itemsPack: null,
    numberOfItems: 10,
    descriptionPl: '',
    displayName: '',
    setName: '',
    color: '',
    inOrder: false,
  };
  pages = [];
  numbersOfItems = [5, 10, 16, 20, 25, 30];
  creatingPdf = false;
  landscape = false;
  childrenPdfTypes;
  sentencesPdfTypes;
  freeSetPdfTypes;
  childrenMode: any;
  glossary: any;
  mixAllItems = false;
  sentencesAndFreePdfTypes = []
  constructor(
    private dialog: MatDialog,
    public filesToSendService: FilesToSendService,
    public dialogRef: MatDialogRef<CreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public pdf2Service: Pdf2Service,
    private helpers: HelpersService,
    private ref: ChangeDetectorRef,
    private exercisesService: Pdf2ExercisesService,
    public userDetailsService: UserDetailsService
  ) { }

  ngOnInit(): void {
    this.childrenMode = this.data?.set?.children;
    this.childrenPdfTypes = this.exercisesService.childrenPdfTypes;
    this.freeSetPdfTypes = this.exercisesService.freeSetPdfTypes
    this.sentencesPdfTypes = this.exercisesService.sentencesPdfTypes;
    this.sentencesAndFreePdfTypes = this.freeSetPdfTypes.concat(this.sentencesPdfTypes)
    console.log('data', this.data);
    if (this.data.avalibleOptions) {
      if (this.childrenMode) {
        this.glossary = true;
      }
      this.childrenPdfTypes = this.childrenPdfTypes.filter((type) =>
        this.data.avalibleOptions.some(
          (option) =>
            option.homeworkData.name == type.name ||
            type.name == 'children-dictionary'
        )
      );
      this.freeSetPdfTypes = this.freeSetPdfTypes.filter((type) =>
        this.data.avalibleOptions.some(
          (option) => option.homeworkData.name == type.name
        )
      );
      this.sentencesPdfTypes = this.sentencesPdfTypes.filter((type) =>
        this.data.avalibleOptions.some(
          (option) => option.homeworkData.name == type.name
        )
      );
    }
    this.addPage();
    console.log(
      '🚀 ~ file: create.component.ts ~ line 76 ~ CreateComponent ~ ngOnInit ~ this.data',
      this.data
    );
    if (!this.data.fromSets) {
      this.data.fromSets = [{ name: this.data.set.name, id: this.data.set.id, type: this.data.set.type }];
    }
  }

  generatePdf2() {
    if (this.childrenMode) {
      this.pages.forEach((page) => {
        page.pageToSend.forEach((task) => {
          task.numberOfItems = 12;
        });
      });
    }
    this.pdf2Service.generatePdf(
      this.data.set.type == 'free' ? 'true' : 'false',
      this.data.set.children ? 'children-set' : 'normal-set',
      this.pages,
      this.mixAllItems,
      null,
      this.data?.group,
      this.landscape ? 'landscape' : 'portrait',
      this.fullPage

    );
  }
  checkOptions(type) {
    return this.data?.avalibleOptions?.some(option => option.homeworkData.name == type.name)
  }
  numberOfItemsChanged(event) {
    console.log(
      '🚀 ~ file: create.component.ts ~ line 83 ~ CreateComponent ~ numberOfItemsChanged ~ event',
      event
    );
    this.data.fromSets;
    console.log(
      '🚀 ~ file: create.component.ts ~ line 84 ~ CreateComponent ~ numberOfItemsChanged ~ this.data.fromSets',
      this.data
    );
    this.calculateNumberOfPackages(event.value);
  }
  calculateNumberOfPackages(numberOfItemsPerTask) {
    this.numberOfItems = 10;
    this.pages = [];
    let result = 0;
    result = Math.ceil(this.data.set.items.length / numberOfItemsPerTask);
    if (this.helpers.isEven(!result)) {
      result++;
    }
    console.log(
      '🚀 ~ file: create.component.ts ~ line 97 ~ CreateComponent ~ calculateNumberOfPackages ~ result',
      result
    );
    for (let index = 0; index < result; index++) {
      this.itemsPacksOptions.push(index);
      this.addPage();
    }
    if (!this.helpers.isEven(result)) {
      this.pages.splice(this.pages.length - 1, 1);
    }
  }
  openSendEmailDialog() {
    const dialogRef = this.dialog.open(SendEmailComponent, {
      width: '100vw',
      height: '100vh',
      minWidth: '100vw',
      minHeight: '100vh',
      panelClass: 'full-screen-dialog',
      data: { group: this.data.group, fromHomeworkPanel: true },
      disableClose: true,
    });
    this.dialogRef.close();
  }
  addChildrenDictionary() {
    this.addPage();
    this.selectPage(this.pages[this.pages.length - 1]);
    this.selectedTask = this.childrenPdfTypes.find(
      (type) => type.name === 'children-dictionary'
    );
    if (this.childrenMode && this.data.fromSets) {
      if (this.selectedTask) {
        this.selectedTask.setName = this.data.fromSets[0].name;
        this.selectedTask.itemsPack = 1;
      }
    }

    this.addTaskToPage();
  }
  autoFillTasks() {
    const setsIds = this.data.fromSets.map((set) => {
      return set.id;
    });
    if (!this.selectedTask.itemsPack) {
      this.selectedTask.itemsPack = -1;
    }

    let connectedTask = this.helpers.clone(this.selectedTask);
    if (!this.selectedTask.displayName.includes('printable')) {
      if (
        this.selectedTask.name == 'sentences-translate-fill-gaps-random-letters'
      ) {
        connectedTask.name = 'sentences-fill-gaps-no-clues';
        connectedTask.displayName =
          'Uzupełnij luki w zdaniach z losowymi literami';
        this.selectedTask.displayName = 'Przetłumacz i dopasuj';
      } else {
        connectedTask.name = 'sentences-fill-gaps-no-clues';
        connectedTask.displayName = 'Uzupełnij luki w zdaniach';
        this.selectedTask.displayName = 'Przetłumacz i dopasuj';
      }
    }
    this.pages.forEach((page, index) => {
      if (this.mixAllItems) {
        if (setsIds[index]) {
          this.selectedTask.setId = setsIds[index];
          connectedTask.setId = setsIds[index];
        } else {
          this.selectedTask.setId = setsIds[0];

          connectedTask.setId = setsIds[0];
        }
      }
      if (this.selectedTask.displayName.includes('printable')) {
        if (page.pageToSend.length == 0) {
          this.selectedTask.itemsPack = this.selectedTask.itemsPack + 1;
          page.pageToSend.push(this.helpers.clone(this.selectedTask));
        }
      } else {
        if (this.helpers.isEven(index)) {
          connectedTask.itemsPack = connectedTask.itemsPack + 1;
          this.selectedTask.itemsPack = this.selectedTask.itemsPack + 1;
          page.pageToSend.push(this.helpers.clone(this.selectedTask));

          this.selectedTask.itemsPack = this.selectedTask.itemsPack + 1;
          page.pageToSend.push(this.helpers.clone(this.selectedTask));
        } else {
          page.pageToSend.push(this.helpers.clone(connectedTask));
          connectedTask.itemsPack = connectedTask.itemsPack + 1;
          page.pageToSend.push(this.helpers.clone(connectedTask));
        }
      }
    });
    this.ref.detectChanges();
  }
  mageGapsWithAnswers() {
    const setsIds = this.data.fromSets.map((set) => {
      return set.id;
    });
    if (!this.selectedTask.itemsPack) {
      this.selectedTask.itemsPack = -1;
    }
    let connectedTask = this.helpers.clone(this.selectedTask);
    connectedTask.name = 'answers-gaps';
    connectedTask.displayName = 'Odpowiedzi';
    this.selectedTask.displayName = 'Uzupełnij luki';
    this.pages.forEach((page, index) => {
      if (this.mixAllItems) {
        if (setsIds[index]) {
          this.selectedTask.setId = setsIds[index];
          connectedTask.setId = setsIds[index];
        } else {
          this.selectedTask.setId = setsIds[0];

          connectedTask.setId = setsIds[0];
        }
      } else {
        this.selectedTask.setId = setsIds[0];

        connectedTask.setId = setsIds[0];
      }
      if (this.helpers.isEven(index)) {
        connectedTask.itemsPack = connectedTask.itemsPack + 1;
        this.selectedTask.itemsPack = this.selectedTask.itemsPack + 1;
        page.pageToSend.push(this.helpers.clone(this.selectedTask));
        this.selectedTask.itemsPack = this.selectedTask.itemsPack + 1;
        page.pageToSend.push(this.helpers.clone(this.selectedTask));
      } else {
        page.pageToSend.push(this.helpers.clone(connectedTask));
        connectedTask.itemsPack = connectedTask.itemsPack + 1;
        page.pageToSend.push(this.helpers.clone(connectedTask));
      }
    });
    this.ref.detectChanges();
  }

  selectPdfType(type, set) {

    if (!type.preset) {

      this.selectedTask.name = type.name;
      if (type.numberOfItems) {
        this.selectedTask.numberOfItems = type.numberOfItems;
      } else {
        this.selectedTask.numberOfItems = 10;
      }
      if (type.allItems) {
        this.selectedTask.numberOfItems = this.data.set.items.length + 1;
      }
      this.selectedTask.displayName = type.displayName;
      this.selectedTask.setId = set.id;
      this.selectedTask.inOrder = type.inOrder;
      this.selectedTask.setName = set.name;
      this.selectedTask.itemsPack = null
      this.addTaskToPage();
    } else {
      switch (type.preset) {

        case 'sentences-picture-dictionary+sentences-text-make-sentences-with-translations':
          if (this.pages.length == 0) {
            this.addPage();
          }
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-picture-dictionary';
          this.selectedTask.displayName = 'Słowniczek obrazkowy';
          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.selectedTask.itemsPack = 1
          this.selectedTask.inOrder = false;

          this.selectedTask.numberOfItems = 32
          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-text-make-sentences-with-translations';
          this.selectedTask.displayName = 'Ułoz zdania z tłumaczeniami';
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = false;
          this.selectedTask.itemsPack = 1
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 9
          this.addTaskToPage();

          break
        case 'freeset-business-p2':
          if (this.pages.length == 0) {
            this.addPage();
          }
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-text-fill-gaps';
          this.selectedTask.displayName = 'Tekst z lukami';
          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 1
          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-sentences-dictionary2';
          this.selectedTask.displayName = 'Słowniczek';
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = true;
          this.selectedTask.itemsPack = null
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 30
          this.addTaskToPage();

          break
        case 'text+3Times+freeset-sentences-dictionary3':
          if (this.pages.length == 0) {
            this.addPage();
          }
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-text-fill-gaps';
          this.selectedTask.displayName = 'Uzupełnij luki w tekście';
          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 1
          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-sentences-dictionary3';
          this.selectedTask.displayName = 'Pytania (1-10)';
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = true;
          this.selectedTask.itemsPack = 1
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 10

          this.addTaskToPage();
          this.selectedTask.name = 'freeset-sentences-dictionary3';
          this.selectedTask.displayName = 'Pytania (11-20)';
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = true;
          this.selectedTask.itemsPack = 2
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 10
          this.addTaskToPage();
          this.selectedTask.name = 'freeset-sentences-dictionary3';
          this.selectedTask.displayName = 'Pytania (21-30)';
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = true;
          this.selectedTask.itemsPack = 3
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 10
          this.addTaskToPage();

          break
        case 'freeset-text-3':
          if (this.pages.length == 0) {
            this.addPage();
          }
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-text-fill-gaps';
          this.selectedTask.displayName = 'Tekst z lukami';
          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.selectedTask.inOrder = true;
          this.selectedTask.numberOfItems = 1
          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-fill-gaps-sentences-no-clues';
          this.selectedTask.displayName = 'Uzupełnij zdania';
          this.selectedTask.descriptionPl = 'wefsafa'
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = true;
          this.selectedTask.itemsPack = 1
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 10
          this.addTaskToPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-fill-gaps-sentences-no-clues';
          this.selectedTask.displayName = 'Uzupełnij zdania';
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = true;
          this.selectedTask.itemsPack = 2
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 10
          this.addTaskToPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-fill-gaps-sentences-no-clues';
          this.selectedTask.displayName = 'Uzupełnij zdania';
          this.selectedTask.setId = set.id;
          this.selectedTask.inOrder = true;
          this.selectedTask.itemsPack = 3
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 10
          this.addTaskToPage();


          break
        case 'picture-write-answer+answers-gaps':

          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'picture-write-answer';

          this.selectedTask.displayName = 'Tekst z lukami';
          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = 1
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 20
          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'answers-gaps';
          this.selectedTask.displayName = 'Odpowiedzi';
          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = 1
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 20
          this.addTaskToPage();

          break

        case 'sentences-translate-mixed+sentences-fill-gaps-no-clues-single-page-write-sntences-50%':
          if (this.pages.length == 0) {

            this.addPage();
          }

          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-picture-dictionary-single';
          this.selectedTask.displayName = 'Słowniczek obrazkowy';

          this.selectedTask.numberOfItems = 16;


          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          this.selectedTask.name = 'sentences-fill-gaps-no-clues';
          this.selectedTask.displayName = 'Utwórz zdania';


          this.selectedTask.numberOfItems = 16;

          this.selectedTask.displayName = 'Uzupełnij luki w zdaniach';
          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-text-write-sentences-in-badges-from-translations-clues-50';
          this.selectedTask.displayName = 'Napisz zdania z tłumaczeń 1/2 uzupełnione';
          this.selectedTask.numberOfItems = 8;
          this.numberOfItems = 8;
          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.selectedTask.itemsPack = null
          this.addTaskToPage();
          break
        case 'freeset-lesson-text-with-exercises-p1+free-lesson-text-with-exercises-p2':
          if (this.pages.length == 0) {
            this.addPage();
          }
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-lesson-text-with-exercises';
          this.selectedTask.displayName = 'Lekcja - tekst z ćwiczeniami p1';
          this.selectedTask.setId = set.id;
          this.currentItemsPack = -1
          this.selectedTask.itemsPack = -1
          this.selectedTask.inOrder = true;
          this.selectedTask.setName = set.name;
          this.selectedTask.numberOfItems = 1;
          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'freeset-lesson-text-with-exercises-p2';
          this.selectedTask.displayName = 'Lekcja - tekst z ćwiczeniami p2';
          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.selectedTask.itemsPack = 0
          this.selectedTask.inOrder = true;

          this.selectedTask.numberOfItems = 20;
          this.addTaskToPage();
          this.landscape = true;
          this.fullPage = true;
          break;
        case 'sentences-picture-dictionary+sentences-fill-gaps-no-clues-single-page':
          if (this.pages.length == 0) {
            this.addPage();
          }

          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-picture-dictionary-single';
          this.selectedTask.displayName = 'Słowniczek obrazkowy';

          this.selectedTask.numberOfItems = 16;


          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          this.selectedTask.name = 'sentences-fill-gaps-no-clues';
          this.selectedTask.displayName = 'Utwórz zdania';


          this.selectedTask.numberOfItems = 16;

          this.selectedTask.displayName = 'Uzupełnij luki w zdaniach';
          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          break;
        case 'sentences-translate-mixed+sentences-fill-gaps-no-clues-single-page':
          this.addPage();

          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-translate-mixed';
          this.selectedTask.displayName = 'Przetłumacz i dopasuj';
          if (type.numberOfItems) {
            console.log("🚀 ~ CreateComponent ~ selectPdfType ~ type.numberOfItems:", type.numberOfItems)
            this.selectedTask.numberOfItems = type.numberOfItems;
          } else {
            this.selectedTask.numberOfItems = 10;
          }

          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          this.selectedTask.name = 'sentences-fill-gaps-no-clues';
          this.selectedTask.displayName = 'Utwórz zdania';

          if (type.numberOfItems) {
            this.selectedTask.numberOfItems = type.numberOfItems;
          } else {
            this.selectedTask.numberOfItems = 10;
          }
          this.selectedTask.displayName = 'Usupełnij luki w zdaniach';
          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          break;
        case 'sentences-translate-fill-gaps-all-items':
          this.mixAllItems = false;
          this.selectedTask.name = 'sentences-translate-mixed';
          this.selectedTask.displayName =
            'Przetłumacz i dopasuj / utwórz zdania';

          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.calculateNumberOfPackages(10);
          setTimeout(() => {
            this.autoFillTasks();
          }, 500);
          break;
        case 'sentences-translate-mixed+sentences-fill-gaps-no-clues':
          this.addPage();

          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-translate-mixed';
          this.selectedTask.displayName = 'Przetłumacz i dopasuj';
          if (type.numberOfItems) {
            console.log("🚀 ~ CreateComponent ~ selectPdfType ~ type.numberOfItems:", type.numberOfItems)
            this.selectedTask.numberOfItems = type.numberOfItems;
          } else {
            this.selectedTask.numberOfItems = 10;
          }

          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          this.addPage();

          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.name = 'sentences-fill-gaps-no-clues';
          this.selectedTask.displayName = 'Utwórz zdania';

          if (type.numberOfItems) {
            this.selectedTask.numberOfItems = type.numberOfItems;
          } else {
            this.selectedTask.numberOfItems = 10;
          }
          this.selectedTask.displayName = type.displayName;
          this.selectedTask.setId = set.id;
          this.selectedTask.itemsPack = this.currentItemsPack;
          this.selectedTask.setName = set.name;
          this.addTaskToPage();
          this.currentItemsPack++;
          break;
        case 'sentences-translate-fill-gaps-all-items':
          this.mixAllItems = false;
          this.selectedTask.name = 'sentences-translate-mixed';
          this.selectedTask.displayName =
            'Przetłumacz i dopasuj / utwórz zdania';

          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.calculateNumberOfPackages(10);
          setTimeout(() => {
            this.autoFillTasks();
          }, 500);

          break;
        case 'sentences-translate-mixed+sentences-fill-gaps-no-clues-random-letters':
          this.mixAllItems = false;
          this.selectedTask.name = 'sentences-translate-mixed-random-letters';
          this.selectedTask.displayName =
            'Przetłumacz i dopasuj / utwórz zdania z losowymi literami';

          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.calculateNumberOfPackages(10);
          setTimeout(() => {
            this.autoFillTasks();
          }, 500);

          break;
        case 'sentences-flashcards-all-items':
          if (type.numberOfItems) {
            this.selectedTask.numberOfItems = type.numberOfItems;
          } else {
            this.selectedTask.numberOfItems = 10;
          }
          this.mixAllItems = false;
          this.selectedTask.name = 'sentences-flashcards-all-items';
          this.selectedTask.displayName = 'Flashcards - printable';

          this.selectedTask.setId = set.id;
          this.selectedTask.setName = set.name;
          this.calculateNumberOfPackages(12);
          setTimeout(() => {
            this.autoFillTasks();
          }, 500);

          break;
        case 'sentences-translate-fill-gaps-all-items-all-sets':
          this.mixAllItems = true;
          this.selectedTask.displayName =
            'Przetłumacz i dopasuj / utwórz zdania';

          this.selectedTask.name = 'sentences-translate-mixed';
          this.selectedTask.setName = 'Pomieszane zestawy';
          this.calculateNumberOfPackages(10);

          this.autoFillTasks();

          break;
        case 'fill-gaps-answers-no-clues':
          if (type.forGaps) {
            this.selectedTask.name = 'freeset-fill-gaps-sentences-no-clues';
          }
          if (!type.forGaps) {
            this.selectedTask.name = 'freeset-no-gaps-translate-answer';
          }
          this.mixAllItems = false;
          this.selectedTask.displayName = 'Uzupełnij luki';

          this.selectedTask.name = 'freeset-fill-gaps-sentences-no-clues';
          this.selectedTask.setName = 'Pomieszane zestawy';
          this.calculateNumberOfPackages(10);

          this.mageGapsWithAnswers();

          break;
        case 'freeset-story-text':
          this.selectedTask.displayName = 'Historia - tekst';
          this.selectedTask.setName = set.name;
          this.selectedTask.setId = set.id;
          this.selectedTask.name = 'freeset-story-text';
          this.selectedTask.setName = set.name;
          this.selectedTask.inOrder = true;

          this.addTaskToPage();
          this.addPage();
          this.selectPage(this.pages[this.pages.length - 1]);
          this.selectedTask.displayName = 'Historia - ślimak';
          this.selectedTask.setName = set.name;
          this.selectedTask.setId = set.id;
          this.selectedTask.name = 'freeset-story-snail';
          this.selectedTask.setName = set.name;
          this.selectedTask.inOrder = true;

          this.addTaskToPage();

          break;
        case 'fill-gaps-answers-no-clues-single-exercise':
          this.mixAllItems = false;
          this.selectedTask.displayName = 'Uzupełnij luki';
          this.selectedTask.setName = set.name;
          this.selectedTask.setId = set.id;
          if (type.forGaps) {
            this.selectedTask.name = 'freeset-fill-gaps-sentences-no-clues';
          }
          if (!type.forGaps) {
            this.selectedTask.name = 'freeset-no-gaps-translate-answer';
          }
          if (!this.selectedTask.itemsPack) {
            this.selectedTask.itemsPack = 0;
          }
          this.selectedTask.itemsPack++;

          this.addPage();

          this.selectPage(this.pages[0]);
          this.addTaskToPage();
          if (this.pages.length === 1) {
            this.addPage();
          }
          this.selectPage(this.pages[1]);
          this.selectedTask.name = 'answers-gaps';
          this.selectedTask.displayName = 'Odpowiedzi';
          this.addTaskToPage();
          break;
        default:
          break;
      }
    }
    if (this.glossary) {
      this.addChildrenDictionary();
    }
  }
  addTaskToPage() {
    if (this.childrenMode) {
      this.selectedTask.setId = this.data.fromSets[0].id;
      this.selectedTask.itemsPack = 1;
    }
    this.selectedPage.pageToSend.push(this.helpers.clone(this.selectedTask));

    console.log(
      '🚀 ~ file: create.component.ts ~ line 93 ~ CreateComponent ~ addTaskToPage ~ this.selectedPage',
      this.selectedPage
    );
    if (!this.selectedTask.numberOfItems) {
      this.numberOfItems = this.selectedTask.numberOfItems
    }
  }
  addPage() {
    this.pages.push({
      id: this.helpers.makeid(),
      selected: false,
      pageToSend: [],
    });
    this.selectPage(this.pages[this.pages.length - 1]);
  }
  selectPage(page) {
    this.selectedPage = page;

    this.pages.forEach((_page) => {
      if (page.id == _page.id) {
        _page.selected = true;
      } else {
        _page.selected = false;
      }
      this.ref.detectChanges();
    });
    // page.pageToSend.push(task);
  }
  removePage(page) {
    console.log(
      '🚀 ~ file: create.component.ts ~ line 147 ~ CreateComponent ~ removePage ~ page',
      page
    );
    this.pages = this.helpers.removeElementFromArray(this.pages, page);
  }
  removeTaskFromPage(task, page) {
    page.pageToSend = this.helpers.removeElementFromArray(
      page.pageToSend,
      task
    );
    console.log(
      '🚀 ~ file: create.component.ts ~ line 249 ~ CreateComponent ~ removeTaskFromPage ~ page',
      page
    );
    console.log(
      '🚀 ~ file: create.component.ts ~ line 249 ~ CreateComponent ~ removeTaskFromPage ~ task',
      task
    );

    // task.pageToSend =  task.pageToSend.filter((_page) => _page.id !== page.id);
  }
  setImgsSize(event) { }
  async openPDF() { }
  setPdfType(event) { }
  setOrientation(orientation) { }
  previewLoaded(event) { }
  setGaps(event, params) { }
  setFont(type, value) { }
  setGlossaryMode(event) { }
  setNumberOfSentences(value) { }
  updatePDF(event) { }
  updatePreview() { }
  changeOrientation() { }
  changeZoom() { }
}
