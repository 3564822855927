import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CanvasService } from "../canvas.service";

@Component({
  selector: "app-canvas-controls",
  templateUrl: "./canvas-controls.component.html",
  styleUrls: ["./canvas-controls.component.css"],
})
export class CanvasControlsComponent implements OnInit {
  selectedColor = { name: "black", value: "#000" };
  @Input() lineThickness = 3;
  @Input() iconsColor: "primary" | "accent" | "warn" = "primary";
  @Input() colors = [
    { name: "black", value: "#000" },
    { name: "white", value: "#fff" },
    { name: "red", value: "#f44336" },
    { name: "pink", value: "#ff00ea" },
    { name: "purple", value: "#9c27b0" },
    { name: "deep purple", value: "#673ab7" },
    { name: "indigo", value: "#3f51b5" },
    { name: "blue", value: "#2196f3" },
    { name: "cyan", value: "#00bcd4" },
    { name: "teal", value: "#009688" },
    { name: "green", value: "#4caf50" },
    { name: "light green", value: "#8bc34a" },
    { name: "yellow", value: "#cddc39" },
    { name: "amber", value: "#ffeb3b" },
    { name: "orange", value: "#ff9800" },
    { name: "deep orange", value: "#ff5722" },
    { name: "brown", value: "#795548" },
    { name: "grey", value: "#9e9e9e" },
    { name: "blue grey", value: "#607d8b" },
  ];
  @Output() colorChanged = new EventEmitter();
  @Output() undoClicked = new EventEmitter();
  @Output() lineChanged = new EventEmitter();

  constructor(private canvasService: CanvasService) {}

  ngOnInit(): void {}
  _undoClicked() {
    this.undoClicked.emit("undo clicked");
    this.canvasService.passMessage({ type: "undoClicked" });
  }
  lineThicknessChanged(event) {
    this.lineThickness = event.value;
    this.lineChanged.emit(this.lineThickness);
    this.canvasService.passMessage({
      type: "changeLineThickness",
      lineThickness: this.lineThickness,
    });
  }
  colorSelected(event) {
    console.log(
      "🚀 ~ file: canvas-controls.component.ts ~ line 39 ~ CanvasControlsComponent ~ colorSelected ~ event",
      event
    );
    this.selectedColor = event.value;
    this.colorChanged.emit(this.selectedColor);
    this.canvasService.passMessage({
      type: "changeColor",
      color: this.selectedColor.value,
    });
  }
}
