import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sentence-from-disassembled',
  templateUrl: './sentence-from-disassembled.component.html',
  styleUrls: ['./sentence-from-disassembled.component.css']
})
export class SentenceFromDisassembledComponent implements OnInit {
  @Input() disassembledSentence = []
  @Input() customConnectionColor

  constructor() { }

  ngOnInit(): void {
    this.disassembledSentence.forEach((item) => {
      if (item.connectionColor === "#fff9c4") {
        item.connectionColor = "#ffcc04";
      }
      if (item.connectionColor === "b2ebf2") {
        item.connectionColor = "#009688";
      }
      if (item.connectionColor === "c8e6c9") {
        item.connectionColor = "#4caf50";
      }
      if (item.connectionColor === "dcedc8") {
        item.connectionColor = "#4caf50";
      }
      if (item.connectionColor === "f0f4c3") {
        item.connectionColor = "#c0ca33";
      }

      if (item.connectionColor === "b3e5fc") {
        item.connectionColor = "#2196f3";
      }
      if (item.connectionColor === "c5cae9") {
        item.connectionColor = "#7e57c2";
      }
      if (item.connectionColor === "e1bee7") {
        item.connectionColor = "#5c6bc0";
      }
      if (item.connectionColor === "d1c4e9") {
        item.connectionColor = "#7e57c2";
      }
      if (item.connectionColor === "#ffecb3") {
        item.connectionColor = "#f96a20";
      }
      if (item.connectionColor === "#ffe0b2") {
        item.connectionColor = "#ff9800";
      }
      if (item.connectionColor === "#ffcdd2") {
        item.connectionColor = "#ff6171";
      }
      if (item.connectionColor === "#b2dfdb") {
        item.connectionColor = "#2de2d2";
      }
      if (item.connectionColor === "#f0f4c3") {
        item.connectionColor = "#43a047";
      }
      if (this.customConnectionColor) {
        item.connectionColor = this.customConnectionColor;
      }

    });
  }

}
