import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalOceanService {
  private url;

  constructor(private http: HttpClient, private versionService: VersionService) {
    this.url = this.versionService.url + '/api/do'
  }

  uploadFileToFolder(file: File, folderName: string, share: boolean, bucketName: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folderName', folderName);
    formData.append('share', share.toString());
    formData.append('bucketName', bucketName);
    let res = this.http.post(`${this.url}/upload`, formData);
    console.log("🚀 ~ DigitalOceanService ~ uploadFileToFolder ~ res:", res)
    return res
  }
  createFolder(folderName: string, bucketName: string): Observable<any> {
    const body = {
      folderName: folderName,
      bucketName: bucketName
    };

    return this.http.post(`${this.url}/create-folder`, body);
  }
  listFilesInFolder(folderName: string, bucketName: string, share: boolean): Observable<any> {
    const body = {
      folderName: folderName,
      bucketName: bucketName,
      share: share
    };

    return this.http.post(`${this.url}/list-files`, body);
  }

  downloadFile(fileName: string, folderName: string, bucketName: string): Observable<any> {
    const body = {
      fileName: fileName,
      folderName: folderName,
      bucketName: bucketName
    };

    return this.http.post(`${this.url}/download`, body, { responseType: 'blob' });
  }

  deleteFile(fileName: string, folderName: string, bucketName: string): Observable<any> {
    const body = {
      fileName: fileName,
      folderName: folderName,
      bucketName: bucketName
    };

    return this.http.post(`${this.url}/delete`, body);
  }

  getDownloadLink(fileName: string, folderName: string, bucketName: string, expiresIn: number): Observable<any> {
    const body = {
      fileName: fileName,
      folderName: folderName,
      bucketName: bucketName,
      expiresIn: expiresIn
    };

    return this.http.post(`${this.url}/get-download-link`, body);
  }

  listBuckets(): Observable<any> {
    return this.http.get(`${this.url}/list-buckets`);
  }
}