import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BigPictureComponent } from '../../pictures/big-picture/big-picture.component';
import { FormGroup, FormControl } from '@angular/forms';
import { EMPTY, Observable, from } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { catchError, concatMap, map, shareReplay } from 'rxjs/operators';
import { DictionaryService } from 'src/app/shared/services/dictionary.service';
import { AudioTestService } from 'src/app/shared/services/audio-test.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { Platform } from '@angular/cdk/platform';
import { VersionService } from 'src/app/shared/services/version.service';
import { GenerateAudioService } from 'src/app/shared/services/database/generate-audio.service';

@Component({
  selector: 'app-set-item',
  templateUrl: './set-item.component.html',
  styleUrls: ['./set-item.component.css'],
})
export class SetItemComponent implements OnInit, OnDestroy {
  isHandset: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );
  @Input() meaning;
  @Input() number;
  @Input() exportMode = false;
  @Input() textToMark;
  @Input() wordId;
  @Input() checkBox = false;
  @Input() checkboxSelected = false;
  @Input() childrenMode = false;
  @Input() showDescriptions = false;
  @Input() showSentencesTranslations = false;
  @Input() showSentencesTranslationsFlags = false;
  @Input() showAudioProperties = false;
  @Input() showDescriptionsFlags = false;
  @Input() editDescriptionAllowed = false;
  url
  editSentenceTranslation = false;
  checkedNumber = 0;
  inFinder = false;
  audioProperties = [];
  @Output() selectedWords = new EventEmitter();
  @Output() checkboxChanged = new EventEmitter();
  sub: any;
  editDescription = false;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private dictionaryService: DictionaryService,
    public dialog: MatDialog,
    private audioTestService: AudioTestService,
    private helpers: HelpersService,
    private versionService: VersionService,
    private generateAudioService: GenerateAudioService,
  ) { }
  form: FormGroup = new FormGroup({
    cb: new FormControl(false),
  });
  cb = this.form.get('cb');
  cbValue;
  ngOnInit(): void {
    this.inFinder = window.location.href.includes('finder');
    if (this.inFinder) {
      this.showAudioProperties = false
    }
    this.meaning.plainAlsoes = [];
    this.url = this.versionService.url;
    let audioProperties = ['wordAudio', 'sentenceAudio', 'blankAudio', 'wordAudioGB', 'sentenceAudioGB', 'blankAudioGB'];

    this.audioProperties =
      audioProperties.map((prop) => {
        return {
          name: prop,
          link: this.url + '/api/read/getaudiodata?url=' + this.meaning[prop],
          canPlay: false
        };
      }
      );
    console.log("🚀 ~ SetItemComponent ~ ngOnInit ~ audioProperties:", this.audioProperties)
    if (this.meaning.alsoes?.length > 0) {
      this.meaning.plainAlsoes = this.meaning.alsoes.map((also) => also.value);
      if (this.meaning.usEquivalent?.length > 0) {
        this.meaning.plainAlsoes.push(this.meaning.usEquivalent);
      }
      if (this.meaning.ukEquivalent?.length > 0) {
        this.meaning.plainAlsoes.push(this.meaning.ukEquivalent);
      }
      this.meaning.plainAlsoes = this.helpers.removeDuplicatesSingle(
        this.meaning.plainAlsoes,
      );
    }
    this.sub = this.dictionaryService
      .propertyChangedListener()
      .subscribe((data: any) => {
        if (data.meaningId === this.meaning.id) {
          this.meaning[data.property] = data.value;
        }
      });
    if (
      (this.meaning?.selectedLevel === 'chosen' && this.meaning?.matchingTag) ||
      this.childrenMode === true
    ) {
      this.cb.setValue(true);

      this.onCheckboxChanged({ checked: true });
    }
  }

  changeQuotationMarks() {
    this.meaning.description = this.meaning.description.replace(/'/g, '"');
    this.dictionaryService.setMeaningsProperty(
      this.meaning.id,
      'description',
      this.meaning.description,
    );
    this.setMeaningsDescriptionChecked(true);
  }
  recordAudio() {
    this.generateAudioService.generateAudio(this.meaning.wordId, this.meaning)
  }
  setMeaningsDescriptionChecked(checked?) {
    this.meaning.descriptionChecked = checked
      ? true
      : !this.meaning.descriptionChecked;
    if (this.meaning.descriptionChecked) {
      this.dictionaryService.setMeaningsProperty(
        this.meaning.id,
        'descriptionToCheck',
        false,
      );
    }
    this.dictionaryService.setMeaningsProperty(
      this.meaning.id,
      'descriptionChecked',
      this.meaning.descriptionChecked,
    );
  }
  setMeaningsSentenceTranslationChecked(checked?) {
    this.meaning.sentenceTranslationChecked = checked
      ? true
      : !this.meaning.sentenceTranslationChecked;
    if (this.meaning.sentenceTranslationChecked) {
      this.dictionaryService.setMeaningsProperty(
        this.meaning.id,
        'sentenceTranslationChecked',
        true,
      );
    }
    this.dictionaryService.setMeaningsProperty(
      this.meaning.id,
      'sentenceTranslationChecked',
      this.meaning.descriptionChecked,
    );
  }
  editDescriptionFinished() {
    this.editDescription = false;
    this.setMeaningsDescriptionChecked(true);
    this.dictionaryService.setMeaningsProperty(
      this.meaning.id,
      'description',
      this.meaning.description,
    );
  }
  editsentenceTranslationFinished() {
    this.editSentenceTranslation = false;
    this.dictionaryService.setMeaningsProperty(
      this.meaning.id,
      'sentenceTranslation',
      this.meaning.sentenceTranslation,
    );
    this.setMeaningsSentenceTranslationChecked(true);
  }
  wrapInQuotationMarks() {
    let selectedText = window.getSelection().toString();
    console.log(selectedText);
    this.meaning.description = this.meaning.description.replace(
      selectedText,
      '"' + selectedText + '"',
    );
    this.editDescriptionFinished();
  }
  wrapInQuotationMarksAll() {
    let selectedText = window.getSelection().toString();
    console.log(selectedText);
    const regex = new RegExp(`(?<!")${selectedText}(?!"|$)`, 'g');
    this.meaning.description = this.meaning.description.replace(
      regex,
      `"${selectedText}"`,
    );

    this.editDescriptionFinished();
  }
  setMeaningsChangeDescription() {
    this.meaning.changeDescription = !this.meaning.changeDescription;
    this.dictionaryService.setMeaningsProperty(
      this.meaning.id,
      'changeDescription',
      this.meaning.changeDescription,
    );
  }
  searchForMeaning(meaning) {
    navigator.clipboard.writeText(this.meaning.description);
    this.dictionaryService.findWordsByMeaning(meaning);
  }
  redoDescription() {
    this.dictionaryService.redoDescription(this.meaning.id);
    this.setMeaningsDescriptionChecked(true);
  }
  changeLike() {
    this.meaning.description = this.meaning.description.replace(
      'Like,',
      'For example,',
    );
    this.meaning.description = this.meaning.description.replace(
      'Like',
      'For example,',
    );
  }
  addA(meaningId) {
    if (meaningId == this.meaning.id) {
      let words = this.meaning.description.split(' '); // Split the text into words
      let firstWord = words[0]; // Get the first word

      let restOfWords = words.slice(1); // Get the rest of the words

      let firstChar = firstWord.charAt(0);
      console.log(
        '🚀 ~ file: set-item.component.ts:109 ~ SetItemComponent ~ setMeaningsAddTo ~ firstChar:',
        firstChar,
      );
      let isQuote = firstChar === '"' || firstChar === "'";

      if (isQuote) {
        // The first character is a quotation mark, apply changes inside the quotation marks
        let wordWithoutQuotes = firstWord.slice(1, -1);
        wordWithoutQuotes =
          wordWithoutQuotes.charAt(0).toLowerCase() +
          wordWithoutQuotes.slice(1);
        firstWord = firstChar + wordWithoutQuotes + firstChar;
      } else {
        // The first character is not a quotation mark, apply changes directly
        firstWord = firstWord.charAt(0).toLowerCase() + firstWord.slice(1);
      }

      // Prepend "To " to the first word and join it with the rest of the words
      let newText = ['A', firstWord, ...restOfWords].join(' ');

      this.meaning.description = newText;
      this.editDescriptionFinished();
    }
  }
  setMeaningsAddTo(meaningId) {
    if (meaningId == this.meaning.id) {
      let words = this.meaning.description.split(' '); // Split the text into words
      let firstWord = words[0]; // Get the first word

      let restOfWords = words.slice(1); // Get the rest of the words

      let firstChar = firstWord.charAt(0);
      console.log(
        '🚀 ~ file: set-item.component.ts:109 ~ SetItemComponent ~ setMeaningsAddTo ~ firstChar:',
        firstChar,
      );
      let isQuote = firstChar === '"' || firstChar === "'";

      if (isQuote) {
        // The first character is a quotation mark, apply changes inside the quotation marks
        let wordWithoutQuotes = firstWord.slice(1, -1);
        wordWithoutQuotes =
          wordWithoutQuotes.charAt(0).toLowerCase() +
          wordWithoutQuotes.slice(1);
        firstWord = firstChar + wordWithoutQuotes + firstChar;
      } else {
        // The first character is not a quotation mark, apply changes directly
        firstWord = firstWord.charAt(0).toLowerCase() + firstWord.slice(1);
      }

      // Prepend "To " to the first word and join it with the rest of the words
      let newText = ['To', firstWord, ...restOfWords].join(' ');

      this.meaning.description = newText;
      this.editDescriptionFinished();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.checkBox === true) {
      const currentItem: SimpleChange = changes.checkboxSelected;
      console.log('got item: ', currentItem.currentValue);
      switch (currentItem.currentValue) {
        case true:
          this.cb.setValue(true);
          this.checkboxChanged.emit({ mode: 'increase', id: this.meaning.id });
          break;
        case false:
          this.cb.setValue(false);
          this.checkboxChanged.emit({ mode: 'decrease', id: this.meaning.id });
          this.ngOnInit();
          break;

        default:
          break;
      }
    }
  }
  copyPrompt() {
    let textToCopy =
      'You are a teacher explaining a phrase to a 5-year-old. Provide a very simple and short definition and example of the specific meaning of the word that is provided in the prompt. Use simple words and structures but grammatically correct and not childish. Only use quotes when you use the described phrase. Only give the definition as the answer. Here’s the example: Input: fine; verb; ukara, dać mandat Output: To "fine" means that someone has to pay because they didnt follow the rules. For example, if someone is driving too fast, the police might stop them and "fine" them. Now do the same for: ';
    textToCopy =
      textToCopy +
      this.meaning.before +
      ' ' +
      this.meaning.word1 +
      ' ' +
      this.meaning.between +
      ' ' +
      this.meaning.word2 +
      ' ' +
      this.meaning.after +
      ';' +
      this.meaning.part +
      ';' +
      this.meaning.translation +
      ' as in the example: ' +
      this.meaning.sentence +
      'Give 10 examples.';
    navigator.clipboard.writeText(textToCopy);
  }
  openBigPicture(imageUrl) {
    const dialogRef = this.dialog.open(BigPictureComponent, {
      data: imageUrl,
    });
  }
  onCheckboxChanged(event) {
    console.log('event: ', event);
    switch (event.checked) {
      case true:
        this.checkboxChanged.emit({ mode: 'increase', id: this.meaning.id });
        break;
      case false:
        this.checkboxChanged.emit({ mode: 'decrease', id: this.meaning.id });
        break;
      default:
        break;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.meaning) {
      const wordToSend = {
        item: {
          wordId: this.wordId,
          meaningId: this.meaning.id,
          selected: this.cb.value,
        },
        simpleWord: (this.meaning.word1 + ' ' + this.meaning.word2).trim(),
      };
      if (wordToSend.item.selected === true) {
        this.selectedWords.emit({
          item: { wordId: this.wordId, meaningId: this.meaning.id },
          simpleWord: (this.meaning.word1 + ' ' + this.meaning.word2)
            .toLowerCase()
            .trim(),
        });
      }
    }
  }
}
