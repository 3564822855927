import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { HelpersService } from './helpers.service';
var platform = require('platform');
let serviceVersion = require('../../../../package.json').version;

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  version = '1.0.503';
  environment: 'dev' | 'test' | 'prod';
  testVersion = 6;
  localBackend = true;
  user;
  url = '';
  platform;
  test = false;

  constructor(
    private userDetailsService: UserDetailsService,
    private helpers: HelpersService,
  ) {
    const loc = window.location.hostname;
    if (!loc.includes('alt2') && !loc.includes('int2') && this.localBackend) {
      this.url = 'https://backend-dev.linget.it';
    } else {
      if (loc.includes('alt2')) {
        this.url = 'https://backend-int.linget.it';
      } else {
        this.url = 'https://backend-int.linget.it';
      }
    }
    this.platform = platform;
    console.log("🚀 ~ file: version.service.ts:28 ~ VersionService ~ this.url:", this.url)
    console.log(
      '🚀 ~ file: version.service.ts ~ line 23 ~ VersionService ~ platform',
      platform,
    );
    if (
      loc !== 'test.lingking.com.pl' &&
      loc !== 'lingking.com.pl' &&
      !loc.includes('alt2')
    ) {
      this.environment = 'dev';
      this.test = true;
    }
    if (loc == 'test.lingking.com.pl') {
      this.environment = 'test';
      this.test = true;
    }
    if (loc === 'lingking.com.pl' || loc === 'int2.linget.it') {
      this.environment = 'prod';
    }
  }

  setVersion() {
    // if (this.environment !== "dev") {
    setTimeout(() => {
      this.user = this.helpers.clone(
        this.userDetailsService.getSimpleUserDetails().name,
      );
    }, 500);
    setTimeout(() => {
      datadogRum.setUser({
        name: this.user,
      });
      datadogRum.init({
        applicationId: 'ef64da8a-cd1e-4657-b2df-81b28e936f88',
        clientToken: 'pubbda586a89bc2713abbca1e9ce8838e40',
        site: 'datadoghq.eu',
        service: 'lswebapp',
        version: this.version,
        sampleRate: 100,
        env: this.environment,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.startSessionReplayRecording();
    }, 1000);

    // }
    console.log();
    // rg4js('setVersion', this.version);
    if (this.environment == 'dev' || this.environment == 'test') {
      this.version =
        this.version +
        '  >>>>>>>>>>> test build >>>>>>>>>>>>>> ' +
        this.testVersion;
      //   rg4js('enableCrashReporting', true);
      //   // rg4js('options', {
      //   // });
    }
    // else{
    //   rg4js('enableCrashReporting', true);
    // }
  }
}
