import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AudioTestService implements OnDestroy {
  private audio: HTMLAudioElement;
  private blobURLs: string[] = [];

  constructor(private http: HttpClient) {
    this.audio = new Audio();
  }

  ngOnDestroy() {
    // Revoke all Blob URLs when the service is destroyed
    this.blobURLs.forEach(URL.revokeObjectURL);
  }

  testAndDownloadAudio(
    propName: string,
    audioURL: string,
  ): Observable<{ propName: string; value: string }> {
    console.log(
      '🚀 ~ file: audio-test.service.ts:26 ~ AudioTestService ~ audioURL:',
      audioURL,
    );
    console.log(
      '🚀 ~ file: audio-test.service.ts:26 ~ AudioTestService ~ propName:',
      propName,
    );
    return new Observable((observer) => {
      this.audio.onerror = function () {
        observer.error(new Error('Audio file is not playable' + propName));
      };
      this.audio.oncanplaythrough = function () {
        this.http
          .get(audioURL, { responseType: 'blob' })
          .pipe(
            catchError((err) => {
              observer.error(err);
              return of(null);
            }),
          )
          .subscribe((data) => {
            if (data !== null) {
              const blobURL = URL.createObjectURL(data);
              this.blobURLs.push(blobURL);
              observer.next({ propName: propName, value: blobURL });
            }
            observer.complete();
          });
      }.bind(this);
      this.audio.src = audioURL;
    });
  }
}
