<div
    style="height: 100%;"
    fxLayout="column"
>
    <h1>Wyślij wiadomość do grupy</h1>
    <div
        fxLayout="row wrap"
        fxLayoutGap="10px"
    >
        <div *ngFor="let student of students">
            <div
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                fxLayout="row"
                fxFlex
            >
                <mat-checkbox [(ngModel)]="student.selected"></mat-checkbox>
                <span class="mb-1">{{student.name}}</span>
                <mat-checkbox
                    *ngIf="!data.fromHomeworkPanel"
                    [(ngModel)]="student.addStudentLoginData"
                >Dane ucznia
                </mat-checkbox>
                <mat-checkbox
                    *ngIf="!data.fromHomeworkPanel"
                    [(ngModel)]="student.addCustomerLoginData"
                >Dane klienta
                </mat-checkbox>
            </div>
        </div>
    </div>
    <mat-form-field appearance="outline">
        <mat-select
            placeholder="Wiadomość zdefiniowana"
            (selectionChange)="presetChosen($event)"
        >
            <mat-option
                *ngFor="let item of presets"
                [value]="item"
            >{{item.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input
            placeholder="Temat wiadomości"
            [(ngModel)]="subject"
            matInput
            type="text"
         />
    </mat-form-field>
    <div>
        <angular-editor
            [config]="editorConfig"
            [placeholder]="'Wpisz treść wiadomości'"
            [(ngModel)]="htmlContent"
        ></angular-editor>
    </div>
    <button
        *ngIf="!data.fromHomeworkPanel"
        mat-raised-button
        color="primary"
        (click)="getPreparedFiles()"
    >Pobierz plik
    </button>
    <input
        *ngIf="!data.fromHomeworkPanel"
        class="image-container"
        style="padding-top: 145px; padding-left: 135px;"
        appAppDragDrop
        multiple
        (onFileDropped)="fileChangeEvent($event)"
        type="file"
        (change)="fileChangeEvent($event)"
     />
    <!-- <input #file (change)="fileSelected()" type="file" placeholder="label" value="value"> -->
    <span fxFlex></span>
    <div
        fxLayout="row"
        fxLayoutGap="10px"
        fxLayoutAlign="end end"
        fxFlex
    >
        <button
            fxFlex
            mat-stroked-button
            mat-dialog-close
            color="primary"
        >Anuluj
        </button>
        <button
            [disabled]="!sendPossible"
            fxFlex
            mat-flat-button
            (click)="sendEmail(true)"
            color="primary"
        >Wyślij
        </button>
        <!-- <button mat-flat-button (click)="sendEmail()" color="primary">Wyślij próbnie </button> -->
    </div>
</div>
