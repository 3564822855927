import { Injectable } from '@angular/core';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  studentName = 'Agnieszka';
  firstQuestionFromSet = '';
  name_question = ["What's your name?", 'And you are?'];
  greetings = ['Hi!', 'Hello!', 'Hi there!'];
  my_name = ['Hi my name is'];
  men_avatars = [
    'https://drive.google.com/file/d/1f_U1bbeQIgdB3huxTFbYCS5hzFzsVbxR/view?usp=sharing',
    'https://drive.google.com/file/d/1iHetOfF-Gi7HT2xxls6Nr50opI4vLrKX/view?usp=sharing',
  ];
  women_avatars = [
    'https://drive.google.com/file/d/1-FI4QTpqlKUnXH-YQbbBGr23L6L0p9-4/view?usp=sharing',
    'https://drive.google.com/file/d/1-XcQRKfGyrQKU7vWKVQvVLfvzzPISdCP/view?usp=sharing',
    'https://drive.google.com/file/d/1-u4Yf9xko1eJuixCY-TPtcGj-MdMT9nS/view?usp=sharing',
    'https://drive.google.com/file/d/1-vXY2DlEScGHuThxlXXZoe943Ipa4tbv/view?usp=sharing',
    'https://drive.google.com/file/d/10E0VzD-bO1r1fDtxCgFbijm-wPuEBJe-/view?usp=sharing',
    'https://drive.google.com/file/d/10WHHkKemLC0adpSy-foANxKEt4s4jv72/view?usp=sharing',
    'https://drive.google.com/file/d/10c7V47V8z2L10Yd5O45WmNnclkg5JlFt/view?usp=sharing',
    'https://drive.google.com/file/d/12OpYICoqDO-YdNvgXY1pISZG0pms1CMC/view?usp=sharing',
    'https://drive.google.com/file/d/14ACkDm4O_zoaKcMd3tcaIHxOBCOfPbnK/view?usp=sharing',
    'https://drive.google.com/file/d/14PAjDA7B-9PH3arirD6c6HYTfzSW41VE/view?usp=sharing',
    'https://drive.google.com/file/d/14QZbdDM6_Ef7fyuRuWcgKevARPfDseLu/view?usp=sharing',
    'https://drive.google.com/file/d/158ydYDWXYPVw1cUXkTMtKkgUcjOEJY60/view?usp=sharing',
    'https://drive.google.com/file/d/1669Z3nmC0B8tspdj2dbZ7y6S96ICxMjQ/view?usp=sharing',
    'https://drive.google.com/file/d/16UCvAyEDvq-_iqvMhBz7W8id1Fag0Na8/view?usp=sharing',
    'https://drive.google.com/file/d/176B8TODPzfJtvmExOpWiIh2IAYVI4cby/view?usp=sharing',
    'https://drive.google.com/file/d/17AYxfBdVkdksX950epk4sy7FJMyIGAzS/view?usp=sharing',
    'https://drive.google.com/file/d/17rKwqmN6kJmxcvDfvWRW9gSzjhn103eE/view?usp=sharing',
    'https://drive.google.com/file/d/17u6nzOKH7tuK4aVw2EQigPkBBd13uTK1/view?usp=sharing',
    'https://drive.google.com/file/d/18RyJwjM-kLIfswBcgYm6mUBKns_MOh8g/view?usp=sharing',
    'https://drive.google.com/file/d/194SI3KQvAbOmW36KRW7e-tZt2vwXGVnL/view?usp=sharing',
    'https://drive.google.com/file/d/195uBwrgkMwY_vk5Ft-MmJX0EH8nKLxZi/view?usp=sharing',
    'https://drive.google.com/file/d/19jCOPP31LmNnoZnMVqV_bROCtpsqVKjr/view?usp=sharing',
    'https://drive.google.com/file/d/19s4yH6DckR6AiUYcCV6nxfclf-JF3OJ8/view?usp=sharing',
    'https://drive.google.com/file/d/19xQdGh5EYv3FwJzNhq8HawMhGo0WbXcO/view?usp=sharing',
    'https://drive.google.com/file/d/1A9K9O0nvTxCYogNLRejdV4lmLvJ2Xghh/view?usp=sharing',
    'https://drive.google.com/file/d/1AYy2pbLt2ebAuR9tGL5xTejWEFhbwEUM/view?usp=sharing',
    'https://drive.google.com/file/d/1BG0_z4Ac9AF6_Q655UuY-XHYslFIzgqv/view?usp=sharing',
    'https://drive.google.com/file/d/1CLxeNMeI12o4OlSTIgUDCaTJtdWwbv5x/view?usp=sharing',
    'https://drive.google.com/file/d/1Cpt8O4NX9d6FG9OJ_XsuH5MY__8BZBKL/view?usp=sharing',
    'https://drive.google.com/file/d/1DFIY4c1AfuWv91TwglJsEhu3_RCNyydV/view?usp=sharing',
    'https://drive.google.com/file/d/1DezB09b2MT0zOkXtHb0Zm8D-xB3w_Zoq/view?usp=sharing',
    'https://drive.google.com/file/d/1DhGEfm5OcqIqTtLdZGKvIui7-wfJedXR/view?usp=sharing',
    'https://drive.google.com/file/d/1G8foivVhvdCwLGtuQPQRaZ1rPW8pwrzW/view?usp=sharing',
    'https://drive.google.com/file/d/1H5QC1VwA84IO2dkJ0u-Bg7DXDaC8QLqn/view?usp=sharing',
    'https://drive.google.com/file/d/1HAcS2xz_Ej72-MzzQcfBMb3IZ7qnoWv0/view?usp=sharing',
    'https://drive.google.com/file/d/1HFwonIDz31EnEf7W8pspPc4lB3wXpU-4/view?usp=sharing',
    'https://drive.google.com/file/d/1HNY8XqAjBkur4El7u2MXCr5qvfCecQXt/view?usp=sharing',
    'https://drive.google.com/file/d/1I231FB3CDLQ3h8GnCgk44pzI1NN1ABcV/view?usp=sharing',
    'https://drive.google.com/file/d/1IFbi04LQ6Q6D3hT0xrE9rh6SDlLQrC-M/view?usp=sharing',
    'https://drive.google.com/file/d/1IJ3l7NYHi_jS6W9We6iIfkYYoLPa3tWC/view?usp=sharing',
    'https://drive.google.com/file/d/1J_7B-B4VjkqtBdPd9-kMLlohv9EeeTBq/view?usp=sharing',
    'https://drive.google.com/file/d/1J_Sm14ojUN0E_jPWb4N05Od8D6M9-CLh/view?usp=sharing',
    'https://drive.google.com/file/d/1JjUFMN_taCQ_o4Jbptwey8Nj2JGwa_LG/view?usp=sharing',
    'https://drive.google.com/file/d/1JvY4dKIiw1fPAK9lH3LAlxaJLMXTtfur/view?usp=sharing',
    'https://drive.google.com/file/d/1K248TkUgv0bjTroW4Fs-VWt5IrFiAiH_/view?usp=sharing',
    'https://drive.google.com/file/d/1KQ1UGVwwCH2BTq5eqzv-OIHtDcfR1KZ0/view?usp=sharing',
    'https://drive.google.com/file/d/1NKpP2sUjwj6jSz3MVqnKf2Q85oywy-xn/view?usp=sharing',
    'https://drive.google.com/file/d/1OMCT6jyV7RWGeWrppQyLtCp03Jhc74bw/view?usp=sharing',
    'https://drive.google.com/file/d/1Q7rGYZnkbRjW-VJ8D11rIiStJWQ5SyaU/view?usp=sharing',
    'https://drive.google.com/file/d/1Rr7bzzFEKQsHdkQ_B6ljyFnLN9UAcJi4/view?usp=sharing',
    'https://drive.google.com/file/d/1S4gPkStEUDB_1eh7QJJCXPbdO8oGtXNU/view?usp=sharing',
    'https://drive.google.com/file/d/1SCLtyPZYpj30ntAGxYwka1leGazvQBTn/view?usp=sharing',
    'https://drive.google.com/file/d/1SMYs78-gY3YThNrCLQejjXqqqz_WxVfg/view?usp=sharing',
    'https://drive.google.com/file/d/1TK2yZg4LCMJ8UsF_JO5xexLtzzG4QlJ5/view?usp=sharing',
    'https://drive.google.com/file/d/1Tij6HFz7V_1YUc5vqnNqUdynPIch9jOA/view?usp=sharing',
    'https://drive.google.com/file/d/1Ujo99PBa2e-LL684YtihzdyU6gHy6LTW/view?usp=sharing',
    'https://drive.google.com/file/d/1UzO_RZ41BGWTGCGcvTGgjfgLCB_AdTjO/view?usp=sharing',
    'https://drive.google.com/file/d/1VUexjUTl52RAOjZFVbQvIFoXoviUPTan/view?usp=sharing',
    'https://drive.google.com/file/d/1VWZlCLMXzMoie-tt2JHlQQzOmMpXE3gS/view?usp=sharing',
    'https://drive.google.com/file/d/1VwxAWEFqWkmkGmH1onrPGvm8uUDEHyny/view?usp=sharing',
    'https://drive.google.com/file/d/1WQ9r9BZjoA5hEhBT5afwKcdxfvQ6ugg0/view?usp=sharing',
    'https://drive.google.com/file/d/1Wda3qWTP-fYnhm9WjR5rNBMPCxytUJ2G/view?usp=sharing',
    'https://drive.google.com/file/d/1XJlluWULCvZXAtC99ZE_qxgjaZCBjclt/view?usp=sharing',
    'https://drive.google.com/file/d/1ZF23XPv8m9mU_4_iWcI41h215afcBx5r/view?usp=sharing',
    'https://drive.google.com/file/d/1Zey6FCJDPt0tL9f_0umhlQ8eNKOybO4M/view?usp=sharing',
    'https://drive.google.com/file/d/1_Vuwm9ZR5Xvr5Gg_r_CEv4aeFHiuwSAR/view?usp=sharing',
    'https://drive.google.com/file/d/1ba4iIH6TJXX9hAI6-aUj_thD3dhnAzql/view?usp=sharing',
    'https://drive.google.com/file/d/1by09fekoZIEvb7we311hy7DmDp68JCAG/view?usp=sharing',
    'https://drive.google.com/file/d/1dAMg3LB1cH86fDf4IxXPkZ_FxFauhpWK/view?usp=sharing',
    'https://drive.google.com/file/d/1eNmyqwAUEndRV93BVRH8gV85LqGe9ctM/view?usp=sharing',
    'https://drive.google.com/file/d/1eXZBfNUiIBQQmo0P38L2MtwdpQWpTFZ3/view?usp=sharing',
    'https://drive.google.com/file/d/1fOauQqG8NHzCVf5rNEJk15v-Tw4-Tw_t/view?usp=sharing',
    'https://drive.google.com/file/d/1gD6X10ViSmb8KzK77Q1wdP4R4znTdcDr/view?usp=sharing',
    'https://drive.google.com/file/d/1iNPjunlsyMJAHoc_aORn37E0XK19mRKV/view?usp=sharing',
    'https://drive.google.com/file/d/1j6E1rgKVHylIk2yr7-fJ-9kIbqveie-k/view?usp=sharing',
    'https://drive.google.com/file/d/1kG9HtQfqiXp7NIs31Os8sW95UyIZCS7Y/view?usp=sharing',
    'https://drive.google.com/file/d/1kOACwYUhtu4_Qqpug0Ks-2DdmpFAPzBa/view?usp=sharing',
    'https://drive.google.com/file/d/1kORpRW0BhLGrB6wfNkzBoC1IOj26wDn1/view?usp=sharing',
    'https://drive.google.com/file/d/1keCiQkZ0n6KXMAyGdGAjmYB3cKhCv9ci/view?usp=sharing',
    'https://drive.google.com/file/d/1lF9INobIX3g5jUS1AKDGrPWcLhioxuyj/view?usp=sharing',
    'https://drive.google.com/file/d/1lHTW9G-ZLt6uNsyuLx10jA_oI74zx1Ti/view?usp=sharing',
    'https://drive.google.com/file/d/1n3BIKdNhBAAgkdFPDirJq8t7XV0lXggH/view?usp=sharing',
    'https://drive.google.com/file/d/1nQdWOqAy-RIJjhufitEj9GOThFmYP3cr/view?usp=sharing',
    'https://drive.google.com/file/d/1nbTLk2YZdyAoIJgOrXV-Y3JIc5eh1uhM/view?usp=sharing',
    'https://drive.google.com/file/d/1pBDpvnkgc8McrB63oC-ITwXtbGpi1c_q/view?usp=sharing',
    'https://drive.google.com/file/d/1pHxgli2GfbHzJPh83VlYFSdmfdAzneKr/view?usp=sharing',
    'https://drive.google.com/file/d/1s6ACgCWUf9_dwwdQqkbSxKP_Q_B4V_cM/view?usp=sharing',
    'https://drive.google.com/file/d/1sGsKlG_UWyDEQc1ZsBFfcgvaXb5CgWsB/view?usp=sharing',
    'https://drive.google.com/file/d/1sqX8DGHNJoY6vjZhbvcopSzrewAE8Gt3/view?usp=sharing',
    'https://drive.google.com/file/d/1t0Qk3VMMDMdRtCzis3cEGtlO_kWKGzz2/view?usp=sharing',
    'https://drive.google.com/file/d/1u3qaYs8PeJSQjrN6F73NvxTFTttKosvA/view?usp=sharing',
    'https://drive.google.com/file/d/1uAqpETts1asnL7uGAboCktQaq7bNMFrD/view?usp=sharing',
    'https://drive.google.com/file/d/1vR8B05yUOgC62ZvIV9U1ql0mEdyCSQkX/view?usp=sharing',
    'https://drive.google.com/file/d/1vZ_DVU9EOlpaNQSVQWc_8gWUHOSY7Zza/view?usp=sharing',
    'https://drive.google.com/file/d/1w4H_OlAy8iBnj81IGrO8LTBzDE403oZc/view?usp=sharing',
    'https://drive.google.com/file/d/1wTc5wPsy3bq_y1dQBWXNFVDF6n5c8dTH/view?usp=sharing',
    'https://drive.google.com/file/d/1wqIKaW1d4Db1hQIaD5NPScJZhWSjveS4/view?usp=sharing',
    'https://drive.google.com/file/d/1wt8RpdSvb9xfmJMpLgY4j0GpWV7A481g/view?usp=sharing',
    'https://drive.google.com/file/d/1ywlcjmHz4XbAdnSpeGGybpK4O88RRmNh/view?usp=sharing',
    'https://drive.google.com/file/d/1zgzDOOUGKkPglLUCK-eBHExZ848gUcUv/view?usp=sharing',
    'https://drive.google.com/file/d/10-Frkviqu76hFB3-vvVMMo5uLKL4GQqK/view?usp=sharing',
    'https://drive.google.com/file/d/11Uxl4Jv6TW0CEkPOov15DRkertCkh4A4/view?usp=sharing',
    'https://drive.google.com/file/d/11Vj-UXPSOLaLNB3k0a30nq0hX2BvSO6o/view?usp=sharing',
    'https://drive.google.com/file/d/12C32h9PkohaugPzptPXAXUIbH-qCNei2/view?usp=sharing',
    'https://drive.google.com/file/d/131dD09xWs0-_YWpKW9ZwF5DK1KnJKFlk/view?usp=sharing',
    'https://drive.google.com/file/d/13E-MZEjpem5ZE8mFdnLUpuKwKZ7sumtO/view?usp=sharing',
    'https://drive.google.com/file/d/13HV1eKatyUpNXz_-ToUk1CgQIVXKXmdO/view?usp=sharing',
    'https://drive.google.com/file/d/13seeT8xYLOJ8Pb9WLXxqPsCQPS3qdL-R/view?usp=sharing',
    'https://drive.google.com/file/d/14z3GHkjAaBTyZz-kPyMcl5DT2Gv_tnY-/view?usp=sharing',
    'https://drive.google.com/file/d/15DXGW44ilQJaol6iGpKY7q52h6_LAlIg/view?usp=sharing',
    'https://drive.google.com/file/d/16RO3YrEoQSx7lZ9j0lj48bQX44hO_XNS/view?usp=sharing',
    'https://drive.google.com/file/d/173FnXGqR-LIseNUFzhbUGGMyGvEXQQQY/view?usp=sharing',
    'https://drive.google.com/file/d/17Y7bXQ_MsUqd3z2-KKNCUh-AAahK7azx/view?usp=sharing',
    'https://drive.google.com/file/d/17vrSVYnBxV2bUsDXDQXrV2UEO9dkLGpD/view?usp=sharing',
    'https://drive.google.com/file/d/18GVWY72xv7s31F1KsmCIaoYkK1YY1UKu/view?usp=sharing',
    'https://drive.google.com/file/d/18KTV5O2SY93c460TeFUitVjIR_xyy-du/view?usp=sharing',
    'https://drive.google.com/file/d/18nZT4iwaa83cPtOWCOY2x8aEIHFIJ3L2/view?usp=sharing',
    'https://drive.google.com/file/d/18v_RCCL3z45X3C1VT8oHJtdcRreq3_S_/view?usp=sharing',
    'https://drive.google.com/file/d/19iGNX-m49H4OgB7TukN8nNrPUy45YNDJ/view?usp=sharing',
    'https://drive.google.com/file/d/1A7ozkcjPgwao4go5C7wx4qvNGFboGBiM/view?usp=sharing',
    'https://drive.google.com/file/d/1BMEfASCubUQ8Ep-mIsyvhgwgBlnTxGrr/view?usp=sharing',
    'https://drive.google.com/file/d/1BNs-69RNzxu1Q7jnL4_OG0aWprCidS0g/view?usp=sharing',
    'https://drive.google.com/file/d/1D18zBpfGWukTZ9H2ofMBXFeptPFIQk-G/view?usp=sharing',
    'https://drive.google.com/file/d/1D4iGItn0xwo2G-oxtFoUaYQcKn_gYAql/view?usp=sharing',
    'https://drive.google.com/file/d/1D5pVewjyEXAVOPa9DH52AVNW9eO9Bm9J/view?usp=sharing',
    'https://drive.google.com/file/d/1DRLhA46CgjZMUtFMqYJPgmqUeFOuC0-n/view?usp=sharing',
    'https://drive.google.com/file/d/1ESGVjB77invpCRbmniO6x30-eggjrgjM/view?usp=sharing',
    'https://drive.google.com/file/d/1EyKEboZz-oIO9YflmFCefLJjiFQaZMx-/view?usp=sharing',
    'https://drive.google.com/file/d/1G0rYeUVziP7pPuoh0b32VN_-H8UbTV_W/view?usp=sharing',
    'https://drive.google.com/file/d/1G5gAJfCdSi_GBsaqY1jxUDoBD_LIzbJS/view?usp=sharing',
    'https://drive.google.com/file/d/1GobGeGxpwRcCFc31_w8BX75PT179tpI2/view?usp=sharing',
    'https://drive.google.com/file/d/1J3_cmwyFjwfxHTOrgi5heoLYZi-A-9SB/view?usp=sharing',
    'https://drive.google.com/file/d/1K1ATpz7TKsH2phpOGcYoKKtgeWPGbMI5/view?usp=sharing',
    'https://drive.google.com/file/d/1Mi8_nFYWXSSw_Ofevm6FmOdlaOYbBjJk/view?usp=sharing',
    'https://drive.google.com/file/d/1NakXxYizZwi6A1XXyhJkbdr8_DdYGA3C/view?usp=sharing',
    'https://drive.google.com/file/d/1OCnTKz7qbdtT66IqaMAlSrsyDFDzop0L/view?usp=sharing',
    'https://drive.google.com/file/d/1OaLJ9WQktyzI2mWOhvO-UVv1Vx23CRNq/view?usp=sharing',
    'https://drive.google.com/file/d/1PThERU8yrLsYIz5iXDhbdU40MYvWTM1e/view?usp=sharing',
    'https://drive.google.com/file/d/1QBBLn_vpOqr0cgBgiW5LAan-2brKUaVb/view?usp=sharing',
    'https://drive.google.com/file/d/1RtMs16l8wwI2Qy11zDFjr5Q33D7mp0So/view?usp=sharing',
    'https://drive.google.com/file/d/1S7HMfcWOmg-M-ke1dlFuAtIKgkA3KUx8/view?usp=sharing',
    'https://drive.google.com/file/d/1SBqpvXFuxLLYOz2qOCCH29ohST9P0Wny/view?usp=sharing',
    'https://drive.google.com/file/d/1SMgEc_o6__RIUistXUYnORO5bFfWOukD/view?usp=sharing',
    'https://drive.google.com/file/d/1SddHCNCLZU3Oxz4VfT4teJ7mEM2FJF-D/view?usp=sharing',
    'https://drive.google.com/file/d/1THN8fv4XxvZeN1iTRmt1gPoENnrRH8wZ/view?usp=sharing',
    'https://drive.google.com/file/d/1UDrc7LciKbDeJOAHC45Fhugld1dX9uuW/view?usp=sharing',
    'https://drive.google.com/file/d/1UjRyrvEMK5eQur1VJB68mQFdX5gtKO-9/view?usp=sharing',
    'https://drive.google.com/file/d/1UpYFO_c_P5oorT5t-8o19NfLWqddIeAt/view?usp=sharing',
    'https://drive.google.com/file/d/1WU0DQV8_xnttIL20rlP2DjXH9eklMPW6/view?usp=sharing',
    'https://drive.google.com/file/d/1XLVC4UaWnwx2xc8WaMiHZnrj_lCuEaLm/view?usp=sharing',
    'https://drive.google.com/file/d/1XkCZY9S-mM4TO0Pix_VN2WEBUMy-mmOS/view?usp=sharing',
    'https://drive.google.com/file/d/1_l2uEWvTpVfj6D3liN7NVhA1sx0nGIch/view?usp=sharing',
    'https://drive.google.com/file/d/1aViMFNzs3p44TCP6fJ1Vfrltsqo94gCU/view?usp=sharing',
    'https://drive.google.com/file/d/1alC8ycgJkG2m56poad7BKSXh-dA5D31T/view?usp=sharing',
    'https://drive.google.com/file/d/1cpdF1wstpX0-2ll_UIWzwVXbeQP9hH8U/view?usp=sharing',
    'https://drive.google.com/file/d/1d8wMRNJM_OVxw3xKpaJ1m1mprZGof9Pa/view?usp=sharing',
    'https://drive.google.com/file/d/1d9F1ooXuD9tBZPZgHh5RBOC59BxQ0ywr/view?usp=sharing',
    'https://drive.google.com/file/d/1dKKQSz7X8PcDHdSHtiqt0AnpxQgpKZVX/view?usp=sharing',
    'https://drive.google.com/file/d/1dPwaJSMOvxFUNW8KIESuRD9P_2in0mTw/view?usp=sharing',
    'https://drive.google.com/file/d/1diqJEivgnsZt5Okn3Srz5q37A2GCz-Ck/view?usp=sharing',
    'https://drive.google.com/file/d/1e3bGh8QPW3PwuOS3Stslzd_hqQTzeEv7/view?usp=sharing',
    'https://drive.google.com/file/d/1eGpodNwD2vJNZOWMFKPFATTGUcRpCLT3/view?usp=sharing',
    'https://drive.google.com/file/d/1e_XmC3_qCt57c7-H0kEoQRL5ax5rJQmd/view?usp=sharing',
    'https://drive.google.com/file/d/1emCH7gmpmi468UYIRM0t07J5x3Obkrj6/view?usp=sharing',
    'https://drive.google.com/file/d/1eoALUzF4Chvq5fr6fWZsl1CgEyJwyBn7/view?usp=sharing',
    'https://drive.google.com/file/d/1i-GC1d2xJeJ-kN9Q1p1oPRo_Km2r2OGi/view?usp=sharing',
    'https://drive.google.com/file/d/1i0Y4Uo07ELO_6uBZtcXgJXWXRJGq4kEg/view?usp=sharing',
    'https://drive.google.com/file/d/1i_DcpCfvqclTEeCTOcb2IwK9IRcvfxhP/view?usp=sharing',
    'https://drive.google.com/file/d/1irpO--_9sAYQGMzyIMKeF3_syiNrXjTt/view?usp=sharing',
    'https://drive.google.com/file/d/1itfIJPp8UB5iOo5VbxeYqy7LtFJoFVh3/view?usp=sharing',
    'https://drive.google.com/file/d/1jxRVDveDAywBnzZPK05VdNJZzmrAMTC7/view?usp=sharing',
    'https://drive.google.com/file/d/1kIFmPecsM29zLY7hXNSIt-aJi3RfWUU4/view?usp=sharing',
    'https://drive.google.com/file/d/1kQ_qtdqHpyEBW8yCpl8gDDQ7ygiXzxIW/view?usp=sharing',
    'https://drive.google.com/file/d/1klc0FeLoxEzzFxzUKcBpn4ktcEnUPs0b/view?usp=sharing',
    'https://drive.google.com/file/d/1kySPlwZlaHQRWAOoP7t4dPQKduwM9-kR/view?usp=sharing',
    'https://drive.google.com/file/d/1lsqVX8e8Mf9s7kOZZ-87PfuWOHRKpCqw/view?usp=sharing',
    'https://drive.google.com/file/d/1m8bKoMJ_yY8m-PL6MxV5fleK74h8QctE/view?usp=sharing',
    'https://drive.google.com/file/d/1msdp6AlsjiAzODFSLEIVbIDM_OSNat8W/view?usp=sharing',
    'https://drive.google.com/file/d/1nWtAAP30uLLkGUioVHb4PuU7dELbiusu/view?usp=sharing',
    'https://drive.google.com/file/d/1ngYWfvpO-a0Gr3Dhmy1YIVPPXSu3w9CA/view?usp=sharing',
    'https://drive.google.com/file/d/1oCAfdxK1RySLsYJcM_19VmFlTqXEAqdT/view?usp=sharing',
    'https://drive.google.com/file/d/1pJL6pAkkGcTg-rqbzugQblSrrJxoPb-I/view?usp=sharing',
  ];
  men_names = [
    "My name's Adam.",
    "My name's Aiden.",
    "My name's Alfie.",
    "My name's Allen.",
    "My name's Andrew.",
    "My name's Ashton.",
    "My name's Ben.",
    "My name's Blake.",
    "My name's Bobby.",
    "My name's Brandon.",
    "My name's Cade.",
    "My name's Caleb.",
    "My name's Calvin.",
    "My name's Chad.",
    "My name's Chris.",
    "My name's Christopher.",
    "My name's Collin.",
    "My name's Connor.",
    "My name's Corey.",
    "My name's Curtis.",
    "My name's Darrel.",
    "My name's David.",
    "My name's Dawson.",
    "My name's Dean.",
    "My name's Dylan.",
    "My name's Elliot.",
    "My name's Ethan.",
    "My name's Ewan.",
    "My name's Finley.",
    "My name's Gage.",
    "My name's George.",
    "My name's Harry.",
    "My name's Harvey.",
    "My name's Issac.",
    "My name's Jacke.",
    "My name's Jamie.",
    "My name's Jerry.",
    "I'm Jessie.",
    "I'm Joe.",
    "I'm Joel.",
    "I'm John.",
    "I'm Josh.",
    "I'm Jude.",
    "I'm Liam.",
    "I'm Lewis.",
    "I'm Louis.",
    "I'm Lucas.",
    "I'm Luke.",
    "I'm Mark.",
    "I'm Max.",
    "I'm Michael.",
    "I'm Mike.",
    "I'm Nicholas.",
    "I'm Noah.",
    "I'm Oliver.",
    "I'm Oscar.",
    "I'm Patrick.",
    "I'm Reuben.",
    "I'm Russell.",
    "I'm Ryder.",
    "I'm Samuel.",
    "I'm Sean.",
    "I'm Sheldon.",
    "I'm Stanley.",
    "I'm Taylor.",
    "I'm Theo.",
    "I'm Thomas.",
    "I'm Tom.",
    "I'm Trey.",
    "I'm Tristen.",
    "I'm Walter.",
    "I'm Will.",
    "I'm William.",
    "I'm Zac.",
    "I'm Charlie.",
  ];
  women_names = [
    "My name's Abby.",
    "My name's Alex.",
    "My name's Alice.",
    "My name's Alicia.",
    "My name's Allie.",
    "My name's Amber.",
    "My name's Amy.",
    "My name's Andrea.",
    "My name's Anna.",
    "My name's Bella.",
    "My name's Blair.",
    "My name's Carla.",
    "My name's Charlie.",
    "My name's Charlotte.",
    "My name's Chloe.",
    "My name's Daisy.",
    "My name's Deboarh.",
    "My name's Diana.",
    "My name's Ella.",
    "My name's Emily.",
    "My name's Eva.",
    "My name's Faith.",
    "My name's Georgia.",
    "My name's Ginny.",
    "My name's Hailey.",
    "My name's Hannah.",
    "My name's Heidi.",
    "My name's Holly.",
    "My name's Ivy.",
    "My name's Jennifer.",
    "My name's Jenny.",
    "My name's Julia.",
    "My name's Kate.",
    "My name's Katie.",
    "My name's Keira.",
    "My name's Khloe.",
    "My name's Kourtney.",
    "I'm Laura.",
    "I'm Leah.",
    "I'm Lexi.",
    "I'm Libby.",
    "I'm Lily.",
    "I'm Linda.",
    "I'm Lindsay.",
    "I'm Louise.",
    "I'm Lucy.",
    "I'm Maddison.",
    "I'm Madeline.",
    "I'm Mallory.",
    "I'm Maria.",
    "I'm Mattie.",
    "I'm Max.",
    "I'm Maxine.",
    "I'm Maya.",
    "I'm Meghan.",
    "I'm Mikaela.",
    "I'm Miley.",
    "I'm Miranda.",
    "I'm Monica.",
    "I'm Naomi.",
    "I'm Nicole.",
    "I'm Olivie.",
    "I'm Phoebe.",
    "I'm Rachel.",
    "I'm Rebecca.",
    "I'm Reese.",
    "I'm Rosie.",
    "I'm Ruby.",
    "I'm Sam.",
    "I'm Samantha.",
    "I'm Scarlett.",
    "I'm Sofia.",
    "I'm Stephanie.",
    "I'm Tiana.",
    "I'm Zoe.",
  ];
  nice_to_meet = [
    'Great to meet you!',
    'Nice to meet you!',
    "It's so nice to meet you!",
    'Happy to meet you!',
    'Pleasure to meet you!',
  ];
  nice_to_meet_too = [
    'Great to meet you too! Where are you from?',
    'Nice to meet you too! Where are you from?',
    'It’s so nice to meet you too! Where are you from?',
    'Happy to meet you too! Where are you from?',
    'You too! Where are you from?',
    'Same here! Where are you from?',
  ];
  uk_places = [
    "I'm from London in the UK.",
    "I'm from Birmingham in the UK.",
    "I'm from Liverpool in the UK.",
    "I'm from Bristol in the UK.",
    "I'm from Manchester in the UK.",
    "I'm from Sheffield in the UK.",
    "I'm from Leeds in the UK.",
    "I'm from Leicester in the UK.",
    "I'm from Coventry in the UK.",
    "I'm from Bradford in the UK.",
    "I'm from Nottingham in the UK.",
    "I'm from Hull in the UK.",
    "I'm from Newcastle in the UK.",
    "I'm from Southampton in the UK.",
    "I'm from Portsmouth in the UK.",
    " I'm British. I live in Derby.",
    "I'm British. I live in Brighton.",
    "I'm British. I live in Plymouth.",
    "I'm British. I live in Dover.",
    "I'm British. I live in Wakefield.",
    "I'm British. I live in Sunderland.",
    "I'm British. I live in Derby.",
    "I'm British. I live in Salford.",
    "I'm British. I live in York.",
    "I'm British. I live in Oxford.",
    "I'm British. I live in Canterbury.",
    "I'm British. I live in Lancaster.",
    "I'm British. I live in Norwich.",
    "I'm British. I live in Cambridge.",
    "I'm British. I live in Gloucester.",
  ];
  us_places = [
    "I'm from Atlanta in the US.",
    "I'm from Boston in the US.",
    "I'm from Chicago in the US.",
    "I'm from Colorado Springs in the US.",
    "I'm from Dallas in the US.",
    "I'm from Denver in the US.",
    "I'm from Detroit in the US.",
    "I'm from Houston in the US.",
    "I'm from Kansas City in the US.",
    "I'm from Las Vegas in the US.",
    "I'm from Los Angeles in the US.",
    "I'm from Louisville in the US.",
    "I'm from Memphis in the US.",
    "I'm from Miami in the US.",
    "I'm from Milwaukee in the US.",
    " I'm American. I live in Minneapolis.",
    "I'm American. I live in Nashville.",
    "I'm American. I live in New Orleans.",
    "I'm American. I live in New York.",
    "I'm American. I live in Oklahoma City.",
    "I'm American. I live in Orlando.",
    "I'm American. I live in Philadelphia.",
    "I'm American. I live in Phoenix.",
    "I'm American. I live in San Antonio.",
    "I'm American. I live in San Diego.",
    "I'm American. I live in San Francisco.",
    "I'm American. I live in Santa Fe.",
    "I'm American. I live in Seattle.",
    "I'm American. I live in Tucson.",
    "I'm American. I live in Washington D.C.",
  ];
  english = [
    "I'm trying to improve my English.",
    "My English isn't perfect.",
    'Unfortunately, I only speak a little English.',
    'I want to get better at speaking English.',
  ];
  chat = [
    'Do you think we could chat for a moment?',
    'Do have a moment for a chat?',
    'Do you have time for a little chat?',
    'Do you feel like chatting with me?',
  ];
  agreeing_bot = [
    'Of course!',
    'Sure thing!',
    "I'd love to!",
    'With pleasure!',
    'By all means!',
    "I'd be happy to!",
    'Absolutely!',
  ];
  asking = ["Here's a question for you:", 'So let me ask you:', 'Tell me:'];
  negative = [
    'Oh no.',
    'Oh man.',
    "I'm sorry.",
    "That's too bad.",
    'I get it.',
    "That's tough.",
    'I can imagine.',
    'Same here.',
    "I'm with you.",
    "I've got you.",
    'I can relate to that.',
    'I get that.',
    "I get what you're saying.",
  ];
  positive = [
    'All right!',
    "That's awesome!",
    'Really?',
    'Is that right?',
    'Oh, wow!',
    "That's great!",
    "That's amazing!",
    'No way!',
    'Same here!',
    "I'm with you!",
    'Sounds great!',
  ];
  neutral = [
    'Mhmmm.',
    'Oh.',
    'Ah.',
    'Is that right?',
    'I see.',
    'OK.',
    'Got it.',
    'Right.',
    'Uh-huh.',
  ];
  another_question = [
    'I have another one for you.',
    'I have one more question for you.',
    'I have another question for you.',
    "Here's another question for you.",
    "Here's another one for you.",
    "Here's one more question for you.",
    "Hey, I've been wondering.",
    'Hey, tell me.',
  ];
  leaving = [
    "I'd love to stay and chat, but I have to go now.",
    'Sorry, to cut our chat short, but I have to get going.',
    'It was nice talking to you, but I have to run.',
  ];
  accepting = ['Sure.', 'Of course.', ' No problem.'];

  thanking = [
    'Thank you so much, though.',
    'I really appreciate it.',
    'It was great talking to you.',
  ];
  welcome = [
    "Don't mention it!",
    'You are very welcome!',
    'My pleasure!',
    'Any time!',
    'I had fun too!',
  ];

  again = [
    "Let's do it again sometime.",
    'We should do it again sometime.',
    "But don't be a stranger.",
    "Let's keep in touch!",
  ];
  agreeing_student = ["I'd love to!", 'That would be great!', 'Sounds great!'];

  constructor(private helpers: HelpersService) {
    this.my_name[0] = this.my_name[0] + ' ' + this.studentName;
  }
  addToFirstQuestionFromSet() {
    const chosenAgreeingBot = this.helpers.getUniqueRandomItemsFromArray(
      this.agreeing_bot,
      1
    )[0];
    const chosenAsking = this.helpers.getUniqueRandomItemsFromArray(
      this.asking,
      1
    )[0];
    return chosenAgreeingBot + ' ' + chosenAsking;
  }
  createQuestion(
    questionBank,
    answersBank,
    numberOfAnswers,
    addGreetingsToQuestion?,
    addChat?
  ) {
    let questionToAsk = this.helpers.getUniqueRandomItemsFromArray(
      questionBank,
      1
    )[0];
    let chosenAnswers = [];
    chosenAnswers = this.helpers.getUniqueRandomItemsFromArray(
      answersBank,
      numberOfAnswers
    );
    if (addGreetingsToQuestion) {
      const chosenNiceToMeet = this.helpers.getUniqueRandomItemsFromArray(
        this.nice_to_meet,
        1
      )[0];

      const chosenGreetings = this.helpers.getUniqueRandomItemsFromArray(
        this.greetings,
        1
      )[0];
      questionToAsk =
        chosenGreetings + ' ' + questionToAsk + ' ' + chosenNiceToMeet;
    }

    let answersToSend = chosenAnswers.map((answer) => {
      let answerToSend;
      if (addChat) {
        const chosenChat = this.helpers.getUniqueRandomItemsFromArray(
          this.chat,
          1
        )[0];
        answerToSend = { text: answer + ' ' + chosenChat };
      } else {
        answerToSend = { text: answer };
      }
      return answerToSend;
    });
    const question = {
      item: {
        qa: {
          question1: {
            question: questionToAsk,
          },

          answers: answersToSend,
        },
      },
    };
    console.log(
      '🚀 ~ file: chatbot.service.ts ~ line 364 ~ ChatbotService ~ createQuestion ~ question',
      question
    );
    return question;
  }
  addReactionToQuestion(question, reactionType) {
    if (reactionType === 'neutral') {
      const neutralReaction = this.helpers.getUniqueRandomItemsFromArray(
        this.neutral,
        1
      )[0];
      const nextQuestion = this.helpers.getUniqueRandomItemsFromArray(
        this.another_question,
        1
      );
      question.item.qa.question1.question =
        neutralReaction +
        ' ' +
        nextQuestion +
        ' ' +
        question.item.qa.question1.question;
    }
    if (reactionType === 'negative') {
      const negativeReaction = this.helpers.getUniqueRandomItemsFromArray(
        this.negative,
        1
      )[0];
      const nextQuestion = this.helpers.getUniqueRandomItemsFromArray(
        this.another_question,
        1
      );
      question.item.qa.question1.question =
        negativeReaction +
        ' ' +
        nextQuestion +
        ' ' +
        question.item.qa.question1.question;
    }
    if (reactionType === 'positive') {
      const positiveeReaction = this.helpers.getUniqueRandomItemsFromArray(
        this.positive,
        1
      )[0];
      const nextQuestion = this.helpers.getUniqueRandomItemsFromArray(
        this.another_question,
        1
      );
      question.item.qa.question1.question =
        positiveeReaction +
        ' ' +
        nextQuestion +
        ' ' +
        question.item.qa.question1.question;
    }
    return question;
  }
  getRandomWomensAvatar() {
    const avatar = this.helpers.getUniqueRandomItemsFromArray(
      this.women_avatars,
      1
    )[0];
    return avatar;
  }
  createLeavingAcceptingAndThankingQuestion() {
    let answersBank = [];
    for (let index = 0; index < 4; index++) {
      const accepting = this.helpers.getUniqueRandomItemsFromArray(
        this.accepting,
        1
      )[0];
      const thanking = this.helpers.getUniqueRandomItemsFromArray(
        this.thanking,
        1
      )[0];
      answersBank.push(accepting + ' ' + thanking);
    }

    return this.createQuestion(this.leaving, answersBank, 4);
  }
  createWelcomeAndAgainQuestion() {
    let answersBank = [];
    const welcome = this.helpers.getUniqueRandomItemsFromArray(
      this.welcome,
      1
    )[0];
    const again = this.helpers.getUniqueRandomItemsFromArray(this.again, 1)[0];

    const question = [welcome + ' ' + again];
    return this.createQuestion(question, this.agreeing_student, 4);
  }
}
