import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class Events2Service {
  events = [];
  //  url = 'https://backend-int.linget.it'
  url;
  private eventsUpdated = new Subject<any[]>();
  // url = 'https://test.lingking.com.pl:3001'
  private eventsFoundByIdUpdated = new Subject<any[]>();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url;
  }

  saveNewEvent(event) {
    this.http
      .post(this.url + '/api/events/addevent', event)
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: events2.service.ts ~ line 38 ~ Events2Service ~ saveNewEvent ~ response',
          response,
        );

        this.getEventsList();
      });
  }

  getEventsList() {
    this.http
      .get(this.url + '/api/events/geteventslist', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let eventsArray = Object.keys(response).map((i) => response[i]);

        eventsArray = eventsArray[0].map((incomingevent) => {
          return {
            id: incomingevent?._id,
            name: incomingevent?.data?.name,
            avalibleDays: incomingevent?.data?.avalibleDays,
            added: incomingevent?.data?.added,
            lastModified: incomingevent?.data?.lastModified,
            customerData: incomingevent?.data?.customerData,
            yearsPlan: incomingevent?.data?.yearsPlan,
          };
        });
        this.events = eventsArray;
        console.log(
          '🚀 ~ file: events.service.ts ~ line 56 ~ EventsService ~ geteventsList ~ this.events',
          this.events,
        );

        this.eventsUpdated.next(this.events);
      });
  }

  updateLessonStatus(
    eventId,
    dayToBookId,
    lessonToBookId,
    lessonState,
    customerData,
  ) {
    this.http
      .post(
        this.url + '/api/events/updatelessonstatus/',
        {
          id: eventId,
          dayToBookId: dayToBookId,
          lessonState: lessonState,
          lessonToBookId: lessonToBookId,
          customerData: customerData,
        },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: events2.service.ts ~ line 72 ~ Events2Service ~ updateLessonStatus ~ response',
          response,
        );
      });
  }

  findeventById(id) {
    this.http
      .post(
        this.url + '/api/events/findeventbyid/',
        { id: id },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        let eventsArray = Object.keys(response).map((i) => response[i]);

        eventsArray = eventsArray.map((incomingevent) => {
          return {
            id: incomingevent?._id,
            name: incomingevent?.data?.name,
            avalibleDays: incomingevent?.data?.avalibleDays,
            added: incomingevent?.data?.added,
            lastModified: incomingevent?.data?.lastModified,
            customerData: incomingevent?.data?.customerData,
            yearsPlan: incomingevent?.data?.yearsPlan,
          };
        });
        this.events = eventsArray;

        this.eventsFoundByIdUpdated.next(this.events[0]);
      });
  }

  filterStudents(text) {
    const lowerText = text.toLowerCase();
    const filteredStudents = this.events.filter((e) =>
      e.childFirstName.toLowerCase().includes(lowerText),
    );

    this.eventsUpdated.next(filteredStudents);
  }

  removeEvent(id) {
    this.http
      .delete(this.url + '/api/events/removeevent/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        this.events = this.events.filter((e) => e.id !== id);

        this.eventsUpdated.next(this.events);
      });
  }

  updateEvent(eventId, event) {
    this.http
      .put(this.url + '/api/events/updateevent/' + eventId, event, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        const currentId = this.events.findIndex((e) => e.id === eventId);
        const singleEvent = this.events[currentId];
        (singleEvent.name = event.name),
          (singleEvent.avalibleDays = event.avalibleDays),
          (singleEvent.added = event.added),
          (singleEvent.lastModified = event.lastModified),
          (singleEvent.yearsPlan = event.yearsPlan),
          this.eventsUpdated.next(this.events);
        this.getEventsList();
      });
  }

  eventsUpdatedListener() {
    return this.eventsUpdated.asObservable();
  }

  eventsFoundByIdListener() {
    return this.eventsFoundByIdUpdated.asObservable();
  }
}
