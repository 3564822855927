import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { th } from 'date-fns/locale';
import * as hljs from 'highlight.js';
import 'highlight.js/styles/default.css';  // You can choose different themes
@Component({
  selector: 'app-code-block',
  templateUrl: './code-block.component.html',
  styleUrls: ['./code-block.component.scss']
})
export class CodeBlockComponent implements OnInit {
  @Input() content: string;
  @Input() language
  @ViewChild('codeElement', { static: true }) codeElement!: ElementRef;
  code: string = '';

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.extractCode();
  }

  ngAfterViewInit() {
    this.highlightCode();
  }

  extractCode() {
    const codeRegex = /```(\w+)?\s*([\s\S]*?)```/;
    // extract language that is a first word in code block
    this.language = this.content.split('\n')[0].replace('```', '').trim();
    this.code = this.content.replace('```' + this.language, '').replace('```', '').trim();
    console.log("🚀 ~ CodeBlockComponent ~ extractCode ~ this.language:", this.language)
    const match = this.content.match(codeRegex);
    if (match) {
      this.code = match[2].trim();
      this.language = match[1] || 'plaintext'; // Set language from matched group or default to 'plaintext'
    } else {
      this.code = this.content.trim(); // Fallback to content if no match found
    }
  }

  highlightCode() {
    if (this.codeElement) {
      console.log("🚀 ~ CodeBlockComponent ~ highlightCode ~ this.language:", this.language)
      this.codeElement.nativeElement.className = `language-${this.language}`;
      this.codeElement.nativeElement.textContent = this.code;
      hljs.highlightBlock(this.codeElement.nativeElement);
    }
  }

  copyCode() {
    console.log('this.code:', this.code);
    console.log('this.language:', this.language);
    navigator.clipboard.writeText(this.code.replace(this.language, ''))
      .then(() => {
        console.log('Copy successful');
        this.snackBar.open('Code copied to clipboard', 'Close', { duration: 2000 });
      })
      .catch(err => {
        console.log('Copy failed', err);
      });
  }
}