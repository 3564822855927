<div *ngIf="taskToAdd" style="width: 100%; height: 100%" fxLayout="column">
  <mat-form-field appearance="outline">
    <mat-label>Tytuł</mat-label>
    <input
      required
      placeholder="Tytuł zadania"
      [(ngModel)]="taskToAdd.title"
      type="text"
      matInput
    />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Treść zadania</mat-label>
    <textarea
      required
      cdkAutosizeMaxRows="5"
      cdkTextareaAutosize
      matInput
      placeholder="Treść zadania"
      [(ngModel)]="taskToAdd.text"
    ></textarea>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Rodzaj</mat-label>
    <mat-select placeholder="Rodzaj" [(ngModel)]="taskToAdd.type">
      <mat-option *ngFor="let type of taskTypes" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Ważność</mat-label>
    <mat-select placeholder="Ważność" [(ngModel)]="taskToAdd.importance">
      <mat-option
        *ngFor="let importance of taskImportances"
        [value]="importance"
      >
        {{ importance }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div style="width: 100%" fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field fxFlex appearance="outline" style="margin-bottom: -1.25em">
      <input
        matInput
        type="dateTime-local"
        placeholder="Zaplanuj na"
        [(ngModel)]="taskToAdd.dateScheduled"
      />
    </mat-form-field>
    <button
      (click)="setCurrentDate()"
      matTooltip="Teraz"
      mat-icon-button
      color="primary"
    >
      <mat-icon>today</mat-icon>
    </button>
  </div>
  <mat-divider class="mb-3 mt-3"></mat-divider>
  <div fxLayoutAlign="end" fxLayout="row" fxLayoutGap="10px">
    <button mat-stroked-button color="primary" mat-dialog-close>Anuluj</button>
    <button
      *ngIf="!data?.editMode"
      mat-flat-button
      color="primary"
      (click)="addTask()"
    >
      Dodaj
    </button>
    <button
      *ngIf="!data?.editMode"
      mat-flat-button
      color="primary"
      (click)="correctText(this.taskToAdd.text)"
    >
      ?
    </button>
    <button
      *ngIf="data?.editMode"
      mat-flat-button
      color="primary"
      (click)="addTask()"
    >
      Zaaktualizuj
    </button>
  </div>
</div>
