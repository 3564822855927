<div class="d-flex flex-column">
  <img
    style="border-radius: 5px; max-width: 70vw; max-height: 80vh"
    [src]="data"
  />
  <button
    *ngIf="userDetailsService.owner || userDetailsService.office"
    (click)="setPictureAsMain()"
    mat-raised-button
    color="primary"
  >
    Ustaw jako obrazek ilustracyjny
  </button>
</div>
