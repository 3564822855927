<div class="m-3">
</div>
<div  fxLayoutGap="5px"   fxLayoutGap="gappx" fxLayoutAlign="center center" >
  <mat-card  fxLayout="column" fxFlex="95" fxFlex.xs="90" fxFlex.sm="90" fxFlex.md="60">
  <form fxLayoutAlign="space-between"  fxLayout="column" [formGroup]="registerForm" (ngSubmit)="onNewUserSubmit()" >

    <div >
    <mat-form-field  fxFlex="100%"   appearance="outline">
        <mat-label>Imię i nazwisko</mat-label>
        <input  matInput matInput formControlName="name">
        <mat-icon matSuffix>face</mat-icon>
        <mat-error *ngIf="registerForm.controls.name.hasError('required')">
          Wpisz imię i nazwisko
        </mat-error>
      </mat-form-field>
    </div>
          <mat-form-field  fxFlex="100%"  appearance="outline">
        <mat-label>E-mail</mat-label>
        <input  matInput matInput formControlName="email">
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-error *ngIf="registerForm.controls.email.hasError('email') && !registerForm.controls.email.hasError('required')">
         E-mail nieprawidłowy!
        </mat-error>
        <mat-error *ngIf="registerForm.controls.email.hasError('required')">
          Wpisz E-mail
        </mat-error>
      </mat-form-field>

      <mat-form-field  fxFlex="100%"   appearance="outline">
          <mat-label>Hasło</mat-label>
          <input [type]="hide ? 'password' : 'text'" matInput matInput formControlName="password">
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          <mat-error *ngIf="registerForm.controls.password.hasError('required')">
            Wpisz hasło
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="100"fxFlex.xs="80"  appearance="outline">
          <mat-label>rola</mat-label>
          <mat-select  formControlName="role">
            <mat-option value="owner">Owner</mat-option>
            <mat-option value="teacher">Teacher</mat-option>
            <mat-option value="student">Student</mat-option>
            <mat-option value="admin">Admin</mat-option>
            <mat-option value="agusia">Agusia</mat-option>
            <mat-option value="office">Office</mat-option>
          </mat-select>
        </mat-form-field>
     <div fxLayoutAlign="end end">
      <button (click)="this.changeView.emit('change')" class="mr-3" type="button" mat-stroked-button color="primary">back</button>
      <button mat-raised-button color="primary">Register</button>
     </div>
  </form>
</mat-card>

</div>
