import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fact-create-post',
  templateUrl: './fact-create-post.component.html',
  styleUrls: ['./fact-create-post.component.scss']
})
export class FactCreatePostComponent implements OnInit {
  selectedPostType = 1
  downloadPost = false
  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data)
  }
  download() {
    this.downloadPost = true
    setTimeout(() => {
      this.downloadPost = false
    }
      , 1000)
  }
  ngOnInit() {
  }

}
