import { Component, OnInit } from '@angular/core';
import { VideoMakerService } from '../../video-maker.service';

@Component({
  selector: 'app-video-downloader',
  templateUrl: './video-downloader.component.html',
  styleUrls: ['./video-downloader.component.scss']
})
export class VideoDownloaderComponent implements OnInit {
  itemsDownloaded
  interval
  countDown
  constructor(
    private videoMakerService: VideoMakerService
  ) { }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.itemsDownloaded = this.videoMakerService.checkDownloadingCompletion()
      console.log("🚀 ~ VideoDownloaderComponent ~ this.interval=setInterval ~ this.itemsDownloaded:", this.itemsDownloaded)
    }, 1000);


  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    clearInterval(this.interval);
  }
}
