<h2>Planowanie grupy</h2>
<div fxlayout="column" style="overflow-x: auto">
  <div
    fxLayout="row"
    style="width: 100%"
    fxLayoutAlign="start start"
    fxLAyoutGap="10px"
  >
    <div fxLayout="column">
      <mat-form-field
        style="margin-bottom: -1.25em"
        class="mr-2"
        appearance="outline"
      >
        <mat-label>Nazwa grupy</mat-label>
        <input type="text" matInput [(ngModel)]="groupTitle" />
      </mat-form-field>
      <mat-form-field
        style="margin-bottom: -1.25em"
        class="mr-2"
        appearance="outline"
      >
        <mat-label>Lokalizacja</mat-label>
        <mat-select
          [value]="tempGroup.meta.location"
          [(ngModel)]="tempGroup.meta.location"
        >
          <mat-option value="Bydgoszcz">Bydgoszcz</mat-option>
          <mat-option value="bialeblota">bialeblota</mat-option>
          <mat-option value="online">online</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="mr-2"
        style="margin-bottom: -1.25em"
        appearance="outline"
      >
        <mat-label
          >{{ !selectedTeacher ? "Lektor" : selectedTeacher.name }}
        </mat-label>
        <mat-select [(value)]="selectedTeacher">
          <mat-option [value]="teacher" *ngFor="let teacher of teachers">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
              <img height="30px" [src]="teacher.avatar" alt />
              <span>{{ teacher.name }}</span>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <mat-button-toggle-group
        style="margin-top: 3px"
        [(ngModel)]="selectedDay"
        class="mb-2"
      >
        <mat-button-toggle value="2022-09-05T">Poniedziałek</mat-button-toggle>
        <mat-button-toggle value="2022-09-06T">Wtorek</mat-button-toggle>
        <mat-button-toggle value="2022-09-07T">Środa</mat-button-toggle>
        <mat-button-toggle value="2022-09-08T">Czwartek</mat-button-toggle>
        <mat-button-toggle value="2022-09-09T">Piątek</mat-button-toggle>
        <mat-button-toggle value="2022-09-10T">Sobota</mat-button-toggle>
      </mat-button-toggle-group>
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <mat-form-field fxFlex="50" appearance="outline">
          <mat-label>Godzina początek</mat-label>
          <input
            (focus)="timeEntered()"
            [(ngModel)]="enteredStartTime"
            type="time"
            matInput
          />
        </mat-form-field>
        <mat-form-field fxFlex="50" appearance="outline">
          <mat-label>Godzina koniec</mat-label>
          <input
            [(ngModel)]="enteredEndTime"
            (focus)="timeEntered()"
            type="time"
            matInput
          />
        </mat-form-field>
        <button
          *ngIf="!groupToEdit"
          (click)="setTimes()"
          class="mb-3"
          mat-icon-button
          color="primary"
        >
          <mat-icon class="mat-18">done</mat-icon>
        </button>
        <button
          *ngIf="groupToEdit"
          (click)="addStudentToGroup()"
          class="mb-3"
          mat-icon-button
          color="primary"
        >
          <mat-icon class="mat-18">add_circle_outline</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayoutAlign="center center">
      <mat-chip-list class="mt-3">
        <mat-chip
          (removed)="removeStudentFromGroup(student)"
          color="primary"
          selected
          *ngFor="let student of groupToEdit?.meta?.students"
        >
          <span
            >{{
              student.childFirstName
                ? student.childFirstName + "&nbsp;"
                : student.adultFirstName + "&nbsp;"
            }}
          </span>
          <span
            >{{
              student.childLastName
                ? student.childLastName + "&nbsp;"
                : student.adultLastName
            }}
          </span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
  <div
    fxLayout="row"
    style="width: 100%"
    class="mt-2 mb-2"
    fxLayoutAlign="center center "
  ></div>
  <div *ngIf="studentsForGroup?.length > 0">
    <app-plan-dayview
      [day]="1"
      [studentsForGroup]="studentsForGroup"
    ></app-plan-dayview>
    <hr />
    <app-plan-dayview
      [day]="2"
      [studentsForGroup]="studentsForGroup"
    ></app-plan-dayview>
    <hr />
    <app-plan-dayview
      [day]="3"
      [studentsForGroup]="studentsForGroup"
    ></app-plan-dayview>
    <hr />
    <app-plan-dayview
      [day]="4"
      [studentsForGroup]="studentsForGroup"
    ></app-plan-dayview>
    <hr />
    <app-plan-dayview
      [day]="5"
      [studentsForGroup]="studentsForGroup"
    ></app-plan-dayview>
  </div>
</div>
