import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { randomstring } from 'randomstring';
import { Subject } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { HelpersService } from './helpers.service';
import { ErrorLoggingService } from './error-logging.service';
import { Report } from '../components/create-report/report.model';
import { VersionService } from './version.service';
import { pipe } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  studentsForGroup;
  students = [];
  private studentsUpdated = new Subject<any[]>();
  private foundCustomers = new Subject<any[]>();
  private studentsForGroupUpdated = new Subject<any[]>();
  private studentsForGroupUpdatedForLesson = new Subject<any[]>();
  private statsReplaced = new Subject();
  customerInInvoicingAdded = new Subject();
  // url = 'https://test.lingking.com.pl:3001'
  //  url = 'https://backend-int.linget.it'
  url;
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private dbService: NgxIndexedDBService,
    private helper: HelpersService,
    private errorLoggingService: ErrorLoggingService,
    private versionService: VersionService,
    private helpers: HelpersService,
  ) {
    this.url = this.versionService.url;
  }

  saveNewStudent(studentData) {
    this.http
      .post(this.url + '/api/customers/save', studentData)
      .subscribe((response) => {
        this.getCustomersList();
      });
  }

  saveCustomerPrices(dataToSend) {
    this.http
      .post(this.url + '/api/customers/saveprices', dataToSend)
      .subscribe((response) => {
        this.getCustomersList();
      });
  }

  transformateCustomersToStudents(customers) {
    const adults = [];
    const children = [];

    customers.forEach((e) => {
      e.students?.forEach((s) => {
        if (s.child) {
          const child = {
            customerFirstName: e.customerFirstName,
            customerLastName: e.customerLastName,
            customerPhone: e.customerPhone,
            customerEmail: e.customerEmail,
            childFirstName: s.childFirstName,
            childLastName: s.childLastName,
            childGrade: s.childGrade,
            childLevel: s.childLevel,
            childPreviousClasses: s.childPreviousClasses,
            additionalData: s.additionalData,
            child: s.child,
            studentId: s.id,
            customerId: e.id,
            studentAppId: s.studentAppId,
            customerAppId: e.customerAppId,
            studentGroup: s.studentGroup,
            studentCourses: s.studentCourses,
            studentTodos: s.studentTodos,
            studentFlags: s.studentFlags,
            studentSets: s.studentSets,
            studentCustomerId: s.studentCustomerId,
            studentLogin: s.studentLogin,
            studentlocked: s.studentlocked,
            studentFresh: s.false,
            studentCourse1: s.studentCourse1,
            studentCourses2: s.studentCourses2,
            studentCourse3: s.studentCourse3,
            studentCourse4: s.studentCourse4,
            studentGroups: s.groups,
            studentCoursesPrices: s.studentCoursesPrices,
          };
          children.unshift(child);
        } else {
          if (!s.child) {
            const adult = {
              customerFirstName: e.customerFirstName,
              customerLastName: e.customerLastName,
              customerPhone: e.customerPhone,
              customerEmail: e.customerEmail,
              adultFirstName: s.adultFirstName,
              adultLastName: s.adultLastName,
              adultPhone: s.adultPhone,
              adultEmail: s.adultEmail,
              additionalData: s.adultAdditionalData,
              testResults: s.adultTestData,
              child: s.child,
              studentId: s.id,
              customerId: e.id,
              studentAppId: s.studentAppId,
              customerAppId: e.customerAppId,
              alsoStudent: e.alsoStudent,

              studentGroup: s.studentGroup,
              studentCourses: s.studentCourses,
              studentTodos: s.studentTodos,
              studentFlags: s.studentFlags,
              studentSets: s.studentSets,
              studentCustomerId: s.studentCustomerId,
              studentLogin: s.studentLogin,
              studentlocked: s.studentlocked,
              studentFresh: s.false,
              studentCourse1: s.studentCourse1,
              studentCourses2: s.studentCourses2,
              studentCourse3: s.studentCourse3,
              studentCourse4: s.studentCourse4,
              studentGroups: s.groups,
              studentCoursesPrices: s.studentCoursesPrices,
            };
            //this is wrong, make clear customer student checkbox
            if (s.adult === e.customerId) {
              adult.alsoStudent = true;
              adult.studentAppId = adult.customerAppId;
            }
            adults.unshift(adult);
          }
        }
      });
    });
    return { adults: adults, children: children };
  }
  getCustomersList() {
    this.http
      .get(this.url + '/api/customers/getList', {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        let studentsArray = Object.keys(response).map((i) => response[i]);

        studentsArray = studentsArray[0].map((incomingStudent) => {
          return {
            customerId: incomingStudent._id,
            customerFirstName: incomingStudent.data.customerFirstName,
            customerCompany: incomingStudent.data.customerCompany,
            customerLastName: incomingStudent.data.customerLastName,
            customerPhone: incomingStudent.data.customerPhone,
            customerEmail: incomingStudent.data.customerEmail,
            customerStreet: incomingStudent.data.customerStreet,
            customerHouseholdNumber:
              incomingStudent.data.customerHouseholdNumber,
            customerPostcode: incomingStudent.data.customerPostcode,
            customerCity: incomingStudent.data.customerCity,
            customerNipPesel: incomingStudent.data.customerNipPesel,
            customerAdditionalData: incomingStudent.data.customerAdditionalData,
            customerAppId: incomingStudent.data.customerAppId,
            customersInvoicingId: incomingStudent.data.customersInvoicingId,
            customersInvoicingPanelUrl:
              incomingStudent.data.customersInvoicingPanelUrl,
            customersContractsData: incomingStudent.data.customersContractsData,
            students: incomingStudent.data.students,
          };
        });
        this.students = studentsArray;

        this.studentsUpdated.next(this.students);
      });
  }
  // filterStudents(text) {
  //
  //   const lowerText = text.toLowerCase()
  //   const filteredStudents = this.students.filter(e => e.childFirstName.toLowerCase().includes(lowerText))

  //   this.studentsUpdated.next(filteredStudents)

  // }
  getStudentsForGroup(studentsIds, forLesson?) {
    const sub = this.http
      .post(this.url + '/api/customers/find-students', studentsIds)
      .subscribe((response) => {
        this.studentsForGroup = Object.keys(response).map(
          (i) => response[i],
        )[0];
        if (forLesson) {
          this.studentsForGroupUpdatedForLesson.next(this.studentsForGroup);
        } else {
          this.studentsForGroupUpdated.next(this.studentsForGroup);
        }
        this.studentsForGroupUpdated.next(this.studentsForGroup);
      });
  }
  findCustomers(customersIds) {
    this.http
      .post(this.url + '/api/customers/find-customers', customersIds)
      .subscribe((response) => {
        let foundCustomers = Object.keys(response).map((i) => response[i])[0];
        foundCustomers = foundCustomers[0].map((incomingStudent) => {
          return {
            customerId: incomingStudent._id,
            customerFirstName: incomingStudent.data.customerFirstName,
            customerCompany: incomingStudent.data.customerCompany,
            customerLastName: incomingStudent.data.customerLastName,
            customerPhone: incomingStudent.data.customerPhone,
            customerEmail: incomingStudent.data.customerEmail,
            customerStreet: incomingStudent.data.customerStreet,
            customerHouseholdNumber:
              incomingStudent.data.customerHouseholdNumber,
            customerPostcode: incomingStudent.data.customerPostcode,
            customerCity: incomingStudent.data.customerCity,
            customerNipPesel: incomingStudent.data.customerNipPesel,
            customerAdditionalData: incomingStudent.data.customerAdditionalData,
            customerAppId: incomingStudent.data.customerAppId,
            customersInvoicingId: incomingStudent.data.customersInvoicingId,
            customersInvoicingPanelUrl:
              incomingStudent.data.customersInvoicingPanelUrl,
            customersContractsData: incomingStudent.data.customersContractsData,
            students: incomingStudent.data.students,
          };
        });

        this.foundCustomers.next(foundCustomers);
        return foundCustomers;
      });
  }

  removeCustomer(id) {
    this.http
      .delete(this.url + '/api/customers/delete-customer/' + id, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => {
        this.students = this.students.filter((e) => e.id !== id);

        this.getCustomersList();
      });
  }
  updateCustomer(customer) {
    console.log("🚀 ~ file: customers.service.ts:249 ~ CustomersService ~ updateCustomer ~ customer:", customer)
    //
    customer.students.forEach((student) => {
      if (student?.studentCourses2?.length > 0) {
        student.studentCourses2.forEach((course) => {
          course.fixedCourseData.studentName = student.childFirstName ? student.childFirstName + ' ' + student.childLastName : student.adultFirstName + ' ' + student.adultLastName
        }
        )
      }


    })
    this.http
      .put(
        this.url + '/api/customers/update-customer/' + customer.customerId,
        customer,
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        const currentId = this.students.findIndex(
          (e) => e.customerId === customer.customerId,
        );
        // const adultId = this.students[currentId].students.findIndex(e=>e.id === customer.customerId)
        const singleCustomer = this.students[currentId];
        singleCustomer.customerFirstName = customer.customerFirstName;
        singleCustomer.customerCompany = customer.customerCompany;
        singleCustomer.customerLastName = customer.customerLastName;
        singleCustomer.customerPhone = customer.customerPhone;
        singleCustomer.customerEmail = customer.customerEmail;
        singleCustomer.customerStreet = customer.customerStreet;
        singleCustomer.customerHouseholdNumber =
          customer.customerHouseholdNumber;
        singleCustomer.postCode = customer.postCode;
        singleCustomer.city = customer.city;
        singleCustomer.customerNipPesel = customer.customerNipPesel;
        singleCustomer.customerAdditionalData = customer.customerAdditionalData;
        singleCustomer.customerAppId = customer.customerAppId;
        singleCustomer.customersInvoicingId = customer.customersInvoicingId;
        singleCustomer.customersContractsData = customer.customersContractsData;
        singleCustomer.customersInvoicingPanelUrl =
          customer.customersInvoicingPanelUrl;


        this.http
          .post(
            this.url + '/api/invoices/update-customer-in-invoicing/',
            {
              customerData: customer,
            },

            {
              headers: {
                Authorization: `Bearer ${this.authService.getToken()}`,
              },
            },
          )
          .subscribe((response) => {
            this.studentsUpdated.next(this.students);
            this.getCustomersList();
          });
      });
  }
  updateChild(child) {
    this.http
      .put(
        this.url + '/api/customers/update-child/' + child.customerId,
        child,
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        const currentId = this.students.findIndex(
          (e) => e.id === child.customerId,
        );
        const childId = this.students[currentId].students.findIndex(
          (e) => e.id === child.childId,
        );
        const singleChild = this.students[currentId].students[childId];

        singleChild.childFirstName = child.childFirstName;
        singleChild.childLastName = child.childLastName;
        singleChild.childLevel = child.childLevel;
        singleChild.childGrade = child.childGrade;
        singleChild.childPreviousClasses = child.childPreviousClasses;
        singleChild.additionalData = child.additionalData;
        this.studentsUpdated.next(this.students);
      });
  }
  updateStudentAppId(customerId, studentId, studentAppId) {
    this.http
      .put(
        this.url + '/api/customers/update-student-app-id/' + customerId,
        { studentId: studentId, studentAppId: studentAppId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: customers.service.ts ~ line 292 ~ CustomersService ~ updateStudentAppId ~ response',
          response,
        );
      });
  }

  updateAdult(adult) {
    this.http
      .put(
        this.url + '/api/customers/update-adult/' + adult.customerId,
        adult,
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        const currentId = this.students.findIndex(
          (e) => e.id === adult.customerId,
        );
        const adultId = this.students[currentId].students.findIndex(
          (e) => e.id === adult.adultId,
        );
        const singleAdult = this.students[currentId].students[adultId];
        singleAdult.adultFirstName = adult.adultFirstName;
        singleAdult.adultLastName = adult.adultLastName;
        singleAdult.adultPhone = adult.adultPhone;
        singleAdult.adultEmail = adult.adultEmail;
        singleAdult.adultAdditionalData = adult.adultAdditionalData;

        this.studentsUpdated.next(this.students);
      });
  }
  generateLoginLink(customer, alsoSend?) {
    this.http
      .post(
        this.url + '/api/users/generate-login-link/',
        { customerId: customer.customerAppId, alsoSend: alsoSend },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: customers.service.ts ~ line 292 ~ CustomersService ~ updateStudentAppId ~ response',
          response,
        );
      });
  }
  addCustomerInInvoicing(customerData) {
    const formData = customerData;
    console.log(
      '🚀 ~ file: invoicing.service.ts:33 ~ InvoicingService ~ addCustomerInInvoicing ~ formData:',
      formData,
    );

    // Return the Observable here
    return this.http.post(
      this.url + '/api/invoices/add-customer-to-invoicing',
      {
        customerData: customerData,
      },
    );
  }
  createContract(customer) {
    let coursesToSend = [];
    customer.students.forEach((student) => {
      student.studentCourses2.forEach((course) => {
        if (course.selected) {
          coursesToSend.push(course);
        }
      });
    });
    coursesToSend.forEach((course) => {
      if (
        !course.contractData.courseStartDate ||
        course.contractData.courseStartDate === '' ||
        course.contractData.courseEndDate === '' ||
        !course.contractData.courseEndDate
      ) {
        alert('W którymś kursie brakuje Sprawdź daty kursu');
        return;
      }
    });

    // Check if customersInvoicingId exists
    if (!customer.customersInvoicingId) {
      // If it doesn't exist, add the customer to invoicing first
      this.addCustomerInInvoicing(customer)
        .pipe(
          switchMap((responseFromAddCustomer) => {
            this.getCustomersList();
            console.log(
              '🚀 ~ file: invoicing.service.ts:42 ~ InvoicingService ~ .subscribe ~ response:',
              responseFromAddCustomer,
            );

            // Then create the contract
            return this.http.post(
              this.url + '/api/invoices/add-contract/',
              {
                customerData: customer,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.authService.getToken()}`,
                },
              },
            );
          }),
        )
        .subscribe((responseFromCreateContract) => {
          // this.studentsUpdated.next(this.students);
          // this.getCustomersList();
        });
    } else {
      // If customersInvoicingId exists, directly create the contract
      this.http
        .post(
          this.url + '/api/invoices/add-contract/',
          {
            customerData: customer,
          },
          {
            headers: {
              Authorization: `Bearer ${this.authService.getToken()}`,
            },
          },
        )
        .subscribe(
          (response) => {
            // this.studentsUpdated.next(this.students);
            alert('Umowa została dodana i wysłana do klienta');
          },
          (err) => {
            alert('Wystąpił błąd. Spróbuj ponownie później.');
            console.error(err);
          },
        );
    }
  }

  addReport(report: Report, studentId: string, studentCustomerId: string) {
    this.http
      .put(
        this.url + '/api/customers/add-report/' + studentCustomerId,
        { reportToAdd: report, studentId: studentId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: customers.service.ts ~ line 337 ~ CustomersService ~ addReport ~ response',
          response,
        );
      });
  }
  setStudentsGender(
    gender: 'male' | 'female',
    studentCustomerId: string,
    studentId: string,
  ) {
    this.http
      .put(
        this.url + '/api/customers/set-students-gender/' + studentCustomerId,
        { gender: gender, studentId: studentId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: customers.service.ts ~ line 337 ~ CustomersService ~ addReport ~ response',
          response,
        );
      });
  }
  removeReport(reportDate: Date, studentId: string, studentCustomerId: string) {
    this.http
      .put(
        this.url + '/api/customers/remove-report/' + studentCustomerId,
        { reportDate: reportDate, studentId: studentId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: customers.service.ts ~ line 337 ~ CustomersService ~ addReport ~ response',
          response,
        );
      });
  }
  removeStudentFromCustomer(customerId, studentId) {
    this.http
      .post(
        this.url + '/api/customers/removestudentfromcustomer/',
        { studentId: studentId, customerId: customerId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => {
        this.studentsUpdated.next(this.students);
      });
  }
  updateStudentsSet(set, studentId) {
    this.http
      .put(this.url + '/api/customers/updatestudentsset/' + studentId, set, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => { });
  }

  updateSet(studentId, set) {
    this.http
      .put(this.url + '/api/customers/updateset/' + studentId, set, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((response) => { });
  }
  updateStats(
    userDetails,
    studentId,
    stats,
    setId,
    statsItemUpdate,
    knownItemsUpdate,
    weeklyProgress,
    finished,
    currentSet?,
    quizParams?,
  ) {
    // this.errorLoggingService.sendLogToElastic("debug", {
    //   message: "stats updated successfully",
    //   debugData: { quizParams: "asd" },
    // });

    if (!quizParams) {
      quizParams = stats.quizParams;
    }
    currentSet.stats?.knownItems.map((item) => {
      return {};
    });
    stats.knownItems?.map((item) => {
      return {};
    });
    console.log('stats.knownItems: ', stats.knownItems);

    const statsId = this.helper.makeid(8);

    const setToUpdate = userDetails.userData.sets.filter(
      (set) => set.id === setId,
    )[0];

    if (setToUpdate) {
      if (!setToUpdate?.stats) {
        if (currentSet) {
          setToUpdate.stats = {
            currentProgress: 0,
            initialNumberOfItems: 0,
            items: [],
            knownItems: [],
            lastLearned: 0,
            lastQuestionTime: 0,
            numberOfRuns: 0,
            startTime: Date.now(),
            totalItems: 0,
            weeklyProgress: 0,
          };
          setToUpdate.stats.items.push(statsItemUpdate);
          setToUpdate.stats.knownItems.push({});
          setToUpdate.stats.quizParams = quizParams;
          setToUpdate.stats.weeklyProgress = weeklyProgress;
        }
      } else {
        if (setToUpdate?.stats) {
          setToUpdate.stats.items.push(statsItemUpdate);
          setToUpdate.stats.knownItems.push({});
          setToUpdate.stats.quizParams = quizParams;
          setToUpdate.stats.weeklyProgress = weeklyProgress;
          setToUpdate.stats.quizParams = quizParams;
          setToUpdate.stats.lastQuestionTime = Date.now();

          if (finished) {
            setToUpdate.stats.items = [];
            setToUpdate.stats.knownItems = [];
            if (setToUpdate?.stats?.quizParams?.multiQuizParams) {
              setToUpdate.stats.numberOfRuns++;
            }
            setToUpdate.stats.weeklyProgress = weeklyProgress;
            setToUpdate.stats.lastQuestionTime = Date.now();
          }
        }
      }
      let _weeklyProgress = 0;
      userDetails.userData.sets.forEach((set) => {
        if (set.stats?.weeklyProgress > 0) {
          _weeklyProgress = _weeklyProgress + set.stats?.weeklyProgress;
        }
      });

      userDetails.userData.weeklyProgress = _weeklyProgress;

      // const currentSet = userDetails.userData.sets.filter(set =>set.id == setId)[0]
      currentSet.stats = setToUpdate.stats;
      this.dbService.update('other', { id: 'currentSet', value: setToUpdate });
    }
    const statsItem = {
      setId: setId,
      userId: userDetails._id,
      stats: currentSet.stats,
      statsId: statsId,
    };
    let res = null;
    // this.errorLoggingService.sendErrorToElastic('debug', {message: 'updating stats...', debugData:{
    //   newWeeklyProgress: Math.round(weeklyProgress /60/45*100), setToUpdateStatsDontExist:!setToUpdate?.stats,
    //   stats:stats
    // }, name: userDetails.name } )

    this.http
      .put(this.url + '/api/customers/update-stats/' + studentId, {
        stats: 'no',
        setId: setId,
        statsItemUpdate: statsItemUpdate,
        statsId: statsId,
        knownItemsUpdate: {},
        weeklyProgress: weeklyProgress,
        finished: finished,
        quizParams: quizParams,
      })
      // ,
      // { headers: { Authorization: `Bearer ${this.authService.getToken()}` }})
      .subscribe(
        (response: any) => {
          console.log('response: ', response);
          res = response;
          this.errorLoggingService.debug('stats update completed', response);
        },
        (error) => {
          // this.errorLoggingService.sendLogToElastic("debug", {
          //   message: "stats updated failed",
          //   debugData: {
          //     responseFromServer: res,
          //     error: error,
          //     statsItem: statsItem,
          //   },
          // });
        },
      );
  }
  replaceStats(studentId, setId, stats, name, source?) {
    //   console.log('stats: ', stats);
    //   console.log('setId: ', setId);
    //   const debugData = {newQuizParams: stats?.quizParams, newWeeklyProgress: Math.round(stats?.weeklyProgress /60/45*100) , source:source}
    //   this.errorLoggingService.sendErrorToElastic('debug', {message: 'replacing stats...', debugData:debugData, name: name } )
    //   // this.dbService.getByKey('other','userDetails' ).subscribe((data) => {
    //   //   if(data){
    //   //    const setToUpdate = data.value.userData.sets.filter(element=>element.id == setId)[0]
    //   //    console.log('setToUpdate: ', setToUpdate);
    //   //    setToUpdate.stats = stats
    //   //     this.dbService.update('other', {id:'userDetails', value: data.value})
    //   //   }
    //   // })
    //      stats.knownItems = stats.knownItems?.map((item)=>{
    //         return ""
    //       })
    //   this.http.post(this.url + '/api/customers/replace-stats/'
    //   + studentId,
    //    {setId:setId, stats:stats }
    //    )
    //   // ,
    //   // { headers: { Authorization: `Bearer ${this.authService.getToken()}` }})
    //   .subscribe((response:any) => {
    //     console.log('response: ', response);
    //     const weeklyProgressPercent = Math.round(response.newWeeklyProgress /60/45*100)
    //     console.log('weeklyProgressPercent: ', weeklyProgressPercent);
    // this.statsReplaced.next({newWeeklyProgress: response.newWeeklyProgress})
    //   }, error=>{
    // this.errorLoggingService.sendError('stats not replaced, problem on the server', {stats:stats, error: error});
    // console.log('error: ', error);
    //   })
  }
  updateStarredItems(studentId, setId, starredItems) {
    const newStarredItems = this.helper.clone(starredItems);
    newStarredItems.forEach((e) => {
      e.iconPicture = '';
      e.childrenIconPicture = '';
      e.childrenSmallPicture = '';
      e.smallPicture = '';
      e.item.iconPicture = '';
      e.item.childrenIconPicture = '';
      e.item.childrenSmallPicture = '';
      e.item.smallPicture = '';
    });
    this.http
      .put(this.url + '/api/customers/update-starred-items/' + studentId, {
        setId: setId,
        starredItems: newStarredItems,
      })
      // ,
      // { headers: { Authorization: `Bearer ${this.authService.getToken()}` }})
      .subscribe((response) => {
        this.dbService.getByKey('other', 'currentSet').subscribe((data) => {
          if (data) {
            const setToUpdate = data.value;
            console.log('setToUpdate: ', setToUpdate);
            setToUpdate.starredItems = starredItems;
            data.value.starredItems = starredItems;
            this.dbService.update('other', {
              id: 'currentSet',
              value: data.value,
            });
          }
        });
      });
  }
  updateSettings(studentId, speed) {
    //empty settings works
    const settings = {};
    this.http
      .put(this.url + '/api/customers/update-settings/' + studentId, {
        settings: settings,
      })
      // ,
      // { headers: { Authorization: `Bearer ${this.authService.getToken()}` }})
      .subscribe((response) => { });
  }
  addGroupToStudent(groupId, studentId) {
    this.http
      .put(
        this.url + '/api/customers/addgrouptostudent/' + studentId,
        { groupId: groupId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }

  removeGroupFromStudent(groupId, studentId) {
    this.http
      .put(
        this.url + '/api/customers/removegroupfromstudent/' + studentId,
        { groupId: groupId },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } },
      )
      .subscribe((response) => { });
  }

  studentsUpdatedListener() {
    return this.studentsUpdated.asObservable();
  }
  statsReplacedListener() {
    return this.statsReplaced.asObservable();
  }
  foundCustomersListener() {
    return this.foundCustomers.asObservable();
  }
  studentsForGroupUpdatedListener() {
    return this.studentsForGroupUpdated.asObservable();
  }
  studentsForGroupUpdatedForLessonListener() {
    return this.studentsForGroupUpdatedForLesson.asObservable();
  }
  customerInInvoicingAddedListener() {
    return this.customerInInvoicingAdded.asObservable();
  }
}
