<div style="width: 100%; height: 100%" class="d-flex flex-column">
  <div *ngFor="let item of data.meanings">
    <app-set-item
      fxFlex
      [showAudioProperties]="true"
      [editDescriptionAllowed]="true"
      [showDescriptions]="true"
      [showDescriptionsFlags]="true"
      [exportMode]="false"
      [checkBox]="false"
      [checkboxSelected]="false"
      [meaning]="item"
    ></app-set-item>
  </div>
  <div style="flex: 1"></div>
  <button mat-flat-button color="primary" [mat-dialog-close]="data.meanings">
    close
  </button>
</div>
