
<div>
<button [color]="iconsColor" (click)="_undoClicked()" mat-icon-button>
    <mat-icon >undo</mat-icon>
  </button>

<button [matMenuTriggerFor]="colorsMenu" [color]="iconsColor"  mat-icon-button>
    <mat-icon [style.color]="selectedColor.value" >palette</mat-icon>
  </button>

<button [matMenuTriggerFor]="linesMenu" [color]="iconsColor"   mat-icon-button>
    <mat-icon >line_weight</mat-icon>
  </button>
</div>


<mat-menu #colorsMenu="matMenu">
  <button (click)="colorSelected({value:color})"
   [style.color]="color.value === '#000' || color.value === '#673ab7' ? 'white': 'black'"
   [style.background-color]="color.value"
   *ngFor="let color of colors" mat-menu-item>
    <mat-icon    [style.color]="color.value === '#000' || color.value === '#673ab7' ? 'white': 'black'"
    >invert_colors</mat-icon>
    <span>{{color.name}}</span>
  </button>
</mat-menu>


<mat-menu #linesMenu="matMenu">
  <button (click)="lineThicknessChanged({value:4})" mat-menu-item>
    <mat-icon>horizontal_rule</mat-icon>
    <span >thin</span>
  </button>
  <button (click)="lineThicknessChanged({value:9})" mat-menu-item>
    <mat-icon>horizontal_rule</mat-icon>
    <span >medium</span>
  </button>
  <button (click)="lineThicknessChanged({value:15})" mat-menu-item>
    <mat-icon>horizontal_rule</mat-icon>
    <span >thick</span>
  </button>

</mat-menu>
