<div class="m-3 d-flex flex-column h-100" style="overflow-y: hidden">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Super audio recorder</mat-card-title>
    </mat-card-header>
    <div
      style="gap: 15px"
      class="d-flex flex-row flex-wrap justify-content-between align-items-center"
    >
      <mat-form-field
        style="margin-top: 1.25em; width: 100%"
        appearance="outline"
      >
        <textarea
          [(ngModel)]="textToRecord"
          matInput
          rows="5"
          placeholder="Enter text to record"
        ></textarea>
      </mat-form-field>
      <mat-form-field
        style="margin-bottom: -1.25em; flex: 1"
        appearance="outline"
      >
        <mat-label>Select language</mat-label>
        <mat-select
          (selectionChange)="fillInVoices()"
          [(ngModel)]="selectedLanguage"
        >
          <mat-option *ngFor="let lang of languages" [value]="lang">
            {{ lang }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        style="margin-bottom: -1.25em; flex: 1"
        appearance="outline"
      >
        <mat-label>Select gender</mat-label>
        <mat-select
          (selectionChange)="fillInVoices()"
          [(ngModel)]="selectedGender"
        >
          <mat-option *ngFor="let gender of genders" [value]="gender">
            {{ gender }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        style="margin-bottom: -1.25em; flex: 1"
        appearance="outline"
      >
        <mat-label>Select voice</mat-label>
        <mat-select [(ngModel)]="selectedVoice">
          <mat-option *ngFor="let voice of voicesToChoose" [value]="voice">
            {{ voice }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        style="margin-bottom: -1.25em; flex: 1"
        appearance="outline"
      >
        <mat-label>Select emotion</mat-label>
        <mat-select [(ngModel)]="selectedEmotion">
          <mat-option *ngFor="let emotion of emotions" [value]="emotion">
            {{ emotion }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        style="margin-bottom: -1.25em; flex: 1"
        appearance="outline"
      >
        <mat-label>Select speed</mat-label>
        <mat-select [(ngModel)]="selectedSpeed">
          <mat-option *ngFor="let speed of speeds" [value]="speed">
            {{ speed }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        (click)="recordAudio()"
        style="flex: 1"
        mat-raised-button
        color="primary"
      >
        Record
      </button>
    </div>
  </mat-card>
  <mat-card style="flex: 0.85" class="mt-5 w-100">
    <mat-card-header>
      <mat-card-title>Super audio Repository</mat-card-title>
    </mat-card-header>
    <mat-card-content style="width: 100%">
      <div style="overflow-y: auto" class="d-flex flex-column w-100 h-100">
        <div
          class="d-flex flex-row justify-content-between align-items-center"
          style="gap: 15px; width: 100%"
        >
          <mat-form-field
            appearance="outline"
            style="margin-bottom: -1.25em; flex: 1"
          >
            <input
              (keydown.enter)="searchAudio()"
              [(ngModel)]="textToSearch"
              matInput
              placeholder="Search"
              #search
            />
          </mat-form-field>
          <button mat-raised-button (click)="searchAudio()" color="primary">
            Search
          </button>
        </div>
        <hr />

        <mat-card
          *ngFor="let audio of audiosList"
          class="d-flex flex-row justify-content-between align-items-center m-2"
        >
          <span>{{ audio.text }}</span>
          <span>{{ audio.voice }}</span>
          <audio controls [src]="audio.fileUrl"></audio>
          <button (click)="copyAudioUrl(audio)" mat-icon-button color="primary">
            <mat-icon>content_copy</mat-icon>
          </button>
        </mat-card>
      </div>
    </mat-card-content>
  </mat-card>
</div>
