<div
  fxLayoutAlign="start center"
  fxLayout="column"
  style="height: calc (100vh - 64px)"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    style="overflow-y: auto; width: 100%; overflow-x: hidden"
  >
    <div
      style="width: 100%; border-radius: 10px; margin: 2px"
      fxLayoutAlign="space-between center"
    >
      <span
        class="pointer ml-2 mr-2"
        fxLayoutAlign="center center"
        style="
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background-color: #673ab7;
          color: white;
        "
        *ngIf="showScrollButtons"
        (click)="scrollLeft()"
        >◀
      </span>
      <div
        class="no-scrollbar"
        #scrollContainer
        fxLayout="row"
        style="
          width: 100%;
          overflow-y: visible;
          overflow-x: auto;
          margin-top: 3px;
        "
        fxLayoutAlign="center center"
      >
        <div
          [ngStyle.gt-sm]="{ width: '99%' }"
          [ngStyle.lt-md]="{ width: '80%' }"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <app-user-avatar-small
            (addTaskClicked)="addTask()"
            [style.border]="
              worker?.name == selectedWorker?.name ? '3px solid #673ab7' : null
            "
            class="mb-1 pointer"
            (click)="this.workerSelected({ value: worker })"
            [name]="worker?.name"
            [avatar]="workersAvatarsService.getAvatarURL(worker?.name)"
            [selected]="worker?.name == selectedWorker?.name"
            style="width: 170px; border-radius: 10px"
            *ngFor="let worker of workers"
          ></app-user-avatar-small>
        </div>
      </div>
      <span
        class="pointer mr-2"
        fxLayoutAlign="center center"
        style="
          width: 40px;
          height: 40px;
          border-radius: 10px;
          background-color: #673ab7;
          color: white;
        "
        *ngIf="showScrollButtons"
        (click)="scrollRight()"
        >▶
      </span>
    </div>
  </div>
  <div fxLayout="row" style="width: 100%; height: 100%">
    <div
      fxFlex
      style="width: 100%; display: flex; flex-direction: column; height: 100%"
    >
      <app-tasks-list
        [title]="'Dzisiejsze'"
        [colorDark]="'#2196f3'"
        [colorMedium]="'#64b5f6'"
        [colorMediumLight]="'#90caf9'"
        [colorLight]="'#bbdefb'"
        style="width: 100%; height: calc(100vh - 188px); overflow-y: scroll"
        [selectedWorker]="selectedWorker"
        [workers]="workers"
        [tasks]="forTodayTasks"
      ></app-tasks-list>
      <div fxLayout="row">
        <span
          (click)="openOverdueTasksDialog()"
          fxLayoutAlign="center center"
          style="
            width: 100%;
            height: 48px;
            background-color: #e0e0e0;
            font-size: larger;
          "
          class="p-2"
          >Nieskończone
        </span>
      </div>
    </div>
    <div
      style="width: 100%"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="space-between center"
    >
      <app-tasks-list
        [smallItems]="true"
        [colorDark]="'#ffa000'"
        [colorMedium]="'#ffb300'"
        [colorMediumLight]="'#ffcc80'"
        [colorLight]="'#fff3e0'"
        [title]="'Przypięte'"
        style="width: 100%; height: calc(50vh - 60px)"
        [selectedWorker]="selectedWorker"
        [workers]="workers"
        [tasks]="pinnedTasks"
      ></app-tasks-list>
      <app-tasks-list
        [smallItems]="true"
        [colorDark]="'#009688'"
        [colorMedium]="'#4db6ac'"
        [colorMediumLight]="'#b2dfdb'"
        [colorLight]="'#e0f2f1'"
        style="width: 100%; height: calc(50vh - 140px)"
        [title]="foundTasks.length > 0 ? 'Znalezione' : 'Zaplanowane'"
        [selectedWorker]="selectedWorker"
        [workers]="workers"
        [tasks]="foundTasks.length > 0 ? foundTasks : forFutureTasks"
      ></app-tasks-list>
      <mat-expansion-panel
        style="width: 95%; border-radius: 0px"
        class="mat-elevation-z0"
        #panel
      >
        <mat-expansion-panel-header
          style="background-color: #e0e0e0; font-size: larger"
        >
          <mat-panel-title>Skończone</mat-panel-title>
        </mat-expansion-panel-header>
        <app-tasks-list
          *ngIf="panel.expanded"
          [smallItems]="true"
          [colorDark]="'#8bc34a'"
          [colorMedium]="'#aed581'"
          [colorMediumLight]="'#e0e0e0'"
          [colorLight]="'white'"
          style="width: 100%; height: calc(33vh - 46.66px)"
          [selectedWorker]="selectedWorker"
          [workers]="workers"
          [tasks]="finishedTasks"
        ></app-tasks-list>
      </mat-expansion-panel>
    </div>
  </div>
</div>
<span
  fxLayoutAlign="start center"
  fxLayoutAlign="center center"
  style="
    width: 100%;
    height: 64px;

    background-color: #e0e0e0;
    font-size: larger;
  "
  class="p-2 mt-1"
>
  <input
    (focus)="$event.preventDefault(); $event.stopPropagation()"
    (click)="$event.preventDefault(); $event.stopPropagation()"
    [(ngModel)]="searchText"
    placeholder="Szukaj"
    style="
      border: 2px solid gray;
      width: 100%;
      height: 100%;

      background-color: #e0e0e0;
      font-size: larger;
    "
  />
  <div style="width: 60%" class="d-flex flex-column ml-2">
    <input
      [(ngModel)]="searchDateFrom"
      style="background-color: #e0e0e0; border: 2px solid gray"
      class="mb-1"
      type="date"
    />
    <input
      [(ngModel)]="searchDateTo"
      style="background-color: #e0e0e0; border: 2px solid gray"
      type="date"
    />
  </div>
  <div class="d-flex flex-column">
    <mat-button-icon
      class="ml-2 mt-2"
      color="primary"
      (click)="findTasks()"
      matTooltip="Szukaj"
      mat-icon-button
      color="primary"
    >
      <mat-icon>search</mat-icon>
    </mat-button-icon>
    <mat-button-icon
      class="ml-2 mt-1"
      color="warn"
      (click)="foundTasks = []; searchText = ''"
      matTooltip="Wyczyść"
      mat-icon-button
    >
      <mat-icon>clear</mat-icon>
    </mat-button-icon>
  </div>
</span>
