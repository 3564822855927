import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ErrorLoggingService } from './error-logging.service';
import { PoorInternetIndicatorService } from './poor-internet-indicator.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root',
})
export class ReadTextService {
  readingFinished = new Subject();
  readingIndicator = new Subject();
  audioGenerated = new Subject();
  audioAdded = new Subject();
  audioRepo = new Audio();
  // audioCtx = new AudioContext();
  url;
  msg;
  reading = false;
  timer1;
  timer2;
  readAudioCount = 0;
  audioPlaying = false;
  _readingFinished = false;
  readingPositive = true;
  audio = new Audio();
  buffer: any;
  source: any;
  xhr: XMLHttpRequest;
  xhr2: any;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private poorInternetIndicator: PoorInternetIndicatorService,
    private dbService: NgxIndexedDBService,
    private errorLoggingService: ErrorLoggingService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url + '/api/read';

    this.audio.onplaying = () => {
      if (this.readingPositive === false) {
        this.readingPositive = true;
      }
    };
    this.audio.onended = () => {
      this.readingPositive = false;
    };
  }

  stopPlaying() {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
    this.audio.pause();
    this.audio.volume = 0;
    this.audio.src = '';
    window.speechSynthesis.cancel();
    this.reading = false;
    this.readingPositive = false;
    this._readingFinished = true;
    this.updateReadingIndicator();
  }
  readText(
    item,
    voice,
    speed,
    next?,
    array?,
    mode?,
    polish?,
    windowSpeaking?,
    generateAudioData?,
    uk?,
    lang?,
  ) {
    if (!this.reading || !this.readingPositive) {
      this.stopPlaying();
      console.log('item: ', item);

      this.readingPositive = false;
      this._readingFinished = false;
      if (!this.reading || this.reading) {
        this.reading = true;

        this.updateReadingIndicator();
        if (item !== undefined || item !== null) {
          if (!window.speechSynthesis.speaking) {
            const initialItem = item;
            this.msg = new SpeechSynthesisUtterance();
            const voices = window.speechSynthesis.getVoices();
            setTimeout(() => {
              if (typeof item == 'object') {
                if (mode === 'blank') {
                  const itemToReadArray = item.map((word) => {
                    if (word.blank) {
                      const splitWord = word.toString().split('');
                      if (
                        splitWord[splitWord.length] === '.' ||
                        splitWord[splitWord.length] === '!' ||
                        splitWord[splitWord.length] === '?' ||
                        splitWord[splitWord.length] === ','
                      ) {
                        return '____________' + splitWord[splitWord.length];
                      } else {
                        return '____________';
                      }
                    } else {
                      return word.word;
                    }
                  });

                  item = itemToReadArray
                    .join(' ')
                    .replace(" '", "'")
                    .replace(/(_)\w+/g, 'blank')
                    .replace(/\(.*?\)/g, '')
                    .replace('_ _', '_')
                    .replace('_ _', '_')
                    .replace('_ _', '_')
                    .replace('_ _', '_');
                } else {
                  const itemToReadArray = item?.map((word) => {
                    return word.word;
                  });

                  item = itemToReadArray?.join(' ').replace(" '", "'");
                }
              }

              this.msg.volume = 1; // 0 to 1
              this.msg.pitch = 1; // 0 to 2
              if (item) {
                try {
                  this.msg.text = item
                    .replace(/(_)\w+/g, 'blank')
                    .replace(/\(.*?\)/g, '');
                } catch (err) {
                  console.log(
                    '🚀 ~ file: read-text.service.ts ~ line 180 ~ ReadTextService ~ setTimeout ~ err',
                    err,
                  );
                  console.log(
                    '🚀 ~ file: read-text.service.ts ~ line 182 ~ ReadTextService ~ setTimeout ~ item',
                    item,
                  );
                  this.msg.text = item;
                }
              }
              if (!polish && this.msg.text !== undefined) {
                let url;
                if (!windowSpeaking) {
                  if (!uk) {
                    url =
                      this.url +
                      '/readfromazureenglish?textToRead=' +
                      this.msg.text +
                      '&token=' +
                      this.authService.getToken();
                  }
                  if (uk) {
                    url =
                      this.url +
                      '/readfromazureenglishuk?textToRead=' +
                      this.msg.text +
                      '&token=' +
                      this.authService.getToken();
                  }
                  if (!generateAudioData) {
                    this.xhr = new XMLHttpRequest();
                    this.xhr.open('GET', url);
                    this.xhr.responseType = 'arraybuffer';
                    this.xhr.onerror = () => {
                      this.windowRead(item, array, polish, speed, true, lang);
                    };
                    this.xhr.onload = () => {
                      this.audio.muted = false;
                      this.audio.volume = 1;
                      const blob = new Blob([this.xhr.response], {
                        type: 'audio/wav',
                      });
                      console.log(
                        '🚀 ~ file: read-text.service.ts ~ line 279 ~ ReadTextService ~ readAudio ~ blob',
                        blob,
                      );
                      this.audio.src = window.URL.createObjectURL(blob);
                      this.audio.playbackRate = speed;
                      this.audio.play().catch((err) => {
                        console.log(
                          '🚀 ~ file: read-text.service.ts ~ line 213 ~ ReadTextService ~ this.audio.play ~ err',
                          err,
                        );
                        this.windowRead(item, array, polish, speed, true, lang);
                      });
                    };
                    this.xhr.send();
                  } else {
                    this.audioGenerated.next({
                      lang: 'en',
                      text: this.msg.text,
                    });
                  }
                }
                if (windowSpeaking) {
                  this.windowRead(this.msg, initialItem, array, speed);
                  console.log('📢[read-text.service.ts:197]: ', this.msg);
                }
              }
              if (polish && this.msg.text !== undefined) {
                if (!windowSpeaking) {
                  // this.audio.src = 'https://api.voicerss.org/?key=bed59d0934c7474ba654281020619015&hl=en-us&r=-2&v=Mary&c=MP3&f=16khz_16bit_stereo&src=' + this.msg.text;
                  this.audio.src =
                    this.url +
                    '/readfromazure?textToRead=' +
                    this.msg.text +
                    '&token=' +
                    this.authService.getToken();
                  if (!generateAudioData) {
                    this.audio.muted = false;
                    this.audio.load();
                    // this.audio.playbackRate = speed;
                    this.audio.playbackRate = speed;

                    this.audio.play().catch((err) => {
                      // this.errorLoggingService.sendLogToElastic('debug', {message: 'audio error', error: err, line:208});
                    });
                  } else {
                    var aud = this.audio;
                    this.xhr = new XMLHttpRequest();
                    this.xhr.open('GET', aud.src);
                    this.xhr.responseType = 'arraybuffer';
                    this.xhr.onerror = () => {
                      this.windowRead(item, array, polish, speed, true, lang);
                    };
                    this.xhr.onload = () => {
                      // let undecodedAudio = this.xhr.response;
                      // this.audioCtx.decodeAudioData(undecodedAudio, (data) => this.buffer = data);
                    };

                    this.xhr.send();
                  }
                }
                if (windowSpeaking) {
                  this.windowRead(initialItem, array, true, speed);
                }
              }
              this.audio.onended = () => {
                clearTimeout(this.timer1);
                clearTimeout(this.timer2);
                this.readingFinished.next({
                  finished: true,
                  readItem: this.msg.text,
                  array,
                  readText: this.msg.text,
                });
                this.reading = false;
                this.readingPositive = false;
                this._readingFinished = true;
                this.xhr.abort();
                this.updateReadingIndicator();
              };
              if (next !== undefined) {
              }
            }, 200);
          }
        }
      }
    }
  }
  initAudio() {
    this.audio.src = 'http://touchbasicapp.com/nothing.wav';
  }
  readAudio(data, speed?, item?, array?, polish?, mode?, uk?) {
    this.stopPlaying();

    this.audio.src = '';

    setTimeout(() => {
      this.audio.playbackRate = speed;

      if (window.innerWidth < 600) {
        setTimeout(() => {
          this.audio.volume = 1;
        }, 150);
      }
      if (window.innerWidth >= 600) {
        setTimeout(() => {
          this.audio.volume = 1;
        }, 150);
      }
      if (!this.reading) {
        console.log('data: ', data);
        this.stopPlaying();
        if (speed) {
          this.audio.playbackRate = speed;
        }
        // if(!speed){
        //   speed = 1;
        // }
        if (!this.reading) {
          this.reading = true;
          this.readingPositive = false;
          this.updateReadingIndicator();
          if (data) {
            // todo check if db ok
            this.dbService.getByIndex('audio', 'link', data).subscribe(
              (audioFile) => {
                // setTimeout(() => {

                if (audioFile?.value) {
                  this.audio.src = URL.createObjectURL(audioFile.value);
                  this.audio.playbackRate = speed;
                  this.audio.play().catch((err) => {
                    console.log('err: ', err);
                    this.stopPlaying();
                    // this.audio.src = this.url + '/getaudiodata?url=' + data
                    // this.audio.play()

                    this.xhr = new XMLHttpRequest();
                    this.xhr.open(
                      'GET',
                      this.url + '/getaudiodata?url=' + data,
                    );
                    this.xhr.responseType = 'arraybuffer';

                    this.xhr.onload = () => {
                      const blob = new Blob([this.xhr.response], {
                        type: 'audio/wav',
                      });
                      console.log(
                        '🚀 ~ file: read-text.service.ts ~ line 279 ~ ReadTextService ~ readAudio ~ blob',
                        blob,
                      );
                      this.audio.src = window.URL.createObjectURL(blob);
                      this.audio.playbackRate = speed;

                      this.audio.play().catch((err) => {
                        console.log(
                          '🚀 ~ no file in the repo! file: read-text.service.ts ~ line 346 ~ ReadTextService ~ this.audio.play ~ err',
                          err,
                        );
                        this.readText(
                          item,
                          null,
                          speed,
                          null,
                          array,
                          null,
                          polish,
                          false,
                          false,
                          uk,
                        );
                      });
                    };
                    this.xhr.send();
                    // this.errorLoggingService.sendLogToElastic('debug', {audioSrc: this.audio.src, err:err} )
                    // this.readText(item, null, speed, null, array, null, polish, false, false, uk);
                  });
                }
                if (!audioFile) {
                  console.log('no file in localDb!');
                  // this.errorLoggingService.sendLogToElastic('debug', {audioSrc: this.audio.src, err:'noFileInDb'} )

                  // this.audio.src = this.url + '/getaudiodata?url=' + data;
                  // this.audio.playbackRate = speed;
                  this.xhr = new XMLHttpRequest();
                  this.xhr.open('GET', this.url + '/getaudiodata?url=' + data);
                  this.xhr.responseType = 'arraybuffer';

                  this.xhr.onload = () => {
                    const blob = new Blob([this.xhr.response], {
                      type: 'audio/wav',
                    });
                    console.log(
                      '🚀 ~ file: read-text.service.ts ~ line 279 ~ ReadTextService ~ readAudio ~ blob',
                      blob,
                    );
                    this.audio.src = window.URL.createObjectURL(blob);
                    this.audio.playbackRate = speed;

                    this.audio.play().catch((err) => {
                      console.log(
                        '🚀 ~ no file in repo! file: read-text.service.ts ~ line 375 ~ ReadTextService ~ this.audio.play ~ err',
                        err,
                      );
                      this.stopPlaying();
                      setTimeout(() => {
                        this.readText(
                          item,
                          null,
                          speed,
                          null,
                          array,
                          null,
                          polish,
                          false,
                          false,
                          uk,
                        );
                      }, 5000);
                    });
                  };
                  this.xhr.send();
                  // this.audio.play().catch(err => {
                  //   console.log('err: ', err);
                  //   // this.readText(item, null, speed, null, array, null, polish, false, false, uk);
                  //   setTimeout(() => {
                  //     // this.audio.src = this.url + '/getaudiodata?url=' + data
                  //     // this.audio.play()

                  //     this.errorLoggingService.sendLogToElastic('debug', {audioSrc: this.audio.src, err:err} )

                  //   }, 500);
                  // });
                }
                // },200)
              },
              (err) => {
                console.log('err: ', err);
                this.stopPlaying();
                // this.readText(item, null, speed, null, array, null, polish, false, false, uk);
                // this.audio.src = this.url + '/getaudiodata?url=' + data
                // this.audio.play()
                this.xhr = new XMLHttpRequest();
                this.xhr.open('GET', this.url + '/getaudiodata?url=' + data);
                this.xhr.responseType = 'arraybuffer';
                this.xhr.onerror = () => {
                  this.windowRead(item, array, polish, speed, true);
                };
                this.xhr.onload = () => {
                  const blob = new Blob([this.xhr.response], {
                    type: 'audio/wav',
                  });
                  console.log(
                    '🚀 ~ file: read-text.service.ts ~ line 279 ~ ReadTextService ~ readAudio ~ blob',
                    blob,
                  );
                  this.audio.src = window.URL.createObjectURL(blob);
                  this.audio.playbackRate = speed;

                  this.audio.play().catch((err) => {
                    this.readText(
                      item,
                      null,
                      speed,
                      null,
                      array,
                      null,
                      polish,
                      false,
                      false,
                      uk,
                    );
                  });
                };
                this.xhr.send();
                // this.errorLoggingService.sendLogToElastic('debug', {audioSrc: this.audio.src, err:err} )
              },
            );
          }
          if (!data) {
            this.stopPlaying();
            this.readText(
              item,
              null,
              speed,
              null,
              array,
              null,
              polish,
              false,
              false,
              uk,
            );
          }
        }
      }
    }, 200);

    this.audio.onended = () => {
      clearTimeout(this.timer1);
      clearTimeout(this.timer2);
      this.reading = false;
      this.readingPositive = false;
      this.readingFinished.next({ finished: true });
      this.updateReadingIndicator();
    };
  }
  getAudioData(url, next, nextProperty, checkAgain?) {
    if (url) {
      if (checkAgain) {
        this.dbService
          .getByIndex('audio', 'link', url)
          .subscribe((audioFile) => {
            if (!audioFile) {
              this.getAudioData(url, next, nextProperty);
            } else {
              //
              this.audioAdded.next({ next: true });
            }
          });
      } else {
        this.xhr2 = new XMLHttpRequest();
        this.xhr2.open('GET', this.url + '/getaudiodata?url=' + url);
        this.xhr2.responseType = 'arraybuffer';

        this.xhr2.onload = () => {
          const blob = new Blob([this.xhr2.response], { type: 'audio/wav' });
          const time1 = Date.now();
          this.dbService
            .add('audio', {
              link: url,
              value: blob,
            })
            .subscribe((key) => {
              const time2 = Date.now();
              const time = (time2 - time1) / 1000;

              this.audioAdded.next({ next: true });

              //         this.dbService.getByIndex('audio', 'link', url).subscribe((audioFile) => {

              //           if(audioFile.value){

              //           }
              //           else{
              //             alert('no file in db')

              // this.getAudioData(url, next, nextProperty)
              //   }
              //   }, err=>{
              //     // alert('error2 ' +  err);
              //     this.getAudioData(url, next, nextProperty)

              //   })
              //     }, err=>{
              //       // alert('error3 ' +  err);
              //       this.getAudioData(url, next, nextProperty)
            });
        };
        this.xhr2.send();
      }
    }
  }
  addFileToDb() {}
  stopGettingAudio() {
    this.audioAdded.next({ next: false });
  }
  windowRead(initialItem, array, polish?, speed?, onlyWindow?, lang?) {
    if (onlyWindow) {
      this.msg = new SpeechSynthesisUtterance();
    }

    this.readingPositive = true;
    this.audio.src = '';
    const voices = window.speechSynthesis.getVoices();
    switch (lang) {
      case 'english':
        this.msg.lang = 'en-US';
        this.msg.voice = voices.filter(
          (voice) =>
            voice.lang == 'en-US' &&
            (voice.name.includes('Google') || voice.name.includes('google')),
        )[0];
        if (!this.msg.voice) {
          this.msg.voice = voices.filter((voice) => voice.lang == 'en-US')[0];
        }

        break;
      case 'german':
        this.msg.lang = 'de-DE';
        this.msg.voice = voices.filter((voice) => voice.lang == 'de-DE')[0];
        break;
      case 'french':
        this.msg.lang = 'fr-FR';
        this.msg.voice = voices.filter((voice) => voice.lang == 'fr-FR')[0];
        break;
      case 'spanish':
        this.msg.lang = 'es-Mx';
        this.msg.voice = voices.filter((voice) => voice.lang == 'es-Mx')[0];
        break;
      case 'norwegian':
        this.msg.lang = 'nb-NO';
        this.msg.voice = voices.filter((voice) => voice.lang == 'nb-NO')[0];
        break;

      default:
        this.msg.lang = 'en-US';
        this.msg.voice = voices.filter(
          (voice) =>
            voice.lang == 'en-US' &&
            (voice.name.includes('Google') || voice.name.includes('google')),
        )[0];
        if (!this.msg.voice) {
          this.msg.voice = voices.filter((voice) => voice.lang == 'en-US')[0];
        }
        break;
    }
    // const goodVoices = voices.filter(
    //   (e) =>
    //     e.name.includes("Microsoft David") ||
    //     (e.name.includes("google") && e.lang === "en-US") ||
    //     (e.name.includes("Google") && e.lang === "en-US") ||
    //     e.name.includes("angielski Stany Zjednoczone")
    // );
    this.msg.rate = speed - 0.3;

    if (polish || lang == 'polish') {
      this.msg.voice = voices.filter((voice) => voice.lang == 'pl-PL')[0];
      this.msg.lang = 'pl-PL';

      window.speechSynthesis.speak(this.msg);
    } else {
      // if (goodVoices.length > 0){
      //         this.msg.voice = goodVoices[0];
      //
      //       }
      //       else{

      // this.msg.voice = voices[3]
      //
      // }
      // this.errorLoggingService.sendLogToElastic('debug', {msg: 'msg', msgobj: this.msg})
      this.msg.text = initialItem.replaceAll('\\(.*?\\)', '');

      window.speechSynthesis.speak(this.msg);
    }

    this.msg.onend = () => {
      clearTimeout(this.timer1);
      clearTimeout(this.timer2);
      this.reading = false;
      this.readingPositive = false;
      this.readingFinished.next({
        finished: true,
        readItem: initialItem,
        array,
        readText: this.msg.text,
      });
      this.updateReadingIndicator();
    };
  }

  readFromOurRepositoryOnline(url, speed?) {
    this.audioRepo.src = 'https://content.lingking.pl/' + url;
    // this.audioRepo.playbackRate = speed;
    this.audioRepo.play();
  }
  readingFinishedIndicator() {
    return this.readingFinished.asObservable();
  }
  audioGeneratedIndicator() {
    return this.audioGenerated.asObservable();
  }
  audioAddedIndicator() {
    return this.audioAdded.asObservable();
  }
  textReadingIndicator() {
    return this.readingIndicator.asObservable();
  }
  updateReadingIndicator() {
    this.readingIndicator.next(this.reading);
  }
}
