import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { SrtManipulatorService } from 'src/app/video-maker/srt-manipulator.service';

@Component({
  selector: 'app-fact-video-question',
  templateUrl: './fact-video-question.component.html',
  styleUrls: ['./fact-video-question.component.scss']
})
export class FactVideoQuestionComponent implements OnInit {
  withReveal: boolean = true;
  fact
  showIntro: boolean = true;
  showContinueWatching: boolean = false;
  playRevealAudio: boolean = false;
  showQuestionText: boolean = true
  correctAnswer: string;
  showRevealText: boolean;
  polishMode: boolean = true;
  introImagePath = 'assets/funfact1.png'
  introImgPath = 'assets/question1.png'
  constructor(
    private dialogRef: MatDialogRef<FactVideoQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public helpers: HelpersService,
    private srtManipulatorService: SrtManipulatorService
  ) {

    this.introImagePath = 'assets/funfact' + this.helpers.getRandomNumber(1, 7) + '.png'
    this.introImgPath = 'assets/question' + this.helpers.getRandomNumber(1, 12) + '.jpg'
    console.log("🚀 ~ FactVideoQuestionComponent ~ this.introImgPath:", this.introImgPath)
  }

  ngOnInit() {
    let introAudio = new Audio();
    this.polishMode = this.data.polishMode ? true : false;
    introAudio.src = 'assets/funfact_intro_1.wav'
    introAudio.load();
    introAudio.play();
    this.withReveal = this.data.mode == 'answer' ? true : false;
    let audio = new Audio();
    audio.src = this.data.fact.ABCQuestionWithAnswersAudio
    audio.load();
    setTimeout(() => {
      this.showIntro = false;
      if (this.data?.introTime) {
        this.srtManipulatorService.startDisplayingText('', this.fact.ABCQuestionWithAnswers, this.data.introTime, null, false);
      }
      audio.play();
    }
      , 2000);
    audio.onended = () => {
      this.srtManipulatorService.endDisplayingText();
      if (!this.withReveal) {
        this.showContinueWatching = true;
        let continueWatchingAudio = new Audio();
        continueWatchingAudio.src = 'https://backend-int.linget.it/api/read/getaudiodata?url=freeAudio/1722865730319.wav'
        continueWatchingAudio.load();
        continueWatchingAudio.play();
        continueWatchingAudio.onended = () => {
          this.srtManipulatorService.endDisplayingText();
          this.finish();
        }


      }

      else {
        if (this.playRevealAudio) {
          this.finish();
        }
        if (!this.playRevealAudio) {
          setTimeout(() => {
            introAudio.src = 'assets/sounds/ding.wav'
            introAudio.load();
            introAudio.play();
            this.showRevealText = true
            this.showQuestionText = false
            audio.src = this.fact.ABCExplanationAudio
            audio.load();
            audio.play();
            this.srtManipulatorService.startDisplayingText('', this.fact.ABCExplanation, 0, null, false);
            this.playRevealAudio = true;
          }, 1000);
        }
      }
    }
    this.fact = this.data.fact
    this.correctAnswer = this.fact.ABCcorrectAnswer.English
    console.log(this.fact);
    this.fact.ABCAnswers.English.forEach((element, index) => {
      if (index == 0 && !this.fact.ABCAnswers.English[index].includes('A)')) {
        this.fact.ABCAnswers.English[index] = 'A) ' + element

      }
      if (index == 1 && !this.fact.ABCAnswers.English[index].includes('B)')) {
        this.fact.ABCAnswers.English[index] = 'B) ' + element

      }
      if (index == 2 && !this.fact.ABCAnswers.English[index].includes('C)')) {
        this.fact.ABCAnswers.English[index] = 'C) ' + element

      }
    });
  }
  getQuestionOnly(english: string): string {
    return english.split(/\?\s*A\)/)[0] + '?';
  }


  finish() {
    let introAudio = new Audio();
    introAudio.src = 'assets/funfact_intro_1.wav'
    introAudio.load();
    introAudio.play();

    setTimeout(() => {
      // this.dialogRef.close();
    }, 1000);
  }
}
