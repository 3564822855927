import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { VersionService } from './version.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { datadogLogs as datadog } from '@datadog/browser-logs';
@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  url: string;
  userDetails: any;
  private initialized = false;
  constructor(
    private http: HttpClient,
    private dbService: NgxIndexedDBService,
    private versionService: VersionService,
    private userDetailsService: UserDetailsService,
  ) {
    this.url = this.versionService.url;

    datadog.init({
      clientToken: 'pubbda586a89bc2713abbca1e9ce8838e40',
      site: 'datadoghq.eu',
      service: 'lswebapp',
      version: this.versionService.version,
      sampleRate: 100,
    });
    this.initialized = true;
  }

  public debug(message: string, context?): void {
    if (this.initialized) {
      datadog.logger.debug(message, context);
    }
  }

  public info(message: string, context?): void {
    if (this.initialized) {
      datadog.logger.info(message, context);
    }
  }

  public warn(message: string, context?): void {
    if (this.initialized) {
      datadog.logger.warn(message, context);
    }
  }

  public error(message: string, context): void {
    if (this.initialized) {
      datadog.logger.error(message, context);
    }
  }
}
