<div class="d-flex flex-column" style="overflow-y: auto">
  <div class="d-flex flex-row align-items-center">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row align-items-center">
        <span class="ml-2 mr-2">{{ fact.level }}</span>
        <span style="color: red">{{ fact.fact }}</span>
        <span class="ml-2 mr-2"> {{ fact.answers }}</span>
        <span *ngIf="fact.correctAnswer">-></span>
        <span style="font-weight: bold" class="ml-2 mr-2">{{
          fact.correctAnswer
        }}</span>

        <span style="flex: 1"></span>
      </div>
      <span class="mt-1 ml-2 mr-2">{{ fact.explanation }}</span>
      <span style="font-weight: bold; color: #673ab7" class="mt-3 ml-2 mr-2">{{
        fact?.category
      }}</span>
    </div>
    <span style="flex: 1"></span>
    <button
      *ngIf="!transformingFact"
      (click)="assignCategory()"
      matTooltip="Assign category"
      mat-icon-button
      class="ml-2 mr-2"
      color="primary"
    >
      <span class="material-symbols-outlined"> assignment_turned_in </span>
    </button>
    <button
      *ngIf="!transformingFact"
      (click)="okFact()"
      mat-icon-button
      color="primary"
    >
      <mat-icon>check_circle_outline</mat-icon>
    </button>

    <mat-spinner *ngIf="transformingFact" diameter="30" class="ml-3">
    </mat-spinner>
    <button
      (dblclick)="removeFact()"
      matTooltip="Double click to remove"
      class="ml-3"
      mat-icon-button
      color="warn"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
<hr />
