<iframe
  width="100%"
  height="100%"
  [src]="lessonUrl"
  frameborder="0"
  scrolling="no"
  allow="camera; microphone; fullscreen; speaker; display-capture"
  sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals allow-popups-to-escape-sandbox allow-top-navigation"
  allowfullscreen
></iframe>
