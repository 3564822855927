<div
  #scrollContainer
  style="
    position: relative;
    border-radius: 10px;
    margin: 2px;
    height: 100%;
    overflow-y: scroll;
  "
>
  <div
    style="
      border-radius: 15px;
      position: sticky;
      top: 0;
      background-color: inherit;
      z-index: 100;
      overflow-y: auto;
    "
  >
    <div style="margin-left: 2px">
      <div style="width: 100%">
        <span
          style="display: flex; justify-content: start; align-items: center"
        >
          <span
            [style.color]="colorDark"
            style="font-weight: bolder"
            class="mat-small ml-1"
            >{{ title }}
          </span>
          <span fxFlex></span>
          <span
            (click)="scrollDown()"
            [style.color]="colorDark"
            *ngIf="checkScrollContainerOverflow()"
            class="material-symbols-outlined animate__animated animate__pulse animate__infinite"
            >arrow_circle_down
          </span>
        </span>
      </div>
    </div>
  </div>
  <div style="overflow-y: scroll">
    <div
      style="
        overflow-y: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        height: 100%;
      "
    >
      <div
        [style.flex-basis]="taskWidth + '%'"
        *ngFor="let task of tasks; let i = index"
      >
        <div
          #workersTasks
          class="p-1"
          *ngIf="(!task.dontDisplay && i < limit) || showAllTasks"
        >
          <mat-card
            style="display: flex; border-radius: 15px"
            fxLayoutAlign="start center"
            class="p-1 pl-2 ml-1 mr-1 d-flex flex-row"
            [style.background-color]="colorMediumLight"
            [style.border]="
              checkNewComment(task) ? '3px solid ' + colorDark : null
            "
            (click)="openTaskViewPanel(task)"
          >
            <div fxLayout="row" class="d-flex">
              <mat-panel-title
                [style.color]="
                  task?.seenBy?.includes(
                    this.userDetailsService.getSimpleUserDetails().id
                  )
                    ? 'black'
                    : colorDark
                "
                [style.font-weight]="
                  task?.seenBy?.includes(
                    this.userDetailsService.getSimpleUserDetails().id
                  )
                    ? ''
                    : 'bolder'
                "
                fxLayout="row"
                fxLayout.lt-md="column"
                [style.width]="
                  (deviceSizeService.isHandset | async) ? '70%' : '50%'
                "
              >
                <div
                  style="width: 100%"
                  fxLayoutAlign="start start"
                  fxLayout="column"
                >
                  <div
                    style="width: 100%"
                    fxLayoutAlign="start stretch"
                    fxLayout="column"
                    fxFlex
                  >
                    <div
                      class="mb-0"
                      fxLayout="row wrap"
                      fxLayoutGap="5px"
                      style="max-width: 40vw; overflow-x: auto"
                    >
                      <div
                        style="white-space: nowrap; display: flex"
                        fxLayoutAlign="start center"
                        fxLayoutGap="5px"
                      >
                        <img
                          [class]="
                            checkImgDisabled(task, user) ? 'img-disabled' : ''
                          "
                          *ngFor="let user of task.recipients"
                          width="15px"
                          height="15px"
                          [src]="workersAvatarsService.getAvatarURLById(user)"
                        />
                        <span fxFlex></span>
                        <span style="font-size: 0.6em"
                          >{{
                            task?.dateScheduled
                              | date: "EEEEEE, dd.MM" : "null" : locale
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-0"
                    fxFlex
                    fxLayoutAlign="center center"
                    fxLayoutGap="5px"
                  >
                    <span
                      style="
                        font-size: 0.9em;
                        text-overflow: ellips;
                        width: 100%;
                      "
                      [style.color]="
                        task.status === '1overdue' &&
                        task?.completion !== 'done'
                          ? 'red'
                          : !task?.seenBy?.includes(
                              userDetailsService.getSimpleUserDetails().id
                            )
                          ? '#673ab7'
                          : '#000'
                      "
                      [style.text-decoration]="
                        task.completion === 'done' ? 'line-through' : null
                      "
                      class="text scroll-text"
                      >{{
                        task?.title.length > 26 && !_isHandset
                          ? task.title.slice(0, 26) + "..."
                          : task.title
                      }}
                    </span>
                  </div>
                </div>
              </mat-panel-title>
            </div>
            <span style="flex: 1"></span>
            <button
              *ngIf="userDetailsService.owner"
              mat-icon-button
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                shareTask(task)
              "
              [style.color]="colorDark"
            >
              <mat-icon [style.color]="colorDark">share </mat-icon>
            </button>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>
