import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-signup-stats',
  templateUrl: './signup-stats.component.html',
  styleUrls: ['./signup-stats.component.css'],
})
export class SignupStatsComponent implements OnInit {
  allChildren = [];
  allAdults = [];
  confirmedChildren = [];
  confirmedAdults = [];
  continuingConfirmedChildren = [];
  continuingConfirmedAdults = [];
  allContinuingChildren = [];
  allContinuingAdults = [];
  newChildren = [];
  revenue = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    this.allChildren = this.data.children.filter(
      (child) => !child.childFirstName.includes('removed'),
    );
    this.newChildren = this.data.children.filter(
      (child) => !child.childFirstName.includes('removed') && !child.continuing,
    );
    this.allAdults = this.data.adults.filter(
      (adult) => !adult.adultFirstName.includes('removed'),
    );
    this.confirmedChildren = this.data.children.filter(
      (child) =>
        child.status == 'confirmedAfterSMS' ||
        child.status == 'movedToCustomersWithContract' ||
        child.status == 'movedToCustomersWithoutContract' ||
        child.status == 'decided' ||
        child.status == 'contractSigned',
    );
    this.continuingConfirmedChildren = this.confirmedChildren.filter(
      (child) => child.continuing && !child.childFirstName.includes('removed'),
    );
    this.allContinuingChildren = this.data.children;
    this.confirmedAdults = this.data.adults.filter(
      (adult) =>
        adult.status == 'confirmedAfterSMS' ||
        adult.status == 'movedToCustomersWithContract' ||
        adult.status == 'movedToCustomersWithoutContract' ||
        adult.status == 'decided' ||
        adult.status == 'contractSigned',
    );
    this.confirmedChildren.forEach((child) => {
      const childPrice = child?.contractPrice;
      if (childPrice > 0) {
        this.revenue = this.revenue + childPrice;
      }
    });
    this.confirmedAdults.forEach((adult) => {
      const adultPrice = adult?.contractPrice;
      if (adultPrice > 0) {
        this.revenue = this.revenue + adultPrice;
      }
    });
    console.log(
      '🚀 ~ file: signup-stats.component.ts ~ line 49 ~ SignupStatsComponent ~ this.confirmedChildren.forEach ~ this.revenue',
      this.revenue,
    );
  }
}
