import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SwUpdate } from "@angular/service-worker";
import { UpdateConfirmationComponent } from "../components/update-confirmation/update-confirmation.component";
@Injectable({
  providedIn: "root",
})
export class UpdateService {
  dialogOpened = false;
  constructor(private updates: SwUpdate, private dialog: MatDialog) {}

  checkUpdates() {
    const loc = window.location.hostname;
    this.updates.checkForUpdate();
    this.updates.available.subscribe((event) => {
      if (loc == "test.lingking.com.pl" || loc == "lingking.com.pl") {
        this.updates.activateUpdate().then(() => {
          // if(!this.dialogOpened){
          //   const dialogRef = this.dialog.open(UpdateConfirmationComponent)
          //   dialogRef.afterOpened().subscribe(()=>{
          //     this.dialogOpened = true;
          //   })
          //   dialogRef.afterClosed().subscribe(result=>{
          //     if(result === true){
          //       document.location.reload()
          //     }
          //   })
          // }
        });
      }
    });
  }
}
