<app-sub-menu
    [controls]="controls"
    (text)='incomingText($event)'
    (buttonClicked)='buttonClicked($event)'
></app-sub-menu>
<app-users-list *ngIf="displayMode === 'list'"></app-users-list>
<app-owner-register
    (changeView)="this.displayMode = 'list'"
    *ngIf="displayMode === 'register'"
></app-owner-register>
