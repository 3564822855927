import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GrammarRulesService {

  constructor() { }




  setVerbBareInfinitive(bareInfinitive){
    return bareInfinitive
  }
  setVerbInfinitive(bareInfinitive){
    return 'to ' + bareInfinitive
  }
  setVerbPerfectInfinitive(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'to have ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPerfectContinuousInfinitive(ing){
    return 'to have been ' + ing
  }
  setVerbContinuousInfinitive(ing){
    return 'to be ' + ing
  }
  setVerbPassiveInfinitive(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'to be ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPassivePerfectInfinitive(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'to have been ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPassiveContinuousInfinitive(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'to be being ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPassivePerfectContinuousInfinitive(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'to have been being ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }

  setVerbInfinitiveNoTo(bareInfinitive){
    return  bareInfinitive
  }
  setVerbPerfectInfinitiveNoTo(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'have ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPerfectContinuousInfinitiveNoTo(ing){
    return 'have been ' + ing
  }
  setVerbContinuousInfinitiveNoTo(ing){
    return 'be ' + ing
  }
  setVerbPassiveInfinitiveNoTo(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'be ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPassivePerfectInfinitiveNoTo(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'have been ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPassiveContinuousInfinitiveNoTo(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'be being ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }
  setVerbPassivePerfectContinuousInfinitiveNoTo(pastParticiple){
    let pastParticiples = []
    pastParticiple.forEach(element => {
      const pastParticipleElement = 'have been being ' + element.word
      pastParticiples.push(pastParticipleElement)
    });

    return pastParticiples
  }


  setVerbInfinitives(bareInfinitive, pastParticiple?, ing?, passive?){
   const _infinitive = this.setVerbInfinitive(bareInfinitive)
   const _perfectInfinitive = this.setVerbPerfectInfinitive(pastParticiple)
   const _perfectContinuousInfinitive = this.setVerbPerfectContinuousInfinitive(ing)
   const _continuousInfinitive = this.setVerbContinuousInfinitive(ing)
  //  const _passiveInfinitive = this.setVerbPassiveInfinitive(passive)
  //  const _passivePerfectInfinitive = this.setVerbPassivePerfectInfinitive(passive)
  //  const _passiveContinuousInfinitive = this.setVerbPassiveContinuousInfinitive(passive)
  //  const _passivePerfectContinuousInfinitive = this.setVerbPassivePerfectContinuousInfinitive(passive)
  let result = []
  result.push(_infinitive)

  _perfectInfinitive.forEach(element => {
    result.push(element)
  })
  result.push(_perfectContinuousInfinitive)
  result.push(_continuousInfinitive)
  // _passiveInfinitive.forEach(element => {
  //   result.push(element)
  // });
  // _passiveContinuousInfinitive.forEach(element => {
  //   result.push(element)
  // });
  // _passivePerfectContinuousInfinitive.forEach(element => {
  //   result.push(element)
  // });
  // _passivePerfectInfinitive.forEach(element => {
  //   result.push(element)
  // });
  return result
  }




  setVerbInfinitives2(bareInfinitive, pastParticiple?, ing?, passive?){
   const _infinitive = this.setVerbInfinitiveNoTo(bareInfinitive)
   const _perfectInfinitive = this.setVerbPerfectInfinitiveNoTo(pastParticiple)
   const _perfectContinuousInfinitive = this.setVerbPerfectContinuousInfinitiveNoTo(ing)
   const _continuousInfinitive = this.setVerbContinuousInfinitiveNoTo(ing)
  //  const _passiveInfinitive = this.setVerbPassiveInfinitiveNoTo(passive)
  //  const _passivePerfectInfinitive = this.setVerbPassivePerfectInfinitiveNoTo(passive)
  //  const _passiveContinuousInfinitive = this.setVerbPassiveContinuousInfinitiveNoTo(passive)
  //  const _passivePerfectContinuousInfinitive = this.setVerbPassivePerfectContinuousInfinitiveNoTo(passive)
  let result = []
  result.push(_infinitive)

  _perfectInfinitive.forEach(element => {
    result.push(element)
  })
  result.push(_perfectContinuousInfinitive)
  result.push(_continuousInfinitive)
  // _passiveInfinitive.forEach(element => {
  //   result.push(element)
  // });
  // _passiveContinuousInfinitive.forEach(element => {
  //   result.push(element)
  // });
  // _passivePerfectContinuousInfinitive.forEach(element => {
  //   result.push(element)
  // });
  // _passivePerfectInfinitive.forEach(element => {
  //   result.push(element)
  // });
  return result
  }


  setPresentSimple3rdPerson(present3rdPerson){
    return present3rdPerson
  }
  setPastSimple(pastSimple){
    let result = []
    pastSimple.forEach(element => {
      result.push(element.word)
    });


    return result
  }
  setPerfect(pastParticiple){
    let result = []
    const options = ["has","hasn't", "'s", "had", "'d", "have","haven't", "'ve", "having", ""]
    options.forEach(e=>{
      pastParticiple.forEach(element => {
        if(e!==""){
          const option = e + ' ' + element.word
          result.push(option)
        }
        else{
          const option = e + element.word
          result.push(option)
        }
      })

    })

    return result
  }
setContinuous(ing){
  let result = []
  const options = [
  "am", "is","isn't", "are", "aren't", "wasn't", "weren't",
  "'m", "'re", "'s", "was", "were", "had been","hadn't been",
  "'d been","have been","haven't been", "been", "'ve been", "has been","hasn't been","'s been", "" ]
  options.forEach(e=>{
    if(e !==""){
      const option = e + ' ' + ing
      result.push(option)

    }
    else{
      const option = e + ing
      result.push(option)

    }
  })

  return result
}


  setTensesTags(baremadeInfinitive, present3rdPerson, ing, pastParticiple, pastSimple){
    let result = []
const _presentSimple3rdPerson = this.setPresentSimple3rdPerson(present3rdPerson)
const _continuousOptions = this.setContinuous(ing)
const _pastSimples = this.setPastSimple(pastSimple)
const _perfects = this.setPerfect(pastParticiple)


_continuousOptions.forEach(element => {
  result.push(element)
});
_pastSimples.forEach(element => {
  result.push(element)
});
_perfects.forEach(element => {
  result.push(element)
});
result.push(_presentSimple3rdPerson)
return result
}

}
