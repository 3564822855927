import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poins-counter',
  templateUrl: './poins-counter.component.html',
  styleUrls: ['./poins-counter.component.css']
})
export class PoinsCounterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
