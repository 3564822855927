import { Component, OnInit } from '@angular/core';
import { HelpersService } from '../../shared/services/helpers.service';

@Component({
  selector: 'app-manipulator',
  templateUrl: './manipulator.component.html',
  styleUrls: ['./manipulator.component.css']
})
export class ManipulatorComponent implements OnInit {
  text = ''
  tempText = ''
  constructor(
    private helpers: HelpersService
  ) { }

  ngOnInit(): void {
  }
  changeApostrophes() {
    this.text = this.text.replace(/’/g, '\'')
  }
  manipulate() {
    this.tempText = ''
    let splitText = this.text.split('\n')
    let splitText2 = splitText.filter(str => str.trim() !== '')
    console.log("🚀 ~ file: manipulator.component.ts:20 ~ ManipulatorComponent ~ manipulate ~ splitText2:", splitText2)
    splitText2.forEach(line => {
      const splitLine = line.split('>')
      console.log("🚀 ~ file: manipulator.component.ts:22 ~ ManipulatorComponent ~ manipulate ~ splitLine:", splitLine)
      const descriptiveSentence = this.helpers.clone(splitLine)[0]
      splitLine.shift()
      this.replaceUncommonParts(splitLine, descriptiveSentence)
    })
    this.text = this.tempText.replace('\n', '').replace(/’/g, '\'')
  }

  replaceUncommonParts(strings, descriptiveSentence) {
    if (strings.length === 0) {
      return '';
    }

    // Find the longest common prefix of all the strings
    let commonPrefix = strings[0];
    for (let i = 1; i < strings.length; i++) {
      const str = strings[i];
      let j = 0;
      while (j < commonPrefix.length && j < str.length && commonPrefix[j] === str[j]) {
        j++;
      }
      commonPrefix = commonPrefix.slice(0, j);
    }

    // Remove the common prefix from all the strings
    const strings2 = strings.map(str => str.slice(commonPrefix.length));

    // Find the longest common suffix of all the reversed strings
    const reversed = strings.map(str => str.split('').reverse().join(''));
    let commonSuffix = reversed[0];
    for (let i = 1; i < reversed.length; i++) {
      const str = reversed[i];
      let j = 0;
      while (j < commonSuffix.length && j < str.length && commonSuffix[j] === str[j]) {
        j++;
      }
      commonSuffix = commonSuffix.slice(0, j);
    }

    // Remove the common suffix from all the strings
    const strings3 = strings2.map(str => str.slice(0, str.length - commonSuffix.length));



    // Find all possible answers by removing the uncommon parts from each string
    const allAnswers = [];
    strings3.forEach(str => {
      const itemToPush = str.replace(/\s+/g, ' ').trim();
      if (itemToPush !== '' && !allAnswers.includes(itemToPush)) {
        allAnswers.push(itemToPush);
      }
    });

    // Build the blank sentence with the common prefix and suffix
    const blankSentence = commonPrefix + '__________ (^)' + commonSuffix.split('').reverse().join('');

    // Find the descriptive sentence without the common prefix and suffix
    const descriptiveSentenceWithoutCommon = descriptiveSentence.slice(commonPrefix.length, descriptiveSentence.length - commonSuffix.length);
    const blankSentenceWithoutCommon = blankSentence.slice(commonPrefix.length, blankSentence.length - commonSuffix.length);

    const [result1, result2] = this.removeCommonParts(descriptiveSentenceWithoutCommon, blankSentenceWithoutCommon);
    const modifiedSentence = blankSentence.replace('^', result1.replace(/\(([^)]+)\)/, '').trim());

    // Return the modified sentence with all possible answers
    this.tempText = this.tempText + '\n' + descriptiveSentence + '>' + modifiedSentence + '>' + allAnswers.join('>') + '>' + modifiedSentence.replace(/\([^)]*\)/g, '');
  }

  // This function removes the longest common substring from two strings
  removeCommonParts(str1, str2) {
    let commonSubstring = '';
    const lowerStr1 = str1.toLowerCase();
    const lowerStr2 = str2.toLowerCase();
    for (let i = 0; i < lowerStr1.length; i++) {
      for (let j = 0; j < lowerStr2.length; j++) {
        let k = 0;
        while (lowerStr1[i + k] === lowerStr2[j + k] && i + k < lowerStr1.length && j + k < lowerStr2.length) {
          k++;
        }
        const currentSubstring = str1.substring(i, i + k);
        if (k > commonSubstring.length &&
          (i === 0 || lowerStr1.charCodeAt(i) >= 65 && lowerStr1.charCodeAt(i) <= 90) &&
          (j === 0 || lowerStr2.charCodeAt(j) >= 65 && lowerStr2.charCodeAt(j) <= 90)) {
          commonSubstring = currentSubstring;
        }
      }
    }
    const trimmedCommonSubstring = commonSubstring.replace(/(^\s*[\p{P}\s]*|\s*[\p{P}\s]*$)/gu, '');
    const result1 = str1.replace(commonSubstring, ' ').trim();
    const result2 = str2.replace(commonSubstring, '').trim();
    return [result1, result2];
  }
}
