<div fxFlex fxLayoutAlign="center center" style="height: 80vh">
  <mat-card *ngIf="!formSubmitted" style="height: 95%; overflow-y: visible">
    <mat-form-field *ngIf="user === 'office'" style="width: 100%">
      <input [(ngModel)]="eventName" matInput placeholder="Nazwa" />
    </mat-form-field>
    <mat-tab-group
      (selectedIndexChange)="setCurrentTab($event)"
      [selectedIndex]="currentTab"
      style="overflow-y: visible"
    >
      <mat-tab
        [disabled]="formSubmitted"
        [label]="
          selectedDate ? ('Data' + selectedDate | date: 'dd.MM.yyyy') : 'Data'
        "
      >
        <mat-calendar
          #calendar
          *ngIf="showCalendar"
          (selectedChange)="dateSelected($event)"
          [dateClass]="dateClass()"
          [startAt]="activeDate"
          [dateFilter]="this.user === 'customer' ? filterDates : null"
          [maxDate]="this.user === 'customer' ? inMonth : null"
          [minDate]="this.user === 'customer' ? tomorrow : null"
        ></mat-calendar>
        <button
          (click)="sendEvent()"
          *ngIf="user === 'office'"
          color="primary"
          mat-flat-button
          style="width: 100%"
        >
          {{ !newEvent ? "Uaktualnij event" : "Zapisz event" }}
        </button>
      </mat-tab>
      <mat-tab
        [disabled]="!selectedDate || formSubmitted"
        [label]="
          CustomerDataToSend.event ? CustomerDataToSend.event.startTime : 'Czas'
        "
        fxFlex
        style="overflow-y: scroll; height: 100%"
      >
        <button
          (click)="addEventTime()"
          *ngIf="user === 'office'"
          mat-icon-button
        >
          <mat-icon>add</mat-icon>
        </button>
        <button (click)="lockDay()" *ngIf="user === 'office'" mat-icon-button>
          <mat-icon>block</mat-icon>
        </button>
        <button
          (click)="markAsAvailableDay()"
          *ngIf="user === 'office'"
          mat-icon-button
        >
          <mat-icon>done_outline</mat-icon>
        </button>
        <mat-card
          (click)="eventSelected(event)"
          *ngFor="let event of currentDay?.avalibleHours"
          [ngClass]="event.state === 'free' ? 'pointer' : 'null'"
          [style.background-color]="
            event.state == 'free' ? '#e1f5fe' : '#f0ebf1'
          "
          [style.text-decoration]="
            user == 'office' ? '' : event.state !== 'free' ? 'line-through' : ''
          "
          class="mt-1 mb-1"
          fxLayout="row"
          fxLayoutAlign="center center"
        >
          <div fxLayout="column">
            <span *ngIf="event.name">
              {{ event.name + " " + event.startTime + "-" + event.endTime }}
            </span>
            <span *ngIf="event.customerData && user === 'office'">
              {{
                event?.customerData?.customerName +
                  " " +
                  event?.customerData?.customerSurname +
                  " " +
                  event?.customerData?.customerPhone +
                  " " +
                  event?.customerData?.customerEmail
              }}
            </span>
          </div>
          <span
            *ngIf="event == 'locked'"
            fxLayoutAlign="center center"
            style="color: #f44336"
          >
            <mat-icon class="mr-2">block</mat-icon>
            DZIEŃ NIEDOSTĘPNY
          </span>
          <span
            *ngIf="event == 'avalible'"
            fxLayoutAlign="center center"
            style="color: #4caf50"
          >
            <mat-icon class="mr-2">done_outline</mat-icon>
            DZIEŃ DOSTĘPNY
          </span>
          <span fxFlex></span>
          <button
            (click)="$event.stopPropagation()"
            (dblclick)="removeEvent(event)"
            *ngIf="user === 'office'"
            mat-icon-button
          >
            <mat-icon>remove_circle_outline</mat-icon>
          </button>
          <button
            (click)="$event.stopPropagation()"
            (dblclick)="markAsBusy(event)"
            *ngIf="user === 'office' && event.name"
            mat-icon-button
          >
            <mat-icon>block</mat-icon>
          </button>
        </mat-card>
        <div></div>
      </mat-tab>
      <mat-tab
        [disabled]="!CustomerDataToSend.event || formSubmitted"
        label="Dane"
        style="overflow-y: visible"
      >
        <h2 class="mt-2">Podaj swoje dane</h2>

        <form
          (ngSubmit)="onSubmit()"
          [formGroup]="form"
          class="mt-4"
          fxLayout="column"
        >
          <div></div>
          <mat-form-field>
            <input formControlName="customerName" matInput placeholder="Imię" />
          </mat-form-field>
          <mat-form-field>
            <input
              formControlName="customerSurname"
              matInput
              placeholder="Nazwisko"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              formControlName="customerEmail"
              matInput
              placeholder="E-mail"
            />
          </mat-form-field>
          <mat-form-field>
            <input
              formControlName="customerPhone"
              matInput
              placeholder="Telefon"
            />
          </mat-form-field>

          <!-- <div class="mt-2" fxLayout="column" mat-dialog-actions > -->

          <mat-checkbox color="primary" formControlName="termsAccepted"
            ><span
              [style.color]="
                !form.controls.termsAccepted.valid &&
                form.controls.termsAccepted.touched
                  ? '#f44336'
                  : 'black'
              "
            >
              Akceptuję warunki polityki prywatnosci</span
            >
          </mat-checkbox>
          <div fxFlex fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
            <button color="primary" mat-flat-button>Zapisuję się!</button>
          </div>
          <!-- </div> -->
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <mat-card
    *ngIf="formSubmitted"
    style="width: 80vw; height: 60vh; background-color: &quot;#b39ddb&quot;"
  >
    <div>
      <h2>
        Twoje spotkanie zostało umówione na dzień
        {{ form?.value?.eventData?.date }} o godzinie
        {{ form?.value?.eventData?.startTime }}.
      </h2>
      <h2>
        Lekcja potrwa {{ form?.value?.eventData?.duration }} minut. Wysłaliśmy
        również maila z potwierdzeniem.
      </h2>
      <h2>Do zobaczenia!</h2>
    </div>
  </mat-card>
</div>
