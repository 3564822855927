<div fxLayoutAlign="center center" style="width:100vw; height:100vh; background-color: gray;">


<mat-card class="m-3">
<div fxLayout="row" fxLayoutAlign="center center">
  <h2>Zapomniałeś hasła? Don't worry, be happy</h2>
    <mat-icon class="mb-3 ml-2">sentiment_very_satisfied</mat-icon>
</div>
  <mat-divider></mat-divider>
  <div fxLayout="column">
  <mat-form-field style="margin-bottom: -1.25em;"  fxFlex appearance="outline" class="mt-3">
    <mat-label>Wpisz nowe hasło</mat-label>
    <input required type="password" matInput [(ngModel)]="newPassword">
  </mat-form-field>
  <div >

    <mat-error *ngIf="message==='noNewPasswordProvided'">Podaj nowe hasło!</mat-error>
    <mat-error *ngIf="message==='userNotFound'">Link jest już nieaktywny!</mat-error>
    <span style="color:#5e35b1" *ngIf="message==='passwordChanged'">Hasło zmienione! Za chwilę nastąpi przekierowanie na stronę logowania.</span>
  </div>
      <button class="mt-1" mat-flat-button fxFlex color="primary" mat-button (click)="resetPassword()">
    Zapisz
  </button>
</div>
</mat-card>
</div>
