import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { types } from 'dooljs';
import { ChatService } from 'src/app/shared/services/chat.service';
import { ChatbotsService } from 'src/app/shared/services/chatbots.service';
import { FactsService } from './facts.service';
import { is } from 'date-fns/locale';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VideosRepoComponent } from '../videos-repo/videos-repo.component';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { UserDetailsService } from 'src/app/profile/user-details.service';

@Component({
  selector: 'app-facts',
  templateUrl: './facts.component.html',
  styleUrls: ['./facts.component.scss']
})
export class FactsComponent implements OnInit {
  selectedLevel = 'Beginner';
  selectedTopic = '';
  allChats = [];
  selectedChat = null;
  numberOfFacts = 1;
  page = 1
  facts = [];
  factsToEdit = [];
  abcFacts = [];
  trueFalseFacts = [];
  bareFacts = [];
  allCategories = []
  selectedCategory = ''
  originalFacts = []



  constructor(
    private chatbotsService: ChatbotsService,
    private chatService: ChatService,
    private factsService: FactsService,
    private helpers: HelpersService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private ref: ChangeDetectorRef,
    private dialogRef: MatDialogRef<FactsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userDetailsService: UserDetailsService
  ) { }

  ngOnInit() {
    this.allChats = this.chatbotsService.chatbots
    this.factsService.getAllFacts().subscribe(
      (res) => {
        console.log('res:', res);
        this.facts = res
        this.originalFacts = res
        res.forEach(fact => {
          fact.selected = false
        }
        )
        this.allCategories = [...new Set(this.facts.map(fact => fact.category))]
        console.log("🚀 ~ FactsComponent ~ ngOnInit ~  this.allCategories:", this.allCategories)
        // this.categorizeFacts()
      },
      (error) => {
        console.error('Error:', error);
      });
  }

  selectFact(fact) {
    console.log("🚀 ~ FactsComponent ~ selectFact ~ fact:", fact)
    let factToSelectIndex = this.facts.findIndex(f => f._id == fact._id)
    this.facts[factToSelectIndex].selected = fact.selected
  }

  finshFactsSelection() {
    this.dialogRef.close(this.facts.filter(fact => fact.selected))
  }
  selectCategory(category) {
    console.log("🚀 ~ FactsComponent ~ selectCategory ~ category:", category)
    this.selectedCategory = category
    if (category === 'all') {
      this.facts = this.helpers.clone(this.originalFacts)
      return
    }
    else {
      this.facts = this.helpers.clone(this.originalFacts).filter(fact => fact.category === category)
    }

  }
  createFacts(random?) {

    this.selectedChat = this.allChats.find(chat => chat.name === 'Walter')
    let promptAddition = '';

    promptAddition += `fact: ${this.selectedTopic}\n`;
    const data = {
      messages: [{ role: 'user', content: promptAddition }],
      aiProvider: this.selectedChat.aiProvider,
      model: this.selectedChat.model,
      maxTokens: this.selectedChat.maxTokens,
      maxMessages: this.selectedChat.maxMessages,
      systemMsg: this.selectedChat.systemMsg,
      files: null
    };

    this.chatService.sendPromptToChosenAi(data).subscribe(
      (res) => {
        this.snackbarService.showSnackBar('Facts created successfully', 'success');
        console.log('res:', res);
        console.log("🚀 ~ FactsComponent ~ createFacts ~ res.res.content:", res.res.content)
        let parsedRes = JSON.parse(res.res.content);
        console.log("🚀 ~ FactsComponent ~ createFacts ~ parsedRes:", parsedRes)
        let recievedFacts = parsedRes
        console.log("🚀 ~ recievedFacts ~ recievedFacts:", recievedFacts)
        if (!Array.isArray(recievedFacts)) {
          recievedFacts = [recievedFacts]
        }
        recievedFacts.forEach(fact => {
          fact.created = Date.now()
          fact.category = this.selectedCategory
          this.factsService.createFact(fact).subscribe(
            (res) => {
              console.log('res:', res);
              fact._id = res._id
              this.facts.unshift(fact)

            },
            (error) => {
              this.snackbarService.showSnackBar('Error creating facts', 'error');
              console.error('Error:', error);
            }

          );
        });
        console.log("🚀 ~ createFacts ~ this.facts:", this.facts)
      },
      (error) => {
        console.error('Error:', error);
      });
  }
  updateFact(fact) {
    this.factsService.updateFact(fact._id, fact).subscribe(
      (res) => {
        this.snackbarService.showSnackBar('Fact updated successfully', 'success');
        console.log('res:', res);
        let factToEditIndex = this.facts.findIndex(f => f._id == res._id)
        this.facts[factToEditIndex] = fact
        this.ref.detectChanges()
      },
      (error) => {
        this.snackbarService.showSnackBar('Error updating the fact', 'error');
        console.error('Error:', error);
      });
  }

  getFactsForTab(event) {
    this.categorizeFacts()

  }
  isFactFinished(fact) {
    return fact?.image?.includes('https') || !fact?.audio?.includes('https') || !fact?.explanationAudio?.includes('https');
  }
  addVideoToFact(fact) {
    this.dialog.open(VideosRepoComponent, {
      data: { fact: fact }
    }).afterClosed().subscribe((res) => {
      console.log('res:', res);
      if (res) {
        fact.video = res
        this.updateFact(fact)
      }
    }

    )
  }
  categorizeFacts() {
    console.log('categorizing facts...')
    this.factsToEdit = this.helpers.clone(this.facts).filter(fact => !this.isFactFinished(fact));

    this.abcFacts = this.helpers.clone(this.facts).filter(fact => fact.type === 'abc' && this.isFactFinished(fact));
    this.trueFalseFacts = this.helpers.clone(this.facts).filter(fact => fact.type === 'trueFalse' && this.isFactFinished(fact));
    this.bareFacts = this.helpers.clone(this.facts).filter(fact => fact.type === 'bareFact' && this.isFactFinished(fact));
  }



  removeFact(id) {
    this.factsService.deleteFact(id).subscribe(
      (res) => {
        this.facts = this.facts.filter(fact => fact._id !== id)
        this.snackbarService.showSnackBar('Fact deleted successfully', 'success');
      },
      (error) => {
        this.snackbarService.showSnackBar('Error deleting the fact', 'error');
        console.log("🚀 ~ FactsComponent ~ removeFact ~ error:", error)
      });
  }


}
