import { Component, OnInit } from '@angular/core';
import { TasksListComponent } from '../../tasks-list/tasks-list.component';
@Component({
  selector: 'app-tasks-finder',
  templateUrl: './tasks-finder.component.html',
  styleUrls: ['./tasks-finder.component.scss']
})
export class TasksFinderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
