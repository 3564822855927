import {
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { DeviceSizeService } from 'src/app/shared/services/device-size.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { RealTimeCommunicationService } from 'src/app/shared/services/real-time-communication.service';
import { UsersServiceService } from 'src/app/shared/services/users-service.service';
import { WorkersAvatarsService } from 'src/app/shared/services/workers-avatars.service';
import { AddTaskDialogComponent } from '../add-task-dialog/add-task-dialog.component';
import { worker } from 'cluster';
import { MatTooltip } from '@angular/material/tooltip';
import { ChatService } from 'src/app/shared/services/chat.service';
import { TasksService } from 'src/app/shared/services/tasks-service';
import { isArray } from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
})
export class TaskViewComponent implements OnInit {
  @Input() taskFromExternal
  @ViewChildren('comments') comments: QueryList<ElementRef>;

  @ViewChild('text') text;
  @ViewChild('input') input: ElementRef;
  task = null;
  commentToAdd;
  authorMode = false;
  savedComment = '';
  selectedWorker;
  futureDays = [];
  previousLength = 0;
  taskLoaded = false;
  commentToCheckId = null;
  messagesForWorkersSub: any;
  workers = [];
  removed: boolean;
  @ViewChild(MatTooltip) tooltip: MatTooltip;
  sub: any;

  constructor(
    public dialogRef: MatDialogRef<TaskViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService,
    @Inject(LOCALE_ID) public locale: string,
    public deviceSizeService: DeviceSizeService,
    private usersService: UsersServiceService,
    private dialog: MatDialog,
    public workersAvatarsService: WorkersAvatarsService,
    private realTimeCommunicationService: RealTimeCommunicationService,
    private chatService: ChatService,
    private tasksService: TasksService,
  ) { }
  onTouchStart(tooltip: MatTooltip) {
    tooltip.show();
  }
  correctText() {
    if (this.commentToAdd.length > 10) {
      this.chatService.correctPolishText(this.commentToAdd);
    } else {
      alert('Za krótki tekst');
    }
  }
  // getCommentHeight(commentId): number {
  //   console.log("🚀 ~ TaskViewComponent ~ getCommentHeight ~ commentId:", commentId)
  //   const element = document.getElementById(commentId);
  //   console.log("🚀 ~ TaskViewComponent ~ getCommentHeight ~ element:", element)
  //   return element ? element.offsetHeight : 0;
  // }
  ngOnInit(): void {
    this.generateFutureDays();

    // create a function that scrolls to the bottom of the comments with a small delay and smooth animation

    this.scrollToBottom()


    if (this.taskFromExternal) {
      this.task = this.taskFromExternal
      this.data = { task: this.task, selectedWorker: this.task.recipient, colorLight: '#f5f5f5', colorDark: '#f5f5f5', colorMedium: '#f5f5f5', colorMediumLight: '#f5f5f5' }
    }
    else {
      this.task = this.data.task;
    }
    setTimeout(() => {
      this.taskLoaded = true;
    }
      , 3000);
    this.sub = this.chatService
      .chatMessagesListener()
      .subscribe((messages: any) => {




        console.log(
          '🚀 ~ file: add-task-dialog.component.ts:41 ~ AddTaskDialogComponent ~ this.sub=this.chatService.chatMessagesListener ~ messages:',
          messages
        );

        this.commentToAdd = messages.res.choices[0].message.content;
        console.log("🚀 ~ TaskViewComponent ~ .subscribe ~ commentToAdd:", this.commentToAdd)
      });
    this.workers = this.workersAvatarsService.workersWithAvatars.filter(
      (worker) =>
        worker.id &&
        worker.name !== this.task.author.name &&
        worker?.priority > 0
    );
    this.workers = this.helpers.sortArrayByProperty(
      this.workers,
      'priority',
      'desc'
    );
    console.log(
      '🚀 ~ file: task-view.component.ts ~ line 56 ~ TaskViewComponent ~ ngOnInit ~ this.teachers',
      this.workers
    );
    this.messagesForWorkersSub = this.realTimeCommunicationService
      .messagesForWorkersListener()
      .subscribe((message: any) => {

        console.log("🚀 ~ file: task-view.component.ts:105 ~ TaskViewComponent ~ .subscribe ~ message:", message)
        this.handleUpdatedTaskMessage(message);


      });
    this.selectedWorker = this.data.selectedWorker;
    this.task = this.task;
    console.log("🚀 ~ file: task-view.component.ts:106 ~ TaskViewComponent ~ ngOnInit ~ task:", this.task)
    if (!this.task?.seenBy) {
      this.task.seenBy = [this.userDetailsService.getSimpleUserDetails().id];
    } else {

      this.task.seenBy.push(
        this.userDetailsService.getSimpleUserDetails().id
      );
      this.tasksService.setTasksSeenBy(
        this.task.id,
        this.userDetailsService.getSimpleUserDetails().id
      );





    }




    this.tasksService.setTasksAllCommentsSeenBy(this.task.id, this.userDetailsService.getSimpleUserDetails().id);

    this.handleIcons();
  }
  ngAfterViewInit(): void {

  }
  addToConversation(task, worker) {
    if (!task.recipients.includes(worker.id)) {
      const newComment = {
        seenBy: [this.userDetailsService.getSimpleUserDetails().id],
        text: worker.name + ' ' + 'dołączył(a) do rozmowy',
        date: Date.now(),
        completion: task.completion,
        author: {
          id: worker.id,
          name: worker.name,
        },
      };
      let recipients = [];
      recipients = task.comments.map((comment) => {
        return comment.author.id;
      });
      recipients.push(worker.id);
      recipients = this.helpers.removeDuplicatesSingle(recipients);
      task.comments.push(newComment);

      this.tasksService.addComment(
        task.id,
        newComment);
      this.handleIcons();
      this.comments.last.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
    else {
      alert('Ta osoba jest już w rozmowie')
    }
  }
  generateFutureDays(): void {
    const daysPL = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
    let currentDate = new Date();

    // Add "today" and "tomorrow"
    this.futureDays.push({ dayName: 'Dzisiaj', date: new Date().toISOString().split('T')[0] });
    currentDate.setDate(currentDate.getDate() + 1);
    this.futureDays.push({ dayName: 'Jutro', date: currentDate.toISOString().split('T')[0] });

    // Add the rest of the days
    for (let i = 0; i < 5; i++) { // Assuming next 5 days
      currentDate.setDate(currentDate.getDate() + 1); // Increment the day
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) { // Exclude weekends
        this.futureDays.push({
          dayName: daysPL[currentDate.getDay()],
          date: currentDate.toISOString().split('T')[0]
        });
      }
    }
  }
  selectCustomDate(event: any) {
    console.log("🚀 ~ OverdueTasksDialogComponent ~ selectCustomDate ~ event:", event);

    let selectedDate;

    if (event.target && event.target.value) {
      // If the event is from the HTML5 date picker
      selectedDate = new Date(event.target.value);
    } else if (typeof event === 'string') {
      // If the date is passed as a string (e.g., from other buttons)
      selectedDate = new Date(event);
    } else {
      // Handle other cases or show an error
      console.error("Invalid date or event format");
      return;
    }

    // Check if the date is valid
    if (isNaN(selectedDate.getTime())) {
      console.error("Invalid date selected");
      return;
    }

    const formattedDate = selectedDate.toISOString().split('T')[0];
    console.log("🚀 ~ Formatted Date:", formattedDate);

    // Continue with your task scheduling logic
    this.tasksService.rescheduleTask(this.task.id, formattedDate);
  }
  planTaskForLater() {
    alert('Funkcja w przygotowaniu')
  }
  setCommentsCompletion(comment, completion: boolean) {
    console.log("🚀 ~ TaskViewComponent ~ setCommentsCompletion ~ completion:", completion)
    let commentToUpdate = this.task.comments.find((_comment) => {
      return comment.date == _comment.date;
    }
    );
    if (completion) {
      if (!confirm('Czy na pewno chcesz oznaczyć jako niezrobione? Spowoduje to usunięcie opisu')) {
        return
      }
    }
    if (completion === false || !completion) {

      const addDescription = confirm('Czy chcesz dodać opis?');
      let description
      if (addDescription) {
        description = prompt('Dodaj opis');
        if (description) {
          comment.description = description;
          commentToUpdate.description = description;
          this.tasksService.updateCommentsDescription(
            this.task.id,
            commentToUpdate.date,
            commentToUpdate.description
          );
        }
      }
      else {
        commentToUpdate.description = '';
        this.tasksService.updateCommentsDescription(
          this.task.id,
          commentToUpdate.date,
          ''
        );
      }
    }
    //find comment in task.comments by date property





    if (!commentToUpdate) {
      return
    }
    if (!commentToUpdate.completion) {
      commentToUpdate.completion = true
    }
    else {
      commentToUpdate.completion = completion;

    }

    if (commentToUpdate.completion == true) {
      commentToUpdate.description = '';
      this.tasksService.updateCommentsDescription(
        this.task.id,
        commentToUpdate.date,
        null
      );
    }
    this.tasksService.updateCommentsCompletion(
      this.task.id,
      commentToUpdate.date,
      commentToUpdate.completion
    );
  }



  addComment(task, readMode?) {
    let recipients = [];
    recipients = task.comments.map((comment) => {
      return comment.author.id;
    });
    recipients.push(this.selectedWorker.id);
    recipients.push(task.author.id);
    recipients.push(this.userDetailsService.getSimpleUserDetails().id);
    recipients = this.helpers.removeDuplicatesSingle(recipients);
    const newComment = {
      seenBy: [this.userDetailsService.getSimpleUserDetails().id],
      text: !readMode ? this.commentToAdd : 'Przeczytano',
      date: Date.now(),
      completion: task.completion,
      author: this.userDetailsService.getSimpleUserDetails(),
    };
    task.comments.push(newComment);
    recipients = recipients.filter(
      (recipient) =>
        recipient !== this.userDetailsService.getSimpleUserDetails().id
    );
    if (this.task.completion == 'done') {
      this.task.completion = 'todo';
      this.tasksService.setTasksCompletion(
        this.task.id,
        'todo'
      );
    }

    this.tasksService.addComment(
      task.id,
      newComment,
    );
    this.commentToAdd = '';
    this.handleIcons();
    this.scrollToBottom();
  }
  scrollToBottom() {
    setTimeout(() => {
      this.comments.last.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }, 1000);
  }
  closeDialog() {

    this.dialogRef.close();
  }
  handleUpdatedTaskMessage(message) {
    console.log("🚀 ~ file: task-view.component.ts:238 ~ TaskViewComponent ~ handleUpdatedTaskMessage ~ message:", message)
    if (message.newComment && message.taskId === this.task.id) {
      let unseenCommentFound = false;

      const commentToAdd = message.newComment;
      console.log("🚀 ~ file: task-view.component.ts:242 ~ TaskViewComponent ~ handleUpdatedTaskMessage ~ commentToAdd:", commentToAdd)
      if (!this.task.comments.some((comment) => comment.date === commentToAdd.date)) {
        this.task.comments.push(commentToAdd);
        this.scrollToBottom();

      }
      const userDetails = this.userDetailsService.getSimpleUserDetails();
      if (userDetails && userDetails.id) {
        this.task.comments.forEach((comment) => {
          if (!comment.seenBy.includes(userDetails.id)) {
            unseenCommentFound = true;
            comment.seenBy.push(userDetails.id);
          }
        });
      }
      if (unseenCommentFound) {
        this.tasksService.setTasksCommentsSeenBy(
          this.task.id,
          commentToAdd.date,
          this.userDetailsService.getSimpleUserDetails().id
        );

        this.handleIcons();

      }
    }
    if (message.addSeenByToTask) {
      if (!this.task.seenBy.includes(message.addSeenByToTask.seenBy)) {
        this.task.seenBy.push(message.addSeenByToTask.seenBy);
      }
      this.handleIcons();
    }
    if (message.addSeenByToComment) {
      this.task.comments.forEach((comment) => {
        if (comment.date === message.addSeenByToComment.commentId) {
          if (!comment.seenBy.includes(message.addSeenByToComment.seenBy)) {
            comment.seenBy.push(message.addSeenByToComment.seenBy);
          }
          this.handleIcons();
        }
      });
    }
  }

  handleIcons() {
    const involvedUsers = this.helpers.removeDuplicatesSingle(this.task.seenBy);
    this.task.comments.forEach((comment) => {
      if (comment) {


        comment.lastSeenBy = [];
      }
    });
    involvedUsers.forEach((user) => {
      let commentsThatUserSeen = this.task.comments.filter((comment) => {
        return comment.seenBy.includes(user);
      });
      commentsThatUserSeen = commentsThatUserSeen.sort((a, b) => {
        return a.date - b.date;
      });
      if (commentsThatUserSeen?.length > 0) {
        commentsThatUserSeen[commentsThatUserSeen.length - 1].lastSeenBy.push(
          user
        );
      }

    });
  }
  taskCompletionChanged(completion) {
    if (completion !== 'pinned' && this.task.completion.length > 1 && isArray(completion)) {
      if (!confirm('Czy na pewno chcesz zmieć status? Spowoduje to usunięcie wszystkich przypisanych osób')) {
        return
      }
    }
    if (completion == 'pinned') {
      completion = [this.userDetailsService.getSimpleUserDetails().id];
    }


    this.task.completion = completion;
    this.tasksService.setTasksCompletion(
      this.task.id,
      completion
    );
  }
  removeFromPinned(userId) {
    if (this.task.completion.includes(userId)) {
      this.task.completion = this.helpers.removeElementFromArray(this.task.completion, userId);
      this.tasksService.setTasksCompletion(
        this.task.id,
        this.task.completion
      );
    }
  }
  addToPinned(userId) {

    if (!isArray(this.task.completion) || !this.task.completion.includes(this.userDetailsService.getSimpleUserDetails().id) && !this.task.completion.includes(userId)) {
      this.task.completion = [this.userDetailsService.getSimpleUserDetails().id];
      this.task.completion.push(userId);
      this.addToConversation(this.task, this.workers.find((worker) => worker.id === userId));
      if (this.task.completion.includes(userId)) {

        this.tasksService.setTasksCompletion(
          this.task.id,
          this.task.completion
        );

      }

    }
    if (isArray(this.task.completion) && this.task.completion.includes(this.userDetailsService.getSimpleUserDetails().id) && !this.task.completion.includes(userId)) {
      this.task.completion.push(userId);
      if (!this.task.recipients.includes(userId)) {
        this.task.recipients.push(userId);
        this.addToConversation(this.task, this.workers.find((worker) => worker.id === userId));
      }
      this.tasksService.setTasksCompletion(
        this.task.id,
        this.task.completion
      );
    }

  }
  // editTask(task) {
  //   const dialogRef = this.dialog.open(AddTaskDialogComponent, {
  //     data: { editMode: true, task: task },
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(
  //       '🚀 ~ file: task-view.component.ts ~ line 61 ~ TaskViewComponent ~ dialogRef.afterClosed ~ result',
  //       result
  //     );
  //     this.usersService.updateTasksData(this.selectedWorker.id, task.id, task);
  //   });
  // }
  // removeComment(comment, task) {
  //   task.comments = this.helpers.removeElementFromArray(task.comments, comment);
  //   // task.completion = null;

  //   this.usersService.removeCommentFromTask(
  //     this.selectedWorker.id,
  //     task.id,
  //     comment.id
  //   );
  // }
  removeTask(task) {
    this.authorMode = true;
    this.removed = true;
    this.tasksService.deleteTask(task.id);
    this.dialogRef.close();
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    // if (!this.removed) {
    //   this.usersService.updateTasksCommentsVisibility(
    //     this.task?.recipient?.id,
    //     this.task
    //   );
    // }

    this.messagesForWorkersSub.unsubscribe();
    this.messagesForWorkersSub.unsubscribe();
  }
}
