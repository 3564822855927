<span
    style="width: 0%; height: 0%;"
    #area
    (click)="this.emit()"
></span>
<div
    class="mat-elevated-z4"
    [style.width]="big ? '60px' : '30px'"
    [style.height]="big ? '60px' : '30px'"
    style="background-color: white; border-radius: 50%;"
    fxLayoutAlign="center center"
    class="pointer mat-elevation-z4"
>
    <mat-icon
        [style.transform]="big ? 'scale(2)' : ''"
        (click)="startRecognition()"
        [color]="understood ? 'primary' : 'warn'"
        *ngIf="!this.listening"
        aria-label="label"
    >mic
    </mat-icon>
    <!-- <mat-icon (click)="stopRecording()" color="warn" *ngIf="!this.listening"  aria-label="label">mic</mat-icon> -->
    <mat-icon
        [style.transform]="big ? 'scale(2)' : ''"
        *ngIf="this.listening"
        class="animate__animated animate__heartBeat animate__infinite"
        color="accent"
    >mic
    </mat-icon>
    <img
        *ngIf="big"
        style="padding:2px; position:absolute; bottom:-15px;  width: 30px; height: 30px; border-radius: 50%; background-color: white;"
        [src]="difficulty=='easy' ? '/assets/emojis/1f476.svg' : difficulty=='medium' ? '/assets/emojis/1f914.svg' :  '/assets/emojis/1f9b8.svg' "
        alt
     />
    <img
        *ngIf="!big"
        style="padding:2px;position:absolute; bottom:-10px;  width: 20px; height: 20px; border-radius: 50%; background-color: white;"
        [src]="difficulty=='easy' ? '/assets/emojis/1f476.svg' : difficulty=='medium' ? '/assets/emojis/1f914.svg' :  '/assets/emojis/1f9b8.svg' "
        alt
     />
</div>
