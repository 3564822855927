import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { VersionService } from './version.service';

export interface UserDetails {
  _id: string;
  email: string;
  name: string;
  role: string;
  status: string;
  exp: number;
  iat: number;
}

interface TokenResponse {
  token: string;
  duplicateUser?: boolean;
}

export interface TokenPayload {
  email: string;
  password: string;
  name?: string;
  role?: string;
  status?: string;
  userData?;
}

@Injectable()
export class AuthenticationService {
  url;
  private token: string;
  userBackendMessages = new Subject();
  codeUpdated = new Subject();
  constructor(
    private http: HttpClient,
    private router: Router,
    private dbService: NgxIndexedDBService,
    private versionService: VersionService,
  ) {
    this.url = this.versionService.url + '/api/users/';
  }

  private saveToken(token: string): void {
    localStorage.setItem('mean-token', token);
    this.token = token;
  }

  public getToken(): string {
    const params = this.router.parseUrl(this.router.url).queryParams;

    if (params.token) {
      console.log(
        '🚀 ~ file: login.compoknent.ts:53 ~ LoginComponent ~ ngOnInit ~ params.token:',
        params.token,
      );
      this.token = params.token;
      localStorage.setItem('mean-token', params.token);
    }
    if (!this.token) {
      const iframe =
        document.referrer.toLowerCase().includes('lingking.pl') ||
        document.referrer.toLowerCase().includes('linget.it');
      console.log('-> iframe', iframe);
      if (!iframe) {
        this.token = localStorage.getItem('mean-token');
      }
    }
    return this.token;
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  private request(
    method: 'post' | 'get',
    type: 'login' | 'register' | 'profile',
    user?: TokenPayload,
  ): Observable<any> {
    let base;

    if (method === 'post') {
      base = this.http.post(this.url + type, user);
    } else {
      base = this.http.get(this.url + type, {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      });
    }

    const request =
      //  this.http.post(`http://lingking.com.pl:3000api/login`, user)
      base.pipe(
        map((data: TokenResponse) => {
          if (data.token) {
            this.saveToken(data.token);
          }

          return data;
        }),
      );

    return request;
  }

  public register(user: TokenPayload): Observable<any> {
    // this.request('post', 'register', user).subscribe(result=>{
    //
    //   if(result.duplicateUser === true){
    //     user.email = user.email +'1'
    //
    //     this.register(user)
    //   }
    // })
    return this.request('post', 'register', user);
  }

  public login(user: TokenPayload): Observable<any> {
    return this.request('post', 'login', user);
  }

  public profile(): Observable<any> {
    return this.request('get', 'profile');
  }
  changePassword(oldPassword, newPassword, id) {
    this.http
      .post<{ message: any }>(this.url + 'changepassword', {
        oldPassword: oldPassword,
        newPassword: newPassword,
        id: id,
      })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message.newUserId);
      });
  }
  setPassword(newPassword, id) {
    this.http
      .post<{ message: any }>(this.url + 'setpassword', {
        newPassword: newPassword,
        id: id,
      })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  changeAppsEmail(newEmail, id) {
    console.log(
      '🚀 ~ file: authentication.service.ts ~ line 169 ~ AuthenticatisonService ~ changeAppsEmail ~ id',
      id,
    );
    this.http
      .post<{ message: any }>(this.url + 'changeEmail', {
        newEmail: newEmail,
        id: id,
      })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  forgotPassword(email) {
    this.http
      .post<{ message: any }>(this.url + 'forgot', { email: email })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  resetPassword(token, newPassword) {
    this.http
      .post<{ message: any }>(this.url + 'reset', {
        token: token,
        newPassword: newPassword,
      })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  resetFreshPassword(id) {
    this.http
      .post<{ message: any }>(this.url + 'resetfreshpassword', { id: id })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  generateCode(id) {
    this.http
      .post<{ message: any }>(this.url + 'generate-code', { id: id })

      .subscribe((message) => {
        this.codeUpdated.next(message);
      });
  }
  resetFreshWithTempPassword(id) {
    this.http
      .post<{ message: any }>(this.url + 'reset-fresh-with-temp-password', {
        id: id,
      })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  sendEmail(id) {
    this.http
      .post<{ message: any }>(this.url + 'sendemail', { id: id })
      .pipe(
        map((message) => {
          return message.message;
        }),
      )
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('mean-token');
    sessionStorage.removeItem('userDetails');
    this.router.navigateByUrl('/');
    this.dbService.delete('other', 'userDetails').subscribe((result) => {
      console.log('result', result);
    });
  }
  codeUpdatedListener() {
    return this.codeUpdated.asObservable();
  }
  userBackendMessagesListener() {
    return this.userBackendMessages.asObservable();
  }
}
