import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Component({
  selector: 'app-pirates-game',
  templateUrl: './pirates-game.component.html',
  styleUrls: ['./pirates-game.component.css'],
})
export class PiratesGameComponent implements OnInit {
  grid = [];
  @Output() points = new EventEmitter();
  numberOfItems = 100;
  tileSize = null;
  tileHeight = null;
  chosenMap = '';
  music = new Audio();
  sound = new Audio();
  _points: number;
  constructor(
    private helpers: HelpersService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.music.src = '../assets/game-elements/pirates/music.wav';

    this.music.loop = true;
    this.music.volume = 0.2;
    this.music.play();

    this.chosenMap =
      'url(/assets/game-elements/pirates/maps/' +
      this.helpers.getRandomNumber(1, 6) +
      '.svg)';
    console.log(
      '🚀 ~ file: pirates-game.component.ts:32 ~ PiratesGameComponent ~ ngOnInit ~ this.chosenMap:',
      this.chosenMap,
    );
    this.tileSize = '10%';

    for (let index = 0; index < this.numberOfItems; index++) {
      this.grid.push({ value: 0, opened: false });
    }

    const numbers = this.helpers.getUniqueRandomItemsFromArray(
      [...Array(100).keys()],
      40,
    );
    numbers.forEach((number) => {
      this.grid[number].value = 1;
    });
    const numbers2 = this.helpers.getUniqueRandomItemsFromArray(
      [...Array(100).keys()],
      20,
    );
    numbers2.forEach((number) => {
      this.grid[number].value = 2;
    });
    const numbers3 = this.helpers.getUniqueRandomItemsFromArray(
      [...Array(100).keys()],
      10,
    );
    numbers3.forEach((number) => {
      this.grid[number].value = 3;
    });
    const numbers4 = this.helpers.getUniqueRandomItemsFromArray(
      [...Array(100).keys()],
      10,
    );
    numbers4.forEach((number) => {
      this.grid[number].value = -1;
    });
    console.log(
      '🚀 ~ file: pirates-game.component.ts ~ line 30 ~ PiratesGameComponent ~ ngOnInit ~ this.grid',
      this.grid,
    );
  }
  openTile(tile): void {
    tile.opened = true;
    this._points = 0;
    switch (tile.value) {
      case -1:
        this._points = -400;
        this.sound.src = '../assets/game-elements/pirates/pirate7.wav';
        break;
      case 0:
        this.sound.src = '../assets/game-elements/pirates/shot.mp3';
        break;
      case 1:
        this._points = 100;
        this.sound.src = '../assets/game-elements/pirates/some.mp3';
        break;
      case 2:
        this._points = 300;
        this.sound.src = '../assets/game-elements/pirates/more.mp3';
        break;
      case 3:
        this._points = 500;
        this.sound.src = '../assets/game-elements/pirates/most.mp3';
        break;
    }
    tile.showBigPicture = true;
    setTimeout(() => {
      tile.showBigPicture = false;
    }, 3000);
    setTimeout(() => {
      this.sound.play();
    }, 100);

    this.points.emit(this._points);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.music.pause();
  }
}
