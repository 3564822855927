import { SwPush, SwUpdate } from "@angular/service-worker";
import { NgxSpinnerService } from "ngx-spinner";
import { SpinnerParamsService } from "./shared/services/spinner-params.service";
import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { PushService } from "./shared/services/push.service";
import { VersionService } from "./shared/services/version.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "lswebapp2";

  constructor(
    private spinner: NgxSpinnerService,
    public spinnerParams: SpinnerParamsService,
    public updates: SwUpdate,
    private swPush: SwPush,
    private router: Router,
    private versionService: VersionService,
    private pushService: PushService
  ) {}
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.spinner.show();
    // if (
    //   this.versionService.environment === "test" ||
    //   !this.versionService.environment
    // ) {
    //   this.subscribeToNotifications();
    //   setTimeout(() => {
    //     this.pushService.sendNotification();
    //   }, 2000);
    // }
  }
}
