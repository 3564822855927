<div fxFlex style="overflow-y: auto; overflow-x: hidden;">
  <app-tasks-list
    [title]="'Zaległe'"
    [selectedWorker]="selectedWorker"
    [workers]="workers"
    [tasks]="overdueTasks"
  ></app-tasks-list>
  <app-tasks-list
    [title]="'Na dzisiaj'"
    [selectedWorker]="selectedWorker"
    [workers]="workers"
    [tasks]="forTodayTasks"
  ></app-tasks-list>
  <app-tasks-list
    *ngIf="userDetailsService.getSimpleUserDetails()?.role === 'owner'"
    [title]="'Zaplanowane'"
    [selectedWorker]="selectedWorker"
    [workers]="workers"
    [tasks]="forFutureTasks"
  ></app-tasks-list>
  <div
    class="mt-3 mb-3"
    *ngIf="userDetailsService.getSimpleUserDetails()?.role === 'owner'"
  >
    <div class="mt-3 mb-3">
      <div style="width: 100%;">
        <span style="width: 100%; font-size: 1.3em;" fxLayoutAlign="start">
          <span>
            Platforma
          </span>
        </span>
      </div>
      <mat-divider class="mt-2"></mat-divider>
    </div>
  </div>
  <mat-expansion-panel
    class="m-1"
    (closed)="platformTasksOpened = false"
    (opened)="platformTasksOpened = true"
    *ngIf="userDetailsService.getSimpleUserDetails()?.role === 'owner'"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span style="font-size: 1.2em; font-weight: bold;">
          Platforma
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-tasks-list
      *ngIf="platformTasksOpened"
      [selectedWorker]="selectedWorker"
      [workers]="workers"
      [tasks]="platformTasks"
    ></app-tasks-list>
  </mat-expansion-panel>
  <div class="mt-3 mb-3">
    <div style="width: 100%;">
      <span style="width: 100%; font-size: 1.3em;" fxLayoutAlign="start">
        <span>
          Skończone
        </span>
      </span>
    </div>
    <mat-divider class="mt-2"></mat-divider>
  </div>
  <mat-expansion-panel
    class="m-1"
    #panel
    (closed)="finishedTasksOpened = false"
    (opened)="finishedTasksOpened = true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span style="font-size: 1.2em; font-weight: bold;">
          Skończone zadania
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-tasks-list
      *ngIf="finishedTasksOpened"
      [selectedWorker]="selectedWorker"
      [workers]="workers"
      [tasks]="finishedTasks"
    ></app-tasks-list>
  </mat-expansion-panel>
</div>
