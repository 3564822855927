import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'menu-group-item',
  templateUrl: './menu-group-item.component.html',
  styleUrls: ['./menu-group-item.component.css']
})
export class MenuGroupItemComponent implements OnInit {
  @Input() text : string;
  @Input() icon : string;


  constructor() { }

  ngOnInit(): void {
  }

}
