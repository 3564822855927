import { Injectable } from '@angular/core';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class QuizHelperService {
  blanks = [];
  items = [];
  constructor(private helpers: HelpersService) { }

  getItemsToLearnForNormalSet(items, children?, pdf?) {
    items = items.filter((item) => item.item.id);
    this.generateBlanks(items);
    let result = [];
    result = items.map((item) => {
      if (item?.item?.id) {
        const tempItem = [];
        if (item.item.disassembledChildrenSentence) {
        }
        let _tempItem = [];
        let _finalChildrenCorrectAnswer =
          item.item.disassembledChildrenSentence.filter((word) => word.blank);
        _finalChildrenCorrectAnswer.map((wordBlank) => {
          _tempItem.push(
            wordBlank.word
              .replace('.', '')
              .replace(',', '')
              .replace('?', '')
              .replace('!', '')
              .replace(':', ''),
          );
        });
        const finalChildrenCorrectAnswer = _tempItem.join(' ').toLowerCase();

        let correctAnswer = item.item.disassembledSentence.filter(
          (word) => word.blank,
        );
        correctAnswer.map((wordBlank) => {
          tempItem.push(
            wordBlank.word
              .replace('.', '')
              .replace(',', '')
              .replace('?', '')
              .replace('!', '')
              .replace(':', ''),
          );
        });
        correctAnswer = tempItem.join(' ').toLowerCase();
        const answers = this.generateAnswers(
          correctAnswer,
          (item.item.word1 + ' ' + item.item.word2).trim(),
        );
        return {
          id: item.item.id,
          wordId: item.wordId,
          word1: item.item.word1,
          word2: item.item.word2,
          disassembledSentence: item.item.disassembledSentence,
          sentence: item.item.sentence,
          childrenSentence: item.item.disassembledChildrenSentence
            .map((word) => {
              return word.word;
            })
            .join(' ')
            .replace('  ', ' ')
            .trim(),
          childrenImg: item.item.childrenIconPicture,
          allAnswersWithTranslation: this.blanks,
          childrenMediumImg: item.item.childrenSmallPicture,
          disassembledChildrenSentence: item.item.disassembledChildrenSentence,
          img: item.item.iconPicture,
          mediumImg: item.item.smallPicture,
          correctAnswer,
          star: item.star,
          sentenceCorrectAnswer: correctAnswer,
          childrenCorrectAnswer: finalChildrenCorrectAnswer,
          scrambledChildrenCorrectAnswer: this.helpers.scramble(
            finalChildrenCorrectAnswer,
          ),
          answer1: answers[0],
          answer2: answers[1],
          answer3: answers[2],
          answer4: answers[3],
          dummyAnswer: answers[4],
          blanksIds: item.item.disassembledSentence
            .filter((word) => word.blank)
            .map((_word) => {
              return _word.id;
            }),
          childrenBlanksIds: item.item.childrenDisassembledSentence
            ? item.item.childrenDisassembledSentence
              .filter((word) => word.blank)
              .map((_word) => {
                return _word.id;
              })
            : null,
          bareCorrectAnswer: (item.item.word1 + ' ' + item.item.word2).trim(),
          chidrenCorrectAnswerDashes: finalChildrenCorrectAnswer.replace(
            /[a-zA-Z]/g,
            '_',
          ),
          childrenSentenceWithDashes: item.item.disassembledChildrenSentence
            .map((word) => {
              return word.word;
            })
            .join(' ')
            .replace('  ', ' ')
            .replace(
              new RegExp(finalChildrenCorrectAnswer, 'i'),
              finalChildrenCorrectAnswer.replace(/[a-zA-Z]/g, '__'),
            ),
          sentenceWithDashes: item.item.disassembledSentence
            .map((word) => {
              return word.word;
            })
            .join(' ')
            .replace('  ', ' ')
            .replace(
              new RegExp(correctAnswer, 'i'),
              correctAnswer.replace(/[a-zA-Z]/g, '__'),
            ),
          longestWords: this.getLongesWords(item.item.disassembledSentence),

          bareCorrectAnswerDashes: (item.item.word1 + ' ' + item.item.word2)
            .trim()
            .replace(/[a-zA-Z]/g, '_'),
          translation: pdf
            ? item.item.translation.replace(/ *\([^)]*\) */g, '').toLowerCase()
            : item.item.translation,
          level: 2,
          children: children,
          questionType: '',
          gif: '/assets/gifs/' + Math.floor(Math.random() * 18 + 1) + '.gif',
          sentenceAudio: item.item.sentenceAudio,
          sentenceAudioGB: item.item.sentenceAudioGB,
          blankAudio: item.item.blankAudio,
          answersLang: item.item.tags?.some(
            (tag) => tag?.tag?.includes('lang:spanish'),
          )
            ? 'spanish'
            : null,
          plainSpeakingImage:
            '/assets/people-speaking-plain/' +
            Math.floor(Math.random() * 165 + 1) +
            '.png',

          blankAudioGB: item.item.blankAudioGB,
          fromSet: item.fromSet,
          part: item.item.part,
          wordAudio: item.item.wordAudio,
          wordAudioGB: item.item.wordAudioGB,
          sentenceTranslation: item.item.sentenceTranslation,
          polishAudio: item.item.polishAudio,
          childrenSentenceAudio: item.item.childrenSentenceAudio,
          childrenSentenceAudioGB: item.item.childrenSentenceAudioGB,
          childrenBlankAudio: item.item.childrenBlankAudio,
          childrenBlankAudioGB: item.item.childrenBlankAudioGB,
          fullItem: item,
        };
      }
    });
    return result;
    this.items = result;
  }
  getLongesWords(disassembledSentence) {
    const forbiddenWords = ['the', 'dont', 'doesnt', 'cant', 'wont', 'isnt'];
    let words = [];
    disassembledSentence.forEach((word) => {
      const lowercaseWord = word.word.toLowerCase().replace(/[^\w\s]/g, '');
      if (word.connectedWith = []) {

        words.push(lowercaseWord);
      }
    });
    words.sort((a, b) => b.length - a.length);
    disassembledSentence.forEach((word) => {
      const lowercaseWord = word.word.toLowerCase().replace(/[^\w\s]/g, '');
      if (word.verb) {
        if (word.connectedWith = []) {

          words.unshift(lowercaseWord);
        }
      }
    }
    );
    let uniqueWords = [...new Set(words)];
    uniqueWords = uniqueWords.filter((word) => word.length > 2 || word.includes(' ') || word.includes('\''));
    uniqueWords = uniqueWords.filter((word) => !forbiddenWords.includes(word));
    return uniqueWords.slice(0, 6);
  }



  generateAnswers(correctAnswer, correctAnswerBareWord) {
    let answers = [];
    while (answers.length < 3) {
      const numberToTry = Math.floor(Math.random() * this.blanks.length);
      if (
        answers.indexOf(this.blanks[numberToTry].word) === -1 &&
        this.blanks[numberToTry].word !== correctAnswer &&
        this.blanks[numberToTry].word !== correctAnswerBareWord &&
        !answers.includes(this.blanks[numberToTry].word)
      ) {
        answers.unshift(this.blanks[numberToTry].word.toLowerCase());
      }
    }
    answers.unshift(correctAnswer.toLowerCase());
    answers = this.helpers.shuffle(answers);
    while (answers.length < 5) {
      const numberToTry = Math.floor(Math.random() * this.blanks.length);
      if (
        answers.indexOf(this.blanks[numberToTry].word) === -1 &&
        this.blanks[numberToTry].word !== correctAnswer &&
        this.blanks[numberToTry].word !== correctAnswerBareWord &&
        !answers.includes(this.blanks[numberToTry].word)
      ) {
        answers.push(this.blanks[numberToTry].word.toLowerCase());
      }
    }
    return answers;
  }

  generateBlanks(items) {
    items = items.filter((item) => item.item.id);
    this.blanks = items.map((blank) => {
      // const tempBlanks = [];

      // const wordBlanks = blank.item.disassembledSentence.filter(
      //   (word) => word.blank
      // );

      // wordBlanks.map((wordBlank) => {
      //   if (wordBlank.word) {
      //     tempBlanks.push(
      //       wordBlank.word
      //         .replace(".", "")
      //         .replace(",", "")
      //         .replace("?", "")
      //         .replace("!", "")
      //         .replace(":", "")
      //     );
      //   }
      // });
      return {
        // word: tempBlanks.join(" "),
        word: (blank.item.word1 + ' ' + blank.item.word2).trim(),
        translation: blank.item.translation,
      };
    });
  }
  createSentenceWithBlank(item) {
    let result;
    result = item.sentence;
    const textToReplace = item.item.word1 + ' ' + item.item.word2;
    const blanks = (item.item.word1 + ' ' + item.item.word2)
      .trim()
      .replace(/[a-zA-Z]/g, '_');
    result = result.replace(textToReplace, blanks);
  }

  getItemsForFreeSet(items, questionLang, answersLang) {
    let result = [];
    result = items.map((item) => {
      if (item && item.question && item.correctAnswer) {
        let answers = this.helpers.shuffle([
          item.correctAnswer,
          item.wrongAnswer1,
          item.wrongAnswer2,
          item.wrongAnswer3,
        ]);
        const numberToTry = Math.floor(Math.random() * items.length);
        if (
          answers.indexOf(items[numberToTry].wrongAnswer1) === -1 &&
          items[numberToTry].wrongAnswer1 !== item.correctAnswer
        ) {
          answers.push(items[numberToTry].wrongAnswer1.toLowerCase());
        }
        return {
          id: item.id,
          img: item.iconPicture,
          mediumImg: item.smallPicture,
          question: item.question.includes('_')
            ? item.question?.replace(/(_)/g, '_')
            : item.question,
          freeSetItem: true,
          correctAnswer: item.correctAnswer,
          filledQuestion: item.question
            .replace(/(_)\w+/g, item.correctAnswer.toLowerCase().trim())
            .replace(/\(.*?\)/g, '')
            .replace('  ', ' ')
            .replace('  ', ' '),
          answer1: answers[0],
          answer2: answers[1],
          answer3: answers[2],
          answer4: answers[3],
          dummyAnswer: answers[4],
          gif: '/assets/gifs/' + Math.floor(Math.random() * 18 + 1) + '.gif',
          plainSpeakingImage:
            '/assets/people-speaking-plain/' +
            Math.floor(Math.random() * 165 + 1) +
            '.png',
          questionLang: questionLang,
          smallDescriptionPicture: item.smallDescriptionPicture,
          bigdescriptionPicture: item.bigdescriptionPicture,
          iconDescriptionPicture: item.iconDescriptionPicture,
          answersLang: answersLang,
          termAudioUS: item.termAudioUS,
          fromSet: item.fromSet,
          termAudioUK: item.termAudioUK,
          definitionAudioUS: item.definitionAudioUS,
          definitionAudioUK: item.definitionAudioUK,
          termAndDefinitionAudioUS: item.termAndDefinitionAudioUS,
          spot: item.spot,
        };
      }
    });
    this.items = result;
    return result;
  }
}
