<div
    style="height: 100%; "
    class="m-3"
>
    <div *ngFor="let category of sortedWords">
        <mat-card
            fxLayout="row"
            fxLayout.lt-sm="column"
            class="mb-2 p-0  mat-elevation-z4"
            *ngFor="let meaning of category"
        >
            <div
                fxLayout="row"
                fxFlex
            >
                <div
                    fxLayoutAlign="center center"
                    style="width: 30px; text-transform: uppercase; color: white; font-weight: bolder;"
                    [style.background-color]="meaning.color"
                >{{meaning.cefr}}
                </div>
                <div style="width: 10px;"></div>
                <div
                    fxLayout="column"
                    fxFlex
                >
                    <div
                        class="ml-2 mb-2 mt-2 primary"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        style="font-size: 1.5em; "
                    >
                        <span
                            fxLayoutAlign="center center"
                            style=" font-weight:bolder; font-size: 1.3em;"
                        >
                            <div
                                fxFlex
                                fxLayoutAlign="center center"
                                style="text-align: center;"
                                fxLayout="row wrap"
                            >
                                <div fxLayout="row">
<span
                                        class="title mb-1"
                                        *ngIf="meaning?.before "
                                    >(
                                    </span>
                                    <span
                                        class="title mb-1"
                                        [innerHTML]="meaning?.before"
                                    ></span>
                                    <span
                                        class="title mb-1"
                                        *ngIf="meaning?.before "
                                    >)&nbsp;
                                    </span>
                                </div>
                                <span
                                    class="title mb-1"
                                    style="font-weight: bold;"
                                    [innerHTML]="meaning?.word1 | filterPipe: textToMark"
                                ></span>
                                <div fxLayout="row">
                                    <span
                                        class="title mb-1"
                                        *ngIf="meaning?.between "
                                    >&nbsp;(
                                    </span>
                                    <span
                                        class="title mb-1"
                                        [innerHTML]="meaning?.between | filterPipe: textToMark"
                                        *ngIf="meaning?.between !== ''"
                                    ></span>
                                    <span
                                        class="title mb-1"
                                        *ngIf="meaning?.between "
                                    >)
                                    </span>
                                </div>
                                <span
                                    class="title mb-1"
                                    style="font-weight: bold;"
                                    [innerHTML]="'&nbsp;' + meaning?.word2 | filterPipe: textToMark"
                                ></span>
                                <div fxLayout="row">
                                    <span
                                        class="title mb-1"
                                        *ngIf="meaning?.after"
                                    >&nbsp;(
                                    </span>
                                    <span
                                        class="title mb-1"
                                        [innerHTML]="meaning?.after"
                                    ></span>
                                    <span
                                        class="title mb-1"
                                        *ngIf="meaning?.after "
                                    >)
                                    </span>
                                </div>
                            </div>
                        </span>
                        <span class="ml-2">
                            <img
                                *ngIf="meaning.langMode=='uk' && !meaning.difference"
                                class="md-20"
                                src="/assets/langmode/uks.png"
                                alt
                             />
                            <img
                                *ngIf=" meaning.langMode=='us'&& !meaning.difference "
                                class="md-20"
                                src="/assets/langmode/uss.png"
                                alt
                             />
                        </span>
                        <button
                            mat-icon-button
                            (click)="readWord(meaning)"
                        >
                            <mat-icon>volume_up</mat-icon>
                        </button>
                    </div>
                    <span style="font-size:1em; color:#673ab7 ;">{{meaning.translation}}</span>
                    <img
                        *ngIf="(isHandset | async)"
                        style="width:90%; border:10px solid white; transform:rotate(-3deg)"
                        class="mat-elevation-z4 mr-2 mb-2"
                        [src]="meaning.iconPicture"
                        alt
                     />
                    <div fxFlex>
                        <mat-chip-list
                            fxLayoutAlign="start center"
                            *ngIf="!data.fromButton"
                            [class]="(isHandset | async) ? 'mt-3' : ''"
                            class="p-2"
                            fxFlex
                        >
                            <span
                                style="color:#673ab7"
                                *ngFor="let word of meaning.disassembledSentence"
                            >
                                <span
                                    class="mb-1 translationExists"
                                    *ngIf=" !word.blank && word.translationExists"
                                    style="font-weight: bolder; "
                                    [innerHTML]="word.word "
                                ></span>
                                <span
                                    class="mb-1 "
                                    *ngIf=" !word.blank && !word.translationExists"
                                    style="font-weight: bolder; "
                                    [innerHTML]="word.word"
                                ></span>
                                <span
                                    class="mb-1"
                                    *ngIf=" !word.blank"
                                    style="font-weight: bolder; "
                                    [innerHTML]="'&nbsp'"
                                ></span>
                                <mark
                                    class="mb-1 "
                                    *ngIf="word.blank"
                                    style="font-weight: bolder; border-radius:10px;
            padding-top: 1px;padding-bottom: 1px; padding-left:6px; padding-right: 6px;
            "
                                    [innerHTML]="word.word "
                                ></mark>
                                <span
                                    class="mb-1"
                                    *ngIf="word.blank"
                                    style="font-weight: bolder;"
                                    [innerHTML]="'&nbsp'"
                                ></span>
                            </span>
                        </mat-chip-list>
                    </div>

                </div>
            </div>
            <div
                *ngIf="!(isHandset | async)"
                style="height: 100px;"
                fxLayoutAlign.xs="center"
                fxLayoutAlign="end center"
            >
                <img
                    style="height:90%; border:10px solid white; transform:rotate(-3deg)"
                    class="mat-elevation-z4 mr-2"
                    [src]="meaning.iconPicture"
                    alt
                 />
            </div>
        </mat-card>
    </div>
</div>
