import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poor-internet-indicator',
  templateUrl: './poor-internet-indicator.component.html',
  styleUrls: ['./poor-internet-indicator.component.css']
})
export class PoorInternetIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
