import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-chatbot-speak',
  templateUrl: './chatbot-speak.component.html',
  styleUrls: ['./chatbot-speak.component.css']
})
export class ChatbotSpeakComponent implements OnInit {
  showMic = true
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ChatbotSpeakComponent>
  ) {
    // this.dialogRef.close('correctAnswer')
  }
  textRecognized(event) {
    console.log("🚀 ~ file: chatbot-speak.component.ts ~ line 15 ~ ChatbotSpeakComponent ~ textRecognized ~ event", event)
    if (event.answer == 'correct') {
      this.dialogRef.close('correctAnswer')
    }
    this.showMic = false
    setTimeout(() => {
      this.showMic = true
    }, 1000)
  }
  ngOnInit(): void {
  }

}
