import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { differenceInMonths, startOfMonth } from 'date-fns';
import { GroupsService } from 'src/app/shared/services/groups.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { SmallDataService } from 'src/app/shared/services/small-data.service';
import { YearlyPlanService } from 'src/app/shared/services/yearly-plan.service';
import { FinancesStatsService } from './finances-stats.service';

@Component({
  selector: 'app-finances-stats',
  templateUrl: './finances-stats.component.html',
  styleUrls: ['./finances-stats.component.css']
})
export class FinancesStatsComponent implements OnInit {
  allExpenses = 0
  allIncomes = 0
  result = 0
  allInvoicesSumTillToday = 0
  recurringInvoicesSumMonthly = 51500
  estimatedIncomesWholeYear = 0
  PaidTillToday = 0
  toPay = 0
  summarizedExpenses = []
  summarizedIncomes = []
  sub: any;
  teachers = []
  allStates = 0
  endDateExpenses = new Date(2024, 9, 1)
  endDateIncomes = new Date(2024, 6, 18)
  numberOfMonthsExpenses = 0;
  numberOfMonthsIncomes = 0
  numberOfMonthsExpensesTeacher = 0;
  companyExpenses = 0
  companyResult = 0
  incomeFromSeptember = 0
  teachersCosts
  finances
  toPayTillToday: number;
  constructor(
    public dialogRef: MatDialogRef<FinancesStatsComponent>,
    private smallDataService: SmallDataService,
    private groupsService: GroupsService,
    private yearlyPlanService: YearlyPlanService,
    private financesStatsService: FinancesStatsService,
    public helpers: HelpersService
  ) { }

  ngOnInit(): void {
    console.log("🚀 ~ file: finances-stats.component.ts:45 ~ FinancesStatsComponent ~ ngOnInit ~ this.endDateExpenses:", this.endDateExpenses)
    console.log("🚀 ~ file: finances-stats.component.ts:47 ~ FinancesStatsComponent ~ ngOnInit ~ endDateIncomes:", this.endDateIncomes)
    this.numberOfMonthsExpenses = differenceInMonths(this.endDateExpenses, startOfMonth(new Date())) - 1
    this.numberOfMonthsIncomes = differenceInMonths(this.endDateIncomes, startOfMonth(new Date())) - 1
    console.log("🚀 ~ file: finances-stats.component.ts:44 ~ FinancesStatsComponent ~ ngOnInit ~ this.numberOfMonthsIncomes", this.numberOfMonthsIncomes)
    this.numberOfMonthsExpenses = this.numberOfMonthsExpenses - 1
    this.numberOfMonthsExpensesTeacher = this.numberOfMonthsIncomes + 1
    console.log("🚀 ~ file: finances-stats.component.ts:52 ~ FinancesStatsComponent ~ ngOnInit ~ numberOfMonthsExpensesTeacher:", this.numberOfMonthsExpensesTeacher)
    console.log("🚀 ~ file: finances-stats.component.ts:36 ~ FinancesStatsComponent ~ ngOnInit ~ this.numberOfMonths", this.numberOfMonthsExpenses)
    this.teachersCosts = this.financesStatsService.teachersCosts
    this.finances = this.financesStatsService.financesData
    this.sub = this.groupsService.groupsUpdatedListener().subscribe(groups => {
      console.log("🚀 ~ file: finances-stats.component.ts:26 ~ FinancesStatsComponent ~ this.sub=this.groupsService.groupsUpdatedListener ~ groups", groups)
      groups.forEach(group => {
        group.lessons.forEach(lesson => {
          if (!this.teachers.some(teacher => teacher.name === lesson.assignedTeacher.name)) {
            this.teachers.push({ name: lesson.assignedTeacher.name, groups: [] })
          }
          const lessonDate = lesson.startDateMs
          let monthStart = startOfMonth(Date.now())
          let monthStart2 = Date.parse(this.yearlyPlanService.getDateWithTimeObject(monthStart))
          if (lesson.attributes.includes('avalible') && lesson.dateMs > monthStart2) {
            let selectedTeacher = this.teachers.find(teacher => teacher.name === lesson.assignedTeacher.name)
            if (!selectedTeacher.groups.some(_group => _group.name === group.name)) {
              selectedTeacher.groups.push({ name: group.name, lessons: [] })
            }
            selectedTeacher.groups.forEach(group => {
              // group.lessons = group.lessons.filter
            })
            selectedTeacher.groups.find(_group => _group.name === group.name).lessons.push(lesson)
          }
        })
      })
      this.teachers = this.teachers.filter(teacher => teacher.groups.length > 0)
      console.log("🚀 ~ file: finances-stats.component.ts:29 ~ FinancesStatsComponent ~ this.sub=this.groupsService.groupsUpdatedListener ~ this.teachers", this.teachers)
      this.calculate()
    })
    this.groupsService.getGroupsList()
  }
  calculate() {
    this.teachers.forEach(teacher => {
      const teacherCost = this.teachersCosts.find(cost => cost.name.trim() === teacher.name.trim()).value / 60
      let teachersCombinedCost = 0
      console.log("🚀 ~ file: finances-stats.component.ts:87 ~ FinancesStatsComponent ~ calculate ~ teacher.groups", teacher.name, teacher.groups)
      teacher.groups.forEach(group => {
        group.lessons.forEach(lesson => {
          const lessonCost = lesson.lessonDuration * teacherCost
          teachersCombinedCost = teachersCombinedCost + lessonCost
        })
      })
      teacher.combinedCost = teachersCombinedCost
      this.finances.expenses.push({ name: teacher.name, value: teachersCombinedCost, type: 'combined', company: true, teacher: true })
    })
    console.log("🚀 ~ file: finances-stats.component.ts:71 ~ FinancesStatsComponent ~ calculate ~ this.finances", this.finances)

    this.finances.expenses.forEach(expense => {
      if (expense.type === 'monthly') {
        this.summarizedExpenses.push({ teacher: expense.teacher, name: expense.name, value: expense.value * this.numberOfMonthsExpenses, company: expense.company })
      }
      else {
        this.summarizedExpenses.push({
          name: expense.name, value: expense.value, company: expense.company, type: expense.type, teacher: expense.teacher
        })
      }
    })
    this.finances.incomes.forEach(income => {
      if (income.type === 'monthly') {
        this.summarizedIncomes.push({ name: income.name, value: income.value * this.numberOfMonthsIncomes, })
      }
      else {
        this.summarizedIncomes.push({ name: income.name, value: income.value, })
      }
    })
    this.summarizedExpenses.forEach(expense => {
      this.allExpenses = this.allExpenses + expense.value
      if (expense.company) {
        this.companyExpenses = this.companyExpenses + expense.value
      }
    })

    this.summarizedIncomes.forEach(income => {
      this.allIncomes = this.allIncomes + income.value
    })
    this.estimatedIncomesWholeYear = this.allInvoicesSumTillToday + this.recurringInvoicesSumMonthly * this.numberOfMonthsIncomes
    console.log("🚀 ~ file: finances-stats.component.ts:133 ~ FinancesStatsComponent ~ calculate ~ this.estimatedIncomesWholeYear:", this.estimatedIncomesWholeYear)
    this.toPay = this.estimatedIncomesWholeYear - this.PaidTillToday
    console.log("🚀 ~ file: finances-stats.component.ts:136 ~ FinancesStatsComponent ~ calculate ~ toPay:", this.toPay)
    this.toPayTillToday = this.allInvoicesSumTillToday - this.PaidTillToday
    console.log("🚀 ~ file: finances-stats.component.ts:138 ~ FinancesStatsComponent ~ calculate ~ toPayTillToday:", this.toPayTillToday)
    this.finances.states.forEach(state => {
      this.allStates = this.allStates + state.value
    })
    this.result = (this.allIncomes + this.allStates) - this.allExpenses
    this.companyResult = (this.allIncomes + this.allStates) - this.companyExpenses
    console.log("🚀 ~ file: finances-stats.component.ts:116 ~ FinancesStatsComponent ~ calculate ~ this.result", this.result)
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
