<div fxLayout="row" fxFlex>
  <div>
    <div fxLayout="column">
      <span>Dzieci</span>
      <br />
      <div fxLayout="column">
        <span>Ilość wpisów: {{allChildren.length}}</span>
        <br />
        <span>Zdecydowani: {{confirmedChildren.length}}</span>
        <br />
        <span
          >Kontynuujący {{continuingConfirmedChildren.length}} /
          {{allContinuingChildren.length}}</span
        >
        <br />
        <span>Nowi {{newChildren.length}}</span>
      </div>
    </div>
  </div>
  <div>
    <div fxLayout="column">
      <span>Dorośli</span>
      <br />

      <span>Ilość wpisów: {{allAdults.length}}</span>
      <br />
      <span>Zdecydowani dorośli {{confirmedAdults.length}}</span>
      <hr />

      <span
        >Razem zdecydowani {{confirmedAdults.length +
        confirmedChildren.length}}</span
      >
      <br />
    </div>
  </div>
</div>
