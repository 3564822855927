import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DeviceSizeService } from "../../services/device-size.service";
import { HelpersService } from "../../services/helpers.service";
import { WorkersAvatarsService } from "../../services/workers-avatars.service";
import { TaskViewComponent } from "../add-task/task-view/task-view.component";
import { filter } from "rxjs/operators";
import { UserDetailsService } from "src/app/profile/user-details.service";
import { OrderPipe } from 'ngx-order-pipe';
import { TasksService } from "../../services/tasks-service";

@Component({
  selector: "app-tasks-list",
  templateUrl: "./tasks-list.component.html",
  styleUrls: ["./tasks-list.component.css"],
})
export class TasksListComponent implements OnInit {
  @Input() colorDark
  @Input() colorMedium
  @Input() colorMediumLight
  @Input() colorLight
  @Input() smallItems

  @Input() tasks;
  @Input() title;
  @Input() limit: number = 50;
  @Input() isHandset;
  @Input() workers;
  @Input() selectedWorker;
  taskWidth = 100
  _isHandset = false
  showAllTasks = false
  tasksWithNewComment = [];
  isHandsetSub: any;
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  constructor(
    public deviceSizeService: DeviceSizeService,
    private dialog: MatDialog,
    public workersAvatarsService: WorkersAvatarsService,
    private helpers: HelpersService,
    private userDetailsService: UserDetailsService,
    private tasksService: TasksService,
  ) {
    if (window.innerWidth < 600) {
      this.taskWidth = 100

    }
    if (window.innerWidth > 600 && window.innerWidth < 900) {
      this.taskWidth = 50
    }
    if (window.innerWidth > 900) {
      this.taskWidth = 33
    }
  }
  checkScrollContainerOverflow() {
    if (this.scrollContainer) {
      return this.scrollContainer.nativeElement.scrollHeight > this.scrollContainer.nativeElement.clientHeight
    }

  }
  // create functions to scroll up and down with smooth animations
  scrollUp() {
    this.scrollContainer.nativeElement.scrollBy({
      top: -100,
      left: 0,
      behavior: 'smooth'
    });
  }
  scrollDown() {
    this.scrollContainer.nativeElement.scrollBy({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.checkNewComments();
    this.tasks.forEach((task, index) => {
      let dates = [task.created]
      task?.comments.forEach((comment) => {
        dates.push(comment?.date)
      });
      let lastModified = dates[dates.length - 1]
      task.dates = dates;
      task.lastModified = lastModified
    })

  }
  onLongPress(event: Event) {
    event.preventDefault();
  }
  shareTask(task) {

    console.log("🚀 ~ TasksListComponent ~ sharaseTask ~ task:", task)

    if (navigator.share) {
      navigator.share({
        title: task.title,
        text: task.text,


      }).then(() => {
        console.log('Thanks for sharing!');
      })
        .catch((error) => {
          console.error('Something went wrong sharing the text', error);
        });
    } else {
      // Fallback, display some UI to notify the user that sharing isn't available
      alert("Web Share API is not available on your device.");
    }
  }
  checkNewComments() {
    // Check if this.tasks is undefined or null
    if (!this.tasks) {
      // If it is, return early
      return;
    }

    // Loop through each task in this.tasks
    this.tasks.forEach((task) => {
      // Check if task is undefined or null
      if (!task) {
        // If it is, skip to the next task
        return;
      }

      // Check if task.recipient and task.author are undefined or null
      if (!task.recipient || !task.author) {
        // If they are, skip to the next task
        return;
      }

      // Create a new Set object with the task recipient and author ids
      const recipients = new Set([task.recipient.id, task.author.id]);

      // Loop through each comment in the task comments array
      if (task.comments) {
        task.comments.forEach((comment) => {
          // Check if comment is undefined or null
          if (!comment) {
            // If it is, skip to the next comment
            return;
          }

          // Check if comment.author is undefined or null
          if (!comment.author) {
            // If it is, skip to the next comment
            return;
          }

          // Add the comment author id to the recipients Set
          recipients.add(comment.author.id);
        });
      }

      // Set the task recipients property to an array converted from the recipients Set
      task.recipients = Array.from(recipients);
    });
  }


  ngOnInit(): void {
    this.isHandsetSub = this.deviceSizeService.isHandset.subscribe((isHandset: any) => {
      this._isHandset = isHandset
      console.log("🚀 ~ file: tasks-list.component.ts:107 ~ TasksListComponent ~ this.isHandsetSub=this.deviceSizeService.isHandset._subscribe ~ this.isHandset:", this._isHandset)
    })
    this.tasks = this.helpers.sortArrayByProperty(
      this.tasks,
      "dateScheduled",
      "desc"
    );
  }
  checkImgDisabled(task, user) {
    if (task && user) {
      return !task?.seenBy?.includes(user) || task?.comments?.some(comment => !comment?.seenBy?.includes(user))
    }
  }
  checkNewComment(task: any): boolean {
    if (!task.comments || task.comments.length === 0) {
      return false;
    }

    const currentUser = this.userDetailsService.getSimpleUserDetails();

    return task.comments.some(comment => !comment?.seenBy?.includes(currentUser.id) && comment?.author.id !== currentUser.id)
  }




  openTaskViewPanel(task) {
    this.tasksService.taskPanelOpened = true;
    const ref = this.dialog.open(TaskViewComponent, {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      panelClass: "full-screen-dialog-no-wrap",
      data: {
        colorDark: this.colorDark,
        colorMedium: this.colorMedium,
        colorMediumLight: this.colorMediumLight,
        colorLight: this.colorLight,
        task: task,
        workers: this.workers,
        selectedWorker: !task.recipient ? this.selectedWorker : task.recipient,
      },
    });
    ref.afterClosed().subscribe((result) => {
      // this.checkNewComments();

      this.tasksService.taskPanelOpened = false;
      this.tasksService.getTasksForUser(this.userDetailsService.getSimpleUserDetails().id)
    });
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.isHandsetSub.unsubscribe()
  }
}
