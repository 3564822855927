import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { TopBarVisibilityService } from "../top-bar-visibility.service";
import { UserDetailsService } from "../user-details.service";

@Injectable({
  providedIn: "root",
})
export class TeacherMainListService {
  showArrow = false;
  teacherMainListMode = new Subject();
  constructor(
    private router: Router,
    private topBarVisibilityService: TopBarVisibilityService,
    private userDetailsService: UserDetailsService
  ) {
    if (
      this.userDetailsService.getSimpleUserDetails()?.role === "office" &&
      window.location.pathname !== "/profile/office"
    ) {
      this.showArrow = true;
    }
  }
  changeMode(mode, subMode?: "createSet" | "setContent" | "quiz" | "test") {
    if (mode == "sentences") {
      this.router.navigateByUrl("/profile/sentences?mode=simple");
      this.topBarVisibilityService.toggle(true);
      this.showArrow = true;
    }
    if (mode == "tasks") {
      this.router.navigateByUrl("/profile/tasks");
      this.topBarVisibilityService.toggle(true);
      this.showArrow = true;
    }
    if (mode === "mainList") {
      this.showArrow = false;
      if (this.userDetailsService.getSimpleUserDetails().role === "office") {
        this.router.navigateByUrl("/profile/office");
      }
    } else {
      this.showArrow = true;
    }
    this.teacherMainListMode.next({ mode: mode, subMode: subMode });
  }
  changeModeListener() {
    return this.teacherMainListMode.asObservable();
  }
}
