import { Component, Inject, OnInit } from '@angular/core';
import { OnlineLessonsService } from '../../services/online-lessons.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-OnlineLessonComponent',
  templateUrl: './OnlineLessonComponent.component.html',
  styleUrls: ['./OnlineLessonComponent.component.scss']
})
export class OnlineLessonComponentComponent implements OnInit {
  lessonUrl
  roomName: string;
  userName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data, private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.roomName = this.data.roomName;
    this.userName = this.data.userName;
    let lessonUrl = "https://teacher.linget.it/remote-lessons?roomName=" +
      this.roomName.normalize('NFD').replace(/[\u0300-\u036f]/g, '').split(' ').join('_') +
      "&userName=" +
      this.userName;
    this.lessonUrl = this.sanitizer.bypassSecurityTrustResourceUrl(lessonUrl)

  }
}