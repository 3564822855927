<div
    style="width:100%; height:100%;"
    class="d-flex flex-column"
>
    <span style="font-weight:bold; color: '#f44336'">Nieskończone zadania: {{(currentIndex +1 ) + '/' + (overdueTasks.length)}}
    </span>
    <mat-progress-bar
        mode="determinate"
        [value]="(currentIndex + 1) / overdueTasks.length * 100"
        style="width:100%"
        [color]="'warn'"
    ></mat-progress-bar>
    <span style="height:10px"></span>
    <span
        class="p-2 mt-2 mb-2"
        style=" font-weight: bold; color:#673ab7 ; font-size: large; border-radius: 15px; border: 3px solid #673ab7;"
    >
        <span style="text-align: center;">{{currentTask.title}}: {{currentTask.text}}
        </span>
    </span>
    <span style="height:10px"></span>
    <div
        class="d-flex flex-column"
        style="flex:1; overflow-y:auto; "
    >
        <div *ngFor="let comment of currentTask?.comments">
            <span
                *ngIf="comment?.text"
                fxLayout="column"
            >
                <span style="width: 100%; border-bottom: 1px solid #e0e0e0;">{{comment.author.name}}:</span>
                <span
                    style="color: '#9575cd'; font-weight: bolder;"
                    class="m-3"
                >{{comment.text}}
                </span>

            </span>
        </div>
    </div>
    <div
        style="font-weight:bolder; width: 100%; height:40px; border-radius: 15px; background-color: #673ab7; color: white;"
        class="d-flex flex-row pointer"
    >
        <div
            (click)="previousTask()"
            fxLayoutAlign="center center"
            style="width:60px; height:100%; "
        >
            <span class="material-symbols-outlined">arrow_circle_left</span>

        </div>
        <div
            (click)="markCurrentTaskAsFinished()"
            fxLayoutAlign="center center"
            style="flex:1; height:100%"
        >Skończone
        </div>
        <span
            (click)="snooze()"
            [matTooltip]="'Uśpij na 10 minut'"
            style="height: 100%; width: 60px;"
            fxLayoutAlign="center center"
        >
            <span class="material-symbols-outlined">notifications_paused</span>
        </span>
        <div
            [matMenuTriggerFor]="menu"
            fxLayoutAlign="center center"
            style="flex:1; height:100%"
        >Zaplanuj
        </div>
        <div
            (click)="nextTask()"
            fxLayoutAlign="center center"
            style="width:60px; height:100%"
        >
            <span class="material-symbols-outlined">arrow_circle_right</span>

        </div>
    </div>
    <mat-menu #menu>
        <span class="mt-2 mb-2">Kiedy zaplanować zadanie?</span>
        <div *ngFor="let day of futureDays">
            <button
                style="color: #673ab7;"
                fxLayoutAlign="stat center"
                mat-menu-item
                (click)="selectCustomDate(day.date)"
            >
                <span class="material-symbols-outlined mr-2">calendar_add_on</span>
                <span>{{ day.dayName }} ({{ day.date  | date: 'dd.MM'}})
                </span>
            </button>
        </div>

        <!-- Button for custom date selection -->
        <button
            (click)="$event.stopPropagation(); dateInput.click();"
            fxLayoutAlign="stat center"
            mat-menu-item
        >
            <span class="material-symbols-outlined mr-2">edit_calendar</span>
            <span>Inna data |</span>
            <input
                style="border: none;"
                type="date"
                (change)="selectCustomDate($event)"
                #dateInput
             />
        </button>

    </mat-menu>

</div>
