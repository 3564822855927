<mat-card
    fxLayout="column"
    style="width: 100%; height: 100%"
>
    <div
        style="width:100%"
        fxLayoutAlign="center center"
    >
        <h1>Grammar Manipulator</h1>
    </div>
    <textarea
        [(ngModel)]="text"
        style="width:99.5%; border: solid 3px #673ab7; border-radius:15px; font-size:large; "
        rows="25"
    ></textarea>
    <div fxLayout="row">
        <button
            (click)="manipulate()"
            class="mt-2"
            mat-raised-button
            color="primary"
            style="width:80%"
        >Manipulate
        </button>
        <button
            (click)="changeApostrophes()"
            class="mt-2 ml-2"
            mat-raised-button
            color="accent"
            style="width:18%"
        >Replace ’ -> '
        </button>
    </div>
</mat-card>
