import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { EvaluateStudentService } from "../../evaluate-student.service";

@Component({
  selector: "app-evaluate-student-select",
  templateUrl: "./evaluate-student-select.component.html",
  styleUrls: ["./evaluate-student-select.component.css"],
})
export class EvaluateStudentSelectComponent implements OnInit {
  @Input() property;
  @Input() displayProperty;
  @Input() report;
  @Input() reportSent: boolean = false;
  @Output() valueChanged = new EventEmitter();
  selectOptions: Array<object>;
  radioSelected: boolean = false;
  constructor(private evaluatestudentService: EvaluateStudentService) {}

  ngOnInit(): void {
    this.selectOptions = this.evaluatestudentService.selectOptions;
  }
  checkIfRadioSelected(option) {
    let radioShouldBeSelected = false;
    if (this.report) {
      const reportItem = this.report[this.property];

      if (reportItem == option.value) {
        radioShouldBeSelected = true;
      } else {
        radioShouldBeSelected = false;
      }
    }
    return radioShouldBeSelected;
  }
  selectValueChanged(event) {
    console.log(
      "🚀 ~ file: evaluate-student-select.component.ts ~ line 23 ~ EvaluateStudentSelectComponent ~ valueChanged ~ event",
      event
    );
    this.valueChanged.emit({
      propertyValue: event.value,
      propertyName: this.property,
    });
  }
}
