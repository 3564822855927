import {
  ChangeDetectorRef,
  Component,
  Injectable,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  DAYS_OF_WEEK,
} from 'angular-calendar';
import { addDays, addMinutes, endOfWeek } from 'date-fns';
import { WeekViewHourSegment } from 'calendar-utils';

import { fromEvent, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { colors } from '../schedule/calendar-colors';
import { CustomDateFormatter } from './custom-date-formatter-provider';
import { MatDialog } from '@angular/material/dialog';
import { ScheduleAddGroupComponent } from './schedule-add-group/schedule-add-group.component';
import { WorkersAvatarsService } from '../../services/workers-avatars.service';
import { HelpersService } from '../../services/helpers.service';
import { SmallDataService } from '../../services/small-data.service';
import { SignupFormService } from '../../services/signup-form.service';
import { TopBarVisibilityService } from 'src/app/profile/top-bar-visibility.service';
import { User } from 'src/app/profile/owner/users/users-list/users-list.component';
import { UserDetailsService } from 'src/app/profile/user-details.service';
import { SendSmsComponent } from './send-sms/send-sms.component';
function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter { }
@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
  styles: [
    `
      .disable-hover {
        pointer-events: none;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleComponent implements OnInit {
  selectedTeacher;
  editMode = false;
  teachers = [];
  daysInWeek = 3;
  showBydgoszcz = true;
  showBialeBlota = true;
  activeTeachers = [];
  dragToCreateActive = false;
  filterTeachersState;
  filteredMode = false;
  viewDate = new Date();
  events = [];
  singleSelectedDay = null;
  // exclude weekends
  excludeDays: number[] = [0, 6];
  users = [];
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  allEvents: any = null;
  tempGroupsSub: any;
  foundstudentsByIdSub: any;
  originalEvents: any;
  constructor(
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private workersService: WorkersAvatarsService,
    private helpers: HelpersService,
    private smallDataService: SmallDataService,
    public userDetailsService: UserDetailsService,
    private signupService: SignupFormService,
    private topBarVisibilityService: TopBarVisibilityService,
  ) {



  }

  startDragToCreate(
    segment: WeekViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement,
  ) {
    if (this.editMode) {
      if (this.selectedTeacher) {
        const dragToSelectEvent: CalendarEvent = {
          id: this.events.length,
          title: '',
          start: segment.date,

          end: addMinutes(segment.date, 45),
          color: {
            primary: this.selectedTeacher.colorDark,
            secondary: this.selectedTeacher.colorLight,
          },
          draggable: true,
          resizable: {
            beforeStart: true, // this allows you to configure the sides the event is resizable from
            afterEnd: true,
          },

          meta: {
            teacher: this.selectedTeacher,
          },
        };
        this.events = [...this.events, dragToSelectEvent];
        this.allEvents = [...this.allEvents, dragToSelectEvent];
        const segmentPosition = segmentElement.getBoundingClientRect();
        this.dragToCreateActive = true;
        delete dragToSelectEvent.meta.tmpEvent;
        this.dragToCreateActive = false;
        this.smallDataService.saveNewTempGroup(dragToSelectEvent);
        this.refresh();
      } else {
        alert('Wybierz lektora!');
      }
    }
  }

  sendSMS() {
    let groups = []
    this.events.forEach(event => {
      if (event.meta.students) {
        groups.push({
          name: event.title, students: event.meta.students.map((student: any) => {
            return {
              student: student?.childFirstName ? student?.childFirstName + ' ' + student?.childLastName :
                student?.adultFirstName + ' ' + student?.adultLastName,
              customer: student?.customerFirstName + ' ' + student?.customerLastName,
              phone: student?.customerPhone.replace(/\s/g, '').replace('/\-/g', ''),
              email: student?.customerEmail,
              selected: true
            };
          })
        });
      }
    });
    groups = groups.filter((group: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (
        t.name === group.name
      ))
    );
    const dialogRef: any = this.dialog.open(SendSmsComponent, {
      disableClose: true,
      width: '90vw',
      height: '90vh',
      data: { groups: groups }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        console.log("🚀 ~ ScheduleComponent ~ dialogRef.afterClosed ~ result:", result);
      }
    });
  }
  showBydgoszczGroups() {
    this.showBydgoszcz = !this.showBydgoszcz;
    if (!this.originalEvents) {
      this.originalEvents = this.helpers.clone(this.events);
    }
    console.log("🚀 ~ ScheduleComponent ~ showBydgoszczGroups ~ this.showBialeBlot:", this.showBialeBlota)
    console.log("🚀 ~ ScheduleComponent ~ showBydgoszczGroups ~ this.showBydgoszcz:", this.showBydgoszcz)

    if (this.showBialeBlota && this.showBydgoszcz) {
      this.events = this.originalEvents
    }
    else if (this.showBialeBlota) {
      this.events = this.originalEvents.filter(event => event.meta.location !== 'Bydgoszcz');
    }

  }
  showBialeBlotaGroups() {
    this.showBialeBlota = !this.showBialeBlota;
    if (!this.originalEvents) {
      this.originalEvents = this.helpers.clone(this.events);
    }
    console.log("🚀 ~ ScheduleComponent ~ showBialeBlotaGroups ~ this.showBialeBlota:", this.showBialeBlota)
    console.log("🚀 ~ ScheduleComponent ~ showBialeBlotaGroups ~ this.showBydgoszcz:", this.showBydgoszcz)
    if (this.showBialeBlota && this.showBydgoszcz) {
      this.events = this.originalEvents
    }
    else if (this.showBydgoszcz) {
      this.events = this.originalEvents.filter(event => event.meta.location !== 'bialeblota');
    }

  }
  filterGroupsWithStudents(text) {
    if (text !== '') {
      this.filteredMode = true;
      if (!this.allEvents) {
        this.allEvents = this.helpers.clone(this.events);
      }
      this.events = this.events.filter((sevent) =>
        sevent.meta.students.some((student) =>
          (student.childFirstName + ' ' + student.childLastName)
            .toLowerCase()
            .includes(text.toLowerCase()),
        ),
      );
    } else {
      this.events = this.allEvents;
    }
  }

  setVisibleDays(event) {
    console.log("🚀 ~ ScheduleComponent ~ setVisibleDays ~ event:", event);

    // Default excluded days are weekends: 0 for Sunday, 6 for Saturday
    let excludedDays = [0, 6];

    if (event && event.value && event.value.length > 0) {
      // Convert selected days from strings to numbers, assuming 1 for Monday through 7 for Sunday
      const selectedDays = event.value.map(number => parseInt(number, 10));

      // Create an array for all days of the week, 1 through 7
      const allDays = [1, 2, 3, 4, 5, 6, 7];

      // Calculate excludedDays: days not selected plus weekends (unless weekends are selected)
      excludedDays = allDays.filter(day => !selectedDays.includes(day)).map(day => {
        // Convert day numbers to match JavaScript's getDay(): 0 for Sunday through 6 for Saturday
        return day % 7; // Adjust if using a different system
      });

      // Ensure weekends are always included in excludedDays unless explicitly selected
      if (!selectedDays.includes(7)) excludedDays.push(0); // Sunday, if not selected
      if (!selectedDays.includes(6)) excludedDays.push(6); // Saturday, if not selected

      // Clone and filter this.allEvents to include only events on selected days
      this.events = this.helpers.clone(this.allEvents).filter(event => {
        const eventDayOfWeek = new Date(event.start).getDay(); // getDay() gives 0 for Sunday through 6 for Saturday
        // Adjust selectedDays to match getDay() by reducing 1, then check inclusion
        return selectedDays.includes(eventDayOfWeek);
      });

      console.log("Filtered events to include only those on selected days of the week.");
    } else {
      // If no valid selection is made, default to exclude weekends
      console.log("No valid day selection made, defaulting to exclude weekends.");
    }

    // Update the component state or handle the excludedDays as needed
    this.excludeDays = excludedDays;
    console.log("🚀 ~ ScheduleComponent ~ setVisibleDays ~ excludedDays:", excludedDays);
    if (JSON.stringify(excludedDays) === JSON.stringify([0, 6])) {
      this.events = this.allEvents;
    }
  }


  filterEventsForSelectedTeachers(bevent) {
    this.filteredMode = true;
    this.filterTeachersState = bevent;
    if (!this.allEvents) {
      this.allEvents = this.helpers.clone(this.events);
    }
    this.events = this.allEvents.filter((event) =>
      bevent.value.includes(event.meta.teacher.teacherId),
    );
  }
  showAllEvents() {
    this.filteredMode = false;
    this.events = this.allEvents;
  }
  refresh() {
    this.activeTeachers = this.teachers.filter((teacher) =>
      this.allEvents.some(
        (event) => event.meta.teacher.teacherId === teacher.teacherId,
      ),
    );

    this.events = [...this.events];
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.viewDate = new Date(2022, 8, 6);
    this.tempGroupsSub = this.smallDataService
      .tempGeoupsUpdatedListener()
      .subscribe((tempGroups: any) => {
        tempGroups.forEach((sevent) => {
          sevent.start = new Date(sevent.start);
          sevent.end = new Date(sevent.end);
          sevent.draggable = false;
          sevent.resizable = { beforeStart: false, afterEnd: false };
        });

        this.events = tempGroups.filter((group: any) => group.start > new Date(2022, 0, 1));
        this.allEvents = this.helpers.clone(this.events);
        console.log("🚀 ~ ScheduleComponent ~ .subscribe ~ this.allEvents:", this.allEvents)
        this.activeTeachers = this.teachers.filter((teacher) =>
          this.allEvents.some(
            (event) => event.meta.teacher.teacherId === teacher.teacherId,
          ),
        );
        tempGroups.forEach((group) => {
          this.signupService.getStudentsById(
            group.meta.studentsIds ? group.meta.studentsIds : [],
            group.dbId,
          );
          if (group.meta.location == 'Bydgoszcz') {
            group.color.primary = '#795548';
          }
          if (group.meta.location == 'bialeblota') {
            group.color.primary = '#757575';
          }
        });
        if (this.filteredMode) {
          this.filterEventsForSelectedTeachers(this.filterTeachersState);
        }
        if (this.editMode) {
          this.setEditMode(false);
        }
        this.topBarVisibilityService.toggle(true);
      });
    this.smallDataService.getTempGroups();
    this.teachers = this.workersService.workersWithAvatars.filter(
      (worker) => worker.teacherId,
    );
    this.foundstudentsByIdSub = this.signupService
      .foundstudentsByIdListener()
      .subscribe((foundStudentsForGroup: any) => {
        let groupToUpdate = this.allEvents.find(
          (event) => event.dbId === foundStudentsForGroup.dbId,
        );
        if (groupToUpdate) {
          groupToUpdate.meta.students = foundStudentsForGroup.students;

        }
        let groupToUpdate2 = this.events.find(
          (event) => event.dbId === foundStudentsForGroup.dbId,
        );
        if (groupToUpdate2) {
          groupToUpdate2.meta.students = foundStudentsForGroup.students;

        }
        const dayNumber = new Date(Date.now()).getDay();
        this.setVisibleDays({ value: [dayNumber] });
      });
  }

  refresh2 = new Subject<void>();
  setEditMode(edit: boolean) {
    if (edit) {
      this.editMode = false;
      this.events.forEach((sevent) => {
        sevent.draggable = false;
        sevent.resizable = { beforeStart: false, afterEnd: false };
      });
    }
    if (!edit) {
      this.editMode = true;
      this.events.forEach((sevent) => {
        sevent.draggable = true;
        sevent.resizable = { beforeStart: true, afterEnd: true };
      });
    }
  }
  editGroup(group) {
    const dialogRef = this.dialog.open(ScheduleAddGroupComponent, {
      disableClose: true,
      width: '80vw',
      height: '80vh',
      data: { groupToEdit: group },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        let eventToReplace = this.events.find(
          (event) => event.id === result.id,
        );
        eventToReplace = result;
        let eventToReplaceFromAllEvents = this.allEvents.find(
          (event) => event.id === result.id,
        );
        this.smallDataService.updateTempGroup(result);
        eventToReplaceFromAllEvents = result;
        this.refresh2.next();
      }
    });
    // todo update this in backend
  }
  eventClicked({ event }: { event: CalendarEvent }): void {
    this.editGroup(event);
  }
  eventTimesChanged({ event, newStart, newEnd }) {
    if (this.editMode) {
      const previousStart = event.start;
      const previousEnd = event.end;
      event.start = newStart;
      event.end = newEnd;
      const works = this.smallDataService.checkAvalibility(
        event.meta.students,
        newStart,
        newEnd,
      );
      if (works) {
        this.smallDataService.updateTempGroup(event);
      } else {
        if (confirm('Czy zapisać mimo to?')) {
          this.smallDataService.updateTempGroup(event);
        } else {
          event.start = previousStart;
          event.end = previousEnd;
        }
      }

      //check students avalibility here
      this.refresh2.next();
    }
  }
  ngOnDestroy(): void {
    this.foundstudentsByIdSub.unsubscribe();
  }
}
