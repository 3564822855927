import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BoardgameService {
  message = new Subject();
  constructor() { }

  sendMessage(message: Object) {
    this.message.next(message);
  }
  redoPlayersForHorizontalRace(newPlayers, oldPlayers, numberOfQuestions) {
    if (newPlayers.players) {
      newPlayers = newPlayers.players
    }
    if (oldPlayers && !newPlayers) {
      newPlayers = oldPlayers;
    }
    if (!oldPlayers && newPlayers) {
      oldPlayers = newPlayers;
    }
    console.log("oldPlayers: ", oldPlayers);
    console.log("newPlayers: ", newPlayers);

    if (newPlayers.length > oldPlayers.length) {
      const _newPlayers = newPlayers.filter(
        (player) => !oldPlayers.some((_player) => _player.id === player.id)
      );
      console.log("_newPlayers: ", _newPlayers);

      _newPlayers.forEach((player) => {
        player.numberOfQuestions = numberOfQuestions;
        player.path = [];
        player.newComer = true;
        (player.currentPoint = {}),
          (player.animationState = "init"),
          (player.prize = "/assets/emojis/1f3c6.svg");
        oldPlayers.push(player);
      });
    } else {
      oldPlayers.forEach((player) => {
        let newPlayer = newPlayers.find((p) => p.id === player.id);
        if (!newPlayer) {
          newPlayer = newPlayers.find((p) => p.oldId === player.id);
        }
        if (newPlayer) {
          player.avatar = newPlayer.avatar;
          player.points = newPlayer.points;
          player.collaborateTresholdPoints =
            newPlayer.collaborateTresholdPoints;
          player.collaborateMoveTreshold = newPlayer.collaborateMoveTreshold;
          player.colmpetitiveMoveTreshold = newPlayer.colmpetitiveMoveTreshold;
          player.colmpetitiveTresholdPoints =
            newPlayer.colmpetitiveTresholdPoints;
          player.colmpetitiveoints = newPlayer.colmpetitiveoints;
          player.collaboratePoints = newPlayer.collaboratePoints;
          player.name = newPlayer.name;
          player.rejoining = newPlayer.rejoining;
          player.money = newPlayer.money;
          // player.numberOfQuestions = newPlayer.numberOfQuestions
          player.id = newPlayer.id;
          player.items = newPlayer.items;
        }
      });
    }
    return oldPlayers;
  }

  messagesListener() {
    return this.message.asObservable();
  }
}
