import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import print from 'print-js'
import { SendEmailComponent } from 'src/app/groups/send-email/send-email.component';
import { CreateComponent } from 'src/app/pdf/create/create.component';

@Component({
  selector: 'app-pdf2-preview',
  templateUrl: './pdf2-preview.component.html',
  styleUrls: ['./pdf2-preview.component.css']
})
export class Pdf2PreviewComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateComponent>,
  ) { }

  ngOnInit(): void {
  }
  printPdf() {
    print(this.data.file)
  }
  openPdfInNewWindow() {
    window.open(this.data.file);
  }
  openSendEmailDialog() {
    const dialogRef = this.dialog.open(SendEmailComponent, {
      width: "100vw",
      height: "100vh",
      minWidth: "100vw",
      minHeight: "100vh",
      panelClass: 'full-screen-dialog',
      data: { group: this.data.group, fromHomeworkPanel: true },
      disableClose: true,
    });
    // this.dialogRef.close()
  }
}
