import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  TemplateRef,
  ElementRef,
  Output,
  EventEmitter,
  Inject,
  ChangeDetectorRef,
} from '@angular/core';
import { PictureService } from 'src/app/shared/services/pictures.service';
import { Subscription } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  filter,
} from 'rxjs/operators';
import { HelpersService } from '../../services/helpers.service';

@Component({
  selector: 'app-picture-finder',
  templateUrl: './picture-finder.component.html',
  styleUrls: ['./picture-finder.component.css'],
})
export class PictureFinderComponent implements OnInit {
  @Input() initialImageData = { url: '', tagToFind: '' };
  @Input() size = 'full';
  @Input() latestPictures;
  @Output() chosenImg = new EventEmitter();
  foundPicturesFieldSize = 75;
  foundPictures = [];
  pictureToFind = '';
  picturesSub: Subscription;
  chosenPicture;
  @ViewChild('input') input: ElementRef;
  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;
  link;
  bigImgSource =
    'https://backend-int.linget.it/images/1596812239242small-1596812226285';
  randomSpeakingMode: boolean;
  constructor(
    private picturesService: PictureService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    public dialogRef2: MatDialogRef<PictureFinderComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private helpers: HelpersService,
  ) { }

  ngOnInit(): void {
    console.log('data', this.initialImageData);
    if (this.initialImageData.url !== '') {
      this.bigImgSource = this.initialImageData.url;
    }
    if (this.initialImageData.tagToFind !== '') {
    }
    this.picturesSub = this.picturesService
      .pictureUpdateListener()
      .subscribe((foundPictures) => {
        // if ((this.randomSpeakingMode = true)) {
        //   this.chooseRandomImageFromFound();
        // }
        this.foundPictures = foundPictures;
        console.log('this.foundPictures: ', this.foundPictures);
      });
    if (this.size === 'half') {
      this.foundPicturesFieldSize = 20;
    }
  }
  chooseRandomImageFromFound() {
    // setTimeout(() => {
    //   this.chosenPicture = this.helpers.getRandomItemFromArray(
    //     this.foundPictures
    //   );
    //   console.log(
    //     "🚀 ~ file: picture-finder.component.ts ~ line 80 ~ PictureFinderComponent ~ chooseRandomImageFromFound ~ this.chosenPicture",
    //     this.chosenPicture
    //   );
    //   setTimeout(() => {
    //     this.bigImgSource = this.chosenPicture.smallUrl;
    //     this.ref.detectChanges();
    //     this.chosenImg.emit(this.chosenPicture);
    //   }, 500);
    // }, 1500);
  }

  ngAfterViewInit() {
    if (this.data?.prefind) {
      this.input.nativeElement.value = this.data.prefind;
      this.pictureToFind = this.input.nativeElement.value;
      this.findPicture();
    }
    if (this.latestPictures || this.data?.latestPictures) {
      this.picturesService.getLatestPictures();
    }
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((text) => {
          this.randomSpeakingMode = false;
          this.pictureToFind = this.input.nativeElement.value;
          this.findPicture();
        }),
      )
      .subscribe();
    fromEvent(this.input.nativeElement, 'mouseenter')
      .pipe(
        filter(Boolean),
        debounceTime(500),
        distinctUntilChanged(),
        tap((text) => {
          this.randomSpeakingMode = false;
          this.pictureToFind = this.input.nativeElement.value;
          this.findPicture();
        }),
      )
      .subscribe();
  }
  chooseRandomPeopleSpeakingImg() {
    // this.randomSpeakingMode = true;
    // this.pictureToFind = "people speaking";
    // this.findPicture();
  }
  findPicture() {
    if (this.pictureToFind !== '') {
      this.picturesService.findPictures(this.pictureToFind, undefined);
    } else {
      this.foundPictures = [];
    }
  }
  openDialog(): void {
    this.dialog.open(this.dialogRef);
  }
  chooseImg(link?) {
    console.log(
      '🚀 ~ file: picture-finder.component.ts:147 ~ PictureFinderComponent ~ chooseImg ~ link',
      this.link,
    );
    if (link) {
      if (this.dialogRef2) {
        this.dialogRef2.close({
          bigUrl: this.link,
          smallUrl: this.link,
          iconUrl: this.link,
        });
      } else {
        this.chosenImg.emit({
          bigUrl: this.link,
          smallUrl: this.link,
          iconUrl: this.link,
        });
      }
    }
    if (!link) {
      this.chosenImg.emit(this.chosenPicture);

      console.log(
        '🚀 ~ file: picture-finder.component.ts ~ line 115 ~ PictureFinderComponent ~ chooseImg ~ this.chosenPicture',
        this.chosenPicture,
      );
      if (this.dialogRef2 && this.chosenPicture) {
        this.dialogRef2.close(this.chosenPicture);
      }
    }
  }
  ngOnDestroy(): void {
    this.picturesSub.unsubscribe();
  }
}
