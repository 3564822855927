<div class="m-3">
  <h3>Zestaw do filmu</h3>
  <mat-expansion-panel
    (opened)="resetFoundSets()"
    [expanded]="foundSets.length == 0"
  >
    <mat-expansion-panel-header>
      Wybierz zestawy do filmów (na razie tylko sety ze
      słownika)</mat-expansion-panel-header
    >
    <div
      style="
        width: 100%;
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 10px;
      "
    >
      <div
        style="
          display: flex;
          flex: 1;
          border: 3px solid #673ab7;
          border-radius: 10px;
          flex-direction: column;
          overflow-y: auto;
        "
        class="h-100"
      >
        <div class="m-3" style="display: flex; flex-direction: row; gap: 10px">
          <input
            [disabled]="searchingForSets"
            (keydown.enter)="findSets()"
            style="flex: 1; border: 2px solid #673ab7; border-radius: 5px"
            placeholder="Szukaj zestawów ze słownika po tagach (enter)"
            [(ngModel)]="searchString"
            type="text"
          />
          <button
            style="flex: 0.3"
            [disabled]="searchingForSets"
            color="primary"
            (click)="findSets()"
            mat-flat-button
          >
            <span *ngIf="!searchingForSets">Szukaj</span>
            <span *ngIf="searchingForSets">Szukam</span>
          </button>
        </div>
        <mat-list>
          <mat-list-item *ngFor="let set of allSets">
            <div class="mat-list-item-content">{{ set.name }}</div>
            <div style="flex: 1"></div>
            <div class="mat-list-item-actions">
              <button
                color="primary"
                mat-icon-button
                (click)="addSetToFind(set)"
              >
                <mat-icon>add_circle_outline</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
      <div
        style="
          display: flex;
          flex: 1;
          border: 3px solid #673ab7;
          border-radius: 10px;
          flex-direction: column;
        "
        class="h-100"
      >
        <mat-list>
          <mat-list-item *ngFor="let set of setsToFind">
            <div class="mat-list-item-content">
              {{ set.name.toUpperCase() }}
            </div>
            <div style="flex: 1"></div>
            <div class="mat-list-item-actions">
              <button
                matTooltip="Usuń zestaw z listy"
                color="accent"
                mat-icon-button
                (click)="removeSetToFind(set)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
              <button
                matTooltip="Usuń zestaw z bazy danych"
                *ngIf="set?.inDb"
                color="warn"
                mat-icon-button
                (click)="removeSetFromDb(set?.id)"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </mat-expansion-panel>
  <span class="mt-3" style="opacity: 0">asd</span>

  <hr />
  <span class="mt-3" style="opacity: 0">asd</span>
  <h3>Zawartość zestawu</h3>
  <mat-accordion *ngIf="foundSets.length > 0">
    <mat-expansion-panel [expanded]="true" *ngFor="let set of foundSets">
      <mat-expansion-panel-header> {{ set.name }} </mat-expansion-panel-header>
      <div class="d-flex flex-column">
        <app-set-item
          *ngFor="let item of set.items"
          fxFlex
          [editDescriptionAllowed]="true"
          [showDescriptions]="true"
          [showDescriptionsFlags]="true"
          [exportMode]="false"
          [checkBox]="false"
          [showAudioProperties]="true"
          [checkboxSelected]="false"
          [meaning]="item"
          >asd</app-set-item
        >
      </div>
    </mat-expansion-panel>
    <hr />
    <div class="d-flex flex-column">
      <h3>Teksty i nagrania do intro</h3>
      <div
        *ngFor="let data of additionalIntroData; let i = index"
        style="gap: 10px"
        class="d-flex flex-row justify-content-center align-items-center"
      >
        <mat-form-field style="flex: 1" appearance="outline">
          <input
            matInput
            placeholder="Tutaj wklej tekst we wszystkich językach z chata w formacie JSON"
            [(ngModel)]="data.texts"
          />
        </mat-form-field>
        <mat-form-field style="flex: 1" appearance="outline">
          <input
            matInput
            placeholder="Tutaj wklej link do nagrania (Database/audio)"
            [(ngModel)]="data.audio"
          />
        </mat-form-field>
        <button
          class="mb-3"
          mat-icon-button
          (click)="removeItemFromAdditionalData(i)"
          color="warn"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button
        (click)="addAdditionalIntroData()"
        mat-raised-button
        color="primary"
      >
        Dodaj tekst intro
      </button>
    </div>
    <div class="d-flex flex-column">
      <h3>Ciekawosstki</h3>
      <div
        *ngFor="let fact of factsData; let i = index"
        style="gap: 10px"
        class="d-flex flex-row justify-content-center align-items-center"
      >
        {{ fact?.ABCQuestionWithAnswers.English }}
        <button
          class="mb-2"
          mat-icon-button
          (click)="removeItemFromFactsData(i)"
          color="warn"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button
        style="flex: 1"
        (click)="addFactsData()"
        mat-raised-button
        color="primary"
      >
        Dodaj ciekawostkę
      </button>

      <hr />
      <h3>Utwórz film</h3>
      <div
        class="flex-row d-flex flex-wrap mt-3 align-items-center"
        style="gap: 10px"
      >
        <div *ngFor="let type of videoTypes">
          <button
            *ngIf="!showDownloader"
            mat-flat-button
            color="warn"
            (click)="createVideo(type.type)"
          >
            {{ type.displayName }}
          </button>
        </div>
      </div>
    </div>
  </mat-accordion>
  <app-video-downloader *ngIf="showDownloader"></app-video-downloader>
  <!-- <button
    [disabled]="gettingSets"
    *ngIf="setsToFind.length > 0"
    class="m-3"
    color="primary"
    (click)="getSets()"
    mat-raised-button
  >
    <div
      class="w-100 d-flex flex-row justify-content-center align-items-center"
    >
      <div style="flex: 1"></div>
      <span *ngIf="!gettingSets">Pobierz zestawy do filmów</span>
      <span *ngIf="gettingSets">Pobieram zestawy do filmów</span>
      <div *ngIf="gettingSets" class="ml-3">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <div style="flex: 1"></div>
    </div>
  </button> -->
  <div class="d-flex flex-row" style="gap: 10px">
    <button
      mat-flat-button
      color="primary"
      *ngIf="showDownloader"
      (click)="downloadWordsTranslations()"
    >
      Pobierz tłumaczenia słów
    </button>
    <button
      #downloadStoryTranslationsButton
      mat-flat-button
      *ngIf="showDownloader"
      color="primary"
      (click)="downloadStoryTranslations()"
    >
      Pobierz tłumaczenia historii
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="downloadDialogueTranslations()"
      *ngIf="showDownloader"
    >
      Pobierz tłumaczenia dialogów
    </button>
    <button
      *ngIf="showDownloader"
      mat-flat-button
      color="primary"
      (click)="downloadItemsAudioAndImages()"
    >
      Pobierz audio i obrazki zdań
    </button>
    <button
      *ngIf="showDownloader"
      mat-flat-button
      color="primary"
      (click)="downloadPolishAudio()"
    >
      Pobierz polskie audio
    </button>
    <button
      *ngIf="showDownloader"
      mat-flat-button
      color="primary"
      (click)="downloadStoriesAudiosAndImages()"
    >
      Pobierz audio i obrazki Historii
    </button>
    <button
      *ngIf="showDownloader"
      mat-flat-button
      color="primary"
      (click)="downloadDialoguesAudiosAndImages()"
    >
      Pobierz audio i obrazki dialogów
    </button>
    <button
      *ngIf="showDownloader"
      mat-flat-button
      color="primary"
      (click)="saveSetToDb()"
    >
      Zapisz zestaw
    </button>
  </div>
  <hr />
  <button mat-flat-button color="accent" (click)="testComponent()">
    test component
  </button>
</div>
