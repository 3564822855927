import { Component, OnInit } from '@angular/core';
import { TokenPayload, AuthenticationService } from '../shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
   credentials: TokenPayload = {
    email: '',
    name: '',
    password: '',
    role:''
  };

  constructor(private auth: AuthenticationService, private router: Router) {}

  register() {
    this.auth.register(this.credentials)
  }
}
