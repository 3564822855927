<h1 mat-dialog-title>Ustaw czas</h1>
<div mat-dialog-content fxLayout="column">
  <mat-form-field>
    <input [(ngModel)]="name" type="text" matInput placeholder="Nazwa">
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="selectedTime" type="time" matInput placeholder="Czas spotkania">
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="duration" type="number" matInput placeholder="Długość spotkania (min)">
  </mat-form-field>
  <mat-form-field>
    <input [(ngModel)]="description" type="text" matInput placeholder="Opis">
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-stroked-button color="primary" mat-dialog-close>Anuluj</button>
  <button  mat-flat-button color="primary" [mat-dialog-close]="selectedTime?calculateLessonTimes():null">OK</button>
</div>



