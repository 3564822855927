import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FinancesStatsService {
  teachersCosts = [
    { name: 'Adrianna Butka', value: 45 },
    { name: 'Wiktoria Zakrzewska', value: 45 },
    { name: 'Weronika Oczki', value: 45 },
    { name: 'Joanna Staniszewska', value: 45 },
    { name: 'Aleksandra Domian', value: 50 },
    { name: 'Julia Ziemka', value: 55 },
    { name: 'Agnieszka Jacek', value: 0 },
    { name: 'Krzysztof Jacek', value: 0 },
    { name: 'Karolina Frasz ', value: 55 },
    { name: 'Nicole Hojnowska ', value: 40 },
  ]
  financesData = {
    incomes: [
      { name: 'Cykliczne', value: 51500, type: 'monthly', company: true },

    ],
    expenses: [
      { name: 'Sekretarka', value: 3700, type: 'monthly', company: true },
      { name: 'Lokal Szwederowo', value: 2500, type: 'monthly', company: true },
      { name: 'Lokal', value: 1933, type: 'monthly', company: true },
      { name: 'ZUS', value: 1800, type: 'monthly', company: true },
      { name: 'Podatek', value: 3400, type: 'monthly', company: true },
      { name: 'Rachunki', value: 500, type: 'monthly', company: true },
      { name: 'Leasing', value: 2300, type: 'monthly', company: false },
      { name: 'Paliwo', value: 350, type: 'monthly', company: false },
      { name: 'Telefony', value: 250, type: 'monthly', company: true },
      { name: 'Śmieci', value: 185, type: 'monthly', company: true },
      { name: 'Księgowość', value: 500, type: 'monthly', company: true },
      { name: 'Aplikacja', value: 1000, type: 'monthly', company: true },
      { name: 'Inwestycje/Naprawy', value: 600, type: 'monthly', company: true },
      { name: 'Zdrowie', value: 770, type: 'monthly', company: true },
      { name: 'Koszty domowe', value: 5000, type: 'monthly', company: false },
      { name: 'Inne wydatki', value: 2000, type: 'monthly', company: false },





    ],

    states: [
      { name: 'Konto', value: 62000 },
      { name: 'Gotówka', value: 0 },
      { name: 'Niezapłacone', value: 19000 },
      { name: 'Strata', value: -1000 },

    ],

  }


  constructor() {

  }
}
