<div fxLayout="column" style="width: 95vw; height: calc(100vh - 48px);">
  <mat-tab-group>
    <mat-tab *ngFor="let student of students" [label]="student.name">
      <div class="mt-2">
        <mat-radio-group
          (change)="studentsGenderChanged($event, student)"
          fxLayoutGap="10px"
          [(ngModel)]="student.gender"
        >
          <mat-radio-button value="male">Chłopiec</mat-radio-button>
          <mat-radio-button value="female">Dziewczynka</mat-radio-button>
        </mat-radio-group>
        <h3>Data dołączenia: {{student.studentId | date}}</h3>
      </div>
      <app-evaluate-student
        *ngIf="showEvaluateStudentPanel"
        (resetReport)="resetReport()"
        [studentData]="student"
        [studentId]="student.studentId"
        [studentName]="student.name"
        [studentReports]="student.reports"
        [studentCustomerId]="student.customerId"
      ></app-evaluate-student>
    </mat-tab>
  </mat-tab-group>
  <button mat-stroked-button mat-dialog-close color="primary">Anuluj</button>
</div>
