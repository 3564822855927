<div class="d-flex flex-row flex-wrap" style="width: 100%; height: 100%">
  <div
    style="width: calc(100% / 5); height: calc(100% / 4)"
    *ngFor="let image of images"
  >
    <img
      [src]="image.image"
      [ngClass]="{
        colorTransition: image.colorTransition,
        colored: image.colored,
        bw: !image.colored && !image.colorTransition
      }"
      style="object-fit: cover; width: 100%; height: 100%"
    />
  </div>
</div>
