<div style="width: 100%" fxLayout="column">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <span style="font-size: 1.5em"> Raporty </span>

      <input
        class="p-2"
        style="border: 1px solid #673ab7; border-radius: 5px"
        [(ngModel)]="studentFirstName"
      />

      <button
        *ngIf="userDetailsService.owner"
        (click)="loadPresetDataFromPreviousReport()"
        mat-stroked-button
        color="primary"
      >
        Dodaj dane z poprzedniego raportu (+1)
      </button>
      <button
        *ngIf="userDetailsService.owner"
        (click)="loadPresetDataFrom2PreviousReports()"
        mat-stroked-button
        color="primary"
      >
        Załaduj dane z 2 poprzednich raportów
      </button>
    </div>
    <mat-divider class="mt-2"></mat-divider>
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <div fxFlex="19" *ngFor="let report of studentReports">
        <div
          *ngIf="report?.date"
          class="mt-2"
          style="border: 3px solid #673ab7; border-radius: 5px"
        >
          <div fxLayoutAlign="start center" fxLayout="row">
            <span class="ml-2" style="font-weight: bold">
              {{ report?.date | date }}
            </span>
            <span fxFlex></span>
            <button
              *ngIf="userDetailsService.owner"
              (click)="removeReport(report)"
              mat-icon-button
              color="warn"
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-radio-group
    (change)="setDate($event)"
    class="mt-2"
    [(ngModel)]="yearMode"
  >
    <mat-radio-button value="start">Początek roku</mat-radio-button>
    <mat-radio-button class="ml-2" value="end">Koniec roku</mat-radio-button>
  </mat-radio-group>
  <!-- <button
    (click)="openDatePickerDialog()"
    class="mt-2"
    mat-raised-button
    color="primary"
  >
    Własna data
  </button> -->

  <div></div>
  <div fxLayoutGap="15px" class="mt-3" fxLayout="row wrap">
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'reading'"
      [displayProperty]="'Czytanie'"
      [report]="report"
    ></app-evaluate-student-select>
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'listening'"
      [displayProperty]="'Słuchanie'"
      [report]="report"
    ></app-evaluate-student-select>
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'writing'"
      [displayProperty]="'Pisanie'"
      [report]="report"
    ></app-evaluate-student-select>
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'speaking'"
      [displayProperty]="'Wymowa'"
      [report]="report"
    ></app-evaluate-student-select>

    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'practicalSkills'"
      [displayProperty]="'Umiejętności praktyczne'"
      [report]="report"
    ></app-evaluate-student-select>
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'schoolSkills'"
      [displayProperty]="'Materiał szkolny'"
      [report]="report"
    ></app-evaluate-student-select>
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'activity'"
      [displayProperty]="'Aktywność'"
      [report]="report"
    ></app-evaluate-student-select>
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'attendance'"
      [displayProperty]="'Obecność'"
      [report]="report"
    ></app-evaluate-student-select>
    <app-evaluate-student-select
      [reportSent]="reportSentToday"
      (valueChanged)="setValue($event)"
      [property]="'homework'"
      [displayProperty]="'Zadanie domowe'"
      [report]="report"
    ></app-evaluate-student-select>
  </div>
  <mat-form-field appearance="outline">
    <mat-label>Komentarz Lektora</mat-label>
    <textarea
      [disabled]="reportSentToday"
      matInput
      rows="2"
      [(ngModel)]="report.teachersComment"
    ></textarea>
  </mat-form-field>
  <div>
    <angular-editor
      [config]="editorConfig"
      [placeholder]="'Wpisz treść wiadomości'"
      [(ngModel)]="html"
    ></angular-editor>
  </div>
  <div fxLayout="row" style="width: 100%" fxLayoutGap="10px" class="mt-3">
    <button
      fxFlex
      (click)="saveReport()"
      [disabled]="
        this.yearMode == 'end' ||
        reportSentToday ||
        !validateReport() ||
        this.dataFrom2PreviousReportsLoaded
      "
      class="mb-2"
      mat-flat-button
      color="primary"
    >
      Zapisz raport dla {{ studentName }}
    </button>
    <!-- <button
      fxFlex
      (click)="sendEmail(false)"
      [disabled]="reportSentToday || !validateReport() && this.yearMode !== 'end'"
      class="mb-2"
      mat-flat-button
      color="accent"
    >
      Wyślij raport do {{ studentName }}
    </button> -->
    <button
      fxFlex
      (click)="sendEmail(true)"
      [disabled]="
        this.yearMode == 'start' ||
        reportSentToday ||
        !validateReport() ||
        this.dataFrom2PreviousReportsLoaded
      "
      class="mb-2"
      mat-flat-button
      color="primary"
    >
      Wyślij raport do {{ studentName }} i zapisz
    </button>
  </div>
</div>
