<h3 style="font-weight: bold;">
  {{day==1 ? 'Poniedziałek': day ==2 ? 'Wtorek' : day==3 ? 'Środa' : day== 4 ?
  'Czwartek' : 'Piątek'}}
</h3>
<div fxLayout="row" style="width: 85%; margin-left: 15%;">
  <div>8</div>

  <div *ngFor="let time of times" fxFlex="7.69" fxLayoutAlign="end center">
    <div>{{time}}</div>
  </div>
</div>
<div
  *ngFor="let student of studentsForGroup"
  fxLayoutAlign="center center"
  fxLayout="row"
  class="mb-1"
>
  <div [matTooltip]="student?.childGrade" fxFlex="15">
    <span
      >{{student.childFirstName? student.childFirstName + '&nbsp;':
      student.adultFirstName + '&nbsp;'}}</span
    >
    <span
      >{{student.childLastName? student.childLastName + '&nbsp;':
      student.adultLastName}}</span
    >
  </div>
  <div fxLayout="column" style="width: 100%;">
    <div *ngFor="let aval of student.splitAvalibility">
      <app-schedule-add-group-bar
        *ngIf="aval[0]?.day == day && aval !== 'empty' "
        [avalibility]="aval"
        fxLayout="row"
        fxFlex
      ></app-schedule-add-group-bar>
      <!-- <div
        *ngIf="aval[0]?.day == day && aval.value == 'empty'"
        fxFlex
        style="border: 1px solid red; height: 20px;"
      ></div> -->
    </div>
  </div>
</div>
