<div
    style="width: 100%; height: 100%;"
    fxLayout="row"
    class="p-2"
    fxLayoutGap="5px"
    fxLayoutAlign="center center"
>
    <img
        height="40px"
        style="border-radius:50%"
        [src]="avatar"
        alt
     />
    <span
        [style.font-weight]="selected ? 'bold' : null"
        class="mat-small"
        style="text-align: center;"
    >{{name}}
    </span>
    <button
        (click)="addTaskClicked.emit()"
        [matTooltip]="'Dodaj zadanie dla ' + name"
        color="primary"
        mat-icon-button
    >
        <mat-icon>add_circle_outline</mat-icon>
    </button>
</div>
