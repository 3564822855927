<h2>Downloading content...</h2>
<div class="d-flex flex-column">
  <p
    *ngFor="let item of itemsDownloaded?.data"
    [style.color]="item.value ? 'green' : 'red'"
  >
    {{ item.text }}: {{ item.value ? "ok" : "pobieram" }}
  </p>
  save the downloaded properties of the story and the dialogue to the DB!
  <div
    class="d-flex flex-column"
    style="
      height: 300px;
      width: 600px;
      border: 1px solid black;
      overflow: hidden;
    "
  >
    <span *ngFor="let item of itemsDownloaded?.logs">{{ item }}</span>
  </div>
</div>
