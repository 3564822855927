<app-sub-menu
  *ngIf="!contactRequestsMode"
  [controls]="controls"
  (text)="incomingText($event)"
  (buttonClicked)="buttonClicked($event)"
  [selectOptions]="[
    { name: 'Bydgoszcz', value: 'Bydgoszcz' },
    { name: 'Białe Błota', value: 'bialeblota' },
    { name: 'Wszystko', value: 'Wszystko' }
  ]"
  [selectPlaceholder]="'Lokalizacja'"
  [inputSize]="20"
></app-sub-menu>

<mat-card class="m-3">
  <div class="table-container">
    <table
      style="width: 100%"
      multiTemplateDataRows
      mat-table
      matSort
      class="mat-elevation-z2"
      [dataSource]="dataSource"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            class="mt-2"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <ng-container sticky matColumnDef="groups">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grupy</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <div fxLayout="row wrap" style="width: 50px">
            <span
              *ngFor="let group of element.weeklyLessonsSpots"
              [class]="
                group == ''
                  ? 'disabled material-symbols-outlined'
                  : 'material-symbols-outlined'
              "
              [style.color]="
                group == 'toFill'
                  ? '#ff5722'
                  : group == 'filled'
                  ? '#4caf50'
                  : null
              "
            >
              {{
                group == "toFill"
                  ? "pending"
                  : group == "filled"
                  ? "account_circle"
                  : group == ""
                  ? "circle"
                  : "error"
              }}
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container sticky matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data dodania</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span [innerHTML]="element?.studentId | date: 'dd.MM.yyyy'"> </span>
        </td>
      </ng-container>
      <ng-container sticky matColumnDef="customerFirstName">
        <th mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          Imię Zgłaszającego
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.customerFirstName"
          >
          </span>

          <mat-form-field
            *ngIf="editMode && i == index"
            style="margin-bottom: -20px"
          >
            <input [(ngModel)]="element.name" matInput />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="customerLastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nazwisko Klienta
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.customerLastName"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="customerPhone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Telefon Klienta
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.customerPhone"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="customerEmail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          E-mail Klienta
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.customerEmail"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lokalizacja</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.location"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.location" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="childFirstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Imię Dziecka</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.childFirstName"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <mat-select [(value)]="element.role">
                <mat-option *ngFor="let role of roles" [value]="role">{{
                  role
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="childLastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nazwisko Dziecka
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.childLastName"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <mat-select [(value)]="element.role">
                <mat-option *ngFor="let role of roles" [value]="role">{{
                  role
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="childGrade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Klasa</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.childGrade"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <mat-select [(value)]="element.status">
                <mat-option *ngFor="let status of statuses" [value]="status">{{
                  status
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="childLevel">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Poziom</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.childLevel"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="contractPrice">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Cena na umowie
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.contractPrice"
          ></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="childPreviousClasses">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Zajęcia</th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.childPreviousClasses"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>

      <ng-container sticky matColumnDef="adultFirstName">
        <th mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
          Imię Słuchacza
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.adultFirstName"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.name" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="adultLastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Nazwisko Słuchacza
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.adultLastName"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="adultPhone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Telefon Słuchacza
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.adultPhone"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="adultEmail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          E-mail Słuchacza
        </th>
        <td mat-cell *matCellDef="let element; let i = dataIndex">
          <span
            *ngIf="!editMode || i !== index"
            [innerHTML]="element.adultEmail"
          ></span>
          <span *ngIf="editMode && i == index">
            <mat-form-field style="margin-bottom: -20px">
              <input [(ngModel)]="element.email" matInput />
            </mat-form-field>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <div>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <!-- <mat-icon class="md-24" *ngIf="!editMode || i!== index" (click)="onEditUserClick(i)" style="cursor: pointer;">edit</mat-icon>
       <a> <mat-icon class="md-24" *ngIf="editMode && i == index" (click)="onUserEditFinished(element)" style="cursor: pointer;">done</mat-icon></a>
       <a> <mat-icon class="md-24" color="warn" (dblclick)="onRemoveUserClick(element)" class="ml-2" style="cursor: pointer;">delete</mat-icon></a> -->
            <a>
              <mat-icon
                *ngIf="!element.isExpanded"
                style="cursor: pointer"
                class="md-24 ml-2"
                (click)="element.isExpanded = !element.isExpanded"
                >expand_more</mat-icon
              ></a
            >
            <a>
              <mat-icon
                *ngIf="element.isExpanded"
                style="cursor: pointer"
                class="md-24 ml-2"
                (click)="element.isExpanded = !element.isExpanded"
                >expand_less</mat-icon
              ></a
            >
          </td>
        </div>
      </ng-container>
      <ng-container matColumnDef="state">
        <div>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = dataIndex">
            <a>
              <mat-icon
                matTooltip="Brak dodadkowych informacji"
                *ngIf="!element.additionalData"
                class="md-24 ml-2 disabled"
                >notifications_none</mat-icon
              ></a
            >
            <a>
              <mat-icon
                matTooltip="Są dodatkowe informacje"
                *ngIf="element.additionalData"
                class="md-24 ml-2 notification-active"
                >notifications_active</mat-icon
              ></a
            >
            <a>
              <mat-icon
                matTooltip="Zapisany przez nas"
                *ngIf="element.internal && !element.continuing"
                class="md-24 ml-2"
                >add</mat-icon
              ></a
            >
            <a>
              <mat-icon
                matTooltip="Zapisał się przez stronę"
                *ngIf="!element.internal && !element.continuing"
                class="md-24 ml-2"
                >web</mat-icon
              ></a
            >
            <span
              matTooltip="Kontynuuje"
              *ngIf="element.continuing"
              class="material-symbols-outlined ml-2"
            >
              settings_accessibility
            </span>
            <span
              [matBadge]="element.avalibility?.length"
              [matBadgeColor]="
                element.status == 'decided' && !element?.avalibility[0]
                  ? 'primary'
                  : 'accent'
              "
              matTooltip="Jest dostępność"
              *ngIf="element.avalibility?.length > 0"
              class="material-symbols-outlined ml-2"
            >
              event
            </span>
            <span
              [matBadge]="element.avalibility?.length"
              matTooltip="Brak dostępności"
              *ngIf="element.avalibility?.length === 0 || !element.avalibility"
              class="material-symbols-outlined ml-2 disabled"
            >
              event
            </span>
            <a>
              <mat-icon
                matTooltip="Edytuj"
                (click)="openDialog(element)"
                class="md-24 ml-2 pointer"
                >edit</mat-icon
              ></a
            >
            <span
              style="color: #4caf50"
              matTooltip="Zdecydowany"
              *ngIf="element.status === 'decided'"
              class="material-symbols-outlined ml-2"
            >
              check
            </span>
            <span
              style="color: #f44336"
              matTooltip="Zdecydowany, problem z planem"
              *ngIf="element.status === 'decidedProblemWithSchedule'"
              class="material-symbols-outlined ml-2"
            >
              error
            </span>
            <span
              style="color: #f44336"
              matTooltip="Nie zjawił się"
              *ngIf="element.status === 'didNotShow'"
              class="material-symbols-outlined ml-2"
            >
              sync_problem
            </span>
            <span
              style="color: #ff5722"
              matTooltip="Niezdecydowany"
              *ngIf="element.status === 'undecided'"
              class="material-symbols-outlined ml-2"
            >
              psychology_alt
            </span>
            <span
              style="color: #673ab7"
              matTooltip="Zadzwoń ponownie"
              *ngIf="element.status === 'callAgain'"
              class="material-symbols-outlined ml-2"
            >
              call
            </span>
            <span
              style="color: #ff5722"
              matTooltip="Wróci później"
              *ngIf="element.status === 'willComeBackLater'"
              class="material-symbols-outlined ml-2"
            >
              update
            </span>
            <span
              style="color: #4caf50"
              matTooltip="Potwierdzenie po SMS"
              *ngIf="element.status === 'confirmedAfterSMS'"
              class="material-symbols-outlined ml-2"
            >
              mark_email_read
            </span>
            <span
              style="color: #4caf50"
              matTooltip="Umowa podpisana"
              *ngIf="element.status === 'contractSigned'"
              class="material-symbols-outlined ml-2"
            >
              receipt
            </span>
            <span
              style="color: #4caf50"
              matTooltip="Gotowy do wysłania planu"
              *ngIf="element.status === 'readyToSendSchedule'"
              class="material-symbols-outlined ml-2"
            >
              mark_email_unread
            </span>
            <span
              style="color: #673ab7"
              matTooltip="SMS wysłany"
              *ngIf="element.status === 'scheduleSent'"
              class="material-symbols-outlined ml-2"
            >
              outgoing_mail
            </span>
            <span
              style="color: #f44336"
              matTooltip="Problem po SMS"
              *ngIf="element.status === 'problemAfterSMS'"
              class="material-symbols-outlined ml-2"
            >
              unsubscribe
            </span>
            <span
              matTooltip="Brak statusu"
              *ngIf="!element.status"
              class="material-symbols-outlined ml-2 disabled"
            >
              question_mark
            </span>
            <span
              style="color: #f44336"
              matTooltip="nie kontynuuje"
              *ngIf="element.status === 'resigned'"
              class="material-symbols-outlined ml-2"
            >
              close
            </span>
            <span
              style="color: #2196f3"
              matTooltip="Przepisany z umową"
              *ngIf="element.status === 'movedToCustomersWithContract'"
              class="material-symbols-outlined ml-2"
            >
              snippet_folder
            </span>
            <span
              style="color: #2196f3"
              matTooltip="Przepisany bez umowy"
              *ngIf="element.status === 'movedToCustomersWithoutContract'"
              class="material-symbols-outlined ml-2"
            >
              drive_file_move_outline
            </span>
            <button
              matTooltip="Dodaj ucznia do grupy"
              (click)="addStudent(element)"
              *ngIf="windowMode"
              style="color: #2196f3"
              mat-icon-button
            >
              <mat-icon>add_circle_outline</mat-icon>
            </button>

            <div
              *ngIf="
                userDetailsService.getSimpleUserDetails()?.role === 'owner' ||
                userDetailsService.getSimpleUserDetails()?.role === 'office'
              "
            >
              <a>
                <mat-icon
                  (mouseenter)="changeDeleteIcon(i)"
                  *ngIf="!hover || i !== index"
                  class="md-24 ml-2 notification-warn pointer"
                  >delete_outline</mat-icon
                ></a
              >
              <a>
                <mat-icon
                  matTooltip="Usuń"
                  (dblclick)="onRemoveCustomerClick(element)"
                  style="transform: scale(1.5)"
                  (mouseleave)="hover = false"
                  *ngIf="hover && i == index"
                  class="md-24 ml-2 notification-warn pointer"
                  >delete</mat-icon
                ></a
              >
            </div>
          </td>
        </div>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="row student-element-detail mat-app-background"
            [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'"
          >
            <span mat-subheader>{{ element?.additionalData }}</span>
            <div fxFlex="100" fxLayoutAlign="row">
              <div fxFlex="50">
                <div
                  *ngFor="let item of element.testResults?.firstTask"
                  role="list"
                >
                  <mat-list fxFlex="70">
                    <mat-list-item role="listitem"
                      >{{ item.question }}
                    </mat-list-item>
                  </mat-list>

                  <mat-list fxFlex="30">
                    <mat-list-item role="listitem"
                      ><span style="font-weight: bold">{{ item.answer }}</span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>
              <div fxFlex="50">
                <div
                  *ngFor="let item of element.testResults?.secondTask"
                  role="list"
                >
                  <mat-list fxFlex="70">
                    <mat-list-item role="listitem"
                      >{{ item.question }}
                    </mat-list-item>
                  </mat-list>

                  <mat-list fxFlex="30">
                    <mat-list-item role="listitem">
                      <span style="font-weight: bold">{{ item.answer }}</span>
                    </mat-list-item>
                  </mat-list>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>
      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
      <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="student-element-row"
        [class.student-expanded-row]="element.isExpanded"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="student-detail-row"
      ></tr>
    </table>
  </div>
  <mat-paginator
    class="mat-paginator-sticky"
    class="mat-elevation-z2"
    [pageSizeOptions]="[50, 100, 200]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
