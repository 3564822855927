export class Report {
  studentId: string;
  date: Date;
  speaking: number;
  listening: number;
  reading: number;
  writing: number;
  grammar: number;
  activity: number;
  schoolSkills: number;
  homework: number;
  practicalSkills: number;
  attendance: number;
  teachersComment: string;
}
