<div style="height: 300px; overflow: hidden;">
    <div>
        <div fxLayout="column">
            <img
                (click)="openDialog()"
                height="260px"
                [src]="bigImgSource"
                alt
             />
            <div
                fxFlex
                fxLayout="row"
            >
                <div
                    fxFlex
                    class="ml-1"
                >
                    <mat-form-field fxFlex>
                        <input
                            id="input2"
                            [value]="initialImageData.tagToFind"
                            fxFlex
                            #input
                            matInput
                            placeholder="Search"
                         />
                        <mat-icon
                            class="pointer"
                            (click)="chooseImg()"
                            aria-label="label"
                            matSuffix
                        >done
                        </mat-icon>
                        <!-- <mat-icon
              class="pointer ml-2"
              (click)="chooseRandomPeopleSpeakingImg()"
              aria-label="label"
              matSuffix
              >campaign</mat-icon
            > -->
                    </mat-form-field>
                    <mat-form-field fxFlex>
                        <input
                            [(ngModel)]="link"
                            fxFlex
                            matInput
                            placeholder="Paste link"
                         />
                        <mat-icon
                            class="pointer"
                            (click)="chooseImg(true)"
                            aria-label="label"
                            matSuffix
                        >done
                        </mat-icon>
                        <!-- <mat-icon
              class="pointer ml-2"
              (click)="chooseRandomPeopleSpeakingImg()"
              aria-label="label"
              matSuffix
              >campaign</mat-icon
            > -->
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div
        fxLayout="row wrap"
        fxFlex
        style="overflow-y: auto;"
    >
        <img
            (click)="bigImgSource = picture.smallUrl; chosenPicture = picture"
            class="mt-2 ml-2"
            [src]="picture.iconUrl"
            *ngFor="let picture of foundPictures"
            style="width: 120px; height: 80px;"
         />
    </div>
</div>
<ng-template #dialogRef>
    <img
        [src]="chosenPicture?.smallUrl"
        alt
     />
</ng-template>
